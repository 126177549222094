import { createAction, props } from '@ngrx/store';
import { AircraftPlanning, AircraftPlanningUpload, UpdatePlanningActForm } from 'src/app/models/aircraft-planning';
import { Asset } from 'src/app/models/asset';
import { Family } from 'src/app/models/family';
import { Zone } from 'src/app/models/zone';

export const fetchAllAircraftPlanning = createAction('[AircraftPlanning] Fetch all', props<{ fromDate: string, toDate: string }>());
export const successFetchAllAircraftPlanning = createAction('[AircraftPlanning] Success fetch all', props<{ aircraftPlanning: Array<AircraftPlanning> }>());

export const deletePlannedMovement = createAction('[Assets] delete Planned Movement', props<{ aircraftPlanning: AircraftPlanning }>());
export const successDeletePlannedMovement = createAction('[Assets] success delete Planned Movement', props<{ payload: string }>());
export const errorDeletePlannedMovement = createAction('[Assets] Error delete Planned Movement');

export const successDeletePlannedMovementClash = createAction('[Assets] success delete Planned Movement', props<{ payload: AircraftPlanning }>());

export const createAircraftPlannings = createAction('[AircraftPlannings] create', props<{ aircraftPlanning: Array<AircraftPlanningUpload> }>());
export const successCreateAircraftPlanning = createAction('[AircraftPlanning] success create', props<{ payload: Array<AircraftPlanning> }>());
export const errorCreateAircraftPlanning = createAction('[AircraftPlanning] error create');

export const mappingSomeAircraftPlanningFamilies = createAction('[AircraftPlannings] mapping some',
    props<{ aircraftPlanning: Array<AircraftPlanning>, assets: { [id: string]: Asset }, zones: Array<Zone> }>());
// Replace prev planning lines with new ones
export const mappingSomeAircraftPlanningFamiliesForced = createAction('[AircraftPlannings] Force planning lines with imported ones',
    props<{ aircraftPlanning: Array<AircraftPlanning>, assets: { [id: string]: Asset }, zones: Array<Zone> }>());
export const mappingAllAircraftPlanningFamilyAssetZone = createAction('[AircraftPlanning] Mapping aicraft planning family',
    props<{ aircraftPlanning: Array<AircraftPlanning>, families: { [id: string]: Family }, assets: { [id: string]: Asset }, zones: Array<Zone> }>());

export const updateAircraftPlanning = createAction('[AircraftPlanning] Edit aircraft planning', props<{ payload: UpdatePlanningActForm }>());
export const successUpdateAircraftPlanning = createAction('[AircraftPlanning] Success edit aircraft planning', props<{ payload: AircraftPlanning[] }>());
export const errorUpdateAircraftPlanning = createAction('[AircraftPlanning] Error edit aircraft planning');

export const updateAircraftClash = createAction('[AircraftPlanning] Edit aircraft clash', props<{ oldPlanning: AircraftPlanning, editedPlanning: AircraftPlanning }>());
export const successUpdateAircraftClash = createAction('[AircraftPlanning] Success edit clash aircraft planning', props<{ oldPlanning: AircraftPlanning, editedPlanning: AircraftPlanning, assets: { [id: string]: Asset }, zones: Zone[] }>());

export const fixClashesAircraftPlanning = createAction('[AircraftPlanning] Fix clashes aircraft planning', props<{ payload: Array<AircraftPlanning> }>());
export const successFixClashesAircraftPlanning = createAction('[AircraftPlanning] Success fix clashes aircraft planning', props<{ payload: Array<AircraftPlanning> }>());

export const createUploadAircraftPlannings = createAction('[AircraftPlanning] create upload aircraft planning',
    props<{ uploadPlanningAPIResponse: Array<AircraftPlanning>, aircraftPlanningUpload: Array<AircraftPlanningUpload> }>());
export const successUploadAircraftPlannings = createAction('[AircraftPlanning] success upload aircraft planning',
    props<{ uploadPlanningAPIResponse: Array<AircraftPlanning>, aircraftPlanningUpload: Array<AircraftPlanningUpload> }>());
