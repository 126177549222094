import { Component } from '@angular/core';
import ChartBaseComponent from 'src/app/components/shared/kpi/chart.base';
import { Ng2ChartConfiguration } from 'src/app/components/shared/kpi/chart.model';
import { KpiFamily } from 'src/app/services/kpi.service';

@Component({
  selector: 'app-bar-family-cycle',
  templateUrl: '../../../shared/kpi/chart-card.component.html',
})
export class BarFamilyCycleComponent extends ChartBaseComponent<KpiFamily, 'bar', string> {

  public chartConfiguration: Ng2ChartConfiguration<'bar', number[], string> = {
    type: 'bar',
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      plugins: {
        title: {
          display: true,
          text: this._translate.instant('CYCLE_TIME_MONITORING'),
          position: 'top',
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
          beginAtZero: true,
        },
        y: {
          display: true,
          beginAtZero: true,
        }
      },
    },
  };

  setKpiData(): void {
    this.chartConfiguration.data = {
      labels: [this.dataKPI.familyName],
      datasets: [
        {
          label: `${this._translate.instant('MIN')} (${this._translate.instant('HOUR')})`,
          backgroundColor: '#19EAFF',
          data: [+this.dataKPI.getFamilyMinCycleTime().toFixed(2)],
          borderColor: '#19EAFF',
          borderWidth: 1,
        },
        {
          label: `${this._translate.instant('MEDIAN')} (${this._translate.instant('HOUR')})`,
          backgroundColor: '#CA6722',
          data: [+this.dataKPI.getFamilyMedianCycleTime().toFixed(2)],
          borderColor: '#CA6722',
          borderWidth: 1,
        },
        {
          label: `${this._translate.instant('MEAN')} (${this._translate.instant('HOUR')})`,
          backgroundColor: 'orange',
          data: [+this.dataKPI.getFamilyMeanCycleTime().toFixed(2)],
          borderColor: 'orange',
          borderWidth: 1,
        },
        {
          label: `${this._translate.instant('MAX')} (${this._translate.instant('HOUR')})`,
          backgroundColor: 'blue',
          data: [+this.dataKPI.getFamilyMaxCycleTime().toFixed(2)],
          borderColor: 'blue',
          borderWidth: 1,
        }
      ]
    };
  }

}
