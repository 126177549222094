import { Planning } from 'src/app/models/planning';
import { Zone } from 'src/app/models/zone';
import { Tracker } from 'src/app/models/tracker';
import { createAction, props } from '@ngrx/store';
import { AircraftPlanning } from 'src/app/models/aircraft-planning';
import { Asset } from 'src/app/models/asset';
import { ParentFamily, ParentFamilyRight } from 'src/app/models/family';
import { Certificate } from 'src/app/models/certificate';
import { UserRoleLevel } from 'src/app/models/user-right';
import { Group } from 'src/app/models/user-right';
import { Anchor } from 'src/app/models/anchor';

export const fetchAllFilters = createAction('[Filters] fetch all Filters');
export const errorFetchAllFilters = createAction('[Filters] Error Filters');
export const successFiltersMapAssets = createAction('[Filters] Success filters map assets', props<{ mapAssets: Array<Asset>, loaded?: boolean }>()); // TODO loaded = true by default
export const successFiltersAdminAssets = createAction('[Filters] Success Filters Admin Assets', props<{ assets: Array<Asset>, loaded: boolean }>());
export const successFiltersAdminFamilies = createAction('[Filters] Success Filters Admin Families', props<{ parentFamilies: Array<ParentFamily> }>());
export const successFiltersAdminDevices = createAction('[Filters] Success Filters Admin Devices', props<{ devices: Tracker[] }>());
export const successFiltersAdminZones = createAction('[Filters] Success Filters Admin Zones', props<{ zones: Zone[] }>());
export const successFiltersOversizePlanning = createAction('[Filters] Success Filters Oversize Planning', props<{ oversizePlanning: Planning[] }>());
export const successFiltersAircraftPlanning = createAction('[Filters] Success Filters Aircraft Planning', props<{ aircraftPlanning: AircraftPlanning[] }>());
export const successFiltersRequestForRights = createAction('[Filters] Success Filters Request for rights', props<{ rights: Array<ParentFamilyRight> }>());
export const successFiltersManageUserRole = createAction('[Filters] Success Filters manage user role', props<{ roles: Array<ParentFamilyRight> }>());
export const successFiltersCertificates = createAction('[Filters] Success Filters manage certificates', props<{ certificates: Array<Certificate> }>());
export const successFiltersManageFamilyUsers = createAction('[Filters] Success Filters manage familyUusers', props<{ users: Array<UserRoleLevel> }>());
export const successFiltersGroups = createAction('[Filters] Success Filters manage groups', props<{ groups: Array<Group> }>());
export const successFiltersAnchors = createAction('[Filters] Success Filters Anchors', props<{ anchors: Array<Anchor> }>());
