import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {

    constructor(
        private authService: AuthService,
        private userService: UserService,
    ) { }

    ngOnInit() {
        if (!localStorage.getItem('token') || !this.authService.isAuthenticated()) {
            return this.userService.getAuthorizationCode();
        }
    }
}
