import { Pipe, PipeTransform } from '@angular/core';
import { Zone } from '../models/zone';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable, catchError, filter, of, switchMap } from 'rxjs';
import { selectZoneById$, selectZoneState$ } from '../store/selectors/zone.selector';

@Pipe({
    name: 'zoneName'
})
export class ZoneNamePipe implements PipeTransform {

    constructor(
        private _translate: TranslateService,
        private store: Store,
    ) { }

    transform(id: Zone['id'], undefinedZoneI18n = 'UNDEFINED_LOCATION'): Observable<string> {
        return this.store.select(selectZoneState$).pipe(
            filter(({ loaded }) => loaded),
            switchMap(() => this.store.select(selectZoneById$(id))),
            catchError(() => of(null)),
            switchMap(zone => {
                if (zone?.name) {
                    return of(zone.name);
                }
                return this._translate.get(undefinedZoneI18n)
            }),
        )
    }

}
