import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import ChartBaseComponent from 'src/app/components/shared/kpi/chart.base';
import { Ng2ChartConfiguration } from 'src/app/components/shared/kpi/chart.model';
import { KpiScale } from 'src/app/models/kpiform';
import { KpiAsset, KpiFamily } from 'src/app/services/kpi.service';

enum DatasetColors {
  in = 'orange',
  out = 'blue',
}

@Component({
  selector: 'app-bar-asset-in-out',
  templateUrl: '../../../shared/kpi/chart-card-scrollable.component.html',
  styleUrls: ['../../../shared/kpi/chart-card-scrollable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarAssetInOutComponent extends ChartBaseComponent<KpiFamily, 'bar', string> implements OnChanges {

  @Input() scale: KpiScale;

  public chartConfiguration: Ng2ChartConfiguration<'bar', number[], string> = {
    type: 'bar',
    data: { datasets: [] },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      onClick: (event, item) => this.chartClicked.emit({ event, item }),
      plugins: {
        title: {
          display: true,
          position: 'top',
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          stacked: true,
          display: true,
          beginAtZero: true,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          display: true,
        },
      },
    },
  };

  private _unit: string;

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    super.ngOnChanges(changes);
    if (changes.scale) {
      if (changes.scale.currentValue !== changes.scale.previousValue) {
        this._handleScaleChange();
        this._cd.markForCheck();
      }
    }
  }

  /**
   * Handle a change of scope
   * Set the values relative to percentage or values
   */
  private _handleScaleChange() {
    this._unit = this.scale === 'percent' ? '%' : this._translate.instant('HOURS');
    this.chartConfiguration.options.scales.y.ticks = this.scale === 'percent' ? { callback(value) { return value + '%'; } } : undefined;
    if (this.dataKPI) {
      this.chartConfiguration.options.plugins.title.text = `${this._translate.instant('TIME_IN_OUT_ZONE_ASSET', { zoneName: this.dataKPI.familyInZone })} (${this._unit})`;
      this.setKpiData();
    }
  }

  /**
   * Get the percentage of time relative to all times (static + moving + unknown)
   * @param time The time value
   * @returns The percentage
   */
  private _getPercentageOfTime(time: number, asset: KpiAsset): number {
    const sum = asset.assetInTime + asset.assetOutTime;
    return +(time / sum * 100).toFixed(2);
  }

  setKpiData(): void {
    this.chartConfiguration.data = {
      labels: this.dataKPI.familyAssets.map(
        asset => asset.assetName
      ),
      datasets: [
        {
          label: `${this._translate.instant('TIME_IN_ZONE')} (${this._unit})`,
          backgroundColor: DatasetColors.in,
          data: this.dataKPI.familyAssets.map(
            asset => this.scale === 'percent' ? this._getPercentageOfTime(asset.assetInTime, asset) : asset.assetInTime
          ),
          borderColor: DatasetColors.in,
          borderWidth: 1,
        },
        {
          label: `${this._translate.instant('TIME_OUT_ZONE')} (${this._unit})`,
          backgroundColor: DatasetColors.out,
          data: this.dataKPI.familyAssets.map(
            asset => this.scale === 'percent' ? this._getPercentageOfTime(asset.assetOutTime, asset) : asset.assetOutTime
          ),
          borderColor: DatasetColors.out,
          borderWidth: 1,
        },
      ],
    };
    this.graphSize = (this.dataKPI.familyAssets.length > 30) ? `${100 * this.dataKPI.familyAssets.length}px` : 'auto';
  }

}
