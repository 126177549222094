import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'selectionLabel',
    pure: true,
})
export class SelectionLabelPipe implements PipeTransform {

    constructor(
        private readonly _translate: TranslateService,
    ) {}

    transform(value: any[], property?: string): string {
        if (!value?.length) {
            return null;
        }
        const firstSelectionValue = value[0][property] ?? value[0];
        switch (value.length) {
            case 1:
                return firstSelectionValue;
            case 2:
                return `${firstSelectionValue} (+ 1 ${this._translate.instant('OTHER').toLowerCase()})`;
            default:
                return `${firstSelectionValue} (+ ${this._translate.instant('N_OTHERS', { n: value.length }).toLowerCase()})`;
        }
    }

}
