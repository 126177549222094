<!-- Overlay if the context is not valid -->
<div *ngIf="(contextIsValid$ | async) === false"
     class="overlay">
</div>

<div class="way-component--filterable-container">

  <!-- Filter -->
  <div [appHidden]="clashMode" class="way-filter-container">
    <app-filter [parentSourceView]="view" [dataForFilter$]="aircraftPlanning$"></app-filter>
  </div>

  <!-- Header  -->
  <div class="way-component--header">

    <span class="way-component--header-title">{{ 'APP_TAB_AIRCRAFT_PLANNING' | translate }}
      <span *ngIf="clashMode"> &#47; {{ 'UPLOAD_PLANNING' | translate }} &#47; {{ 'CLASHES' | translate }}</span>
    </span>

    <form [appHidden]="clashMode" [formGroup]="dateForm">
      <mat-form-field appearance="outline" class="never-hint">
        <mat-date-range-input [rangePicker]="picker">
          <input readonly matStartDate formControlName="fromDate" placeholder="{{ 'START_DATE' | translate }}">
          <input readonly matEndDate formControlName="toDate" placeholder="{{ 'END_DATE' | translate }}"
            (dateChange)="refreshData()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </form>

    <div class="way-component--header-buttons">
      <button *ngIf="!clashMode" mat-stroked-button color="primary" (click)="refreshData()">
        <fa-icon icon="sync-alt"></fa-icon>
        {{ 'REFRESH_DATA' | translate }}
      </button>
      <button *ngIf="trackerPlanningManager && !clashMode" mat-flat-button color="primary" (click)="uploadAircraftPlanning()">
        <fa-icon icon="plus-circle"></fa-icon>
        {{ 'UPLOAD_PLANNING' | translate }}
      </button>
      <button *ngIf="trackerPlanningManager && clashMode" mat-flat-button color="primary" (click)="uploadAircraftPlanning()">
        {{ 'NEXT' | translate }}
      </button>
    </div>
  </div>

  <!-- Loader -->
  <div [appHidden]="!loader" class="overlay">
    <mat-progress-spinner class="spinner" mode="indeterminate" value="50">
    </mat-progress-spinner>
  </div>

  <!-- Table -->
  <div class="way-table--main-container">

    <!-- Live filtering on aircraft planning status type -->
    <div class="planning-type-selector">
    <mat-button-toggle-group data-color="primary"
                             value="all">
        <mat-button-toggle *ngFor="let count of aircraftPlanningCount | appPlanningCountKeyvalue"
                           [value]="count.key"
                           class="planning-type-selector-item"
                           (click)="liveFilterAircraftPlanning(count.key)">
          <span class="title">{{ count.key | uppercase | translate }}</span>
          &nbsp;
          <span class="number-{{ count.key }}">{{ count.value }}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="table-container mat-elevation-z8 custom-scrollbar">

      <table class="way-table" [attr.aria-label]="'ARIA_AIRCRAFT_PLANNING_TABLE' | translate" #table mat-table matSort
        [dataSource]="dataSource">

        <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns; let colIndex = index">

          <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header
            [disabled]="col === 'actions' || col === 'status'">
            <span *ngIf="col !== 'actions'">{{ col | uppercase | translate }}</span>
          </th>

          <td mat-cell *matCellDef="let element">

            <!-- Status columns -->
            <ng-container *ngIf="col === 'status'">
                <p [ngClass]="{
                       'green': element.status?.type?.toLowerCase() === 'success',
                       'warn': element.status?.type?.toLowerCase() === 'error',
                       'orange': element.clashes?.length
                   }"
                   class="planning-status">
                <fa-icon *ngIf="element.status?.type?.toLowerCase() === 'success'" icon="check"></fa-icon>
                <fa-icon *ngIf="element.status?.type?.toLowerCase() === 'error'" icon="exclamation-triangle"></fa-icon>
                {{ element[displayedColumns[colIndex]]?.message | translate }}
                <span *ngFor="let message of messagesClashes[element.id]">
                  <p>
                    <fa-icon icon="exclamation-triangle"></fa-icon>
                    {{message}}
                  </p>
                </span>
              </p>
            </ng-container>

            <!-- Date columns -->
            <ng-container *ngIf="col === 'arrivalTime'">
              <p>{{ (element[displayedColumns[colIndex]] | typeOf) === 'date' ? (element[displayedColumns[colIndex]] | moment:'L LT') : element[displayedColumns[colIndex]] }}</p>
            </ng-container>

            <!-- Zone columns -->
            <ng-container *ngIf="col === 'currentPosition' || col === 'futurePosition'">
              <p>{{ element[displayedColumns[colIndex]]?.name }}</p>
            </ng-container>

            <!-- Normal columns -->
            <ng-container
              *ngIf="col !== 'actions' && col !== 'arrivalTime' && col !== 'status' && col !== 'currentPosition' && col !== 'futurePosition'">
              <p>{{ element[displayedColumns[colIndex]] }}</p>
            </ng-container>

            <!-- Actions columns -->
            <ng-container *ngIf="col === 'actions'">
              <div class="action-column">
                <button mat-icon-button [matMenuTriggerFor]="actionMenu" (click)="$event.stopPropagation()">
                  <fa-icon icon="ellipsis-v"></fa-icon>
                </button>
                <mat-menu #actionMenu="matMenu" xPosition="before">
                  <button mat-menu-item focusable="false"
                    [disabled]="!trackerPlanningManager || moment(element.arrivalTime).isBefore(moment(), 'day')"
                    (click)="openEditDialog(element)">
                    <fa-icon icon="pencil-alt"
                             [fixedWidth]="true"></fa-icon>
                        {{ 'EDIT' | translate }}
                  </button>
                  <button class="warn" mat-menu-item focusable="false" [disabled]="!trackerPlanningManager"
                    (click)="deleteEditDialog(element)">
                    <fa-icon icon="trash"
                             [fixedWidth]="true"></fa-icon>
                        {{ 'DELETE' | translate }}
                  </button>
                </mat-menu>
              </div>
            </ng-container>

          </td>

        </ng-container>

        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="table-row"></tr>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky : true"></tr>

      </table>

    </div>

    <mat-paginator class="way-table--footer" (page)="scrollUp()" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>

  </div>

</div>
