import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';
import { AlertService } from 'src/app/services/alert.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { createAlert, createAlertError, createAlertSuccess, editAlert, editAlertError, editAlertSuccess, fetchAllAlerts, successFetchAllAlerts } from '../actions/alert.actions';
import { Alert } from 'src/app/models/alert';

export type EffectResult = 'success' | 'error';

@Injectable()
export class AlertEffects {

    public fetchAllAlerts$ = createEffect(() => { return this._actions$.pipe(
        ofType(fetchAllAlerts),
        switchMap(() => this._alertService.getAlerts()),
        map((alerts: Array<Alert>) => successFetchAllAlerts({ payload: alerts.map(
          ({ zone, ...alert }) => ({
            ...alert,
            zone: typeof zone === 'object' && Object.keys(zone).length > 0 ? zone : undefined,
          })
        ) }))
    ) });

    public CreateAlert$ = createEffect(() => { return this._actions$.pipe(
        ofType(createAlert),
        switchMap((actions) => this._alertService.createAlert(actions.payload).pipe(
            map((new_alert: Alert) => {
                const text = this._translate.instant('BANNER_SUCCESS_CREATE', { item: new_alert.name });
                this._snackBar.open(text, 'green-snackbar', 5000);
                this.effectSubject.next('success');
                return createAlertSuccess({ payload: new_alert });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
                this.effectSubject.next('error');
                return of(createAlertError());
            }))
        )) });

    public EditAlert$ = createEffect(() => { return this._actions$.pipe(
        ofType(editAlert),
        switchMap((actions) => this._alertService.updateAlert(actions.payload).pipe(
            map((alert_updated: Alert) => {
                const text: string = this._translate.instant('BANNER_SUCCESS_EDIT', { value: alert_updated.name });
                this._snackBar.open(text, 'green-snackbar', 5000);
                this.effectSubject.next('success');
                return editAlertSuccess({ payload: alert_updated });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_EDIT');
                this.effectSubject.next('error');
                return of(editAlertError());
            }))
        )) });

    public effectSubject: Subject<EffectResult>;
    constructor(
        private _alertService: AlertService,
        private _actions$: Actions,
        private _snackBar: SnackbarComponent,
        private _translate: TranslateService,
        private _errorTranslationService: ErrorTranslationService,
    ) {
        this.effectSubject = new Subject<EffectResult>();
    }
}
