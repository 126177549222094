import { inflate } from 'pako';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Zone } from '../models/zone';
import { AircraftPlanning, AircraftPlanningUpload, UpdatePlanningActForm, UploadPlanningAPIResponse } from '../models/aircraft-planning';
import { ApiBuffer } from '../models/utils';

@Injectable({
    providedIn: 'root'
})
export class AircraftPlanningService {
    constructor(private _http: HttpClient) { }

    /**
     * Get the planning data from API
     * @param fromDate The date from when we want to fetch the planning
     * @param toDate The date to when we want to fetch the planning
     */
    public getAircraftPlanning(fromDate: string, toDate: string): Observable<Array<AircraftPlanning>> {
        const httpParams = new HttpParams()
            .append('fromDate', fromDate)
            .append('toDate', toDate);
        return this._http.get<ApiBuffer>(`${environment.API_ENDPOINT}/scopes/act/plannings`, { params: httpParams })
            .pipe<Array<AircraftPlanning>>(
                map(d => JSON.parse(inflate(d['data'], { to: 'string' })))
            );
    }

    /**
     * Edit an aircraft planning line calling API
     * @param planning The planning line to edit
     */
    public editAircraftPlanning(planning: UpdatePlanningActForm): Observable<AircraftPlanning[]> {
        return this._http.put<AircraftPlanning[]>(
            `${environment.API_ENDPOINT}/scopes/act/plannings/${planning.id}`,
            planning
        );
    }

    /**
     * Get all zone from API
     */
    public getAllZones(): Observable<Zone[]> {
        return this._http.get<Zone[]>(
            `${environment.API_ENDPOINT}/zones`
        );
    }

    /**
     * Delete a planning movement
     * @param aircraftPlanning The planning line to delete
     */
    public deleteAircraftPlanning(aircraftPlanning: AircraftPlanning): Observable<AircraftPlanning[]> {
        return this._http.delete<AircraftPlanning[]>(
            `${environment.API_ENDPOINT}/scopes/act/plannings/${aircraftPlanning.id}`,
        );
    }

    /**
     * Upload act planning
     * @param planning The planning to upload
     * @param submit True if the planning should be saved in database, else it will be just checked
     */
    public uploadAircraftPlanning(planning: AircraftPlanningUpload[], submit = true): Observable<UploadPlanningAPIResponse> {
        const params = new HttpParams().append('submit', submit ? 'true' : 'false');
        return this._http.post<UploadPlanningAPIResponse>(`${environment.API_ENDPOINT}/scopes/act/plannings`, planning, { params });
    }
}
