import { Certificate } from 'src/app/models/certificate';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CertificateService {

    constructor(private http: HttpClient) { }

    /**
     * Get the certificates requests advancement
     */
    public getCertificates(): Observable<Certificate[]> {
        return this.http.get<Certificate[]>(`${environment.API_ENDPOINT}/machines`);
    }

    /**
     * Create a device certificate request
     * @param certificateRequests An array of certificate to create
     */
    public createCertificateRequest(certificateRequests: Array<Certificate>): Observable<Certificate[]> {
        return this.http.post<Certificate[]>(`${environment.API_ENDPOINT}/machines`, certificateRequests);
    }

    /**
     * Update a device certificate request
     * @param certificateRequest The certificate to update
     */
    public updateCertificaterequest(certificateRequest: Certificate): Observable<Certificate> {
        return this.http.put<Certificate>(`${environment.API_ENDPOINT}/machines/${certificateRequest.id}`, certificateRequest);
    }

    /**
     * Delete a device certificate request
     * @param certificateRequestId The id of the certificate request to delete
     */
    public deleteCertificatesRequest(certificateRequestId: string): Observable<Certificate> {
        return this.http.delete<Certificate>(`${environment.API_ENDPOINT}/machines/${certificateRequestId}`);
    }

    /**
     * Renew a device certificate request
     * @param certificateRequestId The id of the device to renew certificate on
     */
    public renewCertificatesRequest(certificateRequestId: Certificate): Observable<Array<Certificate>> {
        return this.http.post<Array<Certificate>>(`${environment.API_ENDPOINT}/machines/renew-certificate`, [{ machineId: certificateRequestId.machineId }]);
    }

    /**
     * Accept a certificate request coming from mobile
     * @param certificateRequests Array containing the ids of certificates to accept
     */
    public acceptCertificateRequest(certificateRequests: Array<{ machineId: string }>): Observable<Array<Certificate>> {
        return this.http.post<Array<Certificate>>(`${environment.API_ENDPOINT}/machines/accept`, certificateRequests);
    }

    /**
     * Refuse a certificate request coming from mobile
     * @param certificateRequests Array containing the ids of certificates to refuse
     */
    public refuseCertificateRequest(certificateRequests: Array<{ machineId: string }>): Observable<Array<Certificate>> {
        return this.http.post<Array<Certificate>>(`${environment.API_ENDPOINT}/machines/decline`, certificateRequests);
    }

    /**
     * Cancel the deletion of a device certificate
     * @param id The device id to cancel certificate deletion on
     */
    public cancelCertificateDeletion(id: string): Observable<Certificate> {
        return this.http.put<Certificate>(`${environment.API_ENDPOINT}/machines/${id}/cancel-machine-deletion`, {});
    }

    /**
     * Reinstallation of a device certificate
     * @param id The device id to reinstall certificate
     */
    public reinstallationCertificate(id: string): Observable<Certificate> {
        return this.http.put<Certificate>(`${environment.API_ENDPOINT}/machines/${id}/status-available`, {});
    }

    /**
     * Send the TOTP code to authorize certificate download
     * @param id The device id to reinstall certificate
     * @param totp The TOTP code
     */
    public sendCertificateTotp(id: string, totp: string): Observable<Certificate> {
        return this.http.post<Certificate>(`${environment.API_ENDPOINT}/machines/${id}/totp`, { totp });
    }

    /**
     * Get the number of remaining tries for TOTP code
     * @param id The device id to reinstall certificate
     */
    public getCertificateTotpRemainingTries(id: string): Observable<number> {
        return this.http.get<{ message: number }>(`${environment.API_ENDPOINT}/machines/${id}/totp/tries`).pipe(
            map(({ message }) => message),
        );
    }
}
