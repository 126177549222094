export interface Certificate {
    id?: string;
    machineId: string;
    mail?: string;
    company?: string;
    phoneModel?: string;
    phoneNum?: string;
    scope?: Array<string>;
    validatedBy?: string;
    validatedAt?: string;
    status?: MachineStatusProgress;
    newStatus?: MachineStatusProgress;
    certificateExpirationDate?: string;
    newCertificateExpirationDate?: string;
    lastConnexion?: string;
    contactMail?: string;
    contactName?: string;
    contactPhoneNum?: string;
    delay?: number; // Front only property
    focalPoint?: {
        name: string;
        email: string;
        phoneNumber: string;
    };
}

export interface MachineStatusProgress {
    status1?: MachineStatus;
    status2?: MachineStatus;
    status3?: MachineStatus;
    status4?: MachineStatus;
}

export interface CreateCertificateRequest {
    machineId: string;
    company: string;
    contactMail: string;
    contactName: string;
    contactPhoneNum: string;
    phoneNum: string;
    phoneModel: string;
}

export enum MachineStatus {
    InProgress = 'in_progress',
    Done = 'done',
    Pending = 'pending',
    OnHold = 'on_hold',
    Empty = '',
    Rejected = 'rejected',
    Deleted = 'deleted',
    Validated = 'validated',
    Activated = 'activated',
    Expirated = 'expirated'
}

export interface CertificateTotp {
    code: string;
    expires: Date;
}
