import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MachineStatusProgress } from 'src/app/models/certificate';

@Component({
    selector: 'app-dialog-detailed',
    templateUrl: './dialog-detailed.component.html',
    styleUrls: ['./dialog-detailed.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogCertificateStatusProgressDetailedComponent {

    public progress: MachineStatusProgress & { expiration: string } = inject(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef);

}
