export enum ObjectStore {
    Assets = 'assets',
    LastUpdatedTime = 'lastUpdatedTime',
    ExpiredBy = 'expiredBy',
    ParentFamilies = 'parentFamilies',
    Families = 'families',
    FamilyRight = 'familyRight',
    Company = 'company',
    Sites = 'sites',
    Zone = 'zone',
}

export interface Cache {
    isExpired: boolean;
    lastRefreshTime?: { [key: string]: string };
}

export type CacheEntry = {
    [key in ObjectStore]?: CacheItem;
};

export interface CacheItem {
    lastRefresh?: string;
    isExpired: boolean;
}

export interface CacheDeltaResponse<T> {
    createdData?: T[];
    updatedData: T[];
    deletedData: T[];

}
