import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { selectProfileAssetCount$, selectProfileContext$ } from './profile.selectors';
import { MAX_ASSET_COUNT } from 'src/app/services/init-filter.service';

export const selectContextState = (state: AppState) => state.initFilters;

export const selectContextsState$ = createSelector(selectContextState, (state) => state);
export const selectContextsLoading$ = createSelector(selectContextState, (state) => !state.userFilterLoaded);

export const selectDataForContextLoading$ = createSelector(selectContextState, (state) => state.contextDataLoading);

export const selectDataForContexts$ = createSelector(selectContextState, (state) => state.data.dataForContexts);

export const selectCompaniesForContexts$ = createSelector(selectContextState, (state) => state.data.dataForContexts.companies);
export const selectSitesForContexts$ = createSelector(selectContextState, (state) => state.data.dataForContexts.sites);
export const selectParentFamiliesForContexts$ = createSelector(selectContextState, (state) => state.data.dataForContexts.parentFamilies);
export const selectFamiliesForContexts$ = createSelector(selectContextState, (state) => state.data.dataForContexts.families);
export const selectProvidersForContexts$ = createSelector(selectContextState, (state) => state.data.dataForContexts.providers);

export const selectUserSavedContexts$ = createSelector(selectContextState, (state) => state.data.userContexts.filter(
    ({ id }) => !!id
));

export const selectUserFavoriteContexts$ = createSelector(selectContextState, (state) => state.data.userContexts.filter(
    ({ favorite }) => favorite
));

export const selectUserSessionContexts$ = createSelector(selectContextState, (state) => state.data.userContexts.filter(
    ({ id }) => !id
));

export const selectUserSelectedContext$ = createSelector(selectContextState, selectProfileContext$, (state, context) => state.data.userContexts?.find(
    ({ id }) => id === context
));

export const contextIsValid$ = createSelector(
    selectProfileAssetCount$,
    (count) => count <= MAX_ASSET_COUNT
);
