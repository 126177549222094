import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Banner, BannerService } from '../../../services/banner.service';

@Component({
    selector: 'app-banner',
    template: `
    <div *ngFor="let banner of banners$ | async"
         class="banner {{ banner.type }}">
        <div>
            <ng-container [ngSwitch]="banner.type">
                <mat-icon *ngSwitchCase="'info'">info</mat-icon>
                <mat-icon *ngSwitchCase="'warning'">warning</mat-icon>
                <mat-icon *ngSwitchCase="'error'">error</mat-icon>
                <mat-icon *ngSwitchCase="'success'">check_circle</mat-icon>
            </ng-container>
            <span class=title>{{ banner.message }}</span>
            <button mat-button
                    *ngIf="banner.action"
                    (click)="handleAction(banner.action)">
                {{ banner.action.text }}
            </button>
        </div>
        <button mat-icon-button
                (click)="dismiss(banner)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
  `,
    styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {

    public banners$: Observable<Banner[]>;

    constructor(
        private _bannerService: BannerService,
    ) {
        this.banners$ = this._bannerService.banners$;
    }

    public handleAction(action: Banner['action']) {
        action.callback();
    }

    public dismiss(banner: Banner) {
        this._bannerService.dismiss(banner);
    }

}
