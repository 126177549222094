<div mat-dialog-title>
    <span>{{"MANAGE_CONTEXT" | translate}}</span>
    <button *ngIf="canCloseDialog$ | async"
            mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="content">
        <div class="search-form">
            <mat-form-field class="full-width"
                            appearance="outline">
                <mat-label>{{ "SEARCH" | translate }}</mat-label>
                <input matInput
                       type="text"
                       [formControl]="searchFormField">
                <mat-icon matPrefix>search</mat-icon>
                <mat-hint>{{ "SEARCH_HINT" | translate }}</mat-hint>
            </mat-form-field>
        </div>
        <header class="context-header">
            <span>{{ "YOUR_CONTEXTS" | translate}}</span>
            <button mat-flat-button
                    color="primary"
                    (click)="openCreateContext()"
                    [matTooltip]="'CREATE_NEW_CONTEXT' | translate">
                <fa-icon icon="plus"></fa-icon>
                {{ "CREATE" | translate }}
            </button>
        </header>
        <span *ngIf="(warningTooManyFavorites$ | async) === true"
              class="subheader warning">
            {{ "CONTEXT_FAVORITES_OVERLAP_LIMIT" | translate:{limit:5} }}
        </span>

        <div class="context-list-container"
             [ngStyle]="{
                'height.px': scrollContainerHeight
            }">
            <cdk-virtual-scroll-viewport #scrollViewport
                                         itemSize="56"
                                         class="context-list">
                <mat-list-item role="listitem"
                               *ngFor="let context of userContexts$
                                        | async
                                        | sort:false'name'">
                    <div mat-line
                         class="context-line"
                         (click)="!context.defaultContext && onApply(context.id)"
                         [ngClass]="{
                          'active': context.defaultContext,
                          'invalid': context.invalid
                         }">
                        <button mat-icon-button
                                [matTooltip]="context.defaultContext ?
                                    ('ACTIVE_CONTEXT' | translate:{context: context.name}) :
                                    ('INACTIVE_CONTEXT' | translate)">
                            <mat-icon>
                                {{ context.defaultContext ? 'radio_button_checked' : 'radio_button_unchecked' }}
                            </mat-icon>
                        </button>
                        <span>
                            {{context.name}}
                        </span>
                        <div class="actions">
                            <mat-icon *ngIf="context.invalid"
                                    color="warn"
                                    [matTooltip]="'INVALID_CONTEXT' | translate">
                                error
                            </mat-icon>
                            <button mat-icon-button
                                    [matTooltip]="(context.favorite ? 'FAVORITE_REMOVE' : 'FAVORITE_ADD') | translate"
                                    [disabled]="disabledFavoritesButtons[context.id] === true"
                                    (click)="toggleFavorite(context, $event)">
                                <mat-icon class="favorite-icon"
                                        [ngClass]="{'active': context.favorite}">
                                    {{ context.favorite ? 'star' : 'star_border' }}
                                </mat-icon>
                            </button>
                            <button mat-icon-button
                                    [matTooltip]="'EDIT_CONTEXT' | translate"
                                    (click)="openEditContext(context, $event)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button
                                    [matTooltip]="'DELETE_CONTEXT' | translate"
                                    [disabled]="context.defaultContext"
                                    (click)="openDeleteDialog(context, $event)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-item>
            </cdk-virtual-scroll-viewport>
            <div *ngIf="loading"
                 class="loader">
                <mat-progress-spinner mode="indeterminate"
                                      diameter="32"></mat-progress-spinner>
            </div>
        </div>
    </div>
</mat-dialog-content>
