<div mat-dialog-title>
    <span>{{ "MANAGE_SPECIFIC_ROLE" | translate }}</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<form class="form-container"
      (submit)="onClickUpdate()"
      [formGroup]="specificRoleForm">

    <div mat-dialog-content>
        <div class="role-container"
             *ngFor="let specificRole of allSpecificRole">
            <mat-slide-toggle color="primary"
                              [formControlName]="specificRole.id">
                {{ specificRole.name | translate }}
            </mat-slide-toggle>
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-stroked-button
                mat-dialog-close
                type="button"
                color="primary">
            {{"CANCEL" | translate}}
        </button>
        <button mat-flat-button
                color="primary"
                type="submit"
                [disabled]="checkSameProperty()">
            {{"UPDATE" | translate}}
        </button>
    </div>

</form>
