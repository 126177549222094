import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { KpiFamily } from 'src/app/services/kpi.service';
import { KpiScale } from 'src/app/models/kpiform';
import { ChartClickEvent } from '../../shared/kpi/chart.base';

@Component({
  selector: 'app-kpi-static-time-monitoring',
  templateUrl: './kpi-static-time-monitoring.component.html',
  styleUrls: ['./kpi-static-time-monitoring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiStaticTimeMonitoringComponent {
  @Input() public dataKPIstatic: KpiFamily;
  @Input() public loaded: boolean;

  public selectedScale: KpiScale = 'percent';
  public indexAssetClicked?: number;

  constructor(
    private _cd: ChangeDetectorRef,
  ) { }

  public onScaleChange(event: MatButtonToggleChange): void {
    this.selectedScale = event.value;
    this._cd.detectChanges();
  }

  public onAssetChartClicked({ item }: ChartClickEvent): void {
    if (item[0] !== undefined) {
      this.indexAssetClicked = item[0].index;
      this._cd.detectChanges();
    }
  }

}
