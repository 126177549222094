import { createAction, props } from '@ngrx/store';
import { Anchor, UpdateAnchor } from 'src/app/models/anchor';

export const fetchAllAnchors = createAction('[anchor] Load Admin anchor');
export const successFetchAllAnchors = createAction('[anchor] Success Admin Anchors', props<{ anchor: Anchor[] }>());
export const errorFetchAllAnchors = createAction('[anchor] Error Admin Anchors');

export const updateAnchor = createAction('[anchor] Update Anchor', props<{ payload: UpdateAnchor, id: Anchor['id'] }>());
export const updateAnchorSuccess = createAction('[anchor] Success Update Anchor', props<{ payload: Anchor }>());
export const updateAnchortError = createAction('[anchor] Error Update Anchor');

