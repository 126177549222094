import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActiveDataPoint } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import ChartBaseComponent from 'src/app/components/shared/kpi/chart.base';
import { Ng2ChartConfiguration } from 'src/app/components/shared/kpi/chart.model';
import { KpiFamily } from 'src/app/services/kpi.service';

@Component({
  selector: 'app-trend-zone-cycle',
  templateUrl: '../../../shared/kpi/chart-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendZoneCycleComponent extends ChartBaseComponent<KpiFamily, 'bar', string> implements OnChanges {

  @Input() selectedAssetIndex: number;
  @Input() selectedCycleIndex: number;
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  public chartConfiguration: Ng2ChartConfiguration<'bar', number[], string> = {
    type: 'bar',
    data: { datasets: [] },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      onClick: (event, items) => this._onClick(items),
      plugins: {
        title: {
          display: true,
          text: this._translate.instant('CYCLE_TIME_MONITORING'),
          position: 'top',
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            filter: (item) => !item.text.includes(this._translate.instant('HIDDEN_ZONE')),
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      interaction: {
        intersect: false,
        mode: 'nearest',
      },
      scales: {
        x: {
          stacked: true,
          display: true,
          beginAtZero: true,
        },
        y: {
          stacked: true,
          display: true,
          beginAtZero: false,
        },
      },
    },
  };

  private _excludedZones: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    const skip = super.ngOnChanges(changes);
    if (skip) {
      return;
    }
    if (changes.selectedCycleIndex) {
      if (changes.selectedCycleIndex.currentValue !== changes.selectedCycleIndex.previousValue) {
        this._excludedZones = [];
        this.setKpiData();
        this._cd.markForCheck();
      }
    } else if (changes.selectedAssetIndex) {
      if (changes.selectedAssetIndex.currentValue !== changes.selectedAssetIndex.previousValue) {
        this._excludedZones = [];
        this.setKpiData();
        this._cd.markForCheck();
      }
    }
  }

  setKpiData(): void {
    if (this.selectedAssetIndex === undefined || this.selectedAssetIndex === null) {
      return;
    }
    if (this.selectedCycleIndex === undefined || this.selectedCycleIndex === null) {
      return;
    }
    const zones = this.dataKPI.familyAssets[this.selectedAssetIndex].assetCycles[this.selectedCycleIndex].cycleZones;
    const assetName = this.dataKPI.familyAssets[this.selectedAssetIndex].assetName;
    this.chartConfiguration.data = {
      labels: [
        `${this._translate.instant('ASSET')}: ${assetName} ${this._translate.instant('LOOP')}: ${this.selectedCycleIndex + 1}`,
      ],
      datasets: zones.map(
        (zone, index) => this._commonKPIService.getDatasetAndColour(zone.zoneName, [{
          y: +zone.zoneTimePassedIn.toFixed(2),
          label: this._translate.instant('TIME_IN_ZONE'),
          name: zone.zoneName,
        }], index, -1)
      )
    };
    this.chartConfiguration.options.plugins.title.text = `${this._translate.instant('CYCLE_TIME_MONITORING')} (${this._translate.instant('ASSET')}: ${assetName} ${this._translate.instant('LOOP')}:  ${this.selectedCycleIndex + 1} ${this._translate.instant('LEVEL')} 1)`;
    this.chart?.update();
  }

  /**
   * Triggered when an item is clicked
   * @param items The selected items
   */
  private _onClick(items: ActiveDataPoint[]) {
    const activePoint = items?.[0];
    if (!activePoint) {
      // Avoid errors when you do not click on the graph
      return;
    }
    const OTHERS = this._translate.instant('others');
    const datasetIndex = activePoint.datasetIndex;
    const label = this.chartConfiguration.data.datasets[datasetIndex].label;
    const value = this.chartConfiguration.data.datasets[datasetIndex].data.reduce(
      (sum, v) => sum + v, 0
    );
    // This onClick works for areas except Others
    if (label !== OTHERS) {
      this._addAsOther(label, value);
      this.chartConfiguration.data.datasets.splice(datasetIndex, 1);
      this.chart?.update();
      this._cd.markForCheck();
    }
  }

  /**
   * Add a zone as "Others" (ie. exclude)
   * @param label The label
   * @param value The time
   */
  private _addAsOther(label: string, value: number) {
    const OTHERS = this._translate.instant('others');
    this._excludedZones.push(label);
    const datasetOtherIndex = this.chartConfiguration.data.datasets.findIndex(
      (dataset) => dataset.label === OTHERS
    );
    // If Others does not exist, it must be added to the dataset of the graph
    if (datasetOtherIndex === -1) {
      this.chartConfiguration.data.datasets.push({
        data: [value],
        label: OTHERS,
        backgroundColor: 'black',
        borderColor: 'black',
        borderWidth: 1,
      });
    } else {
      this.chartConfiguration.data.datasets[datasetOtherIndex].data[0] += value;
    }
  }

}
