<div class="linear-steps {{direction}}">
    <app-status-step [status]="progress.status1"
                     statusText="{{'STATUS1' | translate}}: {{progress.status1 | uppercase | translate}}"></app-status-step>
    <app-status-separator [previousStatus]="progress.status1"
                          [nextStatus]="progress.status2"></app-status-separator>
    <app-status-step [status]="progress.status2"
                     statusText="{{'STATUS2' | translate}}: {{progress.status2 | uppercase | translate}}"></app-status-step>
    <app-status-separator [previousStatus]="progress.status2"
                          [nextStatus]="progress.status3"></app-status-separator>
    <app-status-step [status]="progress.status3"
                     statusText="{{'STATUS3' | translate}}: {{progress.status3 | uppercase | translate}}"></app-status-step>
    <app-status-separator [previousStatus]="progress.status3"
                          [nextStatus]="progress.status4"></app-status-separator>
    <app-status-step [status]="progress.status4"
                     statusText="{{'STATUS4' | translate}}: {{progress.status4 | uppercase | translate}}"></app-status-step>
</div>
