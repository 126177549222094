import { ErrorTranslationService } from './../../../services/error-translation.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject, Subscription, interval } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, skipUntil, startWith } from 'rxjs/operators';
import { DecodedToken } from 'src/app/models/user-right';
import { ConfigurationStorageKeys, LocalStorageService } from 'src/app/services/local-storage.service';
import { SnackbarComponent } from '../snackbar/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { Language, LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-profile-card',
    templateUrl: './profile-card.component.html',
    styleUrls: ['./profile-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileCardComponent implements OnInit, OnDestroy {

    public readonly LANGUAGES = this._languageService.LANGUAGES;

    public readonly DATE_FORMATS = this._languageService.DATE_FORMATS;

    public readonly NOW$ = interval(1000).pipe(
        map(() => new Date()),
        startWith(new Date),
    );

    public settingsForm: FormGroup<{
        language: FormControl<Language>;
        dateFormat: FormControl<Language>;
    }> = this._fb.group({
        language: this._fb.control(this._translate.getDefaultLang() as Language),
        dateFormat: this._fb.control(this._translate.getDefaultLang() as Language),
    });
    public ergonomyForm: FormGroup<{
        wcagSFI: FormControl<boolean>;
    }> = this._fb.group({
        wcagSFI: this._fb.control(null),
    });

    private _subscription = new Subscription();

    public decodedToken: DecodedToken;

    private _localStorageFetched$ = new Subject<void>();

    constructor(
        private helper: JwtHelperService,
        private _dialogRef: MatDialogRef<ProfileCardComponent>,
        private _localStorageService: LocalStorageService,
        private readonly _fb: FormBuilder,
        private readonly _snackBar: SnackbarComponent,
        private readonly _translate: TranslateService,
        private readonly _errorTranslationService: ErrorTranslationService,
        private readonly _cd: ChangeDetectorRef,
        private readonly _languageService: LanguageService,
    ) {
    }

    ngOnInit(): void {
        this._initUserSettingsForm();
        this._initErgonomySettingsForm();
        this._resetForms();
    }

    private _initUserSettingsForm(): void {
        this._subscription.add(
            this.settingsForm.controls.language.valueChanges.pipe(
                skipUntil(this._localStorageFetched$),
                distinctUntilChanged(),
            ).subscribe(
                language => {
                    this._languageService.selectedLanguage = language;
                    this._snackBar.open(this._translate.instant('SAVED'));
                }
            )
        );
        this._subscription.add(
            this.settingsForm.controls.dateFormat.valueChanges.pipe(
                skipUntil(this._localStorageFetched$),
                distinctUntilChanged(),
            ).subscribe(
                dateFormat => {
                    this._languageService.selectedDateFormat = dateFormat;
                    this._snackBar.open(this._translate.instant('SAVED'));
                }
            )
        );
    }

    private _initErgonomySettingsForm(): void {
        this._subscription.add(
            this.ergonomyForm.controls.wcagSFI.valueChanges.pipe(
                skipUntil(this._localStorageFetched$),
                distinctUntilChanged(),
            ).subscribe(
                wcagSFI => {
                    this._localStorageService.store$(ConfigurationStorageKeys.wcagSFI, JSON.stringify(wcagSFI)).subscribe({
                        next: () => this._snackBar.open(this._translate.instant('SAVED')),
                        error: (error) => this._errorTranslationService.handleError(error, this._translate.instant('BANNER_FAIL_EDIT')),
                    });
                }
            )
        );
    }

    private _resetForms() {
        this._subscription.add(
            this._localStorageService.storageValue$.pipe(
                filter(({ key }) => [
                    ConfigurationStorageKeys.dateFormat,
                    ConfigurationStorageKeys.language,
                    ConfigurationStorageKeys.wcagSFI,
                    'token',
                ].includes(key)),
            ).subscribe(
                ({ key, value }) => {
                    switch (key) {
                        case ConfigurationStorageKeys.dateFormat:
                            this.settingsForm.patchValue({ dateFormat: value as Language });
                            break;
                        case ConfigurationStorageKeys.language:
                            this.settingsForm.patchValue({ language: value as Language });
                            break;
                        case ConfigurationStorageKeys.wcagSFI:
                            this.ergonomyForm.patchValue({ wcagSFI: JSON.parse(value) });
                            break;
                        case 'token':
                            this.decodedToken = this.helper.decodeToken(value);
                            break;
                        default:
                            // No other parameters for now
                    }
                    this._cd.markForCheck();
                }
            )
        );
        this._localStorageService.fetchLocalStorageValues$().pipe(
            finalize(() => this._localStorageFetched$.next()),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

}
