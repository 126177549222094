import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { requireMatchValidator } from 'src/app/components/shared/require-match-validator.directive';
import { User } from 'src/app/models/user';
import { AskRightDialogData, AskRightDialogResult, RoleLevel, RoleLevelEnum } from 'src/app/models/user-right';

@Component({
    selector: 'app-dialog-ask-role',
    templateUrl: './dialog-ask-role.component.html',
    styleUrls: ['./dialog-ask-role.component.scss']
})
export class DialogAskRoleComponent implements OnInit, OnDestroy {
    public managers: User[];
    public requestForm: FormGroup<{
        manager: FormControl<User['id']>;
        role: FormControl<RoleLevelEnum>;
    }>;
    public searchManagerForm: FormControl<string>;
    public selectedRole: RoleLevel;
    public selectedManager: User;
    public filteredManagers$: Observable<User[]>;
    public familyName: string;
    public roles: RoleLevel[] = ['reader', 'operator', 'manager'];
    private _subscription = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: AskRightDialogData,
        public dialogRef: MatDialogRef<DialogAskRoleComponent>,
    ) { }

    ngOnInit(): void {
        this.managers = this.dialogData.managers;
        this.familyName = this.dialogData.familyName;
        const level = this.dialogData.level;
        const index = this.roles.findIndex(
            role => role.toLowerCase() === level.toLowerCase()
          );
          if (index > -1) {
            this.roles.splice(index, 1);
          }
        this.requestForm = new FormGroup({
            manager: new FormControl(null, [Validators.required, requireMatchValidator(this.managers, 'id')]),
            role: new FormControl(null, [Validators.required, requireMatchValidator(this.roles)]),
        });
        this.searchManagerForm = new FormControl('');
        this.filteredManagers$ = this.searchManagerForm.valueChanges.pipe(
            startWith(''),
            map(value => {
                const regexp = new RegExp(value, 'i');
                return this.managers.filter(
                    ({ email, userId }) => regexp.test(email) || regexp.test(userId)
                );
            })
        );
    }

    /**
     * Called on click on confirm button
     * - Return the selected manager and the selected role to the RequestForRights Component
     */
    public onClickConfirm() {
        const formValue = this.requestForm.value;
        const dialogResult: AskRightDialogResult = {
            action: 'confirm',
            manager: this.managers.find(
                ({ id }) => id === formValue.manager
            ),
            level: formValue.role,
        };
        this.dialogRef.close(dialogResult);
    }

    /**
     * Called when the cancel button or the closing cross button are pressed
     */
    public onClickCancel() {
        const dialogResult: AskRightDialogResult = {
            action: 'cancel'
        };
        this.dialogRef.close(dialogResult);
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }
}
