import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectProfileParentFamilies$, selectProfilesLoaded$ } from '../store/selectors/profile.selectors';
import { filter, switchMap } from 'rxjs/operators';
import { SnackbarComponent } from '../components/shared/snackbar/snackbar';
import { TranslateService } from '@ngx-translate/core';

export const hasRightGuard: CanActivateFn = () => {
    // #region Injection
    const store = inject(Store);
    const router = inject(Router);
    const snackbar = inject(SnackbarComponent);
    const translate = inject(TranslateService);
    // #endregion

    return new Observable((observer) => {
        store.select(selectProfilesLoaded$).pipe(
            filter(isLoaded => isLoaded),
            switchMap(() => store.select(selectProfileParentFamilies$))
        ).subscribe(
            (parentFamilies) => {
                if (parentFamilies.length > 0) {
                    observer.next(true);
                } else {
                    observer.next(false);
                    snackbar.open(
                        translate.instant('DIALOG_USER_NO_RIGHTS_TEXT'),
                        'orange-snackbar',
                        5000,
                    );
                    router.navigate(['rights', 'request']);
                }
                observer.complete();
            }
        );
    });
};
