<div mat-dialog-title>
    <span>{{ "DIALOG_GENERATE_PLANNED_LINE" | translate}}</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>
<form [formGroup]="eventForm"
      (ngSubmit)="generateEvent()">

    <div class="custom-scrollbar"
         mat-dialog-content>
        <div class="form-container">
            <div class="operation-selection">
                <label id="operations">
                    {{'OPERATIONS_RADIO_BUTTON_LABEL' | translate}}
                </label>
                <mat-radio-group aria-labelledby="operations"
                                formControlName="operationType">
                    <mat-radio-button class="form-radio-button"
                                    *ngFor="let operation of OperationType | keyvalue"
                                    [value]="operation.value">
                        {{ operation.value | uppercase | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'PARENT_FAMILY' | translate }}</mat-label>
                <mat-select formControlName="parentFamily">
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                               [formControl]="searchForm.controls.parentFamily"
                                               [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option>
                        <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                    </mat-option>
                    <mat-option *ngFor="let parentFamily of parentFamilies$
                                        | async
                                        | listFilter:'name':searchForm.value.parentFamily:true:true
                                        | sort:false:'name'
                                        | slice:0:200"
                                [value]="parentFamily.id">
                        {{ parentFamily.name }}
                    </mat-option>
                </mat-select>
                <mat-hint>
                    <ng-container *ngIf="(loadings.parentFamilies$ | async) === true; else hintTemplate">
                        <mat-progress-spinner mode="indeterminate"
                                              diameter="12"
                                              strokeWidth="2"></mat-progress-spinner>
                        {{ "LOADING_DATA" | translate }}
                    </ng-container>
                    <ng-template #hintTemplate>
                        <ng-container
                                      *ngIf="(parentFamilies$ | async | listFilter:'name':searchForm.value.parentFamily:true:true)?.length > 200">
                            {{ "LIST_LIMITED_QTY" | translate:{number:'200'} }}
                        </ng-container>
                    </ng-template>
                </mat-hint>
                <mat-error
                           *ngIf="(loadings.parentFamilies$ | async) === false && eventForm.controls.parentFamily.errors?.required">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "REQUIRED_SELECTION" | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'ASSET_FAMILY' | translate }}</mat-label>
                <mat-select formControlName="assetFamily">
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                               [formControl]="searchForm.controls.assetFamily"
                                               [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option>
                        <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                    </mat-option>
                    <mat-option *ngFor="let family of families$
                                        | async
                                        | listFilter:'name':searchForm.value.assetFamily:true:true
                                        | sort:false:'name'
                                        | slice:0:200; trackBy: trackById"
                                [value]="family.id">
                        {{ family.name }}
                    </mat-option>
                </mat-select>
                <mat-hint>
                    <ng-container *ngIf="(loadings.families$ | async) === true; else hintTemplate">
                        <mat-progress-spinner mode="indeterminate"
                                              diameter="12"
                                              strokeWidth="2"></mat-progress-spinner>
                        {{ "LOADING_DATA" | translate }}
                    </ng-container>
                    <ng-template #hintTemplate>
                        <ng-container
                                      *ngIf="(families$ | async | listFilter:'name':searchForm.value.assetFamily:true:true)?.length > 200">
                            {{ "LIST_LIMITED_QTY" | translate:{number:'200'} }}
                        </ng-container>
                    </ng-template>
                </mat-hint>
                <mat-error
                           *ngIf="(loadings.families$ | async) === false && eventForm.controls.assetFamily.errors?.required">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "REQUIRED_SELECTION" | translate }}
                </mat-error>
            </mat-form-field>


            <mat-form-field appearance="outline">
                <mat-label>{{ 'PLANNED_DATE' | translate }}</mat-label>
                <input matInput
                       [mtxDatetimepicker]="plannedDatepicker"
                       formControlName="plannedDate">
                <mtx-datetimepicker-toggle [for]="plannedDatepicker"
                                           matSuffix></mtx-datetimepicker-toggle>
                <mtx-datetimepicker #plannedDatepicker
                                    multiYearSelector></mtx-datetimepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'PLANNED_LOCATION' | translate }}</mat-label>
                <mat-select formControlName="plannedLocation">
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                               [formControl]="searchForm.controls.plannedLocation"
                                               [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option>
                        <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                    </mat-option>
                    <mat-option *ngFor="let zone of zones$
                                        | async
                                        | listFilter:'name':searchForm.value.plannedLocation:true:true
                                        | sort:false:'name'
                                        | slice:0:200; trackBy: trackById"
                                [value]="zone.id">
                        {{ zone.name }}
                    </mat-option>
                </mat-select>
                <mat-hint>
                    <ng-container *ngIf="(loadings.zones$ | async) === true; else hintTemplate">
                        <mat-progress-spinner mode="indeterminate"
                                              diameter="12"
                                              strokeWidth="2"></mat-progress-spinner>
                        {{ "LOADING_DATA" | translate }}
                    </ng-container>
                    <ng-template #hintTemplate>
                        <ng-container *ngIf="(zones$ | async)?.length > 200">
                            {{ "LIST_LIMITED_QTY" | translate:{number:'200'} }}
                        </ng-container>
                    </ng-template>
                </mat-hint>
                <mat-error
                           *ngIf="(loadings.zones$ | async) === false && eventForm.controls.assetFamily.errors?.required">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "REQUIRED_SELECTION" | translate }}
                </mat-error>
            </mat-form-field>

            <ng-container
                          *ngIf="eventForm.value.operationType === OperationType.load || eventForm.value.operationType === OperationType.unload">
                <mat-form-field appearance="outline">
                    <mat-label>{{ "COMPATIBLE_SECTION" | translate }}</mat-label>
                    <mat-select formControlName="section">
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                                   [formControl]="searchForm.controls.section"
                                                   [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option>
                            <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                        </mat-option>
                        <mat-option *ngFor="let section of section$
                                                    | async
                                                    | listFilter:null:searchForm.value.section:true:true
                                                    | sort
                                                    | slice:0:200; trackBy: trackById"
                                    [value]="section">
                            {{ section }}
                        </mat-option>
                    </mat-select>
                    <mat-hint>
                        <ng-container *ngIf="(loadings.families$ | async) === true; else hintTemplate">
                            <mat-progress-spinner mode="indeterminate"
                                                  diameter="12"
                                                  strokeWidth="2"></mat-progress-spinner>
                            {{ "LOADING_DATA" | translate }}
                        </ng-container>
                        <ng-template #hintTemplate>
                            <ng-container
                                          *ngIf="(section$ | async| listFilter:null:searchForm.value.section:true:true)?.length > 200">
                                {{ "LIST_LIMITED_QTY" | translate:{number:'200'} }}
                            </ng-container>
                        </ng-template>
                    </mat-hint>
                    <mat-error
                               *ngIf="(loadings.families$ | async) === false && eventForm.controls.assetFamily.errors?.required">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ "REQUIRED_SELECTION" | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{"MSN" | translate}}</mat-label>
                    <input matInput
                           formControlName="msn" />
                </mat-form-field>
            </ng-container>

        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-stroked-button
                mat-dialog-close
                color="primary"
                type="button">
            {{ "CANCEL" | translate }}
        </button>
        <button type="submit"
                mat-flat-button
                color="primary"
                [disabled]="!eventForm.valid || loadings.submit">
            {{ "GENERATE" | translate }}
            <mat-progress-spinner *ngIf="loadings.submit"
                                  mode="indeterminate"
                                  diameter="16"
                                  strokeWidth="2">
            </mat-progress-spinner>
        </button>
    </div>
</form>
