import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lib, enc } from 'crypto-js';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Profile } from '../models/profile';
import { User } from '../models/user';
import { sha256 } from '../utils/formater';

interface IdToken {
    access_token: string;
    expires_in: number;
    id_token: string;
    token_type: string;
}

interface AccessToken {
    access_token: string;
    expires_in: number;
    token_type: string;
}

export interface FirstTime {
    Id: string;
    ng: string;
    is_first_login: boolean;
    insert_time: string;
}

export interface Validation {
    PK: string;
    ng: string;
    validation: boolean;
}

@Injectable({
    providedIn: 'root'
})

export class UserService {
    public user: User;

    constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document) { }

    public getProfiles(): Observable<Profile> {
        return this.http.get<Profile>(`${environment.API_ENDPOINT}/auth/profile`);
    }

    public getAuthorizationCode() {
        const verifier = enc.Base64.stringify(lib.WordArray.random(50)).replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
        localStorage.setItem('redirect_auth', '1');
        const clientId = environment.clientId;
        const responseType = 'code';
        const redirectUri = encodeURIComponent(new URL(window.location.href).origin + '/oauth2redirect');
        const scope = 'openid%20email%20profile';
        const grantType = 'urn%3Aacr%3Aform';
        const codeChallenge = enc.Base64.stringify(sha256(verifier)).replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');

        localStorage.setItem('pkce', codeChallenge);

        this.document.location.href = `${environment.appBaseUri}/FedBroker/as/authorization.oauth2?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&scope=${scope}&grant_type=${grantType}&code_challenge=${codeChallenge}`;
    }

    public getIdToken(code: string): Observable<IdToken > {
        const clientId = environment.clientId;
        const grantType = 'authorization_code';
        const redirectUri = encodeURIComponent(new URL(window.location.href).origin + '/oauth2redirect');
        const codeVerifier = localStorage.getItem('pkce');

        const body = `client_id=${clientId}&grant_type=${grantType}&code_verifier=${codeVerifier}&code=${code}&redirect_uri=${redirectUri}`;
        return this.http.post<IdToken>(
            `${environment.appBaseUri}/FedBroker/as/token.oauth2`,
            body,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }
        );
    }

    public getAccessToken(assertion: string): Observable<AccessToken> {
        const clientId = environment.clientId;
        const grantType = 'urn%3Aietf%3Aparams%3Aoauth%3Agrant-type%3Ajwt-bearer';
        const scope = environment.scope;

        const body = `client_id=${clientId}&grant_type=${grantType}&scope=${scope}&assertion=${assertion}`;
        return this.http.post<AccessToken>(
            `${environment.appBaseUri}/FedBroker/as/token.oauth2`,
            body,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }
        );
    }

    public setUserFirstTime(): Observable<FirstTime> {
        return this.http.put<FirstTime>(
            `${environment.API_ENDPOINT}/auth/first-time`,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }
        );
    }

    public setUserValidation(): Observable<Validation> {
        return this.http.put<Validation>(
            `${environment.API_ENDPOINT}/auth/validation`,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }
        );
    }
}
