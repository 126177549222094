import { inflate } from 'pako';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Planning, Zone } from '../models/planning';
import { ApiBuffer } from '../models/utils';
import moment from 'moment';
import { LoadAssetInput, UnloadAssetInput } from '../models/operation';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root'
})
export class PlanningService {

    public fromDate: string = moment().subtract(1, 'weeks').startOf('day').toISOString();
    public toDate: string = moment().add(1, 'weeks').endOf('day').toISOString();

    constructor(private http: HttpClient) { }

    /**
     * Get the planning data from API
     * @param fromDate The date from when we want to fetch the planning
     * @param toDate The date to when we want to fetch the planning
     */
    public getPlanning(fromDate: string, toDate: string): Observable<Planning[]> {
        const httpParams = new HttpParams()
            .append('fromDate', fromDate)
            .append('toDate', toDate);
        return this.http.get<ApiBuffer>(
            `${environment.API_ENDPOINT}/scopes/oversize/plannings`, { params: httpParams }
        ).pipe<Planning[]>(map(d => JSON.parse(inflate(d['data'], { to: 'string' }))));
    }

    /**
     * Edit a planning line calling API
     * @param planning The planning line to edit
     */
    public editPlanning(planning: Planning): Observable<Planning> {
        const payload = {
            confirmedDate: planning.confirmedDate,
            confirmedLocation: planning.confirmedLocation.level3 ?? planning.confirmedLocation.level2 ?? planning.confirmedLocation.level1,
            familyId: planning.familyId /** for transport mean operation */ || planning.jigs?.[0]?.familyId /** load or unload jig */,
            planningId: planning.planningId,
            lineId: planning.lineId,
            operationId: planning.operationId,
            type: planning.type
        };
        return this.http.put<Planning>(
            `${environment.API_ENDPOINT}/scopes/oversize/plannings`,
            JSON.stringify(payload),
            httpOptions
        );
    }

    /**
     * Delete a planning line calling API
     * @param planning The planning line to delete
     */
    public deletePlanning(planning: Planning): Observable<string[]> {
        return this.http.delete<string[]>(
            `${environment.API_ENDPOINT}/scopes/oversize/plannings/${planning.operationId}`);
    }

    public getAllZones(): Observable<Zone[]> {
        return this.http.get<Zone[]>(
            `${environment.API_ENDPOINT}/zones`
        );
    }

    public loadOperation(loadAssetInput: LoadAssetInput, assetId: string): Observable<{ message: string }> {
        return this.http.put<{ message: string }>(
            `${environment.API_ENDPOINT}/assets/${assetId}/load`,
            JSON.stringify(loadAssetInput),
            httpOptions
        );
    }

    public unloadOperation(unloadAssetInput: UnloadAssetInput, assetId: string): Observable<{ message: string }> {
        return this.http.put<{ message: string }>(
            `${environment.API_ENDPOINT}/assets/${assetId}/unload`,
            JSON.stringify(unloadAssetInput),
            httpOptions
        );
    }
}
