import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const selectCertificateState$ = (state: AppState) => state.certificates;

export const selectCertificates$ = createSelector(selectCertificateState$, (certificate) => certificate.data);

export  const  selectCertificatesLoading$ = createSelector(selectCertificateState$, (certificate) =>  certificate.loading);

export  const  selectCertificatesLoaded$ = createSelector(selectCertificateState$, (certificate) =>  certificate.loaded);
