import { ChangeDetectorRef, Injectable, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment, { LongDateFormatKey } from 'moment';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { LanguageService } from '../services/language.service';

@Injectable()
@Pipe({
    name: 'moment',
    pure: false,
})
export class MomentTranslatePipe implements PipeTransform, OnDestroy {
    public lastPattern: string;
    public lastValue: unknown;
    public onLangChange: Subscription | undefined;
    public onDefaultLangChange: Subscription | undefined;
    public onDateFormatChange: Subscription | undefined;
    value: string;
    private _dateFormatLang = this._translate.defaultLang;

    constructor(
        private _translate: TranslateService,
        private _ref: ChangeDetectorRef,
        private _language: LanguageService,
    ) {}

    /**
     * Update the value with respect to the given pattern.
     * Adapt the pattern to the selected locale for the dates while keeping the locale for texts
     * Example, if the language English is selected but the date locale is French, for the date `2024-05-13T15:22:00` formatted with the pattern `LLLL`
     * - for the English locale, it would give `Monday, May 13, 2024 3:22 PM`
     * - for the French locale, it would give `Lundi 13 mai 2024 15:22`
     * - but with this pattern transformation, it gives a combinaison of both: `Monday 13 May 2024 15:22`
     */
    updateValue(value: any, pattern?: string): void {
        this.lastValue = value;
        const localeData = moment.localeData(this._dateFormatLang);
        const matches = pattern.match(/LTS|LT|L+/gi) ?? [];
        for (const match of matches) {
            pattern = pattern.replace(/LTS|LT|L+/i, localeData.longDateFormat(match as LongDateFormatKey));
        }
        this.value = moment(value).format(pattern);
        this._ref.markForCheck();
    }

    transform(value: unknown, pattern?: string): string|null {
        if (!value) {
            return null;
        }

        // store the query, in case it changes
        this.lastValue = value;

        // store the params, in case they change
        this.lastPattern = pattern;

        // set the value
        this.updateValue(value, pattern);

        // if there is a subscription to onLangChange, clean it
        this._dispose();

        // subscribe to onLangChange event, in case the language changes
        if (!this.onLangChange) {
            this.onLangChange = this._translate.onDefaultLangChange.subscribe(() => {
                if (this.lastValue) {
                    this.lastValue = null; // we want to make sure it doesn't return the same value until it's been updated
                    this.updateValue(value, pattern);
                }
            });
        }

        // subscribe to onDefaultLangChange event, in case the default language changes
        if (!this.onDefaultLangChange) {
            this.onDefaultLangChange = this._translate.onDefaultLangChange.subscribe(() => {
                if (this.lastValue) {
                    this.lastValue = null; // we want to make sure it doesn't return the same value until it's been updated
                    this.updateValue(value, pattern);
                }
            });
        }

        // subscribe to onLangChange event, in case the language changes
        if (!this.onDateFormatChange) {
            this.onDateFormatChange = this._language.selectedDateFormat$.pipe(
                distinctUntilChanged(),
            ).subscribe((format) => {
                this._dateFormatLang = format;
                if (this.lastValue) {
                    this.lastValue = null; // we want to make sure it doesn't return the same value until it's been updated
                    this.updateValue(value, pattern);
                }
            });
        }

        return this.value;
    }

    /**
     * Clean any existing subscription to change events
     */
    private _dispose(): void {
        if (typeof this.onLangChange !== 'undefined') {
            this.onLangChange.unsubscribe();
            this.onLangChange = undefined;
        }
        if (typeof this.onDefaultLangChange !== 'undefined') {
            this.onDefaultLangChange.unsubscribe();
            this.onDefaultLangChange = undefined;
        }
    }

    ngOnDestroy(): void {
        this._dispose();
    }

}
