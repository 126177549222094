import { createSelector } from '@ngrx/store';
import { AppState } from '../../store';

export const selectFamilyState = (state: AppState) => state.families;

export const selectFamilyState$ = createSelector(selectFamilyState, (state) => state);

export const selectFamily$ = createSelector(selectFamilyState$, (families) => families.data);

export const selectFamilyArray$ = createSelector(selectFamilyState$, (families) => Object.values(families.data.families));

export const selectFamilyById$ = (familyId: string) => createSelector(selectFamily$, (data) => data.families[familyId]);

export const selectFamiliesByParentFamilyIds$ = (ids: string[]) => createSelector(selectFamilyState, (state) => {
    return Object.values(state.data.families).filter(
        family => ids.includes(family.parentFamilyId)
    )
});

export const selectSectionByFamilyId$ = (id: string) => createSelector(selectFamilyState, (state) => {
    return state.data.families[id]?.compatibleSection;
})

export const selectFamilyLoaded$ = createSelector(selectFamilyState$, (families) => families.loaded);
