import { FamilyUsersConfirmationDialogData, ROLES_I18N } from '../../../../models/user-right';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FamilyUsersConfirmationDialogResult } from 'src/app/models/user-right';

@Component({
  selector: 'app-family-users-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class FamilyUsersConfirmationDialogComponent implements OnInit {

  public role: string;

  constructor(
    public dialogRef: MatDialogRef<FamilyUsersConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: FamilyUsersConfirmationDialogData,
  ) { }

  ngOnInit(): void {
    this.role = ROLES_I18N.find(role => role.value === this.dialogData.role)?.text ?? this.dialogData.role;
  }

  public onClickCancel(): void {
    const dialogResult: FamilyUsersConfirmationDialogResult = {
      action: 'cancel'
    };
    this.dialogRef.close(dialogResult);
  }

  public onClickConfirm(): void {
    const dialogResult: FamilyUsersConfirmationDialogResult = {
      action: 'confirm'
    };
    this.dialogRef.close(dialogResult);
  }

}
