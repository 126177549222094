import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserFilters, InitFiltersData } from '../models/filter-context.model';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogConfigureContextComponent } from '../components/init-filter/configure-context/dialog-configure-context.component';
import { AuthService } from '../auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

export const MAX_ASSET_COUNT = 1e4;

export const CONTEXT_DIALOG_OPENED = new InjectionToken<BehaviorSubject<boolean>>('context dialog opened');

export type CreateSessionContextPayload = Partial<Pick<UserFilters, 'companies'|'sites'|'parentFamilies'|'families'|'providers'>>;
export type CreateSavedContextPayload = Required<Pick<UserFilters, 'name'>> & Partial<Pick<UserFilters, 'companies'|'sites'|'parentFamilies'|'families'|'providers'>>;
export type UpdateContextPayload = Required<Pick<UserFilters, 'id'|'name'>> & Partial<Pick<UserFilters, 'companies'|'sites'|'parentFamilies'|'families'|'providers'>>;

@Injectable({
    providedIn: 'root'
})
export class ContextService {

    public contextConfigDialogRef?: MatDialogRef<DialogConfigureContextComponent>;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private jwt: JwtHelperService,
    ) { }

    public fetchDataForContexts(): Observable<InitFiltersData> {
        return this.http.get<InitFiltersData>(`${environment.API_ENDPOINT}/context-data`);
    }

    public createUserContext(filters: CreateSavedContextPayload): Observable<UserFilters> {
        return this.http.post<UserFilters>(`${environment.API_ENDPOINT}/user-context`, filters);
    }

    public fetchAllUserContexts(): Observable<UserFilters[]> {
        return this.http.get<UserFilters[]>(`${environment.API_ENDPOINT}/user-context`);
    }

    public editUserContext(filters: UpdateContextPayload): Observable<UserFilters> {
        return this.http.put<UserFilters>(`${environment.API_ENDPOINT}/user-context/${filters.id}`,
            filters
        );
    }

    public toggleContextFavorite(filterId: string): Observable<UserFilters> {
        return this.http.put<UserFilters>(`${environment.API_ENDPOINT}/user-context/${filterId}/favorite`, null);
    }

    public setDefaultContext(filterId: string): Observable<UserFilters> {
        return this.http.put<UserFilters>(`${environment.API_ENDPOINT}/user-context/${filterId}/default`, null);
    }

    /**
     * Delete a filter calling API
     * @param filterId The filter to delete
     */
    public deleteUserContext(filterId: string): Observable<UserFilters> {
        return this.http.delete<UserFilters>(`${environment.API_ENDPOINT}/user-context/${filterId}`);
    }

    public createSessionContext(filters: CreateSessionContextPayload): Observable<UserFilters> {
        const idToken = this.authService.getIdToken();
        const expiration = this.jwt.getTokenExpirationDate(idToken);
        return this.http.post<UserFilters>(`${environment.API_ENDPOINT}/create-session-filter`, {
            ...filters,
            expiration,
        });
    }
}
