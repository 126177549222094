import { createSelector } from '@ngrx/store';
import { AppState } from '../../store';

export const selectBeaconLayerState$ = (state: AppState) => state.beaconLayer;

export const selectBeaconLayer$ = createSelector(selectBeaconLayerState$, (beaconLayer) => beaconLayer.data);

export  const  selectBeaconLayerLoading$ = createSelector(selectBeaconLayerState$, (beaconLayer) =>  beaconLayer.loading);

export  const  selectBeaconLayerLoaded$ = createSelector(selectBeaconLayerState$, (beaconLayer) =>  beaconLayer.loaded);
