import { ErrorTranslationService } from './../../services/error-translation.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from './../../components/shared/snackbar/snackbar';
import { Certificate } from './../../models/certificate';
import { CertificateService } from './../../services/certificate.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    acceptCertificateRequest,
    cancelCertificateDeletion,
    createCertificateRequest,
    deleteCertificateRequest,
    errorAcceptCertificateRequest,
    errorCancelCertificateDeletion,
    errorCreateCertificateRequest,
    errorDeleteCertificateRequest,
    errorFetchCertificate,
    errorRefuseCertificateRequest,
    errorReinstallCertificate,
    errorRenewCertificateRequest,
    errorUpdateCertificateRequest,
    fetchCertificate,
    refuseCertificateRequest,
    reinstallCertificate,
    renewCertificateRequest,
    successAcceptCertificateRequest,
    successCancelCertificateDeletion,
    successCreateCertificateRequest,
    successDeleteCertificateRequest,
    successFetchCertificate,
    successRefuseCertificateRequest,
    successReinstallCertificate,
    successRenewCertificateRequest,
    successUpdateCertificateRequest,
    updateCertificateRequest
} from '../actions/certificate.action';

@Injectable()
export class CertificateEffects {
    public FetchCertificate$ = createEffect(() => { return this._actions$.pipe(
        ofType(fetchCertificate),
        switchMap(() => this._certificateService.getCertificates().pipe(
            map((certificates: Array<Certificate>) => {
                return successFetchCertificate({ payload: certificates });
            }),
            catchError(() => {
                return of(errorFetchCertificate());
            }))
        )) });

    public CreateCertificateRequest$ = createEffect(() => { return this._actions$.pipe(
        ofType(createCertificateRequest),
        switchMap((actions) => this._certificateService.createCertificateRequest(actions.payload).pipe(
            map((certificates: Array<Certificate>) => {
                this._snackbar.open(this._translate.instant('BANNER_SUCCESS_CERTIFICATE_REQUEST_CREATION'), 'green-snackbar', 5000);
                return successCreateCertificateRequest({ payload: certificates });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_CERTIFICATE_REQUEST_CREATION');
                return of(errorCreateCertificateRequest());
            }))
        )) });

    public UpdateCertificateRequest$ = createEffect(() => { return this._actions$.pipe(
        ofType(updateCertificateRequest),
        switchMap((actions) => this._certificateService.updateCertificaterequest(actions.payload).pipe(
            map((certificate: Certificate) => {
                this._snackbar.open(this._translate.instant('BANNER_SUCCESS_CERTIFICATE_REQUEST_UPDATE'), 'green-snackbar', 5000);
                return successUpdateCertificateRequest({ payload: [certificate] });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_CERTIFICATE_REQUEST_UPDATE');
                return of(errorUpdateCertificateRequest());
            }))
        )) });

    public DeleteCertificateRequest$ = createEffect(() => { return this._actions$.pipe(
        ofType(deleteCertificateRequest),
        switchMap((actions) => this._certificateService.deleteCertificatesRequest(actions.payload).pipe(
            map((certificate: Certificate) => {
                this._snackbar.open(this._translate.instant('BANNER_SUCCESS_CERTIFICATE_REQUEST_DELETE'), 'green-snackbar', 5000);
                this.effectSubject.next();
                return successDeleteCertificateRequest({ payload: [certificate] });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_CERTIFICATE_REQUEST_DELETE');
                this.effectSubject.next();
                return of(errorDeleteCertificateRequest());
            }))
        )) });

    public RenewCertificateRequest$ = createEffect(() => { return this._actions$.pipe(
        ofType(renewCertificateRequest),
        switchMap((actions) => this._certificateService.renewCertificatesRequest(actions.payload).pipe(
            map((certificates: Array<Certificate>) => {
                this._snackbar.open(this._translate.instant('BANNER_SUCCESS_CERTIFICATE_REQUEST_RENEW'), 'green-snackbar', 5000);
                this.effectSubject.next();
                return successRenewCertificateRequest({ payload: certificates });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_CERTIFICATE_REQUEST_RENEW');
                this.effectSubject.next();
                return of(errorRenewCertificateRequest());
            }))
        )) });

    public AcceptCertificateRequest$ = createEffect(() => { return this._actions$.pipe(
        ofType(acceptCertificateRequest),
        switchMap((actions) => this._certificateService.acceptCertificateRequest(actions.payload).pipe(
            map((certificates: Array<Certificate>) => {
                this._snackbar.open(this._translate.instant('BANNER_SUCCESS_CERTIFICATE_REQUEST_ACCEPT'), 'green-snackbar', 5000);
                this.effectSubject.next();
                return successAcceptCertificateRequest({ payload: certificates });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_CERTIFICATE_REQUEST_ACCEPT');
                this.effectSubject.next();
                return of(errorAcceptCertificateRequest());
            }))
        )) });

    public RefuseCertificateRequest$ = createEffect(() => { return this._actions$.pipe(
        ofType(refuseCertificateRequest),
        switchMap((actions) => this._certificateService.refuseCertificateRequest(actions.payload).pipe(
            map((certificates: Array<Certificate>) => {
                this._snackbar.open(this._translate.instant('BANNER_SUCCESS_CERTIFICATE_REQUEST_REFUSE'), 'green-snackbar', 5000);
                this.effectSubject.next();
                return successRefuseCertificateRequest({ payload: certificates });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_CERTIFICATE_REQUEST_REFUSE');
                this.effectSubject.next();
                return of(errorRefuseCertificateRequest());
            }))
        )) });

    public CancelCertificateDeletion$ = createEffect(() => { return this._actions$.pipe(
        ofType(cancelCertificateDeletion),
        switchMap((actions) => this._certificateService.cancelCertificateDeletion(actions.payload).pipe(
            map((certificates: Certificate) => {
                this._snackbar.open(this._translate.instant('BANNER_SUCCESS_CANCEL_CERTIFICATE_DELETION'), 'green-snackbar', 5000);
                this.effectSubject.next();
                return successCancelCertificateDeletion({ payload: certificates });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_CANCEL_CERTIFICATE_DELETION');
                this.effectSubject.next();
                return of(errorCancelCertificateDeletion());
            }))
        )) });

    public ReinstallCertificate$ = createEffect(() => { return this._actions$.pipe(
        ofType(reinstallCertificate),
        switchMap((actions) => this._certificateService.reinstallationCertificate(actions.payload).pipe(
            map((certificates: Certificate) => {
                this._snackbar.open(this._translate.instant('BANNER_SUCCESS_REINSTALL_CERTIFICATE'), 'green-snackbar', 5000);
                this.effectSubject.next();
                return successReinstallCertificate({ payload: certificates });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_REINSTALL_CERTIFICATE');
                this.effectSubject.next();
                return of(errorReinstallCertificate());
            }))
        )) });

    public effectSubject: Subject<void>;
    constructor(
        private _certificateService: CertificateService,
        private _actions$: Actions,
        private _snackbar: SnackbarComponent,
        private _translate: TranslateService,
        private _errorTranslationService: ErrorTranslationService
    ) {
        this.effectSubject = new Subject();
    }
}
