<div class="left"></div>
<div class="right">
    <img [src]="imageSrc"
         alt="Text field illustration">
    <p class="subtitle">
        {{ title }}
    </p>
    <p class="fill-panel--grey">
        {{ subtitle }}
    </p>
</div>
