<div mat-dialog-title>
    <span>{{ "DIALOG_GENERATE_PLANNING" | translate }}</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"> </fa-icon>
    </button>
</div>
<form [formGroup]="planningForm"
      (ngSubmit)="generatePlanning()">
    <div class="custom-scrollbar"
         mat-dialog-content>
        <!-- Input Description -->
        <mat-form-field class="input-field"
                        appearance="outline">
            <mat-label>Planning</mat-label>
            <textarea matInput
                      formControlName="planning"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="5"
                      spellcheck="false"></textarea>
            <mat-error *ngIf="planningForm.controls.planning.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_INPUT" | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button type="button"
                mat-stroked-button
                mat-dialog-close
                color="primary">
            {{ "CANCEL" | translate }}
        </button>
        <button type="submit"
                mat-flat-button
                color="primary"
                [disabled]="planningForm.invalid || loading">
            {{ "Generate" }}
            <mat-progress-spinner *ngIf="loading"
                                  mode="indeterminate"
                                  diameter="16"
                                  color="accent"
                                  strokeWidth="2">
            </mat-progress-spinner>
        </button>
    </div>
</form>
