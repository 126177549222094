import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SpecificRole } from 'src/app/models/masterdata';
import { SpecificRoleDialogData, SpecificRoleDialogResult } from 'src/app/models/user-right';
import { selectMasterdata$ } from 'src/app/store/selectors/masterdata.selectors';
import { isEqual } from 'lodash';

@Component({
    selector: 'app-dialog-specific-role',
    templateUrl: './dialog-specific-role.component.html',
    styleUrls: ['./dialog-specific-role.component.scss']
})
export class DialogSpecificRoleComponent implements OnInit {

    private _subscription: Subscription = new Subscription();
    public allSpecificRole: Array<SpecificRole>;
    public specificRoleForm: UntypedFormGroup;
    constructor(
        private store: Store,
        private _formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public dialogData: SpecificRoleDialogData,
        public dialogRef: MatDialogRef<DialogSpecificRoleComponent>
    ) { }

    ngOnInit(): void {
        this._initData();
        this._initForm();
    }

    /**
     * subscribe to the adminRoles from masterData in store
     */
    private _initData() {
        this._subscription.add(this.store.select(selectMasterdata$).pipe(
            take(1),
        ).subscribe((masterdata) => {
            this.allSpecificRole = masterdata.adminRoles;
        }));
    }

    /**
     * init the form dinamically from master data adminRoles
     */
    private _initForm() {
        this.specificRoleForm = this._formBuilder.group({});
        this.allSpecificRole.forEach(specificRole => {
            this.specificRoleForm.addControl(specificRole.id, new UntypedFormControl(this.dialogData.option && this.dialogData.option[specificRole.id] ? this.dialogData.option[specificRole.id] : false));
        });
    }

    /**
     * check if all the properties of option object is the same that the form controls object
     * @returns boolean to check if the init option object is modified
     */
    public checkSameProperty() {
        return isEqual(this.dialogData.option, this.specificRoleForm.getRawValue());
    }

    /**
     * Called when the user click on update button
     * - Return the selected specific role to the ManageUserRole component
     */
    public onClickUpdate() {
        const dialogResult: SpecificRoleDialogResult = {
            action: 'update',
            option: this.specificRoleForm.getRawValue()
        };
        this.dialogRef.close(dialogResult);
    }

    /**
     * Called when the cancel button or the closing cross button are pressed
     */
    public onClickCancel() {
        const dialogResult: SpecificRoleDialogResult = {
            action: 'cancel',
        };
        this.dialogRef.close(dialogResult);
    }

}
