import { createAction, props } from '@ngrx/store';
import { Company } from 'src/app/models/company';

export const fetchAllCompanies = createAction('[company] fetch all companies');
export const successFetchAllCompanies = createAction('[company] success fetch all companies', props<{ payload: Company[] }>());

export const successCreateCompanies = createAction('[company] success create companies', props<{ payload: Company[] }>());

export const successUpdateCompanies = createAction('[company] success update companies', props<{ payload: Company[] }>());

export const successDeleteCompanies = createAction('[company] success delete companies', props<{ payload: Company[] }>());

export const errorFetchAllCompanies = createAction('[company] failure companies');
