import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Certificate, MachineStatus } from '../models/certificate';

@Pipe({
    name: 'certificateRenewal'
})
export class CertificateRenewalPipe implements PipeTransform {
    transform(value: Certificate): boolean {
        let dayDifference = 0;
        if (value.certificateExpirationDate) {
            const startDate = moment();
            const endDate = moment(value.certificateExpirationDate);
            dayDifference = moment.duration(endDate.diff(startDate)).asDays();
        }
        if (dayDifference > 0 && dayDifference <= 60) {
            // Check the expiration date of the certificate, if it's less than 60 days before today, renew available
            return false;
        } else if (value.status?.status4 === MachineStatus.Expirated) {
            // Check the certificate last status, if expirated, renew is available
            return false;
        } else {
            // If none of the previous condition is resolved, renew is not available
            return true;
        }

    }
}
