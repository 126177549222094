import { Pipe, PipeTransform } from '@angular/core';
import { isEqual } from 'lodash';

@Pipe({
    name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {

    /**
     * Filter a list of objects on a property by a value
     * @param items The items to filter
     * @param property The property on which the filtering is done
     * @param value The value on which the property is filtered
     * @param includes The property includes the value (exact match by default)
     * @param ignoreCase Ignore the case of the value
     * @example
     * // List to filter
     * public myList = [
     *  { name: 'John' },
     *  { name: 'Alice' },
     *  { name: 'Bob' },
     * ];
     *
     * <!-- html -->
     * {{ myList | listFilter:'name':'o':true:true }}
     *
     * The list will be filtered on the following results:
     * [
     *  { "name": "John" },
     *  { "name": "Bob" }
     * ]
     */
    transform<T>(items: T[], property: string, value?: any, includes = false, ignoreCase = false): T[] {
        if (!value?.toString()) {
            return items;
        }
        return items?.filter(
            item => {
                let propertyValue = item;
                if (property && Object.prototype.hasOwnProperty.call(item, property)) {
                    propertyValue = item[property];
                }
                if (includes) {
                    const searched = new RegExp(value.toString(), ignoreCase ? 'i' : '');
                    return searched.test(propertyValue.toString());
                }
                if (propertyValue instanceof Array && value instanceof Array) {
                    propertyValue = propertyValue.sort();
                    value = value.sort();
                }
                return isEqual(propertyValue, value);
            }
        );
    }

}
