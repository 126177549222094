import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectProfileUserRights$, selectProfileUserRightsLoaded$ } from '../store/selectors/profile.selectors';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserRights } from '../models/profile';
import { SnackbarComponent } from '../components/shared/snackbar/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { Option } from '../models/user-right';

export enum UserSpecificRole {
    admin,
    oversizePlanningManager,
    actPlanningManager,
    adminMobile,
    adminPosition,
    adminZones,
    adminGroups,
}

const linkRole2ProfileProperty: Record<UserSpecificRole, keyof UserRights> = {
    [UserSpecificRole.actPlanningManager]: 'isPlanningManagerAct',
    [UserSpecificRole.admin]: 'isAdmin',
    [UserSpecificRole.adminGroups]: 'isGroupAdmin',
    [UserSpecificRole.adminMobile]: 'isMobileAdmin',
    [UserSpecificRole.adminPosition]: 'isPositionAdmin',
    [UserSpecificRole.adminZones]: 'isZoneAdmin',
    [UserSpecificRole.oversizePlanningManager]: 'isPlanningManager',
};

const translationKey: Record<UserSpecificRole, keyof Option> = {
    [UserSpecificRole.actPlanningManager]: 'trackerPlanningManager',
    [UserSpecificRole.admin]: 'administrator',
    [UserSpecificRole.adminGroups]: 'adminGroup',
    [UserSpecificRole.adminMobile]: 'adminMobile',
    [UserSpecificRole.adminPosition]: 'adminPosition',
    [UserSpecificRole.adminZones]: 'adminZone',
    [UserSpecificRole.oversizePlanningManager]: 'planningManager',
}

export const specificRoleGuard: CanActivateFn = (route) => {
    const store = inject(Store);
    const router = inject(Router);
    const snackbar = inject(SnackbarComponent);
    const translate = inject(TranslateService);

    // The role to check is sent throught the data property from app routing module
    const rolesToCheck: UserSpecificRole[] = route.data.roles;

    return store.select(selectProfileUserRightsLoaded$).pipe(
        filter((loaded) => loaded),
        take(1),
        switchMap(() => store.select(selectProfileUserRights$)),
        take(1),
        map((rights) => rolesToCheck.some(role => rights[linkRole2ProfileProperty[role]])),
        catchError(() => of(false)),
        tap(hasRole => {
            if (!hasRole) {
                router.navigate(['/']);
                console.log('open snackbar');
                snackbar.open(
                    translate.instant('NAV_FORBITTEN_MISSING_SPECIFIC_ROLE', {
                        role: rolesToCheck.map(
                            role => translate.instant(translationKey[role])?.toLocaleLowerCase()
                        ).join(` ${translate.instant('OR')} `),
                    }),
                    'red-snackbar',
                    0,
                );
            }
        }),
    );
};
