<main>
    <section class="title">
        <span class="title">{{ 'NOT_FOUND_TITLE' | translate }}</span>
        <img *ngIf="displayLostImage$ | async"
             class="lost"
             src="assets/img/undraw_Lost_re_xqjt.png"
             alt="Image of lost person">
    </section>
    <section class="actions">
        <span class="title">{{ 'NOT_FOUND_SUBTITLE' |translate }}</span>
        <div class="cards">
            <mat-card (click)="navigateTo('/map/view-map')">
                <img mat-card-image
                     src="assets/img/undraw_World_re_768g.png"
                     alt="Illustation of location tracking">
                <mat-card-header>
                    <div mat-card-avatar
                         class="example-header-image">
                        <mat-icon>travel_explore</mat-icon>
                    </div>
                    <mat-card-title>{{ "APP_TAB_MAP" | translate }}</mat-card-title>
                </mat-card-header>
                <!-- <mat-card-actions>
                    <button mat-flat-button
                            color="primary"
                            (click)="">
                        {{ "Visit" | translate }}
                    </button>
                </mat-card-actions> -->
            </mat-card>
            <mat-card (click)="navigateTo('/alert')">
                <img mat-card-image
                     src="assets/img/undraw_Alert_re_j2op.png"
                     alt="Illustration of alert">
                <mat-card-header>
                    <div mat-card-avatar
                         class="example-header-image">
                        <mat-icon>notifications_active</mat-icon>
                    </div>
                    <mat-card-title>{{ "APP_TAB_ALERT" | translate }}</mat-card-title>
                </mat-card-header>
                <!-- <mat-card-actions>
                    <button mat-flat-button
                            color="primary"
                            (click)=">
                        {{ "Visit" | translate }}
                    </button>
                </mat-card-actions> -->
            </mat-card>
            <mat-card (click)="navigateTo('/rights/request')">
                <img mat-card-image
                     src="assets/img/undraw_secure_login_pdn4.png"
                     alt="Illustration of secure login">
                <mat-card-header>
                    <div mat-card-avatar
                         class="example-header-image">
                        <mat-icon>lock_person</mat-icon>
                    </div>
                    <mat-card-title>{{ "REQUEST_SOME_RIGHTS" | translate }}</mat-card-title>
                </mat-card-header>
                <!-- <mat-card-actions>
                    <button mat-flat-button
                            color="primary"
                            (click)=">
                        {{ "Visit" | translate }}
                    </button>
                </mat-card-actions> -->
            </mat-card>
        </div>
    </section>
</main>
