import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { expandAnimationTimingFunction, rotate } from '../animations';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BooleanInput, coerceArray, coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatTooltipDefaultOptions } from '@angular/material/tooltip';

@Component({
    selector: 'app-chip-wrapper',
    templateUrl: './chip-wrapper.component.html',
    styleUrls: ['./chip-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        rotate(180),
        trigger('expand', [
            state('collapsed', style({
                height: '48px',
                minHeight: '48px',
            })),
            state('expanded', style({
                height: '*'
            })),
            transition('void <=> rotate', animate(expandAnimationTimingFunction)),
        ]),
    ],
})
export class ChipWrapperComponent {

    @Input()
    get chips(): string[] {
        return this._chips;
    }
    set chips(value: string|string[]) {
        this._chips = value ? coerceArray(value) : [];
    }

    @Input()
    get expanded() {
        return this._expanded;
    }
    set expanded(value: BooleanInput) {
        this._expanded = coerceBooleanProperty(value);
    }

    @Input() tooltipPosition: MatTooltipDefaultOptions['position'] = 'left';

    @Output() toggle = new EventEmitter<boolean>();

    private _chips: string[];
    private _expanded: boolean;

    constructor(
        private readonly _cd: ChangeDetectorRef,
    ) {}

    /**
     * Fired on click on three doted button
     */
    public onToggle() {
        this._expanded = !this.expanded;
        this.toggle.emit(this._expanded);
        this._cd.markForCheck();
    }

}
