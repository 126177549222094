import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    DEFAULT_DIALOG_OPTIONS as DEFAULT_CONTEXT_MANAGE_DIALOG_OPTIONS,
    DialogManageContextComponent,
} from '../../init-filter/dialog-manage-context/dialog-manage-context.component';
import { selectContextsLoading$, selectUserSavedContexts$, selectUserSelectedContext$ } from 'src/app/store/selectors/filter-context.selectors';
import { Store } from '@ngrx/store';
import { UserFilters } from 'src/app/models/filter-context.model';
import { fetchAllUserContexts, setDefaultContext } from 'src/app/store/actions/filter-context.action';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import {
    DEFAULT_DIALOG_OPTIONS as DEFAULT_CONTEXT_CONFIG_DIALOG_OPTIONS,
    DialogConfigureContextComponent,
} from '../../init-filter/configure-context/dialog-configure-context.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FilterContextEffects } from 'src/app/store/effects/filter-context.effects';
import { distinctUntilChanged, map, shareReplay, take, tap } from 'rxjs/operators';
import { selectProfileContext$ } from 'src/app/store/selectors/profile.selectors';
import { CONTEXT_DIALOG_OPENED } from 'src/app/services/init-filter.service';
import { SnackbarComponent } from '../snackbar/snackbar';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-filter-context-menu',
    templateUrl: './filter-context-menu.component.html',
    styleUrls: ['./filter-context-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('slide', [
            state('out', style({
                transform: 'translateX(100%)',
            })),
            state('in', style({
                transform: 'translateX(0%)',
            })),
            transition('in <=> out', animate('.5s ease-in')),
        ]),
        trigger('hide', [
            state('collapse', style({
                transform: 'rotate(360deg) scale(0)',
            })),
            transition('void <=> collapse', animate('.4s ease-in')),
        ]),
    ],
})

export class FilterContextMenuComponent implements OnDestroy {

    @ViewChild('slider') slider: ElementRef<HTMLDivElement>;

    public contextsLoading$ = this.store.select(selectContextsLoading$);
    public currentContext$: Observable<UserFilters> = this.store.select(selectUserSelectedContext$);
    public unselectedUserContexts$: Observable<UserFilters[]> = combineLatest([
        this.store.select(selectProfileContext$),
        this.store.select(selectUserSavedContexts$),
    ]).pipe(
        map(([selectedContext, contexts]) => contexts.filter(
            ({ id }) => id !== selectedContext
        )),
        shareReplay(1),
    );

    // Used for slider animation of the selected context
    public showSlider = false;
    public loading = false;

    private _subscription: Subscription = new Subscription();

    constructor(
        @Inject(CONTEXT_DIALOG_OPENED) public contextConfigOpened$: BehaviorSubject<boolean>,
        private _cd: ChangeDetectorRef,
        private _dialog: MatDialog,
        private store: Store,
        private _contextEffects: FilterContextEffects,
        private _translate: TranslateService,
        private _snackbar: SnackbarComponent,
    ) {
        this.store.dispatch(fetchAllUserContexts());
        this.contextConfigOpened$.pipe(
            distinctUntilChanged(),
            tap(() => this._cd.markForCheck())
        );
    }

    /**
     * This method make the selected context active and load the application with selected conetxt.
     * @param context UserFilter
     */
    public loadSelectedContext(context: UserFilters) {
        this.loading = true;
        // Make selected context active and save into database
        this._snackbar.open(
            this._translate.instant('CONTEXT_SELECTING', { context: context.name }),
            'blue-snackbar',
            Infinity,
        );
        this._contextEffects.effectSubject$.pipe(take(1)).subscribe(({ result }) => {
            this.loading = false;
        });
        this.store.dispatch(setDefaultContext({ id: context.id }));
    }

    /**
     * This method open the edit context form
     */
    public openEditContext() {
        this.currentContext$.pipe(
            take(1),
        ).subscribe(
            (context) => this._dialog.open(DialogConfigureContextComponent, {
                ...DEFAULT_CONTEXT_CONFIG_DIALOG_OPTIONS,
                data: context,
            })
        );
    }

    /**
     * This method opens the Create Context form.
     */
    public openCreateContext() {
        this._dialog.open(DialogConfigureContextComponent, {
            ...DEFAULT_CONTEXT_CONFIG_DIALOG_OPTIONS,
        });
    }

    /**
     * This method opens Manage Context form as popup
     */
    public openManageContext() {
        this._dialog.open(DialogManageContextComponent, {
            ...DEFAULT_CONTEXT_MANAGE_DIALOG_OPTIONS
        });
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }
}
