import { createAction, props } from '@ngrx/store';
import { ParentFamily } from 'src/app/models/family';
import { Site } from 'src/app/models/site';

export const fetchAllParentFamilies = createAction('[ParentFamilies] fetch all ParentFamilies');
export const successFetchAllParentFamilies = createAction('[ParentFamilies] Success fetch all ParentFamilies', props<{ parentFamilies: ParentFamily[] }>());
export const errorFetchAllParentFamilies = createAction('[ParentFamily] Error ParentFamilies');

export const createParentFamily = createAction('[ParentFamily] create ParentFamily', props<{ parentFamily: ParentFamily }>());
export const successCreateParentFamilies = createAction('[ParentFamilies] Success create ParentFamilies', props<{ family: ParentFamily }>());
export const errorCreateParentFamilies = createAction('[ParentFamily] Error create ParentFamily');

export const updateParentFamily = createAction('[ParentFamily] update ParentFamily', props<{ parentFamily: ParentFamily }>());
export const successUpdateParentFamilies = createAction('[ParentFamilies] success update ParentFamily', props<{ payload: ParentFamily[] }>());
export const errorUpdateParentFamilies = createAction('[ParentFamilies] Error update ParentFamily');

export const deleteParentFamily = createAction('[ParentFamilies] delete ParentFamily', props<{ payload: string }>());
export const successDeleteParentFamilies = createAction('[ParentFamilies] success delete ParentFamilies', props<{ payload: { id: string }[] }>());
export const errorDeleteParentFamilies = createAction('[ParentFamilies] Error update ParentFamily');

export const mappingAllParentFamiliesSites = createAction('[ParentFamilies] Mapping all parentFamilies and sites', props<{ parentFamilies: ParentFamily[], sites: Site[] }>());
export const mappingSomeParentFamiliesSites = createAction('[ParentFamilies] Mapping some parentFamilies and sites', props<{ parentFamilies: ParentFamily[], sites: Site[] }>());
