<div class="chips-wrapper"
     *ngIf="chips.length">
    <mat-chip-set role="list"
                  class="mat-mdc-chip-set-stacked"
                  [ngClass]="{'collapsed': !(expanded || chips.length === 1)}"
                  [@expand]="expanded || chips.length === 1 ? 'expanded' : 'collapsed'"
                  aria-orientation="vertical">
        <div #content class="content">
            <ng-content></ng-content>
        </div>
        <ng-container *ngIf="!content.innerHTML.trim()">
            <mat-chip *ngFor="let chip of chips"
                      role="listitem"
                      [matTooltipPosition]="tooltipPosition"
                      [matTooltip]="chip">
                {{ chip }}
            </mat-chip>
        </ng-container>
    </mat-chip-set>
    <button mat-icon-button
            *ngIf="chips.length > 1"
            [@rotate180]="expanded ? 'rotate' : 'void'"
            [matTooltip]="(expanded ? 'VIEW_LESS' : 'VIEW_MORE') | translate"
            [ngStyle]="{'align-items': expanded ? 'start' : 'center'}"
            (click)="onToggle()">
        <mat-icon>expand_more</mat-icon>
    </button>
</div>
