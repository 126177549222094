import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ScatterDataPoint } from 'chart.js';
import { KpiTimeInZone } from 'src/app/services/kpi.service';
import ChartBaseComponent from '../../../shared/kpi/chart.base';
import { Ng2ChartConfiguration } from '../../../shared/kpi/chart.model';

enum DatasetColor {
  min = 'gray',
  mean = 'blue',
  max = 'green',
  asset = 'black',
}

interface AssetDataByZone {
  assetName: string;
  cycles: {
    number: number;
    time: number;
  }[];
  minCycle?: number;
  meanCycle?: number;
  maxCycle?: number;
}

@Component({
  selector: 'app-in-zone-cycles',
  templateUrl: '../../../shared/kpi/chart-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InZoneCyclesComponent extends ChartBaseComponent<KpiTimeInZone, 'line', string> {

  @Input() selectedAssetId: string;

  public chartConfiguration: Ng2ChartConfiguration<'line', (number | ScatterDataPoint)[], string> = {
    type: 'line',
    data: { datasets: [] },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          position: 'top',
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            filter: (item) => !item.text.includes(`${this._translate.instant('ASSET')}: `),
          }
        },
      },
      scales: {
        x: {
          stacked: false,
          display: true,
          beginAtZero: false,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: false,
          display: true,
          beginAtZero: false,
        },
      },
      elements: {
        line: {
          tension: 0.000001,
        }
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
    },
  };

  /**
   * Get the asset data for each zone
   */
  private _getAssetDataByZone(): Record<string, AssetDataByZone|null> {
    const assetByZones: Record<string, AssetDataByZone|null> = {};
    this.dataKPI.kpiTimeZone.forEach(
      ({ kpiTimeZoneName, kpiTimeZoneAssets }) => {
        const assetForZone = kpiTimeZoneAssets.find(
          ({ asset }) => asset.assetId === this.selectedAssetId
        )?.asset;
        if (!assetForZone) {
          assetByZones[kpiTimeZoneName] = null;
          return;
        }
        const assetData: AssetDataByZone = {
          assetName: assetForZone.assetName,
          cycles: [],
        };
        const assetCycleForZone = assetForZone.assetCycles.map(
          ({ cycleZones, cycleCycleOrder }) => ({
            cycleNumber: cycleCycleOrder,
            cycle: cycleZones.find(
              ({ zoneName }) => zoneName === kpiTimeZoneName
            ),
          })
        );
        assetCycleForZone.forEach(
          ({ cycle, cycleNumber }) => {
            if (cycle) {
              assetData.cycles.push({
                number: cycleNumber,
                time: +cycle.zoneTimePassedIn.toFixed(2),
              });
            }
          }
        );
        const times = assetData.cycles.map(({ time }) => time);
        assetData.maxCycle = Math.max(...times);
        assetData.minCycle = Math.min(...times);
        assetData.meanCycle = +(times.reduce((sum, time) => sum + time, 0) / times.length).toFixed(2);
        assetByZones[kpiTimeZoneName] = assetData;
      }
    );
    return assetByZones;
  }

  /**
   * Set the data for the chart
   */
  public setKpiData(): void {
    const assetByZone = this._getAssetDataByZone();
    const zones = Object.keys(assetByZone);
    const assetNameClicked: string = Object.values(assetByZone).find(
      // Find the first asset defined (some zones may not have the asset)
      asset => !!asset
    )?.assetName;
    // Set data
    this.chartConfiguration.data = {
      labels: zones,
      datasets: [
        {
          label: `${this._translate.instant('MIN')} (${this._translate.instant('HOURS')})`,
          data: Object.values(assetByZone).map(
            ({ minCycle }) => minCycle
          ),
          borderColor: DatasetColor.min,
          backgroundColor: DatasetColor.min,
          fill: false,
          borderWidth: 2,
          pointBorderColor: DatasetColor.min,
          pointBackgroundColor: DatasetColor.min,
          pointBorderWidth: 3,
          showLine: true,
        },
        {
          label: `${this._translate.instant('MEAN')} (${this._translate.instant('HOURS')})`,
          data: Object.values(assetByZone).map(
            ({ meanCycle }) => meanCycle
          ),
          borderColor: DatasetColor.mean,
          backgroundColor: DatasetColor.mean,
          fill: false,
          borderWidth: 2,
          pointBorderColor: DatasetColor.mean,
          pointBackgroundColor: DatasetColor.mean,
          pointBorderWidth: 3,
          showLine: true,
        },
        {
          label: `${this._translate.instant('MAX')} (${this._translate.instant('HOURS')})`,
          data: Object.values(assetByZone).map(
            ({ maxCycle }) => maxCycle
          ),
          borderColor: DatasetColor.max,
          backgroundColor: DatasetColor.max,
          fill: false,
          borderWidth: 2,
          pointBorderColor: DatasetColor.max,
          pointBackgroundColor: DatasetColor.max,
          pointBorderWidth: 3,
          showLine: true,
        },
        ...this._getDatasetsByCycle(assetByZone),
      ],
    };
    this.chartConfiguration.options.plugins.title.text = `${this._translate.instant('TIME_IN_ZONE_LOOP')} (${this._translate.instant('ASSET')}: ${assetNameClicked})`;
    this.chart?.update();
  }

  /**
   * Get the label for the dataset of the asset cycle
   * @param assetName The name of the asset
   * @param cycle The number of the cycle
   * @param zone The name of the zone
   * @returns The label for the dataset
   */
  private _getLabelForCycle(assetName: string, cycle: number): string {
    return `${this._translate.instant('ASSET')}: ${assetName} - ${this._translate.instant('CYCLE')}: ${cycle}`;
  }

  /**
   * Build the dataset for each cycle (one dataset by cycle)
   * @param assetByZone The asset by zone
   */
  private _getDatasetsByCycle(assetByZone: Record<string, AssetDataByZone>): typeof this.chartConfiguration.data.datasets {
    // Use array for faster access to dataset
    const datasets: Record<string, typeof this.chartConfiguration.data.datasets[0]> = {};
    const zoneQuantity = Object.keys(assetByZone).length;
    Object.values(assetByZone).forEach(
      (asset, index) => asset.cycles.forEach(
        cycle => {
          const label = this._getLabelForCycle(asset.assetName, cycle.number);
          // If there is no dataset for this label (ie. the cycle), add blank one
          if (!datasets[label]) {
            datasets[label] = {
              label,
              // Set the data as array of as many items as zones
              data: Array.of(zoneQuantity),
              borderColor: DatasetColor.asset,
              backgroundColor: DatasetColor.asset,
              fill: false,
              borderWidth: 2,
              pointBorderColor: DatasetColor.asset,
              pointBackgroundColor: DatasetColor.asset,
              pointBorderWidth: 3,
              showLine: false,
            };
          }
          // Set the cycle time for the zone
          datasets[label].data[index] = cycle.time;
        }
      )
    );
    return Object.values(datasets);
  }

}
