import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KpiFamily } from 'src/app/services/kpi.service';
import ChartBaseComponent from '../../../shared/kpi/chart.base';
import { Ng2ChartConfiguration } from '../../../shared/kpi/chart.model';

@Component({
  selector: 'app-bar-cycle-loop',
  templateUrl: '../../../shared/kpi/chart-card-scrollable.component.html',
  styleUrls: ['../../../shared/kpi/chart-card-scrollable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarCycleLoopComponent extends ChartBaseComponent<KpiFamily, 'bar', number> implements OnChanges {

  @Input() selectedAssetIndex: number;

  public chartConfiguration: Ng2ChartConfiguration<'bar', number[], number> = {
    type: 'bar',
    data: { datasets: [] },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      onClick: (event, item) => this.chartClicked.emit({ event, item }),
      plugins: {
        title: {
          display: true,
          text: this._translate.instant('CYCLE_TIME_MONITORING'),
          position: 'top',
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          display: true,
          beginAtZero: true,
          grid: {
            display: false,
          },
        },
        y: {
          display: true,
          beginAtZero: true,
        },
      },
    },
  };

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    const skip = super.ngOnChanges(changes);
    if (skip) {
      return;
    }
    if (changes.selectedAssetIndex) {
      if (changes.selectedAssetIndex.currentValue !== changes.selectedAssetIndex.previousValue) {
        this.setKpiData();
        this._cd.markForCheck();
        return;
      }
    }
  }

  public setKpiData(): void {
    if (this.selectedAssetIndex === undefined || this.selectedAssetIndex === null) {
      return;
    }
    const cycles = this.dataKPI.familyAssets[this.selectedAssetIndex].assetCycles;
    const assetName = this.dataKPI.familyAssets[this.selectedAssetIndex].assetName;
    this.graphSize = (cycles.length > 30) ? `${60 * cycles.length}px` : 'auto';
    this.chartConfiguration.data = {
      labels: cycles.map(
        (cycle) => cycle.cycleCycleOrder
      ),
      datasets: [
        {
          label: this._translate.instant('CYCLE_TIME'),
          backgroundColor: 'blue',
          data: cycles.map(
            (cycle) => +cycle.cycleCycleTime.toFixed(2),
          ),
          borderColor: 'blue',
          borderWidth: 1,
        }
      ]
    };
    this.chartConfiguration.options.plugins.title.text = `${this._translate.instant('CYCLE_TIME_MONITORING')} (${this._translate.instant('ASSET')} ${assetName})`;
    this.chartConfiguration.options.plugins.annotation = {
      annotations: {
        familyMeanCycleTime: {
          type: 'line',
          yMin: this.dataKPI.familyMeanCycleTime.toFixed(2),
          yMax: this.dataKPI.familyMeanCycleTime.toFixed(2),
          borderColor: 'tomato',
          borderWidth: 1,
          borderDash: [2, 2],
        },
      },
    };
    this.chart?.update();
  }

}
