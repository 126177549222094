import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { RedirectComponent } from './components/auth/redirect/redirect.component';
import { QuicklinkviewComponent } from './components/quick-link-view/quick-link-view.component';
import { authGuard } from './auth/auth.guard';
import { hasRightGuard } from './auth/has-right.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
    {
        path: 'map',
        loadChildren: () => import('./components/esri-map/esri-map.module').then(m => m.EsriMapModule),
        canActivate: [authGuard],
        data: { context: true },
    },
    {
        path: 'planning',
        loadChildren: () => import('./components/planning/planning.module').then(m => m.PlanningModule),
        canActivate: [authGuard],
        data: { context: true },
    },
    {
        path: 'aircraft-planning',
        loadChildren: () => import('./components/aircraft-planning/aircraft-planning.module').then(m => m.AircraftPlanningModule),
        canActivate: [authGuard],
        data: { context: true },
    },
    {
        path: 'aircraftPlanning',
        redirectTo: 'aircraft-planning',
    },
    {
        path: 'admin',
        loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule),
        canActivate: [authGuard, hasRightGuard],
        data: { context: true },
    },
    {
        path: 'adminAssets',
        redirectTo: '/admin/assets',
    },
    {
        path: 'adminFamilies',
        redirectTo: '/admin/families',
    },
    {
        path: 'adminDevices',
        redirectTo: '/admin/devices',
    },
    {
        path: 'adminAllDevices',
        redirectTo: '/admin/search-devices',
    },
    {
        path: 'adminAnchors',
        redirectTo: '/admin/anchors',
    },
    {
        path: 'adminZones',
        redirectTo: '/admin/zones',
    },
    {
        path: 'alert',
        loadChildren: () => import('./components/alert/alert.module').then(m => m.AlertModule),
        canActivate: [authGuard, hasRightGuard],
        data: { context: true },
    },
    {
        path: 'kpi',
        loadChildren: () => import('./components/kpi/kpi.module').then(m => m.KpiModule),
        canActivate: [authGuard, hasRightGuard],
        data: { context: true },
    },
    {
        path: 'usage-metrics',
        loadChildren: () => import('./components/usage-metrics/usage-metrics.module').then(m => m.UsageMetricsModule),
        canActivate: [authGuard],
    },
    {
        path: 'dev-tools',
        loadChildren: () => import('./components/dev-tools/dev-tools.module').then(m => m.DevToolsModule),
        canActivate: [authGuard]
    },
    {
        path: 'association',
        component: QuicklinkviewComponent,
        canActivate: [authGuard, hasRightGuard]
    },
    {
        path: 'rights',
        loadChildren: () => import('./components/user-right/user-right.module').then(m => m.UserRightModule),
        canActivate: [authGuard],
    },
    {
        path: 'requestForRights',
        redirectTo: '/rights/request',
    },
    {
        path: 'manageUserRole',
        redirectTo: '/rights/manage-user-roles',
    },
    {
        path: 'manageFamilyUsers',
        redirectTo: '/rights/manage-family-users',
    },
    {
        path: 'manageRightRequest',
        redirectTo: '/rights/manage-requests',
    },
    {
        path: 'manageGroups',
        redirectTo: '/rights/groups',
    },
    {
        path: 'certificates',
        loadChildren: () => import('./components/certificates/certificates.module').then(m => m.CertificatesModule),
        canActivate: [authGuard],
    },
    {
        path: 'manageCertificates',
        redirectTo: '/certificates',
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'oauth2redirect',
        component: RedirectComponent,
        canActivate: [authGuard]
    },
    {
        path: '',
        redirectTo: '/map/view-map',
        pathMatch: 'full',
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {}),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
