import { Inject, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import moment, { LongDateFormatKey, Moment } from 'moment';
import { Language, LanguageService } from '../services/language.service';
import { distinctUntilChanged } from 'rxjs';

export class LocaleMomentDateAdapter extends MomentDateAdapter {

    private _dateFormatLang: Language;

    constructor(
        @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
        @Inject(LanguageService) private language: LanguageService,
    ) {
        super(dateLocale);
        this.language.selectedDateFormat$.pipe(
            distinctUntilChanged(),
        ).subscribe((value) => {
            this._dateFormatLang = value;
        });
    }

    override format(date: Moment, displayFormat: string): string {
        date = this.clone(date);
        if (!this.isValid(date)) {
          throw Error('MomentDateAdapter: Cannot format invalid date.');
        }
        const localeData = moment.localeData(this._dateFormatLang);
        const matches = displayFormat.match(/LTS|LT|L+/gi) ?? [];
        for (const match of matches) {
            displayFormat = displayFormat.replace(/LTS|LT|L+/i, localeData.longDateFormat(match as LongDateFormatKey));
        }
        return date.format(displayFormat);
    }
}
