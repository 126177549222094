import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { MachineStatus } from 'src/app/models/certificate';
import { Status, getStatusFromMachineStatus } from '../_shared';

@Component({
    selector: 'app-status-step',
    templateUrl: './status-step.component.html',
    styleUrls: ['./status-step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusStepComponent {

    private cd = inject(ChangeDetectorRef);

    @Input() statusText: string;

    public stepStatus: Status;
    @Input() set status(value: MachineStatus) {
        this.stepStatus = getStatusFromMachineStatus(value);
        this.cd.markForCheck();

    }

    public readonly Status = Status;

}
