import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KpiScale } from 'src/app/models/kpiform';
import { KpiFamily } from 'src/app/services/kpi.service';
import ChartBaseComponent from '../../../shared/kpi/chart.base';
import { Ng2ChartConfiguration } from '../../../shared/kpi/chart.model';

enum DatasetColors {
  static = 'orange',
  moving = 'blue',
  unknown = 'grey',
}

@Component({
  selector: 'app-bar-family-static',
  templateUrl: '../../../shared/kpi/chart-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarFamilyStaticComponent extends ChartBaseComponent<KpiFamily, 'bar', string> implements OnChanges {

  @Input() scale: KpiScale;

  public chartConfiguration: Ng2ChartConfiguration<'bar', number[], string> = {
    type: 'bar',
    data: { datasets: [] },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          position: 'top',
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          stacked: true,
          display: true,
          beginAtZero: true,
          grid: {
            display: false
          }
        },
        y: {
          stacked: true,
          display: true,
        },
      },
    }
  };

  private _unit: string;

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    super.ngOnChanges(changes);
    if (changes.scale) {
      if (changes.scale.currentValue !== changes.scale.previousValue) {
        this._handleScaleChange();
        this._cd.markForCheck();
      }
    }
  }

  /**
   * Handle a change of scope
   * Set the values relative to percentage or values
   */
  private _handleScaleChange() {
    this._unit = this.scale === 'percent' ? '%' : this._translate.instant('HOURS');
    this.chartConfiguration.options.plugins.title.text = `${this._translate.instant('STATIC_TIME_MONITORING')} - ${this._translate.instant('ASSET_TABLE_PARENT')} (${this._unit})`;
    this.chartConfiguration.options.scales.y.ticks = this.scale === 'percent' ? { callback(value) { return value + '%'; } } : undefined;
    if (this.dataKPI) {
      this.setKpiData();
    }
  }

  /**
   * Get the percentage of time relative to all times (static + moving + unknown)
   * @param time The time value
   * @returns The percentage
   */
  private _getPercentageOfTime(time: number): number {
    const sum = this.dataKPI.familyStaticTime + this.dataKPI.familyMovingTime + this.dataKPI.familyUnknownTime;
    return +(time / sum * 100).toFixed(2);
  }

  setKpiData(): void {
    this.chartConfiguration.data = {
      labels: [this.dataKPI.familyName],
      datasets: [
        {
          label: `${this._translate.instant('STATIC_TIME')} (${this._unit})`,
          backgroundColor: DatasetColors.static,
          data: [this.scale === 'percent' ? this._getPercentageOfTime(this.dataKPI.familyStaticTime) : this.dataKPI.familyStaticTime],
          borderColor: DatasetColors.static,
          borderWidth: 1,
        },
        {
          label: `${this._translate.instant('MOVING_TIME')} (${this._unit})`,
          backgroundColor: DatasetColors.moving,
          data: [this.scale === 'percent' ? this._getPercentageOfTime(this.dataKPI.familyMovingTime) : this.dataKPI.familyMovingTime],
          borderColor: DatasetColors.moving,
          borderWidth: 1,
        },
        {
          label: `${this._translate.instant('UNKNOWN_TIME')} (${this._unit})`,
          backgroundColor: DatasetColors.unknown,
          data: [this.scale === 'percent' ? this._getPercentageOfTime(this.dataKPI.familyUnknownTime) : this.dataKPI.familyUnknownTime],
          borderColor: DatasetColors.unknown,
          borderWidth: 1,
        },
      ],
    };
  }
}
