<div class="way-component--filterable-container">

    <div class="way-filter-container">
        <app-filter [parentSourceView]="view" [dataForFilter$]="rightsTree$"></app-filter>
    </div>

    <div class="way-component--header">
        <div class="way-component--header-title">{{"REQUEST_RIGHT_TITLE" | translate}}</div>
        <div class="groups">
          <span class="title">{{ "MY_GROUP" | translate }}</span>
          <app-user-groups-list [user]="user$ | async"
                                [action]="GROUP_ACTIONS.request"
                                [qtyGroupsToDisplay]="qtyGroupsToDisplay"
                                [disabled]="isLoadingTable"></app-user-groups-list>
        </div>
    </div>

    <div class="way-table--main-container">

        <div class="table-container custom-scrollbar">

            <table class="way-table" mat-table matSort multiTemplateDataRows [dataSource]="dataSource"
                [attr.aria-label]="'ARIA_REQUEST_FOR_RIGHT_TABLE' | translate">
                <!-- Parent Families -->
                <ng-container matColumnDef="{{ column }}" *ngFor="let column of displayedColumns">
                    <th mat-header-cell mat-sort-header [disabled]="column === 'actionColumns' ? true : false"
                        *matHeaderCellDef scope="col">
                        {{ COLUMN_NAME_I18N_KEY[column] | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <!-- Datas -->
                        <div *ngIf="element && column !== 'actionColumns'">
                            <ng-container [ngSwitch]="column">
                                <div *ngSwitchCase="'level'">
                                    <mat-chip-option *ngIf="element.level !== 'basic'" class="{{element.level}}">
                                        {{ element.level | translate }}
                                    </mat-chip-option>
                                </div>
                                <div *ngSwitchCase="'site'">
                                    {{ element.site?.name }}
                                </div>
                                <div *ngSwitchCase="'company'">
                                    {{ element.company?.name }}
                                </div>
                                <div *ngSwitchDefault>
                                    {{ element[column] }}
                                </div>
                            </ng-container>
                        </div>
                        <!-- Buttons -->
                        <ng-container *ngIf="element && column === 'actionColumns'" class="action-column">

                            <ng-container [ngTemplateOutlet]="!element.isActionLoading ? parentFamilyRequestSent : subTableLoader"
                                          [ngTemplateOutletContext]="{ expanded: element.expanded }"></ng-container>
                            <ng-template #parentFamilyRequestSent>
                                <span *ngIf="element.requestedLevel">
                                    <fa-icon icon="paper-plane"
                                             class="action-menu-icon"
                                             [matTooltip]="'REQUEST_RIGHT_STATUS_TEXT' | translate:{manager: element.manager.email, level: element.requestedLevel}">
                                    </fa-icon>
                                </span>
                            </ng-template>

                            <div [matTooltip]="'RIGHTS_ONLY_FROM_GROUP' | translate"
                                [matTooltipDisabled]="!element.familyFromGroup">
                                <button mat-icon-button [matMenuTriggerFor]="parentFamilyActionMenu"
                                    [disabled]="element.familyFromGroup" (click)="$event.stopPropagation()">
                                    <fa-icon icon="ellipsis-v" class="table--action-icon"></fa-icon>
                                </button>
                            </div>

                            <button mat-icon-button
                                    *ngIf="!element.isLoading; else subTableLoader">
                                <mat-icon [@rotate180]="element.expanded ? 'rotate' : 'void'">expand_more</mat-icon>
                            </button>

                            <mat-menu #parentFamilyActionMenu="matMenu" xPosition="before">
                                <button mat-menu-item
                                        *ngIf="!element.requestedLevel"
                                        (click)="onClickAskRightButton(element)">
                                    <fa-icon icon="envelope"
                                             [fixedWidth]="true"
                                             class="action-menu-icon"></fa-icon>
                                    {{"REQUEST_RIGHT_ASK" | translate}}
                                </button>
                                <button mat-menu-item
                                        *ngIf="element.level !== 'basic'"
                                        (click)="onClickRemoveRoleButton(true, element)">
                                    <fa-icon icon="times"
                                             [fixedWidth]="true"
                                             class="action-menu-icon"></fa-icon>
                                    {{"MANAGE_RIGHT_REMOVE_ROLE" | translate}}
                                </button>
                                <button mat-menu-item
                                        *ngIf="element.requestedLevel"
                                        (click)="onClickCancelRequestButton(element)">
                                    <fa-icon icon="times"
                                             [fixedWidth]="true"
                                             class="action-menu-icon"></fa-icon>
                                    {{"REQUEST_RIGHT_CANCEL" | translate}}
                                </button>
                            </mat-menu>

                        </ng-container>

                    </td>
                </ng-container>

                <!-- Families  -->
                <ng-container matColumnDef="expandedDetail">
                    <th mat-cell *matCellDef="let parentFamily" [attr.colspan]="displayedColumns.length" scope="col">
                        <div class="table-expanded-details"
                            [@detailExpand]="parentFamily.expanded ? 'expanded' : 'collapsed'">
                            <table *ngIf="parentFamily.families.length; else noDataTpl" class="table-sub-table"
                                mat-table matSort [dataSource]="parentFamily.families"
                                [attr.aria-label]="'ARIA_REQUEST_FOR_RIGHT_TABLE_EXPANDED' | translate">

                                <ng-container matColumnDef="{{ column }}" *ngFor="let column of displayedColumns">
                                    <th mat-header-cell mat-sort-header
                                        [disabled]="column === 'actionColumns' ? true : false" *matHeaderCellDef
                                        scope="col">{{ COLUMN_NAME_I18N_KEY[column] | translate }} </th>
                                    <td mat-cell *matCellDef="let element" class="expanded-td">

                                        <ng-container [ngSwitch]="column">
                                            <div *ngSwitchCase="'level'">
                                                <mat-chip-option *ngIf="element.level !== 'basic'"
                                                    class="{{element.level}}">
                                                    {{ element.level | translate }}
                                                </mat-chip-option>
                                            </div>
                                            <div *ngSwitchCase="'site'">
                                                {{ parentFamily?.site?.name }}
                                            </div>
                                            <div *ngSwitchCase="'company'">
                                                {{ parentFamily?.company?.name }}
                                            </div>
                                            <div *ngSwitchDefault>
                                                {{ element[column] }}
                                            </div>
                                        </ng-container>

                                        <!-- Buttons -->
                                        <ng-container *ngIf="element && column === 'actionColumns'" class="action-column">
                                            <ng-container *ngIf="!element.isActionLoading ; else subTableLoader">
                                                <ng-container *ngIf="element.requestedLevel">
                                                    <span>
                                                        <fa-icon icon="paper-plane"
                                                            class="action-menu-icon"
                                                            [matTooltip]="'REQUEST_RIGHT_STATUS_TEXT' | translate:{manager: element.manager.email, level: element.requestedLevel}">
                                                        </fa-icon>
                                                    </span>
                                                </ng-container>
                                            </ng-container>
                                            <div [matTooltip]="'RIGHTS_ONLY_FROM_GROUP' | translate"
                                                [matTooltipDisabled]="!element.fromGroupId">
                                                <button mat-icon-button [matMenuTriggerFor]="roleActionMenu"
                                                    [disabled]="!!element.fromGroupId">
                                                    <fa-icon icon="ellipsis-v" class="table--action-icon"></fa-icon>
                                                </button>
                                            </div>
                                        </ng-container>
                                        <mat-menu #roleActionMenu="matMenu"
                                                  xPosition="before">
                                            <button mat-menu-item
                                                    *ngIf="!element.requestedLevel"
                                                    (click)="onClickAskRightButton(parentFamily, element)">
                                                <fa-icon icon="envelope"
                                                         [fixedWidth]="true"
                                                         class="action-menu-icon"></fa-icon>
                                                {{"REQUEST_RIGHT_ASK" | translate}}
                                            </button>
                                            <button mat-menu-item
                                                    *ngIf="element.level !== 'basic'"
                                                    (click)="onClickRemoveRoleButton(false, element)">
                                                <fa-icon icon="times"
                                                         [fixedWidth]="true"
                                                         class="action-menu-icon"></fa-icon>
                                                {{"MANAGE_RIGHT_REMOVE_ROLE" | translate}}
                                            </button>
                                            <button mat-menu-item
                                                    *ngIf="element.requestedLevel"
                                                    (click)="onClickCancelRequestButton(parentFamily, element)">
                                                <fa-icon icon="times"
                                                         [fixedWidth]="true"
                                                         class="action-menu-icon"></fa-icon>
                                                {{"REQUEST_RIGHT_CANCEL" | translate}}
                                            </button>
                                        </mat-menu>
                                    </td>
                                </ng-container>

                                <tr mat-header-row style="display: none;" *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let familyRow; columns: displayedColumns;"></tr>

                            </table>
                        </div>
                    </th>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky : true"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="general-row"
                    [class.table-expanded-row]="element.expanded" (click)="onClickParentFamilyRow(element);">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
            </table>

            <ng-template #noDataTpl>
                <div class="expanded-row--no-data">
                    <span>{{'MY_RIGHTS_NO_SUB_FAMILLY' | translate }}</span>
                </div>
            </ng-template>

            <div *ngIf="isLoadingTable" class="table-loader">
                <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
                </mat-progress-spinner>
            </div>

        </div>
        <mat-paginator class="way-table--footer" (page)="scrollUp()" [pageSizeOptions]="[25, 50, 100]">
        </mat-paginator>

        <ng-template #subTableLoader let-expanded="expanded" class="table-loader">
                <mat-progress-spinner [color]="expanded ? 'accent' : 'primary'" mode="indeterminate" diameter="16">
                </mat-progress-spinner>
        </ng-template>

    </div>

</div>
