import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { inflate } from 'pako';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Tracker, UpdateTracker } from '../models/tracker';
import { ApiBuffer } from '../models/utils';
import { ErrorTranslationService } from './error-translation.service';
import { Anchor, UpdateAnchor } from '../models/anchor';

export class AddDevice {
    provider: string;
    serial: string;
    beaconId?: string;
}

export class UpdateDevice {
    trackerId: string;
    beaconId: string;
}

export interface UploadDevices {
    provider: Tracker['provider'];
    devices: {
        sn: Tracker['serial'];
        beaconId?: Tracker['beaconId'];
    }[];
}

export interface CreateDeviceFailure {
    serial: string;
    message: string;
}

export interface CreateDeviceReturn {
    provider: string;
    failures: CreateDeviceFailure[];
    successes: Tracker[];
}

export interface CreateTrackerByFile {
    UUID: string;
    devicesCount: number;
    provider: string;
}

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root'
})

export class TrackerService {
    limit = 10;
    limit$ = new BehaviorSubject<number>(this.limit);

    constructor(private http: HttpClient,
        private _errorTranslationService: ErrorTranslationService,
    ) { }

    public getTrackers(): Observable<Tracker[]> {
        return this.http.get<ApiBuffer>(
            `${environment.API_ENDPOINT}/trackers`
        ).pipe<Tracker[]>(map(data => JSON.parse(inflate(data['data'], { to: 'string' }))));
    }

    public getTrackersByRights(): Observable<Tracker[]> {
        return this.http.get<Tracker[]>(
            `${environment.API_ENDPOINT}/trackers-rights`
        );
    }

    public searchForTrackers(trackersCharacters: string): Observable<Tracker[]> {
        const httpParams = new HttpParams().append('serial', trackersCharacters);
        return this.http.get<ApiBuffer>(
            `${environment.API_ENDPOINT}/trackers-search`, { params: httpParams }
        ).pipe<Tracker[]>(map(data => JSON.parse(inflate(data['data'], { to: 'string' }))));
    }

    public getTrackersSns(): Observable<string[]> {
        return this.http.get<string[]>(`${environment.API_ENDPOINT}/trackers/sn`);
    }

    public getTrackersUnlinked(): Observable<Tracker[]> {
        return this.http.get<ApiBuffer>(
            `${environment.API_ENDPOINT}/trackers?associated=false`
        ).pipe<Tracker[]>(map(data => JSON.parse(inflate(data['data'], { to: 'string' }))));
    }

    public getTrackersUnlinkedBySignalSource(signalSource: string): Observable<Tracker[]> {
        return this.http.get<ApiBuffer>(
            `${environment.API_ENDPOINT}/trackers?associated=false&signalSource=${signalSource}`
        ).pipe<Tracker[]>(map(data => JSON.parse(inflate(data['data'], { to: 'string' }))));
    }

    /**
     * Get non associated trackers by scope
     */
    public getTrackersByScope(scope: string): Observable<Tracker[]> {
        return this.http.get<Tracker[]>(`${environment.API_ENDPOINT}/trackers-by-scope?scope=${scope}`).pipe<Tracker[]>(map(data => {
            return JSON.parse(inflate(data['data'], { to: 'string' }));
        }));
    }

    public createTracker(tracker: AddDevice): Observable<Tracker> {
        return this.http.post<Tracker>(
            `${environment.API_ENDPOINT}/trackers`, tracker,
            { observe: 'response' }
        ).pipe(
            catchError((err: HttpErrorResponse) => { throw of(this._handleCreationErrors(err)); }),
            map(data => data.body)
        );
    }

    public createTrackers(dataFile: UploadDevices['provider'], allTextLines: UploadDevices['devices']): Observable<CreateTrackerByFile> {
        return this.http.post(`${environment.API_ENDPOINT}/upload/import`, { provider: dataFile, devices: allTextLines })
            .pipe(map(data => data as CreateTrackerByFile)
        );
    }

    public deleteTracker(trackerId: string): Observable<Tracker> {
        return this.http.delete<Tracker>(`${environment.API_ENDPOINT}/trackers/${trackerId}`);
    }

    public updateTracker(tracker: UpdateTracker, trackerId: string): Observable<Tracker> {
        return this.http.put<Tracker>(
            `${environment.API_ENDPOINT}/trackers/${trackerId}`,
            JSON.stringify(tracker),
            httpOptions
        );
    }
    public getProviders(): Observable<string[]> {
        return this.http.get<string[]>(`${environment.API_ENDPOINT}/providers`);
    }

    private _handleCreationErrors(error: HttpErrorResponse) {
        this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
        return error;
    }

    public getAllAnchors(): Observable<Anchor[]> {
        return this.http.get<Anchor[]>(`${environment.API_ENDPOINT}/anchors`);
    }

    public updateAnchor(anchor: UpdateAnchor, id: string): Observable<UpdateAnchor> {
        return this.http.put<Anchor>(`${environment.API_ENDPOINT}/anchor/${id}`, JSON.stringify((anchor)), httpOptions);
    }

    public updateDevice(tracker: UpdateDevice): Observable<Tracker> {
        return this.http.put<Tracker>(
            `${environment.API_ENDPOINT}/trackers/${tracker.trackerId}`, {beaconId : tracker.beaconId});
    }
}
