import { createAction, props } from '@ngrx/store';
import { FamilyRight, ParentFamilyRight } from 'src/app/models/family';
import { Scope } from 'src/app/models/profile';
import { UsageMetrics } from 'src/app/models/usage-metrics';
import { Group } from 'src/app/models/user-right';


export const fetchUsageMetrics = createAction('[UsageMetrics] fetch usageMetrics', props<{
    fromDate: Date,
    toDate: Date,
    scopes?: Scope[],
    parentFamilies?: ParentFamilyRight['id'][],
    families?: FamilyRight['id'][],
    groups?: Group['id'][],
}>());
export const successFetchUsageMetrics = createAction('[UsageMetrics] success fetch usageMetrics', props<{ payload: UsageMetrics[] }>());
export const errorFetchUsageMetrics = createAction('[UsageMetrics] error fetch usageMetrics');
