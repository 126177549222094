import { Component } from '@angular/core';
import { KpiFamily } from 'src/app/services/kpi.service';
import ChartBaseComponent from 'src/app/components/shared/kpi/chart.base';
import { GraphSize, Ng2ChartConfiguration } from 'src/app/components/shared/kpi/chart.model';

@Component({
  selector: 'app-bar-assets-cycle',
  templateUrl: '../../../shared/kpi/chart-card-scrollable.component.html',
  styleUrls: ['../../../shared/kpi/chart-card-scrollable.component.scss'],
})
export class BarAssetsCycleComponent extends ChartBaseComponent<KpiFamily, 'bar', string> {

  public chartConfiguration: Ng2ChartConfiguration<'bar', number[], string> = {
    type: 'bar',
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      'onClick': (event, item) => this.chartClicked.emit({ event, item }),
      plugins: {
        title: {
          display: true,
          text: this._translate.instant('CYCLE_TIME_MONITORING'),
          position: 'top',
        },
        legend: {
          display: true,
          position: 'bottom',

        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          display: true,
          beginAtZero: true,
          grid: {
            display: false
          }
        },
        y: {
          display: true,
          beginAtZero: true,
        },
      },
    },
  };
  public graphSize: GraphSize = 'auto';

  setKpiData(): void {
    // Set annotation
    this.chartConfiguration.options.plugins.annotation = {
      annotations: {
        familyMean: {
          type: 'line',
          yMin: this.dataKPI.getFamilyMeanCycleTime().toFixed(2),
          yMax: this.dataKPI.getFamilyMeanCycleTime().toFixed(2),
          borderColor: 'tomato',
          borderWidth: 1,
          borderDash: [2, 2]
        },
      },
    };
    // Set data
    this.chartConfiguration.data = {
      labels: this.dataKPI.familyAssets.map(
        asset => asset.assetName
      ),
      datasets: [
        {
          label: `${this._translate.instant('MIN')} (${this._translate.instant('HOURS')})`,
          backgroundColor: '#19EAFF',
          data: this.dataKPI.familyAssets.map(
            (asset) => +asset.getAssetMinCycleTime().toFixed(2)
          ),
          borderColor: '#19EAFF',
          borderWidth: 1,
        },
        {
          label: `${this._translate.instant('MEAN')} (${this._translate.instant('HOURS')})`,
          backgroundColor: 'orange',
          data: this.dataKPI.familyAssets.map(
            (asset) => +asset.getAssetMeanCycleTime().toFixed(2)
          ),
          borderColor: 'orange',
          borderWidth: 1,
        },
        {
          label: `${this._translate.instant('MAX')} (${this._translate.instant('HOURS')})`,
          backgroundColor: 'blue',
          data: this.dataKPI.familyAssets.map(
            (asset) => +asset.getAssetMxCycleTime().toFixed(2)
          ),
          borderColor: 'blue',
          borderWidth: 1,
        }
      ]
    };
    this.graphSize = (this.dataKPI.familyAssets.length > 20) ? `${100 * this.dataKPI.familyAssets.length}px` : 'auto';
  }

}
