import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject, Subscription } from 'rxjs';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'range-input',
    templateUrl: './range-input.component.html',
    styleUrls: ['./range-input.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: RangeInputComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeInputComponent implements ControlValueAccessor, MatFormFieldControl<Range>, AfterViewInit, OnDestroy {
    @Input()
    get min(): number {
        return this._min;
    }
    set min(value: NumberInput) {
        this._min = coerceNumberProperty(value);
        this._updateMinMaxValidators();
    }
    @Input()
    get max(): number {
        return this._max;
    }
    set max(value: NumberInput) {
        this._max = coerceNumberProperty(value);
        this._updateMinMaxValidators();
    }

    get empty(): boolean {
        if (this.parts) {
            const {
                value: { min, max }
            } = this.parts;
            return !min?.toString() && !max?.toString();
        }
        return true;
    }

    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }
    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.parts.disable() : this.parts.enable();
        this.stateChanges.next();
    }

    @Input()
    get value(): Range | null {
        const {
            value: { min, max }
        } = this.parts;
        let minimum: number;
        let maximum: number;
        if (min?.toString() && !isNaN(+min)) {
            minimum = +min;
        }
        if (max?.toString() && !isNaN(+max)) {
            maximum = +max;
        }
        return new Range(minimum, maximum);
    }
    set value(range: Range | null) {
        const { min, max } = range || new Range();
        this.parts.setValue({ min: min || null, max: max || null });
        this.stateChanges.next();
    }

    get errorState(): boolean {
        return this.parts.invalid && this.touched;
    }
    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
        this._required ? this.parts.addValidators([Validators.required]) : this.parts.removeValidators([Validators.required]);
        this.parts.updateValueAndValidity();
        this.stateChanges.next();
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        if (this.ngControl !== null && typeof this.ngControl !== 'undefined') {
            this.ngControl.valueAccessor = this;
        }
        this.parts = this._formBuilder.group({
            min: this._formBuilder.control<number>(null),
            max: this._formBuilder.control<number>(null),
        });
    }
    static nextId = 0;
    @HostBinding('class.label-floating') get classLabelFloating() {
        return this.shouldLabelFloat;
    }
    @ViewChild('minInput') minInput: ElementRef<HTMLInputElement>;
    @ViewChild('maxInput') maxInput: ElementRef<HTMLInputElement>;
    public readonly MAX_VALUE = 1000;
    public parts: FormGroup<{
        min: FormControl<number>;
        max: FormControl<number>;
    }>;
    public stateChanges = new Subject<void>();
    public focused = false;
    public touched = false;
    public controlType = 'range-input';
    @HostBinding('id') public id = `range-input-${RangeInputComponent.nextId++}`;
    public autofilled = false;

    private _minValidator: ValidatorFn = Validators.min(this.min);
    private _maxValidator: ValidatorFn = Validators.max(this.max);
    private _subscription = new Subscription();

    private _min: number;

    private _max: number;

    // tslint:disable-next-line:no-input-rename
    @Input() 'aria-describedby': string;

    private _placeholder: string;

    private _disabled: boolean;

    private _required: boolean;
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    public onChange = (_: Range) => { };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onTouched = () => { };

    private _updateMinMaxValidators() {
        // Min validator
        this.parts?.controls.min.removeValidators(this._minValidator);
        this.parts?.controls.max.removeValidators(this._minValidator);
        this._minValidator = Validators.min(this._min);
        this.parts?.controls.min.addValidators(this._minValidator);
        this.parts?.controls.max.addValidators(this._minValidator);
        // Max validator
        this.parts?.controls.min.removeValidators(this._maxValidator);
        this.parts?.controls.max.removeValidators(this._maxValidator);
        this._maxValidator = Validators.max(this._max);
        this.parts?.controls.min.addValidators(this._maxValidator);
        this.parts?.controls.max.addValidators(this._maxValidator);
        // Update validation
        this.parts?.updateValueAndValidity();
    }

    public ngAfterViewInit(): void {
        // Propagate errors
        this._subscription.add(
            this.ngControl?.statusChanges?.subscribe(
                () => {
                    this.parts.setErrors(this.ngControl.errors);
                    this.stateChanges.next();
                }
            )
        );
    }

    public ngOnDestroy(): void {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
        this._subscription.unsubscribe();
    }

    public onFocusIn(): void {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    public onFocusOut(event: FocusEvent): void {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    public autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
        if (!control.errors && +control.value === this.MAX_VALUE && nextElement) {
            this._focusMonitor.focusVia(nextElement, 'program');
        }
    }

    public autoFocusPrev(control: AbstractControl, prevElement?: HTMLInputElement): void {
        if (!control.value?.toString()) {
            this._focusMonitor.focusVia(prevElement, 'program');
        }
    }

    public setDescribedByIds(ids: string[]): void {
        const controlElement = this._elementRef.nativeElement.querySelector(
            '.range-container',
        );
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    public onContainerClick(event: MouseEvent): void {
        if (event.target === this.minInput.nativeElement || event.target === this.maxInput.nativeElement) {
            return;
        }
        if (!this.empty && this.parts.controls.min.valid) {
            this._focusMonitor.focusVia(this.maxInput, 'program');
        } else {
            this._focusMonitor.focusVia(this.minInput, 'program');
        }
    }

    public writeValue(range: Range | null): void {
        this.value = range;
    }

    public registerOnChange(fn: (_: Range) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
        this.autoFocusNext(control, nextElement);
        this.onChange(this.value);
    }
}

export class Range {
    constructor(public min?: number, public max?: number) { }
    get valid(): boolean {
        return typeof this.min === 'number' && typeof this.max === 'number';
    }
}

// eslint-disable-next-line @typescript-eslint/prefer-namespace-keyword, @typescript-eslint/no-namespace
export module RangeValidators {

    export function minMaxOrder(): ValidatorFn {
        return (control: AbstractControl<Range>): ValidationErrors | null => {
            const { min: minimum, max: maximum } = control.value ?? {};
            return (+minimum > +maximum) ? { minMaxOrder: { value: control.value } } : null;
        };
    }

    export function bothFilled(): ValidatorFn {
        return (control: AbstractControl<Range>): ValidationErrors | null => {
            const { min: minimum, max: maximum } = control.value ?? {};
            if ((!minimum?.toString() && maximum?.toString()) || (!maximum?.toString() && minimum?.toString())) {
                return { notAllFilled: { value: control.value } };
            }
            return null;
        };
    }

    export function use(validator: ValidatorFn): ValidatorFn {
        return (control: AbstractControl<Range>): ValidationErrors | null => {
            const { min, max } = control.value ?? {};
            const minControl = new FormControl(min?.toString());
            const maxControl = new FormControl(max?.toString());
            const minError = validator(minControl);
            const maxError = validator(maxControl);
            if (minError || maxError) {
                return {
                    ...(minError ?? {}),
                    ...(maxError ?? {}),
                };
            }
            return null;
        };
    }
}
