import {  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentLoaderService {

  constructor(private http: HttpClient) { }

  /**
   * Return the file url when the authencation process is completed
   */
  public getFileUrl(fileId: string, fileType: string): Observable<{ message: string }> {
    return this.http.get<{ message: string }>(`${environment.API_ENDPOINT}/protected-content/type/${fileType}/file/${fileId}`);

  }
}
