import { Action, createReducer, on } from '@ngrx/store';
import {
    successFetchDataForContexts,
    successEditUserContext,
    successDeleteUserFilterContext,
    successSetDefaultContext,
    deleteFamilyFromUserFilter,
    fetchDataForContexts,
    errorFetchDataForContexts,
    fetchAllUserContexts,
    successToggleContextFavorite,
    successFetchAllUserContexts,
    successCreateUserContext,
    successApplyContextForSession,
} from '../actions/filter-context.action';
import { InitFiltersData, UserFilters } from 'src/app/models/filter-context.model';

export interface State {
    data: {
        dataForContexts: InitFiltersData;
        userContexts: UserFilters[];
    };
    loading: boolean;
    loaded: boolean;
    contextDataLoading: boolean;
    userFilterLoaded: boolean;
}

// default state
const INIT_STATE: State = {
    data: {
        dataForContexts: {
            sites: [],
            companies: [],
            parentFamilies: [],
            families: [],
            providers: []
        },
        userContexts: [],
    },
    loading: false,
    loaded: false,
    contextDataLoading: false,
    userFilterLoaded: false,
};

const InitFilterReducer = createReducer(
    INIT_STATE,
    on(fetchDataForContexts, (state) => ({
        ...state,
        contextDataLoading: true,
    })),
    on(errorFetchDataForContexts, (state) => ({
        ...state,
        contextDataLoading: false,
    })),
    on(successFetchDataForContexts, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        contextDataLoading: false,
        data: (() => {
            const datas = { ...state.data };
            const uniqueProviders = [...new Set(payload.families.flatMap(item => Object.keys(item.assetCount)))] as InitFiltersData['providers'];
            const updatedData = { ...payload, providers: uniqueProviders };
            datas.dataForContexts = updatedData;
            return datas;
        })()
    })),
    on(fetchAllUserContexts, (state) => ({
        ...state,
        loading: true,
        userFilterLoaded: false,
    })),
    on(successFetchAllUserContexts, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: {
            ...state.data,
            userContexts: payload.map(
                context => {
                    const storedContext = state.data.userContexts.find(
                        ({ id }) => id === context.id
                    );
                    if (storedContext) {
                        return Object.assign(storedContext, context);
                    } else {
                        return context;
                    }
                }
            ),
        },
    })),
    on(successCreateUserContext, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: {
            ...state.data,
            userContexts: state.data.userContexts.concat(payload),
        },
    })),
    on(successApplyContextForSession, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: {
            ...state.data,
            userContexts: (() => {
                // Find session context
                const sessionContextIndex = state.data.userContexts.findIndex(
                    ({ id }) => !id
                );
                if (sessionContextIndex > -1) {
                    state.data.userContexts[sessionContextIndex] = payload;
                } else {
                    state.data.userContexts.push(payload);
                }
                return state.data.userContexts;
            })(),
        }
    })),
    on(successEditUserContext, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: {
            ...state.data,
            userContexts: state.data.userContexts.map(
                (context) =>  context.id === payload.id ? payload : context
            ),
        },
    })),
    on(successSetDefaultContext, (state, { id }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: {
            ...state.data,
            userContexts: ((contexts) => {
                const previousDefault = contexts.find(
                    ({ defaultContext }) => defaultContext
                );
                if (previousDefault) {
                    previousDefault.defaultContext = false;
                }
                const newDefault = contexts.find(
                    (context) => context.id === id
                );
                if (newDefault) {
                    newDefault.defaultContext = true;
                }
                return contexts;
            })(state.data.userContexts),
        },
    })),
    on(successToggleContextFavorite, (state, { context }) => ({
        ...state,
        data: {
            ...state.data,
            userContexts: state.data.userContexts.map(
                userContext => userContext.id === context.id ? context : userContext
            ),
        },
    })),
    on(successDeleteUserFilterContext, (state, { id: id }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: {
            ...state.data,
            userContexts: state.data.userContexts.filter(
                context => context.id !== id
            ),
        },
    })),
    on(deleteFamilyFromUserFilter, (state, { familyId: payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: (() => {
            const { userContexts: userFilters, dataForContexts: initFilter } = state.data;
            const finalfilteredFamilies = userFilters.map(item => {
                const filteredFamilies = item?.families.filter((family) => family !== payload);
                return { ...item, families: filteredFamilies };
              });
            // Delete from initial filter as well
            const initfilteredFamilies = initFilter.families.filter(family => family.id !== payload);
            const datas = {
                ...state.data,
                userFilters: finalfilteredFamilies,
                initFilter: {
                    ...initFilter,
                    families: initfilteredFamilies
                }
            };

            return datas;
        })()
    })),
    // on(deleteParentFromUserFilter, (state, { parentFamilyId }) => ({
    //     ...state,
    //     loading: false,
    //     loaded: true,
    //     data: (() => {
    //         const { userFilters, initFilter } = state.data;

    //         const finalfilteredParentFamilies = userFilters.map(item => {
    //             const filteredParentFamilies = item?.parentFamilies.filter(pf => pf !== parentFamilyId);
    //             const filteredFamilies = item?.families.filter(family => family?.parentFamilyId !== parentFamilyId);
    //             return { ...item, parentFamilies: filteredParentFamilies, families: filteredFamilies };
    //         });

    //         const initfilteredFamilies = initFilter.families.filter(family => family?.parentFamilyId !== parentFamilyId);
    //         const initfilteredParentFamilies = initFilter.parentFamilies.filter(pf => pf?.id !== parentFamilyId);

    //         const datas = {
    //             ...state.data,
    //             userFilters: finalfilteredParentFamilies,
    //             initFilter: {
    //                 ...initFilter,
    //                 families: initfilteredFamilies,
    //                 parentFamilies: initfilteredParentFamilies
    //             }
    //         };

    //         return datas;

    //     })()
    // })),
);

export function reducer(state: State | undefined, action: Action) {
    return InitFilterReducer(state, action);
}
