<div mat-dialog-title>
    <span>{{ 'UPLOAD_AIRCRAFT_PLANNING_DIALOG_TITLE' | translate }}</span>
    <button mat-icon-button mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<div mat-dialog-content>
    <ng-container *ngIf="!(planningToUpload || data ) && !checkLoading">
        <span>{{ 'UPLOAD_AIRCRAFT_PLANNING_DIALOG_TEXT' | translate }}</span>
        <span>
            {{ 'UPLOAD_AIRCRAFT_TEMPLATE' | translate }}&nbsp;&colon;&nbsp;
            <button mat-icon-button
                    (click)="downloadTemplate()">
                <fa-icon icon="download"
                            size="3x"
                            aria-hidden="true"></fa-icon>
            </button>
        </span>
        <app-file-uploader accept=".csv"
                            (filesChange)="onFile($event)"></app-file-uploader>
    </ng-container>

    <div *ngIf="checkLoading">
        <mat-progress-spinner class="spinner"
                                mode="indeterminate"
                                value="30">
        </mat-progress-spinner>
    </div>

    <ng-container *ngIf="(planningToUpload || data ) && !checkLoading">
        <!--- TODO CHECK with L if he like style properties-->
        <span class="planningItem">{{ 'UPLOAD_AIRCRAFT_PLANNING_VALID_ROWS' | translate }} : {{numberOfValidRows}}</span>

        <div *ngIf="errors?.length > 0">
            <mat-error>
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ 'UPLOAD_AIRCRAFT_PLANNING_SOME_ERRORS' | translate }} {{(planningToUpload.length - numberOfValidRows)}}
            </mat-error> <br />

            <ul>
                <mat-error *ngFor="let err of errors">
                    <li>
                        {{err}} <br />
                    </li>
                </mat-error>
            </ul>
        </div>
        <div *ngIf="hasClashes">
            <mat-error class="warning">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ 'UPLOAD_AIRCRAFT_PLANNING_SOME_CLASHES' | translate }}
            </mat-error> <br />

            <ul>
                <mat-error class="warning"
                            *ngFor="let clash of clashes">
                    <li>
                        {{clash}} <br />
                    </li>
                </mat-error>
            </ul>
        </div>
    </ng-container>

<div mat-dialog-actions>
    <button mat-stroked-button
            mat-dialog-close
            color="primary">
        {{ 'CANCEL' | translate }}
    </button>
    <button *ngIf="clashes?.length > 0 && errors?.length === 0"
            mat-flat-button
            color="primary"
            [disabled]="loading || loadingClash"
            (click)="fixClashes()">
        {{ 'FIX' | translate }}
        <mat-progress-spinner *ngIf="loadingClash"
                              mode="indeterminate"
                              diameter="16"
                              strokeWidth="2">
        </mat-progress-spinner>
    </button>
    <button mat-flat-button
            color="primary"
            [disabled]="loading || loadingClash || errors?.length > 0"
            (click)="uploadPlanning(planningToUpload, true)">
        {{ 'APPLY' | translate }}
        <mat-progress-spinner *ngIf="loading"
                              mode="indeterminate"
                              diameter="16"
                              strokeWidth="2">
        </mat-progress-spinner>
    </button>
</div>
