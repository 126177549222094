import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ContextService as InitFilterDataService } from 'src/app/services/init-filter.service';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';
import {
    fetchDataForContexts,
    successFetchDataForContexts,
    fetchAllUserContexts,
    successFetchAllUserContexts,
    createUserContext,
    errorCreateUserContext,
    editUserContext,
    successEditUserContext,
    setDefaultContext,
    applyContextForSession,
    successDeleteUserFilterContext,
    errorDeleteUserContext,
    deleteUserContext,
    successSetDefaultContext,
    errorEditUserContext,
    toggleContextFavorite,
    successToggleContextFavorite,
    errorToggleContextFavorite,
    successCreateUserContext,
    successApplyContextForSession,
} from '../actions/filter-context.action';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { UserFilters } from 'src/app/models/filter-context.model';
import { TypedAction } from '@ngrx/store/src/models';
import { UserService } from 'src/app/services/user.service';
import { successFetchAllProfiles } from '../actions/profile.action';
import { CacheService } from 'src/app/services/cache.service';

export interface EffectResult {
    result: 'success' | 'error';
    action?: TypedAction<any>;
    id?: string;
}

@Injectable()
export class FilterContextEffects {

    public fetchDataForContexts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fetchDataForContexts),
            switchMap(() => this.initFilterService.fetchDataForContexts()),
            map((data) => successFetchDataForContexts({ payload: data })),
        );
    });

    public fetchAllUserContexts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fetchAllUserContexts),
            switchMap(() => this.initFilterService.fetchAllUserContexts()),
            map((contexts) => successFetchAllUserContexts({ payload: contexts }))
        );
    });

    public createUserContext$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createUserContext),
            switchMap((actions) => this.initFilterService.createUserContext(actions.payload).pipe(
                map((new_filter: UserFilters) => {
                    this.loader$.next(true);
                    const text = this._translate.instant('BANNER_SUCCESS_CREATE_AND_APPLY', { value: new_filter.name });
                    this._snackBar.open(text, 'green-snackbar', 5000);
                    this.effectSubject$.next({ result: 'success', action: createUserContext });
                    return successCreateUserContext({ payload: new_filter });
                }),
                catchError((error) => {
                    this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
                    this.effectSubject$.next({ result: 'error', action: createUserContext });
                    return of(errorCreateUserContext());
                }),
            ))
        );
    });

    public editUserContext$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(editUserContext),
            switchMap((actions) => this.initFilterService.editUserContext(actions.payload).pipe(
                map((userfilter: UserFilters) => {
                    this.loader$.next(true);
                    const text = this._translate.instant('BANNER_SUCCESS_EDIT_AND_APPLY', { value: userfilter.name });
                    this._snackBar.open(text, 'green-snackbar', 5000);
                    this.effectSubject$.next({ result: 'success', action: editUserContext });
                    return successEditUserContext({ payload: userfilter });
                }),
                catchError((error) => {
                    this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
                    this.effectSubject$.next({ result: 'error', action: editUserContext });
                    return of(errorEditUserContext());
                }),
            ))
        );
    });

    /**
     * Set the udpated / created filter as default
     */
    public setDefaultAfterCreateOrUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successCreateUserContext, successEditUserContext, successApplyContextForSession),
            map((action) => successSetDefaultContext({ id: action.payload.id })),
        );
    });

    public setDefaultContext$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setDefaultContext),
            switchMap((actions) => this.initFilterService.setDefaultContext(actions.id).pipe(
                map((userfilter: UserFilters) => {
                    const text = this._translate.instant('BANNER_SUCCESS_UPDATE_STATUS', { value: userfilter.name });
                    this._snackBar.open(text, 'green-snackbar', 5000);
                    this.effectSubject$.next({ result: 'success', action: setDefaultContext });
                    return successSetDefaultContext({ id: userfilter.id });
                }),
                catchError((error) => {
                    this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
                    this.effectSubject$.next({ result: 'error', action: setDefaultContext });
                    return of(errorEditUserContext());
                }),
            ))
        );
    });

    public successSetDefaultContext$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successSetDefaultContext),
            switchMap(() => this._userService.getProfiles().pipe(
                map(profile => {
                    return successFetchAllProfiles({ profile });
                }),
                catchError(error => {
                    this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
                    this.effectSubject$.next({ result: 'error', action: successSetDefaultContext });
                    return of(errorEditUserContext());
                }),
            )),
        );
    });

    public toggleContextFavorite$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(toggleContextFavorite),
            switchMap(action => this.initFilterService.toggleContextFavorite(action.contextId)),
            map(context => {
                const text = this._translate.instant(context.favorite ? 'CONTEXT_FAVORITE_ADDED' : 'CONTEXT_FAVORITE_REMOVED', { context: context.name });
                this._snackBar.open(text, 'green-snackbar', 5000);
                this.effectSubject$.next({ result: 'success', id: context.id, action: toggleContextFavorite });
                return successToggleContextFavorite({ context });
            }),
            catchError(error => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
                this.effectSubject$.next({ result: 'error', action: toggleContextFavorite });
                return of(errorToggleContextFavorite());
            })
        );
    });

    public deleteUserContext$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteUserContext),
            switchMap((actions) => this.initFilterService.deleteUserContext(actions.id).pipe(
                map(() => {
                    const text = this._translate.instant('BANNER_SUCCESS_DELETE', { item: 'Filter' });
                    this._snackBar.open(text, 'green-snackbar', 5000);
                    this.effectSubject$.next({ result: 'success', action: deleteUserContext });
                    return successDeleteUserFilterContext({ id: actions.id });
                }),
                catchError((error) => {
                    this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_DELETE');
                    this.effectSubject$.next({ result: 'error', action: deleteUserContext });
                    return of(errorDeleteUserContext());
                }),
            ))
        );
    });

    public saveSessionContext$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(applyContextForSession),
            switchMap((actions) => this.initFilterService.createSessionContext(actions.payload).pipe(
                map((userfilter: UserFilters) => {
                    this.loader$.next(true);
                    const text = this._translate.instant('BANNER_SUCCESS_SESSION_CONTEXT');
                    this._snackBar.open(text, 'green-snackbar', 5000);
                    this.effectSubject$.next({ result: 'success', action: applyContextForSession });
                    return successApplyContextForSession({ payload: userfilter });
                }),
                catchError((error) => {
                    this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
                    this.effectSubject$.next({ result: 'error', action: applyContextForSession });
                    return of(errorCreateUserContext());
                }),
            ))
        );
    });

    public effectSubject$: Subject<EffectResult>;
    public loader$ = new BehaviorSubject<boolean>(true);
    constructor(
        private initFilterService: InitFilterDataService,
        private actions$: Actions,
        public translate: TranslateService,
        private _snackBar: SnackbarComponent,
        private _translate: TranslateService,
        private _errorTranslationService: ErrorTranslationService,
        private _userService: UserService,
        private _cacheService : CacheService
    ) {
        // this.effectSubject = new Subject<string>();
        this.effectSubject$ = new Subject<EffectResult>();
    }
}
