import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModifyRoleDialogData, ModifyRoleDialogResult, RoleLevel, RoleLevelEnum, ROLES_I18N } from 'src/app/models/user-right';

@Component({
    selector: 'app-dialog-modify-role',
    templateUrl: './dialog-modify-role.component.html',
    styleUrls: ['./dialog-modify-role.component.scss']
})
export class DialogModifyRoleComponent implements OnInit {
    public selectedRole: RoleLevel;
    public roles = ROLES_I18N.filter(role => role.value !== RoleLevelEnum.administrator);

    constructor(public dialogRef: MatDialogRef<DialogModifyRoleComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: ModifyRoleDialogData) { }

    ngOnInit(): void {
        this.selectedRole = this.dialogData.requestTarget.level;
        if (!this.dialogData.showBasicRole) {
          this.roles = this.roles.filter(
            role => role.value !== RoleLevelEnum.basic
          );
        }
    }

    /**
     * Called when the user click on update button
     * - Return the selected role to the ManageUserRole component
     */
    public onClickUpdate() {
        const dialogResult: ModifyRoleDialogResult = {
            action: 'update',
            level: this.selectedRole
        };
        this.dialogRef.close(dialogResult);
    }

    /**
     * Called when the cancel button or the closing cross button are pressed
     */
    public onClickCancel() {
        const dialogResult: ModifyRoleDialogResult = {
            action: 'cancel'
        };
        this.dialogRef.close(dialogResult);
    }

    public getUpdateDisabled(): boolean {
      return this.roles.map(r => r.value).indexOf(this.selectedRole as RoleLevelEnum) < 0;
    }

}
