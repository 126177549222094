import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const selectSiteState = (state: AppState) => state.sites;

export const selectSiteState$ = createSelector(selectSiteState, (state) => state);

export const selectSite$ = createSelector(selectSiteState, (sites) => sites.data);

export const selectSitesByCompanyIds$ = (companyIds: string[]) => createSelector(selectSiteState, (state) => {
    return state.data.filter(site => companyIds.includes(site.companyId));
});

export const selectSiteLoading$ = createSelector(selectSiteState, (sites) => sites.loading);

export const selectSiteLoaded$ = createSelector(selectSiteState, (sites) => sites.loaded);
