import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActiveElement } from 'chart.js';
import { KpiCycle, KpiFamily } from 'src/app/services/kpi.service';
import ChartBaseComponent from '../../../shared/kpi/chart.base';
import { Ng2ChartConfiguration } from '../../../shared/kpi/chart.model';

@Component({
  selector: 'app-bar-zone-loop',
  templateUrl: '../../../shared/kpi/chart-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarZoneLoopComponent extends ChartBaseComponent<KpiFamily, 'bar', string> implements OnChanges {

  @Input() selectedAssetIndex: number;
  @Input() selectedCycleIndex: number;
  @Input() zoneLevel: 1 | 2 = 1;

  public chartConfiguration: Ng2ChartConfiguration<'bar', number[], string> = {
    type: 'bar',
    data: { datasets: [] },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      onClick: (event, item) => this._onClick(item),
      plugins: {
        title: {
          display: true,
          text: this._translate.instant('CYCLE_TIME_MONITORING'),
          position: 'top',
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          stacked: true,
          display: true,
          beginAtZero: true,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          display: true,
          beginAtZero: true,
        },
      },
    },
  };

  private _excludedZones: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    const skip = super.ngOnChanges(changes);
    if (skip) {
      return;
    }
    if (changes.selectedAssetIndex) {
      if (changes.selectedAssetIndex.currentValue !== changes.selectedAssetIndex.previousValue) {
        this._excludedZones = [];
        this.setKpiData();
        this._cd.markForCheck();
        return;
      }
    }
    if (changes.selectedCycleIndex) {
      if (changes.selectedCycleIndex.currentValue !== changes.selectedCycleIndex.previousValue) {
        this._excludedZones = [];
        this.setKpiData();
        this._cd.markForCheck();
        return;
      }
    }
    if (changes.zoneLevel) {
      if (changes.zoneLevel.currentValue !== changes.zoneLevel.previousValue) {
        this.setKpiData();
        this._cd.markForCheck();
        return;
      }
    }
  }

  /**
   * Set the data for the zones level 1
   * @param selectedCycle The selected cycle
   * @param assetName The asset anem
   */
  private _setKpiDataForLevel1(selectedCycle: KpiCycle, assetName: string): void {
    const zones = selectedCycle.cycleZones;
    this.chartConfiguration.data = {
      labels: [
        `${this._translate.instant('ASSET')}: ${assetName} ${this._translate.instant('LOOP')}: ${this.selectedCycleIndex + 1}`,
      ],
      datasets: zones.map(
        (zone, index) => this._commonKPIService.getDatasetAndColour(
          zone.zoneName,
          [{
            y: +zone.zoneTimePassedIn.toFixed(2),
            label: this._translate.instant('TIME_IN_ZONE'),
            name: zone.zoneName,
          }],
          index,
          -1
        )
      )
    };
  }

  /**
   * Set the data for the zones level 2
   * @param selectedCycle The selected cycle
   * @param assetName The asset anem
   */
  private _setKpiDataForLevel2(selectedCycle: KpiCycle, assetName: string) {
    const listDataZoneWithoutOthers = selectedCycle.cycleZones.filter(
      (zone) => !this._excludedZones.includes(zone.zoneName)
    );
    this.chartConfiguration.data = {
      labels: [
        `${this._translate.instant('ASSET')}: ${assetName} ${this._translate.instant('LOOP')}: ${this.selectedCycleIndex + 1}`,
      ],
      datasets: [],
    };
    listDataZoneWithoutOthers.forEach(
      (zone, index) => {
        const datasets = [];
        const zoneSum = zone.zoneZonesIn1.reduce(
          (sum, zoneLvl2, indexLvl2) => {
            const zoneFullName = `${zone.zoneName} > ${zoneLvl2.zoneIn1Name}`;
            const zoneLvl2Time = +zoneLvl2.zoneIn1TimePassedIn.toFixed(2);
            const dataset = this._commonKPIService.getDatasetAndColour(
              zoneFullName,
              [{
                y: zoneLvl2Time,
                label: zoneFullName,
                name: zoneFullName,
              }],
              index,
              indexLvl2,
            );
            datasets.push(dataset);
            return sum + zoneLvl2Time;
          },
          0
        );
        const zoneLvl1Time = +zone.zoneTimePassedIn.toFixed(2);
        if (zoneSum < zoneLvl1Time) {
          datasets.push(
            this._commonKPIService.getDatasetAndColour(
              zone.zoneName,
              [{
                y: zoneLvl1Time - zoneSum,
                label: this._translate.instant('TIME_IN_ZONE'),
                name: zone.zoneName,
              }],
              index,
              -1,
            )
          );
        }
        this.chartConfiguration.data.datasets.push(...datasets);
      }
    );
    // Add others
    const listDataZoneOthers = selectedCycle.cycleZones.filter(
      (zone) => this._excludedZones.includes(zone.zoneName)
    );
    listDataZoneOthers.forEach(
      (zone) => this._addAsOther(zone.zoneName, +zone.zoneTimePassedIn)
    );
  }

  /**
   * Set the KPI data
   */
  public setKpiData(): void {
    const selectedCycle = this.dataKPI.familyAssets[this.selectedAssetIndex].assetCycles[this.selectedCycleIndex];
    const assetName = this.dataKPI.familyAssets[this.selectedAssetIndex].assetName;
    switch (this.zoneLevel) {
      case 1:
        this._setKpiDataForLevel1(selectedCycle, assetName);
        break;
      case 2:
        this._setKpiDataForLevel2(selectedCycle, assetName);
        break;
      default:
        throw new RangeError(`The zone level ${this.zoneLevel} is not handled`);
    }
    this.chartConfiguration.options.plugins.title.text =
      `${this._translate.instant('CYCLE_TIME_MONITORING')} (${this._translate.instant('ASSET')} ${assetName} ${this._translate.instant('LOOP')} ${this.selectedCycleIndex + 1} ${this._translate.instant('LEVEL')} ${this.zoneLevel})`;
    this.chart?.update();
  }

  /**
   * Triggered when the chart is clicked
   * @param items The clicked items
   */
  private _onClick(items: ActiveElement[]): void {
    const activePoint = items?.[0];
    if (!activePoint) {
      return;
    }
    const OTHERS = this._translate.instant('others');
    const datasetIndex = activePoint.datasetIndex;
    const label = this.chartConfiguration.data.datasets[datasetIndex].label;
    const value = this.chartConfiguration.data.datasets[datasetIndex].data.reduce(
      (sum, v) => sum + v, 0
    );
    // This onClick works for areas except Others
    if (label !== OTHERS) {
      this._addAsOther(label, value);
      this.chartConfiguration.data.datasets.splice(datasetIndex, 1);
      this.chart?.update();
      this._cd.markForCheck();
    }
  }

  /**
   * Add a zone as "Others" (ie. exclude)
   * @param label The label
   * @param value The time
   */
  private _addAsOther(label: string, value: number) {
    if (this.zoneLevel === 2) {
      return;
    }
    const OTHERS = this._translate.instant('others');
    this._excludedZones.push(label);
    const datasetOtherIndex = this.chartConfiguration.data.datasets.findIndex(
      (dataset) => dataset.label === OTHERS
    );
    // If Others does not exist, it must be added to the dataset of the graph
    if (datasetOtherIndex === -1) {
      this.chartConfiguration.data.datasets.push({
        data: [value],
        label: OTHERS,
        backgroundColor: 'black',
        borderColor: 'black',
        borderWidth: 1,
      });
    } else {
      this.chartConfiguration.data.datasets[datasetOtherIndex].data[0] += value;
    }
  }

}
