<mat-card #popover
          class="popover mat-elevation-z6">
    <mat-card-title *ngIf="step.title"
                    class="title">
        <div *ngIf="step.icon"
             mat-card-avatar
             class="avatar">
            <mat-icon>{{ step.icon }}</mat-icon>
        </div>
        <span class="title">{{ step.title }}</span>
    </mat-card-title>
    <mat-card-content>
        <span class="mat-body">{{ step.text }}</span>
    </mat-card-content>
    <mat-card-actions class="actions">
        <ng-container *ngIf="lastStep; else secondaryClose">
            <button mat-raised-button
                    color="primary"
                    (click)="ref.close()">{{ 'DIALOG_CLOSE_OPTION' | translate }}</button>
        </ng-container>
        <ng-template #secondaryClose>
            <button mat-stroked-button
                    color="primary"
                    (click)="ref.close()">{{ 'DIALOG_CLOSE_OPTION' | translate }}</button>
        </ng-template>
        <ng-container *ngIf="!lastStep">
            <button mat-raised-button
                    color="primary"
                    (click)="ref.next()">{{ 'DIALOG_NEXT_OPTION' | translate }}</button>
        </ng-container>
    </mat-card-actions>
</mat-card>
