import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { RequireAtLeastOne } from 'src/app/utils/utils';
import { SafeUrl } from '@angular/platform-browser';

export type ListItem<I = string> = {
    icon?: I;
    titleI18n: string;
    subtitleI18n?: string;
} & RequireAtLeastOne<{
    callback: () => void;
    link: SafeUrl;
}>

@Component({
  selector: 'app-mobile-bottom-sheet',
  templateUrl: './mobile-bottom-sheet.component.html',
  styleUrls: ['./mobile-bottom-sheet.component.scss']
})
export class MobileBottomSheetComponent {

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public items: ListItem[] = [],
        private readonly _bottomSheetRef: MatBottomSheetRef<MobileBottomSheetComponent>,
    ) { }

    public clickItem(item: ListItem): void {
        if (item.callback) {
            item.callback();
        }
        else if (item.link) {
            window.open(item.link as string, '_blank');
        }
        this._bottomSheetRef.dismiss();
    }

}
