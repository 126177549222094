import { Action, createReducer, on } from '@ngrx/store';
import { MasterData } from 'src/app/models/masterdata';
import { errorFetchAllMasterData, fetchAllMasterData, successFetchAllMasterData } from '../actions/masterdata.action';
import { Scope } from 'src/app/models/profile';

export interface State {
    data: MasterData;
    loading: boolean;
    loaded: boolean;
}

// default state
const initialState: State = {
    data: {
        status: [],
        category: {
            [Scope.I3M]: { family: [], parentFamily: [] },
            [Scope.LOG]: { family: [], parentFamily: [] },
            [Scope.OVERSIZE]: { family: [], parentFamily: [] },
            [Scope.ACT]: { family: [], parentFamily: [] },
            [Scope.CATracking]: { family: [], parentFamily: [] }
        },
        program: [],
        zoneType: {
            [Scope.I3M]: [],
            [Scope.LOG]: [],
            [Scope.OVERSIZE]: [],
            [Scope.ACT]: [],
        },
        adminRoles: [],
        categorySignalSource: {
            [Scope.I3M]: [],
            [Scope.LOG]: [],
            [Scope.OVERSIZE]: [],
            [Scope.ACT]: [],
        },
    },
    loading: false,
    loaded: false,
};

const masterDataReducer = createReducer(
    initialState,
    on(fetchAllMasterData, (state): State => {
        return {
            ...state,
            loading: true,
            loaded: false
        };
    }),
    on(successFetchAllMasterData, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            data: (() => {
                state.data = action.masterData;
                return state.data;
            })()
        };
    }),
    on(errorFetchAllMasterData, (state): State => {
        return {
            ...state,
            loading: false,
            loaded: true
        };
    }),
);

export function reducer(state: State | undefined, action: Action) {
    return masterDataReducer(state, action);
}
