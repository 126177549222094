import { Component, ChangeDetectionStrategy, inject, ChangeDetectorRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash';
import { MachineStatusProgress } from 'src/app/models/certificate';

@Component({
    selector: 'app-status-progress',
    templateUrl: './status-progress.component.html',
    styleUrls: ['./status-progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusProgressComponent implements OnChanges {

    //#region Injections
    private cd = inject(ChangeDetectorRef);
    //#endregion

    @Input() progress: MachineStatusProgress;
    @Input() direction: 'horizontal'|'vertical' = 'horizontal';

    ngOnChanges(changes: SimpleChanges): void {
        if (!isEqual(changes.progress?.previousValue, changes.progress?.currentValue)) {
            this.cd.markForCheck();
        }
        if (changes.direction?.previousValue !== changes.direction?.currentValue) {
            this.cd.markForCheck();
        }
    }

}
