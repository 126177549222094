import { ElementRef, Directive, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[appHidden]'
})
export class HiddenDirective implements OnChanges {
    @Input('appHidden') loader: boolean;
    @Input() specificDirectiveDisplay = 'block';

    constructor(
        private elementRef: ElementRef<HTMLElement>
    ) { }

    ngOnChanges() {
        const element = this.elementRef.nativeElement;
        this.loader ? element.style.display = 'none' : element.style.display = this.specificDirectiveDisplay;
    }
}
