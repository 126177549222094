<div [ngSwitch]="data" mat-dialog-title>
    <span *ngSwitchCase="VideoGuide.kpi">{{ 'VIDEO_TUTORIAL_ANALYTICS_MODULE' | translate }}</span>
    <span *ngSwitchCase="VideoGuide.map">{{ 'VIDEO_TUTORIAL_MAP_TRACKING' | translate }}</span>
    <span *ngSwitchCase="VideoGuide.alert">{{ 'VIDEO_TUTORIAL_ALERTS_MODULE' | translate }}</span>
    <span *ngSwitchCase="VideoGuide.admin">{{ 'VIDEO_TUTORIAL_ADMIN_MODULE' | translate }}</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>
<div mat-dialog-content>
    <video controls
           aria-describedby="">
        <source *ngFor="let video of sources"
                [src]="video.src"
                [type]="video.type">
        <track *ngFor="let sub of subtitles"
               [label]="sub.label"
               kind="captions"
               [srclang]="sub.lang"
               [src]="sub.url"
               [default]="sub.default">
        <span>Your browser does not support the video tag.</span>
    </video>
</div>
