import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RangesType, UsageMetrics } from '../models/usage-metrics';
import { INFLATE } from './inflator.interceptor';
import { ParentFamilyRight, FamilyRight } from '../models/family';
import { Scope } from '../models/profile';
import { Group } from '../models/user-right';
import { formateHttpParam } from '../utils/formater';

@Injectable({
    providedIn: 'root'
})
export class UsageMetricsService {

    public readonly defaultFromDate: Date = moment().subtract(1, 'weeks').startOf('day').toDate();
    public readonly defaultToDate: Date = moment().endOf('day').toDate();
    public dropDownFilteredValue = new BehaviorSubject(RangesType.lastWeek);
    public selectedDropDownValue = this.dropDownFilteredValue.asObservable();

    constructor(
        private _http: HttpClient,
    ) { }

    /**
     * Get the usage history data from API
     * @param fromDate The date from when we want to fetch the usage history
     * @param toDate The date to when we want to fetch the usage history
     */
    public fetchUsageMetrics(fromDate: Date, toDate: Date, optionalFiltering: {
        scopes?: Scope[],
        parentFamilies?: ParentFamilyRight['id'][],
        families?: FamilyRight['id'][],
        groups?: Group['id'][],
    } = {}): Observable<UsageMetrics[]> {
        return this._http.post<UsageMetrics[]>(
            `${environment.API_ENDPOINT}/users-history`, {
                fromDate: formateHttpParam(fromDate),
                toDate: formateHttpParam(toDate),
                familiesId: optionalFiltering.families,
                parentFamiliesId: optionalFiltering.parentFamilies,
                scopes: optionalFiltering.scopes,
                groups: optionalFiltering.groups,
            }, {
                context: new HttpContext().set(INFLATE, true),
            }
        );
    }

    /**
     * Handling Date Range period pre-selected
     * @param value : RangeType
     */
    public setDropDownValue(value: number) {
        this.dropDownFilteredValue.next(value);
    }

}
