import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isEqual } from 'lodash';
import { Observable, map, take } from 'rxjs';

function checkFound(list, property, value) {
    const found = !!list.find(
        (item) => property ? item[property] === value : isEqual(item, value)
    );
    return (found || typeof value === 'undefined' || value === null) ? null : { incorrect: true };
}

export function requireMatchValidator<T>(list: T[], property?: string) {
    return (control: AbstractControl): ValidationErrors | null => checkFound(list, property, control.value);
}

export function asyncRequireMatchValidator<T>(list$: Observable<T[]>, property?: string) {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        return list$.pipe(
            map(data => checkFound(data, property, control.value)),
            take(1),
        );
    };
}
