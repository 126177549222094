import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const selectMasterdataState$ = (state: AppState) => state.masterdata;

export const selectMasterdata$ = createSelector(selectMasterdataState$, (masterdata) => masterdata.data);

export  const  selectMasterdataLoading$ = createSelector(selectMasterdataState$, (masterdata) =>  masterdata.loading);

export  const  selectMasterdataLoaded$ = createSelector(selectMasterdataState$, (masterdata) =>  masterdata.loaded);
