import { MtxDatetimepickerIntl } from '@ng-matero/extensions/datetimepicker';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// @Injectable({ providedIn: 'root' })
export class MtxDatetimepickerI18n implements MtxDatetimepickerIntl {

    constructor(
        private _translate: TranslateService,
    ) {
        this._translate.onLangChange.subscribe(
            () => this.changes.next()
        );
    }
    /**
     * Stream to emit from when labels are changed. Use this to notify components when the labels have
     * changed after initialization.
     */
    readonly changes = new Subject<void>();

    /** A label for the calendar popup (used by screen readers). */
    get calendarLabel() {
        return this._translate.instant('MTX_CALENDAR') || MtxDatetimepickerIntl.prototype.calendarLabel;
    }

    /** A label for the button used to open the calendar popup (used by screen readers). */
    get openCalendarLabel() {
        return this._translate.instant('MTX_OPEN_CALENDAR') || MtxDatetimepickerIntl.prototype.openCalendarLabel;
    }

    /** Label for the button used to close the calendar popup. */
    get closeCalendarLabel() {
        return this._translate.instant('MTX_CLOSE_CALENDAR') || MtxDatetimepickerIntl.prototype.closeCalendarLabel;
    }

    /** A label for the previous month button (used by screen readers). */
    get prevMonthLabel() {
        return this._translate.instant('MTX_PREVIOUS_MONTH') || MtxDatetimepickerIntl.prototype.prevMonthLabel;
    }

    /** A label for the next month button (used by screen readers). */
    get nextMonthLabel() {
        return this._translate.instant('MTX_NEXT_MONTH') || MtxDatetimepickerIntl.prototype.nextMonthLabel;
    }

    /** A label for the previous year button (used by screen readers). */
    get prevYearLabel() {
        return this._translate.instant('MTX_PREVIOUS_YEAR') || MtxDatetimepickerIntl.prototype.prevYearLabel;
    }

    /** A label for the next year button (used by screen readers). */
    get nextYearLabel() {
        return this._translate.instant('MTX_NEXT_YEAR') || MtxDatetimepickerIntl.prototype.nextYearLabel;
    }

    /** A label for the previous multi-year button (used by screen readers). */
    get prevMultiYearLabel() {
        return this._translate.instant('MTX_PREVIOUS_24_YEARS') || MtxDatetimepickerIntl.prototype.prevMultiYearLabel;
    }

    /** A label for the next multi-year button (used by screen readers). */
    get nextMultiYearLabel() {
        return this._translate.instant('MTX_NEXT_24_YEARS') || MtxDatetimepickerIntl.prototype.nextMultiYearLabel;
    }

    /** A label for the 'switch to month view' button (used by screen readers). */
    get switchToMonthViewLabel() {
        return this._translate.instant('MTX_CHOOSE_DATE') || MtxDatetimepickerIntl.prototype.switchToMonthViewLabel;
    }

    /** A label for the 'switch to year view' button (used by screen readers). */
    get switchToYearViewLabel() {
        return this._translate.instant('MTX_CHOOSE_MONTH') || MtxDatetimepickerIntl.prototype.switchToYearViewLabel;
    }

    /** A label for the 'switch to multi-year view' button (used by screen readers). */
    get switchToMultiYearViewLabel() {
        return this._translate.instant('MTX_CHOOSE_MONTH_AND_YEAR') || MtxDatetimepickerIntl.prototype.switchToMultiYearViewLabel;
    }

    /** A label for the first date of a range of dates (used by screen readers). */
    get startDateLabel() {
        return this._translate.instant('MTX_START_DATE') || MtxDatetimepickerIntl.prototype.startDateLabel;
    }

    /** A label for the last date of a range of dates (used by screen readers). */
    get endDateLabel() {
        return this._translate.instant('MTX_END_DATE') || MtxDatetimepickerIntl.prototype.endDateLabel;
    }

    /** Formats a range of years (used for visuals). */
    formatYearRange(start: string, end: string): string {
        return `${start} \u007e ${end}`;
    }

    /** Formats a label for a range of years (used by screen readers). */
    formatYearRangeLabel(start: string, end: string): string {
        return this._translate.instant('MTX_YEAR_RANGE_LABEL', {start, end}) || MtxDatetimepickerIntl.prototype.formatYearRangeLabel(start, end);
    }

    /** A label for the 'switch to clock hour view' button (used by screen readers). */
    get switchToClockHourViewLabel() {
        return this._translate.instant('MTX_CHOOSE_HOUR') || MtxDatetimepickerIntl.prototype.switchToClockHourViewLabel;
    }

    /** A label for the 'switch to clock minute view' button (used by screen readers). */
    get switchToClockMinuteViewLabel() {
        return this._translate.instant('MTX_CHOOSE_MINUTE') || MtxDatetimepickerIntl.prototype.switchToClockMinuteViewLabel;
    }

    /** Label used for ok button within the manual time input. */
    get okLabel() {
        return this._translate.instant('MTX_OK') || MtxDatetimepickerIntl.prototype.okLabel;
    }

    /** Label used for cancel button within the manual time input. */
    get cancelLabel() {
        return this._translate.instant('MTX_CANCEL') || MtxDatetimepickerIntl.prototype.cancelLabel;
    }
}
