<div mat-dialog-title>
    <span>{{ 'DIALOG_USER_NO_RIGHTS_TITLE' | translate }}</span>
</div>
<div mat-dialog-content>
    <span>{{ 'DIALOG_USER_NO_RIGHTS_TEXT' | translate }}</span>
    <button color="primary"
            routerLink="rights/request"
            mat-flat-button color="primary"
            mat-dialog-close>
        {{ 'DIALOG_USER_NO_RIGHTS_CTA' | translate }}
    </button>
</div>
