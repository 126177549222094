<div mat-dialog-title>
    <span>{{ (dialogData ? "CERTIFICATE_UPDATE_POPIN_TITLE" : "CERTIFICATE_CREATION_POPIN_TITLE") | translate }}</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<form [formGroup]="certificateRequestForm"
      class="form-container"
      (submit)="submitCertificateRequest()">

    <div mat-dialog-content>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'MACHINEID' | translate }}</mat-label>
            <input matInput
                   type="text"
                   formControlName="machineId" />
            <mat-error *ngIf="certificateRequestForm.controls.machineId.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_INPUT" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'PHONEMODEL' | translate }}</mat-label>
            <input matInput
                   type="text"
                   formControlName="phoneModel" />
            <mat-error *ngIf="certificateRequestForm.controls.phoneModel.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_INPUT" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'PHONENUM' | translate }}</mat-label>
            <input matInput
                   type="tel"
                   formControlName="phoneNum" />
            <mat-error *ngIf="certificateRequestForm.controls.phoneNum.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_INPUT" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'CONTACTNAME' | translate }}</mat-label>
            <input matInput
                   type="text"
                   formControlName="contactName" />
            <mat-error *ngIf="certificateRequestForm.controls.contactName.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_INPUT" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'CONTACTPHONENUM' | translate }}</mat-label>
            <input matInput
                   type="tel"
                   formControlName="contactPhoneNum" />
            <mat-error *ngIf="certificateRequestForm.controls.contactPhoneNum.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_INPUT" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'CONTACTMAIL' | translate }}</mat-label>
            <input matInput
                   type="email"
                   formControlName="contactMail" />
            <mat-error *ngIf="certificateRequestForm.controls.contactMail.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_INPUT" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'COMPANY' | translate }}</mat-label>
            <input matInput
                   type="text"
                   formControlName="company" />
            <mat-error *ngIf="certificateRequestForm.controls.company.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_INPUT" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'FOCALPOINTNAME' | translate }}</mat-label>
            <input matInput
                   type="text"
                   name="focalPointName"
                   formControlName="focalPointName" />
            <mat-error *ngIf="certificateRequestForm.controls.focalPointName.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_INPUT" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'FOCALPOINTEMAIL' | translate }}</mat-label>
            <input matInput
                   type="email"
                   formControlName="focalPointEmail" />
            <mat-error *ngIf="certificateRequestForm.controls.focalPointEmail.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_INPUT" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'FOCALPOINTPHONE' | translate }}</mat-label>
            <input matInput
                   type="tel"
                   formControlName="focalPointPhone" />
            <mat-error *ngIf="certificateRequestForm.controls.focalPointPhone.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_INPUT" | translate }}
            </mat-error>
        </mat-form-field>

    </div>

    <div mat-dialog-actions>
        <button mat-stroked-button
                mat-dialog-close
                type="button"
                color="primary">
            {{"CANCEL" | translate}}</button>
        <button type="submit"
                mat-flat-button
                color="primary"
                [disabled]="!certificateRequestForm.valid">{{"DIALOG_VALIDATE_OPTION" | translate}}</button>
    </div>

</form>
