<mat-tab-group>

  <mat-tab label="{{ 'CYCLE_TIME_MONITORING' | translate }}">

    <!-- Famille -->
    <app-bar-family-cycle [dataKPI]="dataKPIcycle"></app-bar-family-cycle>
    <!-- Assets -->
    <app-bar-assets-cycle [dataKPI]="dataKPIcycle"
                          (chartClicked)="onAssetsChartClicked($event)"></app-bar-assets-cycle>

    <ng-container *ngIf="(indexAssetClicked | typeOf) === 'number'; else selectElementTemplate">
      <!-- Cycles -->
      <app-bar-cycles-cycle [dataKPI]="dataKPIcycle"
                            [selectedAssetIndex]="indexAssetClicked"
                            (chartClicked)="onCyclesChartClicked($event)"></app-bar-cycles-cycle>

      <ng-container *ngIf="(indexCycleClicked | typeOf) === 'number'; else selectElementTemplate">
        <!-- CheckBox for zone 1 and 2 -->
        <div class="container-checkbox-zones-levels">
            <mat-checkbox [(ngModel)]="zoneLevel2"
                          color="primary">
                <span class="checkbox-label">{{ 'KPI_CHECK_BOX_LEVEL_2' | translate }}</span>
            </mat-checkbox>
            <span class="description">{{ 'KPI_CHECK_BOX_LEVEL_2_DESC' | translate }}</span>
        </div>

        <!-- Zone 1/2 -->
        <app-bar-zones-cycle [dataKPI]="dataKPIcycle"
                             [selectedAssetIndex]="indexAssetClicked"
                             [selectedCycleIndex]="indexCycleClicked"
                             [zoneLevel]="zoneLevel2 ? 2 : 1"></app-bar-zones-cycle>
      </ng-container>
    </ng-container>
  </mat-tab>

  <mat-tab label="{{ 'KPI_TENDANCE' | translate }}">
    <app-trend-family-cycle [dataKPI]="dataKPIcycle"
                            (chartClicked)="onTrendFamilyChartClick($event)"></app-trend-family-cycle>
    <app-trend-zone-cycle *ngIf="(trend?.indexAssetClicked | typeOf) === 'number' && (trend?.indexCycleClicked | typeOf) === 'number'; else selectElementTemplate"
                          [dataKPI]="dataKPIcycle"
                          [selectedAssetIndex]="trend?.indexAssetClicked"
                          [selectedCycleIndex]="trend?.indexCycleClicked"></app-trend-zone-cycle>
  </mat-tab>

</mat-tab-group>

<ng-template #selectElementTemplate>
  <div class="placeholder-more-kpis">{{ 'KPI_SELECT_ELEMENT' | translate }}</div>
</ng-template>
