import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalStorageService } from '../services/local-storage.service';

export const LOCAL_STORAGE_ROUTE_KEY = 'auth.rbl';

export const authGuard: CanActivateFn = (route) => {
    // #region Injections
    const authService = inject(AuthService);
    const localStorageService = inject(LocalStorageService);
    const router = inject(Router);
    // #endregion

    const previousLocationAfterAuth = `${window.location.pathname}${window.location.search}`;
    const code = route.queryParamMap.get('code');
    if (!authService.isAuthenticated() && !code) {
        localStorageService.store$(LOCAL_STORAGE_ROUTE_KEY, previousLocationAfterAuth).subscribe();
        router.navigate(['login']);
        return false;
    }

    if (code) {
        if(route.toString().includes('oauth2redirect')) {
            return true;
        }
        localStorageService.store$(LOCAL_STORAGE_ROUTE_KEY, previousLocationAfterAuth).subscribe();
        return router.navigate(['/oauth2redirect'], {
            queryParamsHandling: 'preserve',
        });
    }

    return true;
};
