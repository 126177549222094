import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { MachineStatus } from 'src/app/models/certificate';
import { Status, getStatusFromMachineStatus } from '../_shared';

@Component({
  selector: 'app-status-separator',
  template: `<div class="separator {{direction}} previous-{{previousStepStatus}} next-{{nextStepStatus}}"></div>`,
  styleUrls: ['./status-separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusSeparatorComponent {

    private cd = inject(ChangeDetectorRef);

    @Input() direction: 'horizontal'|'vertical' = 'horizontal';

    public previousStepStatus: Status;
    @Input() set previousStatus(value: MachineStatus) {
        this.previousStepStatus = getStatusFromMachineStatus(value);
        this.cd.markForCheck();
    }

    public nextStepStatus: Status;
    @Input() set nextStatus(value: MachineStatus) {
        this.nextStepStatus = getStatusFromMachineStatus(value);
        this.cd.markForCheck();
    }

}
