<div class="way-component--filterable-container">

    <!-- Filter -->
    <div class="way-filter-container">
        <app-filter [parentSourceView]="view" [dataForFilter$]="certificates$"></app-filter>
    </div>


    <!-- Header -->
    <div class="way-component--header">
        <span class="way-component--header-title">{{ 'CERTIFICATE_SCREEN_TITLE' | translate }}</span>
        <button mat-flat-button color="primary" *ngIf="writeRight" (click)="openUpdateCreateDialog()">
            <fa-icon icon="plus-circle"></fa-icon>
            {{ 'CREATE_DEVICE' | translate }}
        </button>
    </div>

    <!-- Table -->
    <div class="way-table--main-container">

        <div class="table-container mat-elevation-z8 custom-scrollbar">

            <table class="way-table" id="desktop-mat-table" mat-table matSort [dataSource]="dataSource" #sort="matSort"
                multiTemplateDataRows [attr.aria-label]="'ARIA_MANAGE_CERTIFICATE_TABLE' | translate">

                <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns; let colIndex = index">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> <span
                            *ngIf="col !== 'comment'">{{getColumnName(displayedColumns[colIndex])}}</span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <!-- Chips rendering column, following certificates advancement -->
                        <ng-container [ngSwitch]="col">
                            <div *ngSwitchCase="'status'"
                                 matRipple
                                 style="cursor: pointer;"
                                 (click)="openProgressDetails(element.status, element.certificateExpirationDate, $event)">
                                <app-status-progress *ngIf="element.status"
                                                     [progress]="element.status"></app-status-progress>
                            </div>
                            <div *ngSwitchCase="'newStatus'"
                                 matRipple
                                 style="cursor: pointer;"
                                 (click)="openProgressDetails(element.newStatus, element.newCertificateExpirationDate, $event)">
                                <app-status-progress *ngIf="element.newStatus"
                                                     [progress]="element.newStatus"></app-status-progress>
                            </div>
                            <!-- Normal columns -->
                            <ng-container *ngSwitchDefault>
                                <ng-container [ngSwitch]="element[displayedColumns[colIndex]] | typeOf">
                                <ng-container *ngSwitchCase="'date'">
                                    <ng-container *ngIf="displayedColumns[colIndex] === 'certificateExpirationDate'; else defaultDateTemplate">
                                        {{ element[displayedColumns[colIndex]] | moment:'L' }}
                                    </ng-container>
                                    <ng-template #defaultDateTemplate>
                                        {{ element[displayedColumns[colIndex]] | moment:'L LT' }}
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <ng-container *ngIf="col === 'comment'; else defaultTextColumn">
                                        <span *ngIf="element.delay > 10 && element.delay <= 60"
                                            class="certificate-delay certificate-delay-orange">
                                            <fa-icon icon="exclamation-circle"></fa-icon>
                                            {{ "CERTIFICATE_EXPIRES_IN" | translate:{number: element.delay} }}
                                        </span>
                                        <span class="certificate-delay certificate-delay-red"
                                            *ngIf="element.delay > 0 && element.delay <= 10">
                                            <fa-icon icon="exclamation-circle"></fa-icon>
                                            {{ "CERTIFICATE_EXPIRES_IN" | translate:{number: element.delay} }}
                                        </span>
                                        <span class="certificate-delay certificate-delay-primary" *ngIf="element.delay <= 0 ">
                                            <fa-icon icon="times"></fa-icon>
                                            {{ "CERTIFICATE_EXPIRED" | translate }}
                                        </span>
                                    </ng-container>
                                    <ng-template #defaultTextColumn>
                                        {{ element[displayedColumns[colIndex]] }}
                                    </ng-template>
                                </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <!-- No Name Column: comments related to certificate expirations -->

                        <!-- Actions columns -->
                        <ng-container *ngIf="col === 'actions'">
                            <button mat-icon-button *ngIf="writeRight" [matMenuTriggerFor]="actionMenu"
                                (click)="$event.stopPropagation()">
                                <fa-icon icon="ellipsis-v"></fa-icon>
                            </button>
                            <mat-menu #actionMenu="matMenu" xPosition="before">
                                <button mat-menu-item focusable="false"
                                    (click)="openConfirmDialog(CertificateActions.Renew, element)"
                                    [disabled]="element | certificateRenewal">
                                    <fa-icon icon="redo"
                                             [fixedWidth]="true"></fa-icon>
                                    {{ 'CERTIFICATE_RENEW' | translate }}
                                </button>

                                <button mat-menu-item focusable="false"
                                    (click)="openConfirmDialog(CertificateActions.AcceptRequest, element)"
                                    [disabled]="element?.newStatus?.status1?.toLowerCase() !== MachineStatus.OnHold.toLowerCase()
                                && element?.newStatus?.status4?.toLowerCase() !== MachineStatus.Rejected.toLowerCase()">
                                    <fa-icon icon="check"
                                             [fixedWidth]="true"></fa-icon>
                                    {{ 'CERTIFICATE_ACCEPT_REQUEST' | translate }}
                                </button>

                                <button mat-menu-item focusable="false"
                                    (click)="openConfirmDialog(CertificateActions.RefuseRequest, element)"
                                    [disabled]="element?.newStatus?.status1?.toLowerCase() !== MachineStatus.OnHold">
                                    <fa-icon icon="times"
                                             [fixedWidth]="true"></fa-icon>
                                    {{ 'CERTIFICATE_REFUSE_REQUEST' | translate }}
                                </button>

                                <button mat-menu-item ocusable="false" (click)="openUpdateCreateDialog(element)"
                                [disabled]="element?.status?.status4 === MachineStatus.Deleted"
                                >
                                    <fa-icon icon="pencil-alt"
                                             [fixedWidth]="true"></fa-icon>
                                    {{ 'EDIT' | translate }}
                                </button>

                                <button class="warn" mat-menu-item focusable="false"
                                    (click)="openConfirmDialog(CertificateActions.Delete, element)"
                                    [disabled]="element?.status?.status4 === MachineStatus.Deleted"
                                    >
                                    <fa-icon icon="trash"
                                             [fixedWidth]="true"></fa-icon>
                                    {{ 'DELETE' | translate }}
                                </button>

                                <button mat-menu-item focusable="false"
                                    (click)="openConfirmDialog(CertificateActions.CancelDeletion, element)"
                                    [disabled]="element?.status?.status4 !== MachineStatus.Deleted">
                                    <fa-icon icon="arrow-alt-circle-left"
                                             [fixedWidth]="true"></fa-icon>
                                    {{ 'CANCEL_DELETION' | translate }}
                                </button>

                                <button mat-menu-item focusable="false"
                                    [disabled]="element?.status?.status4 !== MachineStatus.Activated"
                                    (click)="openConfirmDialog(CertificateActions.Reinstall, element)">
                                    <fa-icon icon="lock-open"
                                             [fixedWidth]="true"></fa-icon>
                                    {{ 'AUTHORIZE_CERTIFICAT_REINSTALLATION' | translate }}
                                </button>

                                <button mat-menu-item
                                        focusable="false"
                                        *ngIf="element?.newStatus?.status4 === MachineStatus.OnHold"
                                        (click)="openTotpDialog(element)">
                                    <mat-icon>pin</mat-icon>
                                    {{ 'SEE_INSTALLATION_TOTP' | translate }}
                                </button>
                            </mat-menu>

                            <button mat-icon-button [@rotate180]="isRowExpanded(element) ? 'rotate' : 'void'">
                                <mat-icon>expand_more</mat-icon>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Expanded details -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell
                        *matCellDef="let element"
                        style="padding-left:0px; padding-right:0px;"
                        [attr.colspan]="displayedColumns.length">
                        <div [@detailExpand]="element && isRowExpanded(element) ? 'expanded' : 'collapsed'">
                            <div class="additional-data--container">
                                <div class="additional-data--main"
                                     *ngFor="let expandedCol of expandedColumns">
                                    <span class="additional-data--title">{{getColumnName(expandedCol)}}</span>
                                    <span class="additional-data--value"
                                          appShowIfTruncated
                                          [matTooltip]="element[expandedCol]">{{ element[expandedCol] }}</span>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky : true"></tr>

                <tr mat-row *matRowDef="let expandedElement; columns: displayedColumns;"
                    (click)="switchRowExpansion(expandedElement)" class="general-row"
                    [class.expanded-row]="isRowExpanded(expandedElement)"></tr>

                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

            </table>
        </div>

        <!-- Loader -->
        <div *ngIf="(loading$ | async) === true"
             class="table-loader">
            <mat-progress-spinner diameter="32"
                                  mode="indeterminate">
            </mat-progress-spinner>
        </div>

        <mat-paginator class="way-table--footer" (page)="scrollUp()" [pageSizeOptions]="[25, 50, 100]">
        </mat-paginator>

    </div>

</div>
