import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';
import { Directive, HostListener, Input } from '@angular/core';
import { MatMenuPanel, MatMenuTrigger } from '@angular/material/menu';
import { cloneDeep } from 'lodash';

@Directive({
    selector: '[appOpenMenuHover]',
    standalone: true,
})
export class OpenMenuHoverDirective {

    private _buttonHover = false;
    private _menuHover = false;
    private _menu: Element;

    @Input() appOpenMenuHover!: MatMenuTrigger;
    private _menuCloseDelay = 0;
    @Input() set menuCloseDelay(value: NumberInput) {
        this._menuCloseDelay = coerceNumberProperty(value);
    }
    private _parentMouseOver: EventListenerOrEventListenerObject[];
    private _parentMouseOut: EventListenerOrEventListenerObject[];

    @HostListener('mouseenter')
    public onMouseEnter() {
        if (!this.appOpenMenuHover) {
            return;
        }
        // Remove backdrop to prevent leave of element
        this.appOpenMenuHover.menu.hasBackdrop = false;
        setTimeout(() => {
            // Handle menu
            const id = this.appOpenMenuHover.menu.panelId;
            this._preventParentMenuEvents(this.appOpenMenuHover.menu.parentMenu);
            this._menu = document.querySelector(`#${id}`);
            this._listenMenuHover();
        });
        this._buttonHover = true;
        this.appOpenMenuHover.openMenu();
    }

    @HostListener('mouseleave')
    public onMouseLeave() {
        if (!this.appOpenMenuHover) {
            return;
        }
        this._buttonHover = false;
        setTimeout(() => {
            this._closeMenu();
        }, this._menuCloseDelay);
    }

    private _menuOverListener: EventListenerOrEventListenerObject = () => {
        this._menuHover = true;
    }

    private _menuLeaveListener: EventListenerOrEventListenerObject = () => {
        this._menuHover = false;
        setTimeout(() => {
            this._closeMenu();
        });
    }

    private _listenMenuHover() {
        this._menu.addEventListener('mouseover', this._menuOverListener);
        this._menu.addEventListener('mouseout', this._menuLeaveListener);
    }

    private _forgetMenuHover() {
        this._menu.removeEventListener('mouseover', this._menuOverListener);
        this._menu.removeEventListener('mouseout', this._menuLeaveListener);
    }

    private _closeMenu() {
        if (!this._menuHover && !this._buttonHover) {
            this._forgetMenuHover();
            this._restoreParentMenuEvents(this.appOpenMenuHover.menu.parentMenu);
            this.appOpenMenuHover.closeMenu();
            setTimeout(() => document.addEventListener('mousemove', event => {
                (event.target as HTMLElement)?.focus();
            }, {
                once: true,
            }));
        }
    }

    private _preventParentMenuEvents(parentMenu: MatMenuPanel<any>) {
        if (!parentMenu) {
            return;
        }
        const menuEl = document.querySelector(`#${parentMenu.panelId}`) as any;
        this._parentMouseOver = cloneDeep(menuEl.eventListeners?.('mouseover') as EventListenerOrEventListenerObject[]);
        this._parentMouseOver?.forEach(l => menuEl.removeEventListener('mouseover', l));
        this._parentMouseOut = cloneDeep(menuEl.eventListeners?.('mouseout') as EventListenerOrEventListenerObject[]);
        this._parentMouseOut?.forEach(l => menuEl.removeEventListener('mouseout', l));
    }

    private _restoreParentMenuEvents(parentMenu: MatMenuPanel<any>) {
        if (!parentMenu) {
            return;
        }
        const menuEl = document.querySelector(`#${parentMenu.panelId}`);
        this._parentMouseOver?.forEach(l => menuEl.addEventListener('mouseover', l));
        this._parentMouseOut?.forEach(l => menuEl.addEventListener('mouseout', l));
        document.addEventListener('mousemove', (event: MouseEvent) => {
            const { top, bottom, left, right } = menuEl.getBoundingClientRect();
            const { clientX, clientY } = event;
            if (clientX < left || clientX > right || clientY < top || clientY > bottom) {
                menuEl.dispatchEvent(new MouseEvent('mouseout'));
            }
        }, { once: true });
    }

}
