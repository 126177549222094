import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-asset-selection',
  templateUrl: './asset-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetSelectionComponent implements OnChanges {

  constructor(
    @Inject(MAT_DIALOG_DATA) public assets: string[],
    private _dialogRef: MatDialogRef<AssetSelectionComponent>,
    private _cd: ChangeDetectorRef,
  ) {
    this._dialogRef.afterOpened().pipe(
      take(1),
      tap(() => this._cd.detectChanges()),
    ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.assets?.currentValue !== changes.assets?.previousValue) {
      this._cd.detectChanges();
    }
  }

  public onAssetSelect(selection: MatSelectionListChange) {
    this._dialogRef.close(selection.options[0].value);
    this._cd.detectChanges();
  }

}
