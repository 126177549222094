import { createAction, props } from '@ngrx/store';
import { InitFiltersData, UserFilters } from 'src/app/models/filter-context.model';
import { CreateSavedContextPayload, CreateSessionContextPayload, UpdateContextPayload } from 'src/app/services/init-filter.service';

// Filters for selecting
export const fetchDataForContexts = createAction('[InitFilters] fetch All initFilters');
export const successFetchDataForContexts = createAction('[InitFilters] success fetch all Filters', props<{ payload: InitFiltersData }>());
export const errorFetchDataForContexts = createAction('[InitFilters] error fetch all Filters', props<{ payload: InitFiltersData }>());

// User saved filters
export const fetchAllUserContexts = createAction('[InitFilters] fetch All Saved Filter');
export const successFetchAllUserContexts = createAction('[InitFilters] Success fetch all saved user filters', props<{ payload: UserFilters[] }>());

export const createUserContext = createAction('[InitFilters] Create Filter', props<{ payload: CreateSavedContextPayload }>());
export const successCreateUserContext = createAction('[InitFilters] Success Create Filter', props<{ payload: UserFilters }>());
export const errorCreateUserContext = createAction('[InitFilters] Error create User Filter');

export const editUserContext = createAction('[InitFilters] Update Filter', props<{ payload: UpdateContextPayload }>());
export const successEditUserContext = createAction('[InitFilters] Success Edit Filter', props<{ payload: UserFilters }>());
export const errorEditUserContext = createAction('[InitFilters] Error update User Filter');

export const setDefaultContext = createAction('[InitFilters] Update User Filter', props<{ id: string }>());
export const successSetDefaultContext = createAction('[InitFilters] Success Update Filter', props<{ id: string }>());

export const toggleContextFavorite = createAction('[InitFilters] Toggle favorite context', props<{ contextId: string }>());
export const successToggleContextFavorite = createAction('[InitFilters] Toggle favorite context success', props<{ context: UserFilters }>());
export const errorToggleContextFavorite = createAction('[InitFilters] Toggle favorite context error');

export const applyContextForSession = createAction('[InitFilters] Create and Apply Filter', props<{ payload: CreateSessionContextPayload }>());
export const successApplyContextForSession = createAction('[InitFilters] Create and Apply Filter Success', props<{ payload: UserFilters }>());

export const deleteUserContext = createAction('[InitFilters] Delete User Filter', props<{ id: string }>());
export const successDeleteUserFilterContext = createAction('[InitFilters] Success Delete Filter', props<{ id: string }>());
export const errorDeleteUserContext = createAction('[InitFilters] Error delete User Filter');

export const deleteFamilyFromUserFilter = createAction('[InitFilters] Delete Family from User Filter', props<{ familyId: string}>());
export const deleteParentFromUserFilter = createAction('[InitFilters] Delete Parent from User Filter', props<{ parentFamilyId: string}>());
