import { Pipe, PipeTransform } from '@angular/core';
import { ConversionService, Units } from '../services/conversion.service';

@Pipe({
    name: 'convert',
    pure: true,
})
export class ConversionPipe implements PipeTransform {

    constructor(private readonly _conversionService: ConversionService) {}

    transform(value: number, ratio: number): number;
    transform(value: number, from: Units.Length, to: Units.Length): number;
    transform(value: number, ...args: unknown[]): number {
        return this._conversionService.convert.call(this, value, ...args);
    }

}
