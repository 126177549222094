import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concat, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { errorFetchAllMasterData, fetchAllMasterData, successFetchAllMasterData } from '../actions/masterdata.action';
import { ConfigurationStorageKeys, LocalStorageService } from 'src/app/services/local-storage.service';
import { MasterData } from 'src/app/models/masterdata';

@Injectable()
export class MasterDataEffects {
    /**
     * The data is retrieved from local storage, and refreshed when all application data are loaded
     */
    public fetchAllMasterData$ = createEffect(() => { return this._actions$.pipe(
        ofType(fetchAllMasterData),
        switchMap(() => concat(
            this._localStorageService.fetchLocalStorageValueByKey$(ConfigurationStorageKeys.masterData),
            this._masterdataService.getMasterdata().pipe(
                switchMap(masterdata => this._localStorageService.store$(
                    ConfigurationStorageKeys.masterData,
                    JSON.stringify(masterdata)
                )),
            ),
        )),
        map(({ value }) => JSON.parse(value) as MasterData),
        map((masterdata) => {
            return successFetchAllMasterData({ masterData: masterdata });
        }),
        catchError(() => of(errorFetchAllMasterData()))
    ) });

    constructor(
        private _masterdataService: MasterdataService,
        private _actions$: Actions,
        private _localStorageService: LocalStorageService,
    ) { }
}
