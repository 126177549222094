import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Observable } from 'rxjs';
import { catchError, delay, filter, map, retry, switchMap, tap } from 'rxjs/operators';
import { LOCAL_STORAGE_ROUTE_KEY } from 'src/app/auth/auth.guard';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectComponent {

    private _redirectLink$ = this.localStorageService.fetchLocalStorageValues$().pipe(
        filter(({ key }) => key === LOCAL_STORAGE_ROUTE_KEY),
        map(({ value }) => value),
    );

    public redirectLink$: Observable<SafeUrl> = this._redirectLink$.pipe(
        map(v => {
            if (!v || v.includes(this.activatedRoute.snapshot.routeConfig.path)) {
                const tree = this.router.createUrlTree(['map', 'view-map'], {
                    queryParams: {},
                });
                v = tree.toString();
            }
            return this.sanitize.bypassSecurityTrustUrl(v);
        }),
    );

    public tooLong$ = of(true).pipe(
        delay(7000),
    );

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly translate: TranslateService,
        private readonly sanitize: DomSanitizer,
        private userService: UserService,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private errorService: ErrorTranslationService,
    ) {
        const code = this.activatedRoute.snapshot.queryParamMap.get('code');
        this.userService.getIdToken(code).pipe(
            retry(3),
            tap(({ id_token }) => this.authService.setIdToken(id_token)),
            switchMap(({ id_token }) => this.userService.getAccessToken(id_token).pipe(
                retry(3),
                tap(({ access_token }) => this.authService.setJwtToken(access_token)),
                map(() => true),
                catchError((error) => {
                    this.errorService.handleError(error, this.translate.instant('AUTH_ERRROR'));
                    return of(false);
                }),
            )),
            catchError((error) => {
                this.errorService.handleError(error, this.translate.instant('AUTH_ERRROR'));
                return of(false);
            }),
        ).subscribe(
            (result) => {
                if (result) {
                    this._navigateToPreviousRoute();
                }
            }
        );
    }

    private _navigateToPreviousRoute() {
        let previousRouteFound = false;
        this._redirectLink$.subscribe({
            next: value => {
                // Ensure that the base route is not an empty path
                if (/^\/?$/.test(value)) {
                    return;
                }
                // Remove code
                const [root, queryParams] = value.split('?');
                const params = queryParams?.split('&').reduce(
                    (paramsMap, param) => {
                        const [key, paramValue] = param.split('=');
                        paramsMap[key] = paramValue;
                        return paramsMap;
                    }, {}
                );
                if (params && Object.prototype.hasOwnProperty.call(params, 'code')) {
                    delete params['code'];
                }
                this.router.navigate([root], {
                    queryParams: params,
                    replaceUrl: true,
                });
                this.localStorageService.remove$(LOCAL_STORAGE_ROUTE_KEY).subscribe();
                // Mark route as found so no navigation to map
                previousRouteFound = true;
            },
            complete: () => {
                if (!previousRouteFound) {
                    this.router.navigate(['map/view-map']);
                }
            }
        });
    }

}
