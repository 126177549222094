import { EsriQueryService } from 'src/app/services/esri-query/esri-query.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { fetchBeaconLayer, successFetchBeaconLayer } from '../actions/beacon-layer.action';

@Injectable()
export class BeaconLayerEffects {

    public fetchBeaconLayer$ = createEffect(() => { return this._actions$.pipe(
        ofType(fetchBeaconLayer),
        switchMap(() => this._esryQueryService.getBeacons()),
        map((beacons) => successFetchBeaconLayer({ beacons: beacons.features }))
    ) });

    constructor(
        private _esryQueryService: EsriQueryService,
        private _actions$: Actions,
    ) { }
}
