import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { SiteService } from 'src/app/services/site.service';
import { errorFetchAllSites, fetchAllSites, mappingAllSitesCompanies, mappingSomeSitesCompanies, successFetchAllSites, successUpdateSites } from '../actions/site.action';
import { selectCompaniesState$ } from '../selectors/company.selectors';
import { Injectable } from '@angular/core';
import { successUpdateParentFamilies } from '../actions/parent-family.action';
import { selectParentFamiliesBySitesIds$ } from '../selectors/parent-family.selectors';
import { CacheService } from 'src/app/services/cache.service';
import { ObjectStore } from 'src/app/models/cache';
import { LoggerService } from 'src/app/services/logger.service';

@Injectable()
export class SiteEffects {

    fetchAllSites$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fetchAllSites),
            switchMap(() => this._cacheService.retrieveCacheStatus$()),
            map(status => status.sites?.lastRefresh),
            switchMap(lastRefresh => this.siteService.getAllSites(lastRefresh)),
            switchMap(sites => combineLatest([
                this._cacheService.deleteData$(ObjectStore.Sites, ...(sites.deletedData ?? []).map(({ id }) => id)),
                this._cacheService.updateData$(ObjectStore.Sites, ...(sites.updatedData ?? [])),
                this._cacheService.createData$(ObjectStore.Sites, ...(sites.createdData?? [])),
            ])),
            switchMap(() => this._cacheService.getData$(ObjectStore.Sites)),
            map(sites => successFetchAllSites({ sites })),
            catchError((error) => {
                this.logger.error('SiteEffects', 'Cannot fetch sites', error);
                return of(errorFetchAllSites());
            }),
        );
    });

    public successFetchAllSites$ = createEffect(() => { return this.actions$.pipe(
        ofType(successFetchAllSites),
        switchMap((action) => this.store.select(selectCompaniesState$).pipe(
            filter(state => state.loaded),
            take(1),
            map(state => mappingAllSitesCompanies({ companies: state.data, sites: action.sites }))
        ))
    ) });

    public successUpdateSites$ = createEffect(() => { return this.actions$.pipe(
        ofType(successUpdateSites),
        switchMap((action) => this.store.select(selectCompaniesState$).pipe(
            filter(state => state.loaded),
            take(1),
            tap(state => this.store.dispatch(mappingSomeSitesCompanies({ sites: action.payload, companies: state.data }))),
            // update children objects
            switchMap(() => this.store.select(selectParentFamiliesBySitesIds$(action.payload.map(site => site.id))).pipe(take(1))),
            map((parentFamilies) => successUpdateParentFamilies({ payload: parentFamilies }))
        )),
    ) });


    public effectSubject: Subject<string | HttpErrorResponse>;
    constructor(
        private siteService: SiteService,
        private actions$: Actions,
        private store: Store,
        public translate: TranslateService,
        private _cacheService: CacheService,
        private logger: LoggerService,
    ) {
        this.effectSubject = new Subject<string>();
    }
}
