<div mat-dialog-title>
    <span>{{"CERT_INSTALLATION_TOTP" | translate}}</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<form [formGroup]="totpForm"
      (submit)="submitCode()">

    <section mat-dialog-content
             class="main-container">

        <div class="device">
            {{ "MACHINEID" | translate }} {{ certificate.machineId }}
        </div>

        <div class="totp-fields"
             formArrayName="totp"
             #totp>
            <ng-container *ngFor="let num of totpFormArray.controls; let i=index">
                <input id="num-{{ i }}"
                       class="totp-num-field "
                       type="text"
                       [formControlName]="i"
                       matInput
                       maxlength="1"
                       (paste)="onPaste($event, i)"
                       (keyup)="onKeyup($event, i)"
                       (input)="onInput($event, i)">
            </ng-container>
        </div>

        <div *ngIf="errors.totpTooManyErrors"
             class="mat-body-strong error-text">
            {{ 'M2M_TOTP_TOO_MANY_ERRORS_DESCRIPTION' | translate }}
        </div>
        <ng-container *ngIf="(remainingTries | typeOf) === 'number'; else elseTemplate">
            <span class="remaining-tries">
                {{ "REMAINING_TRIES" | translate:{ tries:remainingTries } }}
            </span>
        </ng-container>
        <ng-template #elseTemplate>
            <mat-progress-spinner mode="indeterminate"
                                  diameter="16"
                                  strokeWidth="2"></mat-progress-spinner>
        </ng-template>
    </section>

    <footer mat-dialog-actions>
        <button mat-raised-button
                mat-dialog-close
                type="button"
                color="primary">
            {{ "CANCEL" | translate }}
        </button>
        <button #submitButton
                mat-raised-button
                color="primary"
                type="submit"
                mat-flat-button
                [disabled]="totpForm.invalid || requestInProgress">
            <mat-progress-spinner *ngIf="requestInProgress"
                                  matSuffix
                                  mode="indeterminate"
                                  diameter="16"
                                  strokeWidth="2"></mat-progress-spinner>
            {{ "DIALOG_SEND_OPTION" | translate }}
        </button>
    </footer>
</form>
