<div class="way-component--filterable-component main">

  <section class="header">
    <header class="way-component--header">
      <div class="way-component--header-title">{{ "MANAGE_FAMILY_USERS" | translate }}</div>
    </header>
    <section class="header-content">
      <!-- Family input field -->
      <mat-form-field appearance="outline">
        <mat-label>{{ "FAMILY" | translate }}</mat-label>
        <input type="text"
               name="family"
               [placeholder]="'FAMILY' | translate"
               aria-label="Family"
               matInput
               required
               [formControl]="familyFormControl"
               [matAutocomplete]="familyAutocomplete">
        <mat-hint *ngIf="(familiesLoading$ | async) === true">
            <mat-progress-spinner mode="indeterminate"
                                    diameter="12"
                                    strokeWidth="2"></mat-progress-spinner>
            {{ "LOADING_DATA" | translate }}
        </mat-hint>
        <!-- Family description -->
        <mat-hint *ngIf="familyFormControl.valid">
          {{ familyFormControl.value?.description || ('NO_DESCRIPTION_AVAILABLE' | translate) }}
        </mat-hint>
        <!-- Errors management -->
        <mat-error *ngIf="familyFormControl.errors">
            <fa-icon icon="circle-exclamation"></fa-icon>
            <ng-container *ngIf="familyFormControl.errors?.invalidFamily">
                {{ "MANAGE_FAMILY_USERS_INVALID_FAMILY" | translate }}
            </ng-container>
            <ng-container *ngIf="familyFormControl.errors?.required">
                {{ "MANAGE_FAMILY_USERS_REQUIRED_FAMILY" | translate }}
            </ng-container>
        </mat-error>
        <mat-autocomplete #familyAutocomplete="matAutocomplete"
                          panelWidth="fit-content"
                          [displayWith]="getFamilyName">
          <mat-optgroup *ngFor="let parentFamily of parentFamilies$ | async"
                        [label]="parentFamily.name">
            <mat-option *ngFor="let family of parentFamily.families"
                        [value]="family">
              <span>{{ family.name }}</span>
              <small class="caption"
                     *ngIf="family.description">&nbsp;&#124;&nbsp;{{ family.description }}</small>
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
      </mat-form-field>
    </section>
  </section>

  <ng-container *ngIf="familyFormControl.valid; else selectFamilyTemplate">

    <div class="way-filter-container"
         [appHidden]="!(dataSource.data?.length || (usersLoading$ | async))">
      <app-filter *ngIf="familyFormControl.valid"
                  [dataForFilter$]="users$"
                  [parentSourceView]="viewName"></app-filter>
    </div>

    <ng-container *ngIf="dataSource.data?.length || (usersLoading$ | async); else noDataTemplate">

      <div class="way-component--header">
        <span class="way-component--header-title">
          <span>{{ 'USERS' | translate }}</span>
          <span *ngIf="(usersLoading$ | async) === false"
                class="mat-body-strong"> ({{ dataSource?.data?.length }})</span>
        </span>
        <div class="way-component--header-buttons">
          <button mat-stroked-button
                  color="primary"
                  [disabled]="massUpdateDisabled || (usersLoading$ | async)"
                  (click)="onClickMassUpdateRole()">
            <fa-icon icon="pencil-alt"></fa-icon>
            {{ 'USERS_MASS_UPDATE' | translate }}
          </button>
          <button mat-stroked-button
                  color="primary"
                  [disabled]="usersLoading$ | async"
                  (click)="refreshData()">
            <fa-icon icon="sync-alt"></fa-icon>
            {{ 'REFRESH_DATA' | translate }}
          </button>
        </div>
      </div>

      <div class="way-table--main-container">

        <div class="table-container custom-scrollbar">
          <table class="way-table"
                 mat-table
                 matSort
                 matSortActive="famRightLevel"
                 matSortDirection="desc"
                 [dataSource]="dataSource"
                 aria-label="manage family user table">
            <!-- Columns -->
            <ng-container matColumnDef="data">
              <th mat-header-cell
                  mat-sort-header
                  scope="col"
                  *matHeaderCellDef> {{ 'USER' | translate }} </th>
              <td mat-cell
                  *matCellDef="let row"> {{row.data}} </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell
                  mat-sort-header
                  scope="col"
                  *matHeaderCellDef> {{ 'EMAIL' | translate }} </th>
              <td mat-cell
                  *matCellDef="let row"> {{row.email}} </td>
            </ng-container>
            <ng-container matColumnDef="famRightLevel">
              <th mat-header-cell
                  mat-sort-header
                  scope="col"
                  *matHeaderCellDef> {{ "RIGHT_LEVEL" | translate }} </th>
              <td mat-cell
                  *matCellDef="let row">
                <div [matTooltip]="!!row.fromGroupId ? ('RIGHTS_ONLY_FROM_GROUP' | translate) : ('RIGHTS_FORBIDDEN_SELF_ASSIGN' | translate)"
                     [matTooltipDisabled]="!row.fromGroupId && currentUserId !== row.data">
                  <app-role-selection [role]="row.famRightLevel"
                                      [editableRoleLimit]="selectedFamily.level"
                                      [disabled]="!!row.fromGroupId || currentUserId === row.data"
                                      color="primary"
                                      (selectedRoleChange)="onRoleSelectionChange(row, $event)"></app-role-selection>
                </div>
                <div *ngIf="row.updating"
                     class="row-loader">
                  <mat-progress-spinner color="primary"
                                        mode="indeterminate"
                                        diameter="32"></mat-progress-spinner>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <div *ngIf="usersLoading$ | async"
               class="table-loader">
            <mat-progress-spinner color="primary"
                                  mode="indeterminate"
                                  diameter="32">
            </mat-progress-spinner>
          </div>

        </div>

        <mat-paginator class="way-table--footer"
                       (page)="scrollUp()"
                       [pageSizeOptions]="[25, 50, 100]">
        </mat-paginator>

      </div>

    </ng-container>

  </ng-container>

  <ng-template #noDataTemplate>
    <div class="fill-panel empty-state">
      <div class="fa-stack fa-4x icon">
        <fa-stack>
            <fa-icon icon="ghost" stackItemSize="2x" class="icon-back"></fa-icon>
            <fa-icon icon="scroll" class="icon-front" data-fa-transform="shrink-8"></fa-icon>
        </fa-stack>
      </div>
      <div>
        <p class="mat-subheading-2">{{ "NO_DATA_FOUND" | translate }}</p>
      </div>
    </div>
  </ng-template>

  <ng-template #selectFamilyTemplate>
    <app-empty-state-fill-field [subtitle]="'MANAGE_FAMILY_USERS_TRY_FAMILY' | translate"></app-empty-state-fill-field>
  </ng-template>

</div>
