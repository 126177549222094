import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConversionService {

    convert(value: number, ratio: number): number;
    convert(value: number, from: Units.Length, to: Units.Length): number;
    convert(value: number, ...args: unknown[]): number {
        if (args.length === 1) {
            const ratio = args[0] as number;
            return +value * ratio;
        } else {
            const [from, to] = args as [Units.Any, Units.Any];
            return (+value / to) * from;
        }
    }

}

// eslint-disable-next-line @typescript-eslint/prefer-namespace-keyword, @typescript-eslint/no-namespace
export module Units {
    export enum Length {
        millimeter = 1e-3,
        centimeter = 1e-2,
        decimeter = 1e-1,
        meter = 1,
        decameter = 1e1,
        hectometer = 1e2,
        kilometer = 1e3,
        foot = 0.3048,
        inch = 2.54e-2,
        mile = 1.609344e3,
        nauticalMile = 1.852e3,
        au = 1.495979e11,
    }
    export enum Area {
        millimeter = 1e-6,
        centimeter = 1e-4,
        decimeter = 1e-2,
        meter = 1,
        decameter = 1e2,
        hectameter = 1e4,
        kilometer = 1e6,
    }
    export type Any = Length | Area;
}
