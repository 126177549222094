import { createAction, props } from '@ngrx/store';
import { LoadAssetInput, UnloadAssetInput } from 'src/app/models/operation';
import { Planning } from 'src/app/models/planning';

export const fetchPlanning = createAction('[Planning] fetch planning', props<{ fromDate: string, toDate: string }>());
export const successFetchPlanning = createAction('[Planning] success fetch planning', props<{ payload: Array<Planning> }>());
export const errorFetchPlanning = createAction('[Planning] error fetch planning');

export const createPlanning = createAction('[Planning] create planning');
export const successCreatePlanning = createAction('[Planning] success create planning', props<{ payload: Array<Planning> }>());
export const errorCreatePlanning = createAction('[Planning] error create planning');

export const editPlanning = createAction('[Planning] edit planning', props<{ payload: Planning }>());
export const successEditPlanning = createAction('[Planning] success edit planning', props<{ payload: Array<Planning> }>());
export const errorEditPlanning = createAction('[Planning] error edit planning');

export const deletePlanning = createAction('[Planning] delete planning', props<{ payload: Planning }>());
export const successDeletePlanning = createAction('[Planning] success delete planning', props<{ payload: (string|Planning)[] }>());
export const errorDeletePlanning = createAction('[Planning] error delete planning');

export const loadOperation = createAction('[Planning] create load operation', props<{ payload: LoadAssetInput, assetId: string,  }>());
export const successLoadOperation = createAction('[Planning] success load operation');
export const errorLoadOperation = createAction('[Planning] error load opeartion');

export const unloadOperation = createAction('[Planning] create unload operation', props<{ payload: UnloadAssetInput, assetId: string,  }>());
export const successUnLoadOperation = createAction('[Planning] success unload operation');
export const errorUnLoadOperation = createAction('[Planning] error unload opeartion');
