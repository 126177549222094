import { MachineStatus } from 'src/app/models/certificate';

export enum Status {
    pending,
    onHold,
    inProgress,
    done,
    block,
}

export function getStatusFromMachineStatus(status: MachineStatus): Status {
    switch (status) {
        case MachineStatus.Activated:
        case MachineStatus.Validated:
        case MachineStatus.Done:
            return Status.done;
        case MachineStatus.Deleted:
        case MachineStatus.Expirated:
        case MachineStatus.Rejected:
            return Status.block;
        case MachineStatus.InProgress:
            return Status.inProgress;
        case MachineStatus.OnHold:
            return Status.onHold;
        case MachineStatus.Pending:
            return Status.pending;
    }
}
