import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { KpiScale } from 'src/app/models/kpiform';
import { KpiFamily } from 'src/app/services/kpi.service';
import { ChartClickEvent } from '../../shared/kpi/chart.base';

@Component({
  selector: 'app-kpi-in-out-zone-monitoring',
  templateUrl: './kpi-in-out-zone-monitoring.component.html',
  styleUrls: ['./kpi-in-out-zone-monitoring.component.scss']
})
export class KpiInOutZoneMonitoringComponent {

  @Input() public dataKPIinout: KpiFamily;
  @Input() public loaded: boolean;

  public selectedScale: KpiScale = 'percent';
  public indexAssetClicked?: number;

  constructor(
    private _cd: ChangeDetectorRef,
  ) { }

  public onScaleChange(event: MatButtonToggleChange): void {
    this.selectedScale = event.value;
    this._cd.detectChanges();
  }

  public onAssetChartClicked({ item }: ChartClickEvent): void {
    if (item[0] !== undefined) {
      this.indexAssetClicked = item[0].index;
      this._cd.detectChanges();
    }
  }

}
