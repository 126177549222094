import {
    createAlertError,
    createAlertSuccess,
    deleteAlert,
    editAlertError,
    editAlertSuccess,
    errorFetchOneAlert,
    errorFetchAllAlerts,
    fetchOneAlert,
    successFetchOneAlert,
    successFetchAllAlerts,
    fetchAllAlerts
} from '../actions/alert.actions';
import { Alert } from 'src/app/models/alert';
import { Action, createReducer, on } from '@ngrx/store';

export interface State {
    data: {
        alerts: { [id: string]: Alert },
        ids: string[],
    };
    loading: boolean;
    loaded: boolean;
}

const INIT_STATE: State = {
    data: {
        alerts: {},
        ids: []
    },
    loading: false,
    loaded: false,
};

const AlertsReducer = createReducer(
    INIT_STATE,
    on(fetchOneAlert, (state): State => ({
        ...state, loaded: true,
    })),
    on(successFetchOneAlert, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: (() => {
            state.data.ids.push(payload.id);
            Object.assign(state.data.alerts || {}, state.data.alerts, {
                [payload.id]: payload,
            });

            return state.data;
        })()
    })),
    on(errorFetchOneAlert, (state): State => ({
        ...state, loading: false,
    })),
    on(deleteAlert, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: (() => {
            const elementIndex = state.data.ids.indexOf(payload);
            if (elementIndex < 0) {
                return;
            }
            state.data.ids = state.data.ids.filter(id => id !== payload);
            delete state.data.alerts[payload];
            return state.data;
        })()
    })),
    on(fetchAllAlerts, (state) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(successFetchAllAlerts, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: {
            ids: payload.map(
                ({ id }) => id
            ),
            alerts: ((storedAlerts) => payload.reduce(
                (alertMap, alert) => {
                    alertMap[alert.id] = Object.assign(storedAlerts[alert.id] ?? {}, alert);
                    return alertMap;
                }, {}
            ))(state.data.alerts),
        },
    })),
    on(errorFetchAllAlerts, (state): State => ({
        ...state, loading: false,
    })),
    on(createAlertSuccess, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: (() => {
            state.data.ids.push(payload.id);
            Object.assign(state.data.alerts || {}, state.data.alerts, {
                [payload.id]: payload,
            });

            return state.data;
        })()
    })),
    on(createAlertSuccess, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: (() => {
            state.data.ids.push(payload.id);
            Object.assign(state.data.alerts || {}, state.data.alerts, {
                [payload.id]: payload,
            });
            return state.data;
        })()
    })),
    on(createAlertError, (state): State => ({
        ...state, loading: false,
    })),
    on(editAlertSuccess, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: (() => {
            state.data.ids.push(payload.id);
            Object.assign(state.data.alerts || {}, state.data.alerts, {
                [payload.id]: payload,
            });
            return state.data;
        })()
    })),
    on(editAlertError, (state): State => ({
        ...state, loading: false,
    })),
);

export function reducer(state: State | undefined, action: Action) {
    return AlertsReducer(state, action);
}
