<div class="labels">
    <ng-container *ngIf="!loading; else loadingTemplate">
        <span>{{ 'ASSET_TO_DISPLAY' | translate }}</span>
        <span class="quantity">{{ quantity$ | async }}</span>
        <ng-container *ngIf="assetQuantity > MAX_ASSET_COUNT; else elseTemplate">
            <span>
                <fa-icon icon="exclamation-triangle"></fa-icon>&nbsp;
                {{ 'ASSETS_ABOVE_LIMIT' | translate:{quantity: MAX_ASSET_COUNT} }}
            </span>
        </ng-container>
        <ng-template #elseTemplate>
            <span>
                <fa-icon icon="check"></fa-icon>&nbsp;
                {{ 'ASSETS_UNDER_LIMIT' | translate:{quantity: MAX_ASSET_COUNT} }}
            </span>
        </ng-template>
    </ng-container>
    <ng-template #loadingTemplate>
        <span>{{ 'LOADING_DATA' | translate }}</span>
        <mat-progress-spinner color="primary"
                              mode="indeterminate"
                              diameter="32"></mat-progress-spinner>
    </ng-template>
</div>
