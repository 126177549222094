import { MatDialog } from '@angular/material/dialog';
import { AssetSelectionComponent } from './asset-selection/asset-selection.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveElement, ChartEvent, ScatterDataPoint } from 'chart.js';
import { KpiTimeInZone, KpiTimeInZoneAsset } from 'src/app/services/kpi.service';
import { CommonKPIService } from '../../kpi-common';
import ChartBaseComponent from '../../../shared/kpi/chart.base';
import { Ng2ChartConfiguration } from '../../../shared/kpi/chart.model';
import { take } from 'rxjs/operators';

enum DatasetColor {
  min = 'gray',
  mean = 'blue',
  max = 'green',
  asset = 'black',
}

@Component({
  selector: 'app-in-zone-assets',
  templateUrl: '../../../shared/kpi/chart-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InZoneAssetsComponent extends ChartBaseComponent<KpiTimeInZone, 'line', string> {

  public chartConfiguration: Ng2ChartConfiguration<'line', (number | ScatterDataPoint)[], string> = {
    type: 'line',
    data: { datasets: [] },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      onClick: this._onChartClick.bind(this),
      plugins: {
        title: {
          display: true,
          text: this._translate.instant('TIME_IN_ZONE_ASSET'),
          position: 'top',
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            filter: (item) => !item.text.includes(`${this._translate.instant('ASSET')}: `)
          }
        },
      },
      scales: {
        x: {
          stacked: false,
          display: true,
          beginAtZero: false,
          grid: {
            display: false
          }
        },
        y: {
          stacked: false,
          display: true,
          beginAtZero: false,
        },
      },
      elements: {
        line: {
          tension: 0.000001,
          fill: true,
        }
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
    },
  };

  constructor(
    protected _translate: TranslateService,
    protected _cd: ChangeDetectorRef,
    protected _commonKPIService: CommonKPIService,
    private _dialog: MatDialog,
  ) {
    super(_translate, _cd, _commonKPIService);
  }

  /**
   * Build the dataset for each asset
   * one dataset by asset, with the zones on the horizontal axis
   * For each asset:
   *   - for each zone (in the order of the labels)
   *     - if this zone has this asset, then the `sumTimePassedIn` is added to the data
   *     - else a null value is added to keep the correct position on the abscissa
   */
  private _getDatasetForAssets(labels: typeof this.chartConfiguration.data.labels): typeof this.chartConfiguration.data.datasets {
    const distinctAssets: Record<string, {
      zoneName: string;
      sumTimePassedIn: KpiTimeInZoneAsset['sumTimePassedIn'];
    }[]> = {};
    this.dataKPI.kpiTimeZone.forEach(
      (zone) => zone.kpiTimeZoneAssets.forEach(
        ({ asset, sumTimePassedIn }) => {
          if (!distinctAssets[asset.assetName]) {
            distinctAssets[asset.assetName] = [];
          }
          distinctAssets[asset.assetName].push({
            zoneName: zone.kpiTimeZoneName,
            sumTimePassedIn,
          });
        }
      )
    );
    const zoneOther: typeof this.chartConfiguration.data.datasets = Object.keys(distinctAssets).map(
      assetName => {
        const data = labels.map(
          zoneName => {
            const zoneAsset = distinctAssets[assetName].find(
              (zone) => zone.zoneName === zoneName
            );
            if (zoneAsset) {
              return +zoneAsset.sumTimePassedIn.toFixed(2);
            }
            return null;
          }
        );
        return {
          label: `${this._translate.instant('ASSET')}: ${assetName}`,
          data,
          borderColor: DatasetColor.asset,
          fill: false,
          borderWidth: 2,
          pointBorderColor: DatasetColor.asset,
          pointBackgroundColor: DatasetColor.asset,
          pointBorderWidth: 3,
          showLine: true
        };
      }
    );
    return zoneOther;
  }

  /**
   * Set the data of the chart
   */
  public setKpiData(): void {
    const labels = this.dataKPI.kpiTimeZone.map(
      ({ kpiTimeZoneName }) => kpiTimeZoneName
    );
    // Set the data of the chart
    this.chartConfiguration.data = {
      labels,
      datasets: [
        {
          label: `${this._translate.instant('MIN')} (${this._translate.instant('HOURS')})`,
          data: this.dataKPI.kpiTimeZone.map(
            ({ kpiTimeZoneMin }) => +kpiTimeZoneMin.toFixed(2)
          ),
          borderColor: DatasetColor.min,
          backgroundColor: DatasetColor.min,
          fill: false,
          borderWidth: 2,
          pointBorderColor: DatasetColor.min,
          pointBackgroundColor: DatasetColor.min,
          pointBorderWidth: 3,
          showLine: true,
        },
        {
          label: `${this._translate.instant('MEAN')} (${this._translate.instant('HOURS')})`,
          data: this.dataKPI.kpiTimeZone.map(
            ({ kpiTimeZoneMean }) => +kpiTimeZoneMean.toFixed(2)
          ),
          borderColor: DatasetColor.mean,
          backgroundColor: DatasetColor.mean,
          fill: false,
          borderWidth: 2,
          pointBorderColor: DatasetColor.mean,
          pointBackgroundColor: DatasetColor.mean,
          pointBorderWidth: 3,
          showLine: true,
        },
        {
          label: `${this._translate.instant('MAX')} (${this._translate.instant('HOURS')})`,
          data: this.dataKPI.kpiTimeZone.map(
            ({ kpiTimeZoneMax }) => +kpiTimeZoneMax.toFixed(2)
          ),
          borderColor: DatasetColor.max,
          backgroundColor: DatasetColor.max,
          fill: false,
          borderWidth: 2,
          pointBorderColor: DatasetColor.max,
          pointBackgroundColor: DatasetColor.max,
          pointBorderWidth: 3,
          showLine: true,
        },
        ...this._getDatasetForAssets(labels),
      ],
    };
  }

  /**
   * Triggered when an element of the chart is clicked
   * @param event The click event
   * @param elements The clicked elements
   */
  private _onChartClick(event: ChartEvent, elements: ActiveElement[]): void {
    if (!elements?.length) {
      return;
    }
    // Indentify elements corresponding to assets
    const assetItems = elements.filter(
      el => this.chartConfiguration.data.datasets[el.datasetIndex].label.includes(`${this._translate.instant('ASSET')}: `)
    );
    if (assetItems.length > 1) {
      // Make the user choose the asset to display next
      const sheet = this._dialog.open(AssetSelectionComponent, {
        position: {
          bottom: '0',
        },
        minWidth: '30vw',
        data: assetItems.map(
          ({ datasetIndex }) => this.chartConfiguration.data.datasets[datasetIndex].label
        ),
      });
      this._cd.detectChanges();
      sheet.afterClosed().pipe(
        take(1),
      ).subscribe(
        (selectedAsset) => {
          if (!selectedAsset) {
            return;
          }
          this._cd.detectChanges();
          const datasetItems = assetItems.filter(
            item => this.chartConfiguration.data.datasets[item.datasetIndex].label === selectedAsset,
          );
          this.chartClicked.emit({
            event,
            item: datasetItems,
            others: {
              assetId: this._getSelectedAssetId(datasetItems[0]),
            }
          });
        }
      );
    } else {
      this.chartClicked.emit({
        event,
        item: assetItems,
        others: {
          assetId: this._getSelectedAssetId(assetItems[0])
        }
      });
    }
  }

  /**
   * Get the selected asset id
   * @param datasetItem The selected dataset item
   * @returns The asset id
   */
  private _getSelectedAssetId(datasetItem: ActiveElement): string {
    const selectedAsset = this.chartConfiguration.data.datasets[datasetItem.datasetIndex].label;
    const assets = this.dataKPI.kpiTimeZone.map(
      ({ kpiTimeZoneAssets }) => kpiTimeZoneAssets
    ).flat();
    const assetId = assets.find(
      ({ asset }) => `${this._translate.instant('ASSET')}: ${asset.assetName}` === selectedAsset
    )?.asset?.assetId;
    return assetId;
  }

}
