import { createSelector } from '@ngrx/store';
import { AppState } from '../../store';
import { Scope } from 'src/app/models/profile';

export const selectParentFamilyState = (state: AppState) => state.parentFamilies;

export const selectParentFamilyState$ = createSelector(selectParentFamilyState, state => state);

export const selectParentFamily$ = createSelector(selectParentFamilyState, (parentFamilies) => parentFamilies.data);

export const selectParentFamilyArray$ = createSelector(selectParentFamilyState, (parentFamilies) => Object.values(parentFamilies.data.parentFamilies));

export const selectParentFamilyById$ = (parentFamilyId: string) => createSelector(selectParentFamily$, (data) => data.parentFamilies[parentFamilyId]);

export const selectParentFamiliesByScope$ = (scope: Scope) => createSelector(selectParentFamilyArray$, parentFamilies => parentFamilies.filter(
    (pf) => pf.scope === scope)
);

export const selectParentFamiliesBySitesIds$ = (sitesIds: string[]) => createSelector(selectParentFamilyState, (state) => {
    return Object.values(state.data.parentFamilies).filter(parent => sitesIds.includes(parent.site.id));
});

export const selectParentFamilyLoaded$ = createSelector(selectParentFamilyState, (parentFamilies) => parentFamilies.loaded);
