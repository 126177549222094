import { Component } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { WebSocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-websocket-info',
  template: `
    <div mat-dialog-title>
        <ng-container *ngIf="webSocketOn$ | async; else offTitle">
            <span>{{ 'WEBSOCKET_DISCONNECT_TITLE' | translate }}</span>
        </ng-container>
        <ng-template #offTitle>
            <span>{{ 'REFRESH_REAL_TIME' | translate }}</span>
        </ng-template>
                <button mat-icon-button mat-dialog-close>
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <ng-container *ngIf="webSocketOn$ | async; else offContent">
            {{ 'WEBSOCKET_DISCONNECT_CONTENT' | translate }}
        </ng-container>
        <ng-template #offContent>
            {{ 'WEBSOCKET_DISCONNECT_CONTENT_REFRESH' | translate }}
        </ng-template>

        <div class="disconnect-websocket"></div>
    </div>
    <div mat-dialog-actions>
        <button mat-dialog-close
                mat-stroked-button
                color="primary">
            {{ "DIALOG_CANCEL_OPTION" | translate }}
        </button>
        <button (click)="handleWebsocket()"
                mat-flat-button
                color="primary">
            {{ "APPLY" | translate }}
        </button>
    </div>
  `,
  styles: [
    `.mat-mdc-dialog-content {
        margin-top: 20px;
    }`
  ]
})
export class WebsocketInfoComponent {

    public webSocketOn$: Observable<boolean>;

    constructor(
        private _webSocketService: WebSocketService,
    ) {
        this.webSocketOn$ = combineLatest([
            this._webSocketService.isWebSocketConnected$,
            this._webSocketService.webSocketRealTime$,
        ]).pipe(
            map(([connected, realTime]) => connected && realTime),
        );
    }

    /**
     * Manually disconnect the websocket
     */
    public handleWebsocket() {
        this.webSocketOn$.pipe(
            take(1),
        ).subscribe(
            activated => {
                if (activated) {
                    this._webSocketService.isManualDisconnection = true;
                    this._webSocketService.disconnect();
                } else {
                    window.location.reload();
                }
            }
        );
    }

}
