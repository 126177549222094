import { Action, createReducer, on } from '@ngrx/store';
import {
    errorFetchPlanning, fetchPlanning, successCreatePlanning, successDeletePlanning, successEditPlanning,
    successFetchPlanning,
} from '../actions/planning.action';
import { Planning } from './../../models/planning';

export interface State {
    data: Array<Planning>;
    loading: boolean;
    loaded: boolean;
    planningAPILoaded: boolean;
}

// default state
const initialState: State = {
    data: [],
    loading: false,
    loaded: false, // Alway put true otherwise websocket are not loaded
    planningAPILoaded: false, // Check API called first time
};


const assetReducer = createReducer(
    initialState,
    on(fetchPlanning, (state): State => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    }),
    on(successFetchPlanning, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            planningAPILoaded: true,
            data: (() => {
                const datas = [...action.payload];
                datas.forEach(planningEvent => {
                    planningEvent.sectionsMSNList = [];
                    planningEvent.jigs?.forEach(jig => {
                        if (jig.sectionMsn) { // Prevent undefined value causing filtering issues
                            planningEvent.sectionsMSNList = planningEvent.sectionsMSNList?.concat(jig.sectionMsn);
                        }
                    });
                });
                return datas;
            })()
        };
    }),
    on(errorFetchPlanning, (state): State => {
        return {
            ...state,
            loading: false
        };
    }),
    on(successCreatePlanning, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            data: (() => {
                const data = [...state.data, ...action.payload];
                return data;
            })()
        };
    }),
    on(successEditPlanning, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            planningAPILoaded: state.planningAPILoaded,
            data: (() => {
                // depending on which kind of line is updated, we identify it by different way.
                // In any case, we need to redefine the sectionMSNList attriute (in order to filter on section or msn)
                const newStateData = [...state.data];
                action.payload.forEach(planningEvent => {
                    if (planningEvent.type === 'planned') {
                        const matchIndex = newStateData.findIndex(statePlanningEvent => statePlanningEvent.lineId === planningEvent.lineId
                            && statePlanningEvent.planningId === planningEvent.planningId);
                        if (matchIndex > -1) {
                            newStateData[matchIndex] = planningEvent;
                        }
                    } else if (planningEvent.type === 'operation' || planningEvent.type === 'implied_operation') {
                        const matchIndex = newStateData.findIndex(statePlanningEvent => statePlanningEvent.operationId === planningEvent.operationId);
                        if (matchIndex > -1) {
                            newStateData[matchIndex] = planningEvent;
                        }
                    }
                });
                return newStateData;
            })()
        };
    }),

    on(successDeletePlanning, (state, action) => {
        return {
            loading: false,
            loaded: true,
            ...state,
            data: (() => {
                const datas = [...state.data];
                const arrayToDelete = [...action.payload];
                return datas.filter(unplannedOpe => typeof(arrayToDelete[0]) === 'string' ? !arrayToDelete.includes(unplannedOpe.operationId)
                 : !arrayToDelete.map((planning: Planning) => planning.operationId).includes(unplannedOpe.operationId));  // action.payload contains the list of operationId deleted
            })()
        };
    }),
);

export function reducer(state: State | undefined, action: Action) {
    return assetReducer(state, action);
}
