import { NgModule } from '@angular/core';
import { OrderByPipe } from './orderby.pipe';



@NgModule({
  declarations: [ OrderByPipe, ],
  imports: [ ],
  exports: [ OrderByPipe, ],
})
export class OrderbyModule { }
