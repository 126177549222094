import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import ChartBaseComponent, { ChartClickEvent } from 'src/app/components/shared/kpi/chart.base';
import { Ng2ChartConfiguration } from 'src/app/components/shared/kpi/chart.model';
import { KpiCycle, KpiFamily } from 'src/app/services/kpi.service';

@Component({
  selector: 'app-bar-zones-cycle',
  templateUrl: '../../../shared/kpi/chart-card-scrollable.component.html',
  styleUrls: ['../../../shared/kpi/chart-card-scrollable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarZonesCycleComponent extends ChartBaseComponent<KpiFamily, 'bar', string> implements OnChanges {

  @Input() selectedAssetIndex: number;
  @Input() selectedCycleIndex: number;
  @Input() zoneLevel: 1|2 = 1;
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  private _excludedZones: string[] = [];

  public chartConfiguration: Ng2ChartConfiguration<'bar', number[], string> = {
    type: 'bar',
    data: { datasets: [] },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      onClick: (event, item) => this.onClickBar({ event, item }),
      parsing: {
        xAxisKey: 'label',
      },
      interaction: {
        mode: 'dataset',
      },
      scales: {
        x: {
          stacked: true,
          display: true,
          beginAtZero: true,
          grid: {
            display: false,
          }
        },
        y: {
          stacked: true,
          display: true,
          beginAtZero: true,
        },
      },
      plugins: {
        title: {
          display: true,
          text: this._translate.instant('CYCLE_TIME_MONITORING'),
          position: 'top',
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
        },
      }
    },
  };

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    const skip = super.ngOnChanges(changes);
    if (skip) {
      return;
    }
    if (changes.selectedCycleIndex) {
      if (changes.selectedCycleIndex.currentValue !== changes.selectedCycleIndex.previousValue) {
        this._excludedZones = [];
        this.setKpiData();
        this._cd.markForCheck();
      }
    } else if (changes.zoneLevel) {
      if (changes.zoneLevel.currentValue !== changes.zoneLevel.previousValue) {
        this.setKpiData();
        this._cd.markForCheck();
      }
    }
  }

  /**
   * Set the data for the zone level 1
   * @param listDataCycle The cycle data list
   * @param assetName The asset name
   */
  private _setKpiDataForLevel1(listDataCycle: KpiCycle[], assetName: string): void {
    const listDataZone = listDataCycle[this.selectedCycleIndex].cycleZones;

    const datasetsForOthers = [];
    this.chartConfiguration.data = {
      labels: [
        `${this._translate.instant('ASSET').toUpperCase()} ${assetName} ${this._translate.instant('LOOP').toUpperCase()}: ${listDataCycle[this.selectedCycleIndex].cycleCycleOrder}`,
      ],
      datasets: listDataZone.map(
        (zone, index) => {
          if (this._excludedZones.includes(zone.zoneName)) {
            datasetsForOthers.push(zone);
            return;
          }
          return this._commonKPIService.getDatasetAndColour(
            zone.zoneName,
            [{
              y: +zone.zoneTimePassedIn.toFixed(2),
              label: this._translate.instant('TIME_IN_ZONE'),
              name: zone.zoneName,
            }],
            index,
            -1,
          );
        }
      ).filter(
        d => !!d
      ),
    };
    // Add values other
    datasetsForOthers.forEach(
      zone => this._addAsOther(zone.zoneName, +zone.zoneTimePassedIn.toFixed(2))
    );
  }

  /**
   * Set the data for the zone level 2
   * @param listDataCycle The cycle data list
   * @param assetName The asset name
   */
  private _setKpiDataForLevel2(listDataCycle: KpiCycle[], assetName: string): void {
    const listDataZone = listDataCycle[this.selectedCycleIndex].cycleZones.filter(
      (zone) => +zone.zoneTimePassedIn > 0
    ).filter(
      (zone) => !this._excludedZones.includes(zone.zoneName)
    );
    // Reset data
    this.chartConfiguration.data = {
      labels: [],
      datasets: []
    };
    // For each zone, set the data
    const label = `${this._translate.instant('ASSET').toUpperCase()} ${assetName} ${this._translate.instant('LOOP').toUpperCase()}: ${listDataCycle[this.selectedCycleIndex].cycleCycleOrder}`;
    this.chartConfiguration.data.labels.push(label);
    listDataZone.forEach(
      (zone, index) => {
        const datasets = [];
        const zoneSum = zone.zoneZonesIn1.reduce(
          (sum, zoneLvl2, indexLvl2) => {
            const zoneFullName = `${zone.zoneName} > ${zoneLvl2.zoneIn1Name}`;
            const zoneLvl2Time = +zoneLvl2.zoneIn1TimePassedIn.toFixed(2);
            const dataset = this._commonKPIService.getDatasetAndColour(
              zoneFullName,
              [{
                y: zoneLvl2Time,
                label: zoneFullName,
                name: zoneFullName,
              }],
              index,
              indexLvl2,
            );
            datasets.push(dataset);
            return sum + zoneLvl2Time;
          },
          0
        );
        const zoneLvl1Time = +zone.zoneTimePassedIn.toFixed(2);
        if (zoneSum < zoneLvl1Time) {
          datasets.push(
            this._commonKPIService.getDatasetAndColour(
              zone.zoneName,
              [{
                y: zoneLvl1Time - zoneSum,
                label: this._translate.instant('TIME_IN_ZONE'),
                name: zone.zoneName,
              }],
              index,
              -1,
            )
          );
        }
        this.chartConfiguration.data.datasets.push(...datasets);
      }
    );
  }

  /**
   * Set the data for the KPI
   */
  setKpiData(): void {
    const listDataCycle = this.dataKPI.familyAssets[this.selectedAssetIndex].assetCycles;
    const assetName = this.dataKPI.familyAssets[this.selectedAssetIndex].assetName;
    switch (this.zoneLevel) {
      case 1:
        this._setKpiDataForLevel1(listDataCycle, assetName);
        break;
      case 2:
        this._setKpiDataForLevel2(listDataCycle, assetName);
        break;
      default:
        throw new RangeError(`The zone level ${this.zoneLevel} is not handled`);
    }
    this.chartConfiguration.options.plugins.title.text = `${this._translate.instant('CYCLE_TIME_MONITORING')} (${this._translate.instant('ASSET').toUpperCase()}: ${assetName} ${this._translate.instant('LOOP').toUpperCase()}: ${listDataCycle[this.selectedCycleIndex].cycleCycleOrder} ${this._translate.instant('LEVEL').toUpperCase()} ${this.zoneLevel})`;
    this.chart?.update();
    this._cd.markForCheck();
  }

  /**
   * Triggered when the chart is clicked
   */
  public onClickBar({ item }: ChartClickEvent): void {
    // Only for zone level 1
    if (this.zoneLevel === 2) {
      return;
    }
    const OTHERS = this._translate.instant('others');
    // Avoid errors when you do not click on the graph
    const activePoint = item?.[0];
    if (activePoint) {
      const datasetIndex = activePoint.datasetIndex;
      const label = this.chartConfiguration.data.datasets[datasetIndex].label;
      const value = this.chartConfiguration.data.datasets[datasetIndex].data.reduce(
        (sum, v) => sum + v, 0
      );
      // This onClick works for areas except Others
      if (label !== OTHERS) {
        this._addAsOther(label, value);
        this.chartConfiguration.data.datasets.splice(datasetIndex, 1);
        this.chart?.update();
        this._cd.markForCheck();
      }
    }
  }

  /**
   * Add a zone as "Others" (ie. exclude)
   * @param label The label
   * @param value The time
   */
  private _addAsOther(label: string, value: number) {
    const OTHERS = this._translate.instant('others');
    this._excludedZones.push(label);
    const datasetOtherIndex = this.chartConfiguration.data.datasets.findIndex(
      (dataset) => dataset.label === OTHERS
    );
    // If Others does not exist, it must be added to the dataset of the graph
    if (datasetOtherIndex === -1) {
      this.chartConfiguration.data.datasets.push({
        data: [value],
        label: OTHERS,
        backgroundColor: 'black',
        borderColor: 'black',
        borderWidth: 1,
      });
    } else {
      this.chartConfiguration.data.datasets[datasetOtherIndex].data[0] += value;
    }
  }


}
