import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export enum ContextOverwriteActions {
    create,
    overwrite,
}

@Component({
    selector: 'app-dialog-overwrite-action',
    template: `
    <div mat-dialog-title>
        <span>{{ "OVERWRITE" | translate }}</span>
    </div>
    <section mat-dialog-content>
        <p>{{ "CONFIRM_CONTEXT_OVERWRITE" | translate }}</p>
    </section>
    <footer mat-dialog-actions>
        <button mat-flat-button
                color="primary"
                (click)="create()">
            {{ "NEW_CONTEXT" | translate }}
        </button>
        <button mat-flat-button
                color="primary"
                (click)="overwrite()">
        {{ "OVERWRITE" | translate }}
        </button>
    </footer>
  `,
    styles: [
        `.mat-mdc-dialog-content {
            padding: 2em;
        }`,
    ]
})
export class DialogOverwriteActionComponent {

    constructor(
        private _dialogRef: MatDialogRef<DialogOverwriteActionComponent>,
    ) {}

    public create(): void {
        this._dialogRef.close(ContextOverwriteActions.create);
    }

    public overwrite(): void {
        this._dialogRef.close(ContextOverwriteActions.overwrite);
    }

}
