<div class="dialog"
     [ngClass]="{'large': maintainUsers || maintainFamilies}">
    <div mat-dialog-title>
        <span>{{ title }}</span>
        <button mat-icon-button mat-dialog-close>
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>

    <form [formGroup]="groupForm"
          class="main-container">
        <div mat-dialog-content class="form-content">
            <div class="ownership"
                 *ngIf="data.group">
                <mat-icon>key</mat-icon>
                <ng-container *ngIf="data.group.isOwner; else notOwnerTemplate">
                    <span>{{ "YOU_ARE_GROUP_OWNER" | translate }}</span>
                    <button mat-flat-button color="primary" (click)="transfertOwnership()">
                        {{ "GROUP_TRANSFER_OWNERSHIP" | translate }}
                    </button>
                </ng-container>
                <ng-template #notOwnerTemplate>
                    <span>{{ "OWNER_OF_GROUP_IS" | translate:{
                        owner: (owner$ | async)?.email || ('SOMEONE_ELSE' | translate | lowercase)
                    } }}</span>
                </ng-template>
            </div>
            <mat-form-field appearance="outline">
                <mat-label for="name">{{ "NAME" | translate }}</mat-label>
                <input matInput
                       id="name"
                       name="name"
                       type="text"
                       formControlName="name"
                       [placeholder]="'NAME' | translate">
                <mat-error *ngIf="groupForm.controls.name.invalid">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "REQUIRED" | translate: {subject: 'NAME' | translate} }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label for="description">{{ "DESCRIPTION" | translate }}</mat-label>
                <textarea matInput
                          id="description"
                          name="description"
                          type="text"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5"
                          formControlName="description"
                          [placeholder]="'DESCRIPTION' | translate"></textarea>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{ "MANAGERS" | translate }}</mat-label>
                <mat-chip-grid formControlName="managers"
                               #chipGrid>
                    <mat-chip-row *ngFor="let manager of displayedManagers"
                                  [removable]="(usersLoading$ | async) === false"
                                  [editable]="(usersLoading$ | async) === false"
                                  [value]="manager.id"
                                  (click)="$event.stopImmediatePropagation()"
                                  (removed)="removeManager(manager.id)">
                        {{ manager.email }}
                        <button matChipRemove
                                [attr.aria-label]="'REMOVE_VALUE' | translate:{value: manager}">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    <input [matChipInputFor]="chipGrid"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           (keyup.F4)="addManagers($event)"
                           (matChipInputTokenEnd)="addManagerFromInput($event)"
                           (click)="addManagers($event)">
                    <button *ngIf="displayedManagers.length < 2 && (data.action === 'create' || data.group?.isOwner)"
                            mat-icon-button
                            matSuffix
                            (click)="addManagers($event)">
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-chip-grid>
                <mat-error *ngIf="groupForm.controls.managers.errors?.maxLength">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "ERROR_SELECTION_MAX_MANAGERS" | translate }}
                </mat-error>
            </mat-form-field>

            <div class="ownership" *ngIf="data?.group">
                <ng-container *ngIf="!data.group.isOwner && data.group.isManager">
                    <mat-icon>info</mat-icon>
                    <span>{{ "MANAGER_CANNOT_REMOVE" | translate }}</span>
                </ng-container>
            </div>

            <mat-slide-toggle formControlName="mobile"
                              *ngIf="(isAdmin$ | async) && (data.action === 'create' || data.group?.isOwner)"
                              color="primary">
                {{ "GROUP_MOBILE_RIGHT" | translate }}
            </mat-slide-toggle>

            <mat-expansion-panel #usersPanel
                                 (opened)="fetchUsers()"
                                 [(expanded)]="maintainUsers"
                                 class="mat-elevation-z0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h4>{{ "MANAGE_USERS_OF_GROUP" | translate }}</h4>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-group-users-list *ngIf="maintainUsers"
                                      (addUsers)="addUsers($event)"
                                      (removeUsers)="removeUsers($event)"
                                      [users]="users | listExclude: 'removed' : true"
                                      [usersLoading]="usersLoading"
                                      [height]="'calc(25vh - 28px)'"
                                      [disabled]="data.action !== 'create' && (!data.group.isOwner && !data.group.isManager)"
                                      (pageChange)="onPageChange(usersPanel)"></app-group-users-list>
            </mat-expansion-panel>
            <mat-expansion-panel #familiesPanel
                                 (opened)="fetchFamilies()"
                                 [(expanded)]="maintainFamilies"
                                 class="mat-elevation-z0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h4>{{ "MANAGE_FAMILIES_OF_GROUP" | translate }}</h4>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-group-families-list *ngIf="maintainFamilies"
                                         [families]="families | listExclude:'removed':true"
                                         [isOwner]="data.action === 'create' ? true : data.group.isOwner"
                                         [familiesLoading]="familiesLoading"
                                         [height]="'calc(25vh - 28px)'"
                                         (removeFamilies)="removeFamilies($event)"
                                         (updateFamilyRole)="updateFamilyRole($event)"
                                         (addFamilies)="addFamilies($event)"
                                         (pageChange)="onPageChange(familiesPanel)"></app-group-families-list>
            </mat-expansion-panel>
        </div>

        <div mat-dialog-actions>
            <button mat-dialog-close
                    mat-stroked-button
                    type="button"
                    color="primary">
                {{"CANCEL" | translate}}
            </button>
            <button mat-flat-button
                    color="primary"
                    type="submit"
                    (click)="onFormSubmit()"
                    [disabled]="groupForm.invalid || groupForm.pristine || editInProgress">
                {{(data.action === "create" ? "CREATE" : "UPDATE") | translate}}
                <mat-progress-spinner *ngIf="editInProgress"
                                      color="primary"
                                      mode="indeterminate"
                                      diameter="16">
                </mat-progress-spinner>
            </button>
        </div>

    </form>
</div>
