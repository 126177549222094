import { Store } from '@ngrx/store';
import { Asset } from './../../../../models/asset';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as userRightActions from 'src/app/store/actions/user-right.action';
import { selectGroupsLoaded$, selectRequestGroups$ } from 'src/app/store/selectors/user-right.selectors';
import { GroupIdName, SendGroupRequest } from 'src/app/models/user-right';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import { UserRightEffects } from 'src/app/store/effects/user-right.effects';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';

@Component({
    selector: 'app-dialog-request-group',
    templateUrl: './dialog-request-group.component.html',
    styleUrls: ['./dialog-request-group.component.scss']
})
export class DialogRequestGroupComponent implements OnInit {
    public allGroups$: Observable<GroupIdName[]>;
    public requestInProgress = false;
    public groupForm: FormGroup<{ group: FormControl<GroupIdName>; note: FormControl<string> }>;
    public groupsLoaded$: Observable<boolean>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Asset,
        private _dialogRef: MatDialogRef<DialogRequestGroupComponent>,
        private store: Store,
        private _userRightEffects: UserRightEffects,
        private _fb: FormBuilder,
        private _translate: TranslateService,
        private _snackBar: SnackbarComponent,
    ) {
        this.requestInProgress = false;
        this.groupForm = this._fb.group({
            group: this._fb.control<GroupIdName>(null, [Validators.required, this._groupSelectionValidator()]),
            note: this._fb.control<string>(null),
        });
    }

    ngOnInit(): void {
        this._initializeGroups();
        this.groupsLoaded$ = this.store.select(selectGroupsLoaded$);
    }
    /**
     * Close the dialog
     */
    public closeDialog(): void {
        this._dialogRef.close();
    }

    /**
     * Fetch all the groups
     */
    private _initializeGroups(): void {
        // Build the list of available groups to select
        this.allGroups$ = combineLatest([
            this.store.select(selectRequestGroups$),
            this.groupForm.controls.group.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : ''),
            ),
        ]).pipe(
            map(([allGroups, searchedGroup]) => allGroups.sort(
                (a, b) => a.name.localeCompare(b.name)
            ).filter(
                group => group.name.toLowerCase().includes(searchedGroup.toLowerCase())
            )),
        );
        this.store.dispatch(new userRightActions.FetchListOfAllGroups());
    }

    /**
     * Transform field with correct name of the object of a formControl
     * @param data The data to format
     */
    public getName(data: { name: string; }) {
        return data && data.name ? data.name : '';
    }

    /**
     * Called on click on confirm button
     * - Return the selected manager and the selected role to the RequestForRights Component
     */
    public onClickConfirm() {
        this.requestInProgress = true;
        const payload: SendGroupRequest = {
            groupId: this.groupForm.value.group.id,
            note: this.groupForm.value?.note || undefined
        } 
        this._userRightEffects.effectSubject.pipe(
            filter(({ action }) => [
                userRightActions.ActionTypes.SUCCESS_RAISE_GROUP_REQUEST,
                userRightActions.ActionTypes.ERROR_RAISE_GROUP_REQUEST,
            ].includes(action as userRightActions.ActionTypes)),
            take(1),
        ).subscribe(({ result, payload }) => {
            this.requestInProgress = false;
            if (result === 'success') {
                const text = this._translate.instant('BANNER_SUCCESS_REQUEST_GROUP', {
                    group: payload.groupName,
                });
                this._snackBar.open(text, 'green-snackbar');
            }
            this._dialogRef.close();
        });
        this.store.dispatch(new userRightActions.RaiseGroupRequest({ requestedGroup: payload }));
    }

    private _groupSelectionValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors|null => {
            if (!control.value || typeof control.value !== 'object') {
                return { wrongSelection: { value: control.value } };
            }
            return null;
        };
    }

}
