import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserRightService } from '../services/user-right.service';
import { map } from 'rxjs/operators';

export const managerGuard: CanActivateFn = () => {
    // #region Injections
    const userRightService = inject(UserRightService);
    const router = inject(Router);
    // #endregion

    return userRightService.getIsManager$().pipe(
        map(isManager => {
            if (!isManager) {
                router.navigate(['/']);
            }
            return isManager;
        }),
    );
};
