import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import { map, pairwise, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { MAX_ASSET_COUNT } from 'src/app/services/init-filter.service';

@Component({
    selector: 'app-asset-quantity',
    templateUrl: './asset-quantity.component.html',
    styleUrls: ['./asset-quantity.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetQuantityComponent implements OnChanges {

    @HostBinding('class.valid') get validClass() {
        return this.assetQuantity <= MAX_ASSET_COUNT;
    }
    @HostBinding('class.invalid') get invalidClass() {
        return this.assetQuantity > MAX_ASSET_COUNT;
    }
    @HostBinding('class.loading') get loadingClass() {
        return this.loading;
    }
    @Input() assetQuantity = 0;
    @Input() loading = false;
    public readonly MAX_ASSET_COUNT = MAX_ASSET_COUNT;

    private _quantity$ = new BehaviorSubject<number>(0);
    public quantity$ = this._quantity$.pipe(
        startWith(0),
        pairwise(),
        switchMap(
            ([prev, curr]) => this._setCount(prev, curr)
        ),
        shareReplay(1),
    );

    ngOnChanges({ assetQuantity }: SimpleChanges): void {
        if (assetQuantity?.currentValue !== assetQuantity?.previousValue) {
            this._quantity$.next(assetQuantity.currentValue);
        }
    }

    private _setCount(previousValue = 0, currentValue = 0) {
        const diff = Math.abs(previousValue - currentValue);
        const steps = 100;
        const stepSize = Math.ceil(diff / steps);
        const isIncrease = currentValue > previousValue;
        return interval(1).pipe(
            take(1000),
            map((v) => {
                const value = previousValue + (isIncrease ? 1 : -1) * (stepSize * v);
                if ((isIncrease && value > currentValue) || (!isIncrease && value < currentValue)) {
                    return currentValue;
                }
                return value;
            }),
        );
    }

}
