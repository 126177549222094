/* eslint-disable @typescript-eslint/prefer-namespace-keyword */
import { Asset, AssetStatusState } from '../../models/asset';
import { Scope } from '../../models/profile';
import { SignalSourceProviderValues } from '../../models/signal-source';

// eslint-disable-next-line @typescript-eslint/no-namespace
export module AssetLoadedStatus {

    export enum Status {
        loaded,
        empty,
        none,
    }

    export function transform(asset: Asset): Status {
        if (asset?.family?.scope === Scope.OVERSIZE) {
            return asset.loadedElements?.length ? Status.loaded : Status.empty;
        }
        if (asset?.tracker?.provider === SignalSourceProviderValues.sensolus) {
            switch (asset?.last_distance_event?.status) {
                case AssetStatusState.Loaded:
                    return Status.loaded;
                case AssetStatusState.Empty:
                    return Status.empty;
                default:
                    return Status.none;
            }
        }
        return Status.none;
    }

}
