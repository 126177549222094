import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { TutorialStep } from './tutorial.model';

export interface TutorialCloseEvent {
    type: 'close' | 'next';
}

export class TutorialRef {

    private _afterClosed$ = new Subject<TutorialCloseEvent>();

    public afterClosed$ = this._afterClosed$.asObservable();

    private readonly DEFAULT_HIGHLIGHT_CLASS = 'tutorial-action';

    constructor(
        public overlay: OverlayRef,
        public step: TutorialStep,
        public lastStep: boolean,
    ) {
        this.step.actionOn?.classList.add(step.actionHighlightClass ?? this.DEFAULT_HIGHLIGHT_CLASS);
    }

    public close() {
        this._close('close');
    }

    public next() {
        this._close('next');
    }

    private _close(type: TutorialCloseEvent['type']) {
        this.step.actionOn?.classList.remove(this.step.actionHighlightClass ?? this.DEFAULT_HIGHLIGHT_CLASS);
        this.overlay.dispose();
        this._afterClosed$.next({ type });
        this._afterClosed$.complete();
    }

}
