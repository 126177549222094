import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PictureService } from '../models/scope-management.model';
import { Airline } from '../models/airline';

@Injectable({
  providedIn: 'root'
})
export class AirlineService implements PictureService {

  constructor(private _http: HttpClient) { }

  /**
   * Get all airlines
   */
  public getAirlines(): Observable<Airline[]> {
    return this._http.get<Airline[]>(`${environment.API_ENDPOINT}/airlines`);
  }

  /**
   * Get Airline picture
   */
  public getPicture(airlineId: string): Observable<{ picture: string }> {
    return this._http.get<{ picture: string }>(`${environment.API_ENDPOINT}/airlines/${airlineId}/picture`);
  }
}
