import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const selectFilterState$ = (state: AppState) => state.filters;

export const selectFilters$ = createSelector(selectFilterState$, (filters) => filters);

export const selectMapAssets$ = createSelector(selectFilterState$, (filters) => ({ map_asset : filters.map_asset, map_asset_loaded : filters.map_asset_loaded }));

export const selectMapAssetsFilters$ = createSelector(selectFilterState$, (filters) => filters.map_asset);

export const selectMapAssetFilterLoaded$ = createSelector(selectFilterState$, (fitlers) => fitlers.map_asset_loaded);

export const selectAdminAssetsFilters$ = createSelector(selectFilterState$, (filters) => filters.admin_asset);

export const selectAssetsLoaded$ = createSelector(selectFilterState$, (filters) =>  filters.admin_asset_loaded);

export const selectAdminFamiliesFilters$ = createSelector(selectFilterState$, (filters) => filters.admin_family);

export const selectAdminDevicesFilters$ = createSelector(selectFilterState$, (filters) => filters.admin_device);

export const selectAdminZoneFilters$ = createSelector(selectFilterState$, (filters) => filters.admin_zone);

export const selectZoneFilterLoaded$ = createSelector(selectFilterState$, (fitlers) => fitlers.admin_zone_loaded);

export const selectPlanningFilters$ = createSelector(selectFilterState$, (filters) => filters.planning);

export const selectPlanningFiltersLoaded$ = createSelector(selectFilterState$, (filters) => filters.planning_loaded);

export const selectAircraftPlanningFilters$ = createSelector(selectFilterState$, (filters) => filters.aircraft_planning);

export const selectParentFamilyRightFilters$ = createSelector(selectFilterState$, (filters) => filters.parent_family_right);

export const selectCertificatesFilters$ = createSelector(selectFilterState$, (filters) => filters.manage_certificates);

export const selectAllUsersParentFamilyRightsFilters$ = createSelector(selectFilterState$, (filters) => filters.manage_user_role);

export const selectGroupsFilters$ = createSelector(selectFilterState$, (filters) => filters.manage_groups);

export const selectAllUsersPerFamilyFilters$ = createSelector(selectFilterState$, (filters) => filters.manage_family_users);

export const selectAnchorFilters$ = createSelector(selectFilterState$, (filters) => filters.admin_anchors);
