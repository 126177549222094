import { Pipe, PipeTransform } from '@angular/core';
import { difference, isEqual } from 'lodash';

@Pipe({
    name: 'listExclude',
    pure: true,
})
export class ListExcludePipe implements PipeTransform {

    transform<T extends object>(items: T[], property: string, value?: any, includes = false, ignoreCase = false): T[] {
        if (!value?.toString()) {
            return items;
        }
        let valuesToExclude = value;
        if (value instanceof Array) {
            valuesToExclude = value.map(
                v => v[property]
            );
        } else if (Object.prototype.hasOwnProperty.call(value, property)) {
            valuesToExclude = value[property];
        }
        return items?.filter(
            item => {
                let propertyValue = item;
                if (property && Object.prototype.hasOwnProperty.call(item, property)) {
                    propertyValue = item[property];
                }
                if (includes) {
                    const searched = new RegExp(value.toString(), ignoreCase ? 'i' : '');
                    return !searched.test(propertyValue.toString());
                }
                if (value instanceof Array) {
                    if (propertyValue instanceof Array) {
                        return difference(propertyValue, valuesToExclude);
                    }
                    return !valuesToExclude.includes(propertyValue);
                }
                return !isEqual(propertyValue, value);
            }
        );
    }

}
