<div class="container"
     [@hide]="(contextConfigOpened$ | async) ? 'collapse' : 'void'"
     cdkDragBoundary="body"
     cdkDrag>
    <div class="slider-container"
         *ngIf="(currentContext$ | async | typeOf) === 'object'">
        <!-- Display the context label only if a context is defined -->
        <span [@slide]="showSlider ? 'in' : 'out'"
              class="slider-text">
            {{ 'ACTIVE_CONTEXT' | translate:{context: (currentContext$ | async)?.name || ('CONTEXT_SESSION' | translate) } }}
            &nbsp;&nbsp;
        </span>
    </div>
    <button mat-fab
            color="primary"
            [matMenuTriggerFor]="menu"
            #menuTrigger="matMenuTrigger"
            (mouseover)="showSlider = true"
            (mouseout)="showSlider = false">
        <mat-icon>tune</mat-icon>
    </button>
</div>

<mat-menu #menu="matMenu"
          xPosition="before">
    <ng-container *ngIf="(unselectedUserContexts$ | async | listFilter:'favorite':true) as contexts">
        <div *ngFor="let userFilter of contexts | sort:false:'updatedAt' | slice:0:5">
            <button mat-menu-item
                    (click)="loadSelectedContext(userFilter)">
                {{ userFilter.name }}
            </button>
        </div>
        <mat-divider *ngIf="contexts.length"></mat-divider>
    </ng-container>
    <button *ngIf="(currentContext$ | async | typeOf) === 'object'"
            mat-menu-item
            (click)="openEditContext()">
        <fa-icon icon="pencil-alt"
                 [fixedWidth]="true"></fa-icon>
        {{"EDIT_CONTEXT" | translate}}
    </button>
    <button mat-menu-item
            (click)="openCreateContext()">
        <fa-icon icon="plus"
                 [fixedWidth]="true"></fa-icon>
        {{"CREATE_CONTEXT" | translate}}</button>
    <button *ngIf="(unselectedUserContexts$ | async)?.length > 0 "
            mat-menu-item
            (click)="openManageContext()">
        <fa-icon icon="cog"
                 [fixedWidth]="true"></fa-icon>
        {{"MANAGE_CONTEXT" | translate}}
    </button>
</mat-menu>
