<section [class]="['main', orientation].join(' ')">
    <div class="file-selector"
         appDnD
         role="group"
         [attr.aria-labelledbt]="parentFormField?.getLabelId()"
         (dragover)="setDropReadyStatus(true)"
         (dragleave)="setDropReadyStatus(false)"
         (drop)="setDropReadyStatus(false)">
        <input #fileDropRef
               type="file"
               [accept]="accept"
               [multiple]="multiple"
               [formControl]="fileForm"
               (blur)="onTouched()"
               (change)="onFileSelected($event)">
        <div class="descriptor">
            <fa-icon icon="upload"
                     aria-hidden="true"></fa-icon>
            <div class="text">
                <span>{{ 'DND_INPUT_TEXT' | translate:{type:fileType, format:fileFormats} }}</span>
                <span>{{ 'OR' | translate }}</span>
                <label for="fileDropRef"
                       class="browse-action">{{ 'BROWSE' | translate }}</label>
            </div>
        </div>
        <div class="drop-display"
             *ngIf="dropReady">
            <span>{{ 'DND_DROP_HERE' | translate }}</span>
        </div>
    </div>
    <div class="files-list">
        <ng-content *ngIf="!(files$ | async)?.length"
                    select="[placeholder]"></ng-content>
        <mat-card appearance="outlined"
                  *ngFor="let file of files$ | async"
                  class="file-item">
            <mat-card-content class="content">
                <header class="header">
                    <span class="title">{{ file.name }}</span>
                    <span class="spacer"></span>
                    <button matButton
                            mat-icon-button
                            (click)="removeFile(file)">
                        <fa-icon icon="trash"></fa-icon>
                    </button>
                </header>
                {{ file.size | fileSizeFormat }}
                <canvas [id]="uniqueId + '-canvas-' + file.name"
                        *ngIf="isImage(file.type)"></canvas>
            </mat-card-content>
        </mat-card>
    </div>

</section>
