import {
    errorFetchAllFilters, fetchAllFilters, successFiltersAdminAssets, successFiltersAdminDevices, successFiltersAdminFamilies,
    successFiltersAdminZones, successFiltersAircraftPlanning, successFiltersManageUserRole, successFiltersMapAssets, successFiltersOversizePlanning,
    successFiltersRequestForRights, successFiltersCertificates, successFiltersManageFamilyUsers, successFiltersGroups, successFiltersAnchors
} from './../actions/filter.actions';
import { Planning } from './../../models/planning';
import { AircraftPlanning } from 'src/app/models/aircraft-planning';
import { Asset } from 'src/app/models/asset';
import { ParentFamily, ParentFamilyRight } from 'src/app/models/family';
import { Tracker } from 'src/app/models/tracker';
import { Action, createReducer, on } from '@ngrx/store';
import { Zone } from 'src/app/models/zone';
import { Certificate } from 'src/app/models/certificate';
import { UserRoleLevel } from 'src/app/models/user-right';
import { Group } from 'src/app/models/user-right';
import { Anchor } from 'src/app/models/anchor';

export interface State {
    map_asset: Array<Asset>;
    map_asset_loaded: boolean;
    admin_asset: Array<Asset>;
    admin_family: Array<ParentFamily>;
    admin_device: Array<Tracker>;
    admin_zone: Array<Zone>;
    admin_zone_loaded: boolean;
    planning: Array<Planning>;
    planning_loaded: boolean;
    aircraft_planning: Array<AircraftPlanning>;
    parent_family_right: Array<ParentFamilyRight>;
    manage_user_role: Array<ParentFamilyRight>;
    admin_asset_loaded: boolean;
    manage_certificates: Array<Certificate>;
    manage_groups: Array<Group>;
    manage_family_users: Array<UserRoleLevel>;
    admin_anchors: Array<Anchor>;
}

const initialState: State = {
    map_asset: [],
    map_asset_loaded: false,
    admin_asset: [],
    admin_family: [],
    admin_device: [],
    admin_zone: [],
    admin_zone_loaded: false,
    planning: [],
    planning_loaded: false,
    aircraft_planning: [],
    parent_family_right: [],
    manage_user_role: [],
    admin_asset_loaded: false,
    manage_certificates: [],
    manage_groups: [],
    manage_family_users: [],
    admin_anchors: [],
};

const filterReducer = createReducer(
    initialState,
    on(fetchAllFilters, (state): State => ({ ...state })),
    on(errorFetchAllFilters, (state): State => ({ ...state })),
    on(successFiltersMapAssets, (state, action): State => ({ ...state, map_asset: [...action.mapAssets], map_asset_loaded: action.loaded })),
    on(successFiltersAdminAssets, (state, action): State => ({ ...state, admin_asset: [...action.assets], admin_asset_loaded: action.loaded })),
    on(successFiltersAdminFamilies, (state, action): State => ({ ...state, admin_family: [...action.parentFamilies] })),
    on(successFiltersAdminDevices, (state, action): State => ({ ...state, admin_device: [...action.devices] })),
    on(successFiltersAdminZones, (state, action): State => ({ ...state, admin_zone: [...action.zones], admin_zone_loaded: true })),
    on(successFiltersOversizePlanning, (state, action): State => ({ ...state, planning: [...action.oversizePlanning], planning_loaded: true })),
    on(successFiltersAircraftPlanning, (state, action): State => ({ ...state, aircraft_planning: [...action.aircraftPlanning] })),
    on(successFiltersRequestForRights, (state, action): State => ({ ...state, parent_family_right: [...action.rights] })),
    on(successFiltersManageUserRole, (state, action): State => ({ ...state, manage_user_role: [...action.roles] })),
    on(successFiltersCertificates, (state, action): State => ({ ...state, manage_certificates: [...action.certificates] })),
    on(successFiltersManageFamilyUsers, (state, action): State => ({ ...state, manage_family_users: [...action.users] })),
    on(successFiltersGroups, (state, action): State => ({ ...state, manage_groups: [...action.groups] })),
    on(successFiltersAnchors, (state, action): State => ({ ...state, admin_anchors: [...action.anchors] }))
);

export function reducer(state: State | undefined, action: Action) {
    return filterReducer(state, action);
}
