import { Action, createReducer, on } from '@ngrx/store';
import { ParentFamily } from 'src/app/models/family';
import {
    fetchAllParentFamilies,
    mappingAllParentFamiliesSites,
    mappingSomeParentFamiliesSites,
    successDeleteParentFamilies,
} from '../actions/parent-family.action';

export interface State {
    data: {
        parentFamilies: { [id: string]: ParentFamily },
    };
    loaded: boolean;

}

const initialState: State = {
    data: {
        parentFamilies: {},
    },
    loaded: false,
};

const parentFamilyReducer = createReducer(
    initialState,
    on(fetchAllParentFamilies, (state) => ({
        ...state,
        loaded: false,
    })),
    on(mappingAllParentFamiliesSites, (state, action) => {
        return {
            ...state,
            loaded: true,
            data: (() => {
                const datas = {
                    parentFamilies: {},
                };
                if (!action || !action.parentFamilies) { return; }
                for (const parentFamily of action.parentFamilies) {
                    parentFamily.site = action.sites.find(site => site.id === parentFamily.siteId);
                    Object.assign(datas.parentFamilies, {
                        [parentFamily.id]: parentFamily,
                    });
                }
                return datas;
            })()
        };
    }),
    on(mappingSomeParentFamiliesSites, (state, action) => {
        return {
            ...state,
            loaded: true,
            data: (() => {
                const datas = { ...state.data };
                if (!action || !action.parentFamilies) { return; }
                for (const parentFamily of action.parentFamilies) {
                    parentFamily.site = action.sites.find(site => site.id === parentFamily.siteId);
                    Object.assign(datas.parentFamilies, {
                        [parentFamily.id]: {
                            ...datas.parentFamilies?.[parentFamily.id],
                            ...parentFamily
                        },
                    });
                }
                return datas;
            })()
        };
    }),
    on(successDeleteParentFamilies, (state, action) => {
        return {
            ...state,
            data: (() => {
                for (const parentFamily of action.payload) {
                    delete state.data.parentFamilies[parentFamily.id];
                }
                return state.data;
            })()
        };
    })
);

export function reducer(state: State | undefined, action: Action) {
    return parentFamilyReducer(state, action);
}
