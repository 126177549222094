<main>
    <img src="assets/img/undraw_Access_account_re_8spm.png"
         alt="Illustation of login">
    <div>
        <span class="title">W&commat;Y</span>
        <span class="subtitle">
            {{ "LOGIN_SUBTITLE" | translate }}
        </span>
        <span class="description">
            {{ "LOGIN_DESCRIPTION" | translate }}
        </span>
    </div>
</main>
