import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {

    public displayLostImage$: Observable<boolean> = this._breakpointObserver.observe('(min-height: 720px)').pipe(
        map(({ matches }) => matches),
    );

    constructor(
        private _router: Router,
        private _breakpointObserver: BreakpointObserver,
    ) { }

    public navigateTo(path: string): void {
        this._router.navigateByUrl(path);
    }

}
