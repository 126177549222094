<div>
    <div mat-dialog-title>
        <span>{{ 'ADD_FAMILIES' | translate }}</span>
        <button mat-icon-button
                mat-dialog-close>
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>

    <form [formGroup]="familyForm"
          (ngSubmit)="onFormSubmit()">
        <mat-dialog-content class="form-content">
            <mat-form-field appearance="outline">
                <mat-label>{{ 'PARENT_FAMILY' | translate }}</mat-label>
                <mat-select formControlName="parentFamilies"
                            multiple
                            #multiselectParentFamilies
                            matTooltipClass="multiline-tooltip"
                            [matTooltip]="multiselectParentFamilies.value?.map ? (multiselectParentFamilies.value | extractPropertyPipe: 'name' | join:'') : ''">
                    <mat-select-trigger>
                        {{ familyForm.get('parentFamilies').value?.[0]?.name || '' }}
                        <span *ngIf="(familyForm.get('parentFamilies').value?.length || 0) > 1">
                            (+{{ familyForm.get('parentFamilies').value?.length - 1 }}&nbsp;{{
                            (familyForm.get('parentFamilies').value?.length > 2 ? 'others' : 'other') | translate |
                            lowercase }})
                        </span>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                               [formControl]="searchControls.controls.parentFamily"
                                               [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let family of parentFamilies$ | async | sort:false:'name' | listFilter:'name':searchControls.get('parentFamily').value:true:true"
                                [value]="family">
                        {{ family.name }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="rightsLoading === true">
                    <mat-progress-spinner mode="indeterminate"
                                          diameter="12"
                                          strokeWidth="2"></mat-progress-spinner>
                    {{ "LOADING_DATA" | translate }}
                </mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'ASSET_FAMILY' | translate }}</mat-label>
                <mat-select formControlName="families"
                            multiple
                            #multiselectFamilies
                            matTooltipClass="multiline-tooltip"
                            [matTooltip]="multiselectFamilies.value?.map ? (multiselectFamilies.value | extractPropertyPipe: 'name' | join:'') : ''">
                    <mat-select-trigger>
                        {{ familyForm.get('families').value?.[0]?.name || '' }}
                        <span *ngIf="(familyForm.get('families').value?.length || 0) > 1">
                            (+{{ familyForm.get('families').value?.length - 1 }}&nbsp;{{
                            (familyForm.get('families').value?.length > 2 ? 'others' : 'other') | translate | lowercase
                            }})
                        </span>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                               [formControl]="searchControls.controls.family"
                                               [showToggleAllCheckbox]="true"
                                               [toggleAllCheckboxChecked]="familyForm.get('families').value?.length === (families$ | async).length"
                                               [toggleAllCheckboxIndeterminate]="familyForm.get('families').value?.length > 0 && familyForm.get('families').value?.length < (families$ | async).length"
                                               (toggleAll)="toggleAllFamilies($event)"
                                               [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let family of families$ | async | sort:false:'name' | listFilter:'name':searchControls.get('family').value:true:true"
                                [value]="family">
                        {{ family.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="familyForm.controls.families.errors?.required">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "REQUIRED_SELECTION" | translate }}
                </mat-error>
                <mat-error *ngIf="familyForm.controls.families.errors?.invalid">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "INVALID_SELECTION" | translate }}
                </mat-error>
                <mat-hint *ngIf="rightsLoading === true">
                    <mat-progress-spinner mode="indeterminate"
                                          diameter="12"
                                          strokeWidth="2"></mat-progress-spinner>
                    {{ "LOADING_DATA" | translate }}
                </mat-hint>
            </mat-form-field>
        </mat-dialog-content>

        <div mat-dialog-actions>
            <button mat-dialog-close
                    mat-stroked-button
                    type="button"
                    color="primary">
                {{"CANCEL" | translate}}
            </button>
            <button mat-flat-button
                    color="primary"
                    type="submit"
                    [disabled]="familyForm.invalid">
                {{"DIALOG_VALIDATE_OPTION" | translate}}
            </button>
        </div>

    </form>

</div>
