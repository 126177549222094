import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { TutorialStep } from './tutorial.model';
import { MatCard } from '@angular/material/card';
import { TutorialRef } from './tutorial-ref';

@Component({
    selector: 'app-tutorial-step',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialComponent {

    @ViewChild('popover') popover: MatCard;
    @Output() next = new EventEmitter<void>();
    public step: TutorialStep;
    public lastStep: boolean;

    constructor(
        @Inject(TutorialRef) public ref: TutorialRef,
    ) {
        this.step = ref.step;
        this.lastStep = ref.lastStep;
    }

}
