import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { CompanyService } from 'src/app/services/company.service';
import * as CompanyAction from '../actions/company.actions';
import { successUpdateSites } from '../actions/site.action';
import { selectSitesByCompanyIds$ } from '../selectors/site.selectors';
import { CacheService } from 'src/app/services/cache.service';
import { ObjectStore } from 'src/app/models/cache';
import { LoggerService } from 'src/app/services/logger.service';

export type EffectResult = 'success' | 'error';
@Injectable()
export class CompanyEffects {

    public fetchAllCompanies$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CompanyAction.fetchAllCompanies),
            switchMap(() => this._cacheService.retrieveCacheStatus$()),
            map(status => status.company?.lastRefresh),
            switchMap(lastRefresh => this._companyService.getCompanies(lastRefresh)),
            switchMap(companies => combineLatest([
                this._cacheService.deleteData$(ObjectStore.Company, ...(companies.deletedData ?? []).map(({ id }) => id)),
                this._cacheService.updateData$(ObjectStore.Company, ...(companies.updatedData ?? [])),
                this._cacheService.createData$(ObjectStore.Company, ...(companies.createdData?? [])),
            ])),
            switchMap(() => this._cacheService.getData$(ObjectStore.Company)),
            map(companies => CompanyAction.successFetchAllCompanies(({ payload: companies }))),
            catchError((error) => {
                this.logger.error('CompanyEffects', 'Cannot fetch companies', error);
                return of(CompanyAction.errorFetchAllCompanies());
            }),
        );
    });

    public successUpdateCompanies$ = createEffect(() => { return this._actions$.pipe(
        ofType(CompanyAction.successUpdateCompanies),
        // update children
        switchMap((action) => this.store.select(selectSitesByCompanyIds$(action.payload.map(company => company.id))).pipe(
            take(1),
            map((sites) => successUpdateSites({
                payload: sites,
            }))
        )),
    ) });

    public effectSubject: Subject<EffectResult>;
    constructor(
        private _companyService: CompanyService,
        private _actions$: Actions,
        private store: Store,
        private _cacheService: CacheService,
        private logger: LoggerService,
    ) {
        this.effectSubject = new Subject<EffectResult>();
    }
}
