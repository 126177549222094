import { RoleLevel, RoleLevelEnum } from 'src/app/models/user-right';

/**
 * Get the magnitude of the role (the higher the more rights)
 * @param level The family role
 * @returns The magnitude of the role
 */
export function getLevelSortingMagnitude(level: RoleLevel): number {
    switch (level) {
        case RoleLevelEnum.basic:
            return 0;
        case RoleLevelEnum.reader:
            return 1;
        case RoleLevelEnum.operator:
            return 2;
        case RoleLevelEnum.manager:
            return 3;
        case RoleLevelEnum.administrator:
            return 4;
        default:
            return Infinity;
    }
}
