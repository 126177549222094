<div mat-dialog-title>
    <span>{{ 'MY_INFORMATION' | translate }}</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>
<div mat-dialog-content>

    <article class="user-details">
        <span class="title">{{ decodedToken.given_name | titlecase }} {{ decodedToken.family_name | uppercase }}</span>
        <span class="subtitle">{{ decodedToken.preferred_username | uppercase }}</span>
        <span class="subtitle">{{ decodedToken.email }}</span>
        <p>{{ 'INCORRECT_INFO' | translate }}</p>
    </article>
    <mat-divider></mat-divider>
    <form [formGroup]="settingsForm"
          class="user-settings">
        <span class="subtitle">{{ "USER_SETTINGS" | translate }}</span>
        <mat-form-field appearance="outline">
            <mat-label>
                <mat-icon aria-hidden="true">translate</mat-icon>&nbsp;
                {{ "SELECT_LANGUAGE" | translate }}
            </mat-label>
            <mat-select formControlName="language">
                <mat-option *ngFor="let lang of LANGUAGES | keyvalue"
                            [value]="lang.key">
                    {{ lang.value }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>
                <mat-icon aria-hidden="true">schedule</mat-icon>&nbsp;
                {{ "SELECT_DATE_FORMAT" | translate }}
            </mat-label>
            <mat-select formControlName="dateFormat">
                <mat-option *ngFor="let format of DATE_FORMATS | keyvalue"
                            [value]="format.key">
                    {{ format.value }}
                </mat-option>
            </mat-select>
            <mat-hint>{{ NOW$ | async | moment:'L LT' }}</mat-hint>
        </mat-form-field>
    </form>
    <mat-divider></mat-divider>
    <form [formGroup]="ergonomyForm"
          class="user-settings">
        <span class="subtitle">{{ "ERGONOMY_SETTINGS" | translate }}</span>
        <mat-slide-toggle formControlName="wcagSFI"
                          [matTooltip]="'WCAG_SFI_DETAILS' | translate">
            {{ "WCAG_STRONG_FOCUS_INDICATORS" | translate }}
        </mat-slide-toggle>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button
            mat-dialog-close>
        {{ "CLOSE" | translate }}
    </button>
</div>
