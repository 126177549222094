<mat-selection-list (selectionChange)="onAssetSelect($event)"
                    [multiple]="false">
  <mat-list-option *ngFor="let asset of assets"
                   [value]="asset">
    {{ asset }}
  </mat-list-option>
  <mat-divider></mat-divider>
  <mat-list-option>
    {{ 'CANCEL' | translate }}
  </mat-list-option>
</mat-selection-list>
