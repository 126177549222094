import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AircraftPlanningService } from 'src/app/services/aircraft-planning.service';
import {
    createAircraftPlannings,
    errorCreateAircraftPlanning,
    fetchAllAircraftPlanning,
    mappingSomeAircraftPlanningFamilies,
    successCreateAircraftPlanning,
    successFetchAllAircraftPlanning,
    mappingAllAircraftPlanningFamilyAssetZone,
    updateAircraftPlanning,
    successUpdateAircraftPlanning,
    errorUpdateAircraftPlanning,
    errorDeletePlannedMovement,
    successDeletePlannedMovement,
    deletePlannedMovement,
    successFixClashesAircraftPlanning,
    fixClashesAircraftPlanning,
    successUploadAircraftPlannings,
    createUploadAircraftPlannings,
    mappingSomeAircraftPlanningFamiliesForced,
    updateAircraftClash,
    successUpdateAircraftClash
} from '../actions/aircraft-planning.action';
import { selectAssetState$ } from '../selectors/asset.selectors';
import { selectFamilyState$ } from '../selectors/family.selectors';
import { combineLatest, of, Subject } from 'rxjs';
import { selectZoneState$ } from '../selectors/zone.selector';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';

export type EffectResult = 'success' | 'error';
export type EffectModeResult = true | false;

@Injectable()
export class AircraftPlanningEffects {

    public fetchAllAircraftPlanning$ = createEffect(() => { return this._actions$.pipe(
        ofType(fetchAllAircraftPlanning),
        switchMap((action) => this._aircraftPlanningService.getAircraftPlanning(action.fromDate, action.toDate)),
        map((allAircraftPlanning) => successFetchAllAircraftPlanning({ aircraftPlanning: allAircraftPlanning }))
    ) });


    public createAircraftPlannings$ = createEffect(() => { return this._actions$.pipe(
        ofType(createAircraftPlannings),
        switchMap((action) => this._aircraftPlanningService.uploadAircraftPlanning(action.aircraftPlanning, true).pipe(
            map((res) => {
                this.effectClashSubject.next(false);
                this._snackBar.open(this._translate.instant('UPLOAD_AIRCRAFT_PLANNING_SUCCESS'));
                this.effectSubject.next('success');
                return successCreateAircraftPlanning({ payload: res.plannedOperations });
            }),
            catchError((err) => {
                this._errorService.handleError(err, 'BANNER_FAIL_INTERNAL_CREATE');
                this.effectSubject.next('error');
                return of(errorCreateAircraftPlanning());
            })
        )),
    ) });

    public successFetchAllAircraftPlanning$ = createEffect(() => { return this._actions$.pipe(
        ofType(successFetchAllAircraftPlanning),
        switchMap((action) => combineLatest([
            of(action),
            this.store.select(selectFamilyState$).pipe(filter(state => state.loaded), take(1)),
            this.store.select(selectAssetState$).pipe(filter(state => state.loaded), take(1)),
            this.store.select(selectZoneState$).pipe(filter(state => state.loaded), take(1))
        ])),
        map(([action, families, assets, zones]) => mappingAllAircraftPlanningFamilyAssetZone({
            aircraftPlanning: action.aircraftPlanning,
            families: families.data.families,
            assets: assets.data.assets,
            zones: zones.data
        }))
    ) });

    public createUploadAircraftPlannings$ = createEffect(() => { return this._actions$.pipe(
        ofType(createUploadAircraftPlannings),
        switchMap((action) => combineLatest([
            of(action),
            this.store.select(selectFamilyState$).pipe(filter(state => state.loaded), take(1)),
            this.store.select(selectAssetState$).pipe(filter(state => state.loaded), take(1)),
            this.store.select(selectZoneState$).pipe(filter(state => state.loaded), take(1))
        ])),
        map(([action]) => successUploadAircraftPlannings({
            aircraftPlanningUpload: action.aircraftPlanningUpload,
            uploadPlanningAPIResponse: action.uploadPlanningAPIResponse
        }))
    ) });

    public deletePlannedMovement$ = createEffect(() => { return this._actions$.pipe(
        ofType(deletePlannedMovement),
        switchMap((action) => this._aircraftPlanningService.deleteAircraftPlanning(action.aircraftPlanning).pipe(
            map((res) => {
                this.effectSubject.next('success');
                const text: string = this._translate.instant('BANNER_SUCCESS_DELETE', {
                    item: this._translate.instant('APP_TAB_AIRCRAFT_PLANNING'),
                });
                this._snackBar.open(text, 'green-snackbar', 5000);
                // Return from delete operation send other lines impacted by the deletion
                if (res.length) {
                    // So we updated the impacted lines
                    this.store.dispatch(successUpdateAircraftPlanning({ payload: res }));
                }
                return successDeletePlannedMovement({ payload: action.aircraftPlanning.id });
            }),
            catchError((error) => {
                this._errorService.handleError(error, 'BANNER_FAIL_INTERNAL_DELETE');
                this.effectSubject.next('error');
                return of(errorDeletePlannedMovement());
            }),
        ))) });

    public updateAircraftClash$ = createEffect(() => { return this._actions$.pipe(
        ofType(updateAircraftClash),
        switchMap((action) => combineLatest([
            of(action),
            this.store.select(selectAssetState$).pipe(filter(state => state.loaded), take(1)),
            this.store.select(selectZoneState$).pipe(filter(state => state.loaded), take(1))
        ])),
        map(([action, assets, zones]) => successUpdateAircraftClash({
            oldPlanning: action.oldPlanning,
            editedPlanning: action.editedPlanning,
            assets: assets.data.assets,
            zones: zones.data
        }))
    ) });

    public successCreateOrUpdateAircraftPlanning$ = createEffect(() => { return this._actions$.pipe(
        ofType(successCreateAircraftPlanning, successUpdateAircraftPlanning),
        switchMap((action) => combineLatest([
            of(action),
            this.store.select(selectAssetState$).pipe(filter(state => state.loaded), take(1)),
            this.store.select(selectZoneState$).pipe(filter(state => state.loaded), take(1))
        ])),
        map(([action, assets, zones]) => mappingSomeAircraftPlanningFamilies({
            aircraftPlanning: action.payload,
            assets: assets.data.assets,
            zones: zones.data
        }))
    ) });

    // update local lines when user is managing his clashs
    public successCreateOrUpdateAircraftPlanningWithClashs$ = createEffect(() => { return this._actions$.pipe(
        ofType(successFixClashesAircraftPlanning),
        switchMap((action) => combineLatest([
            of(action),
            this.store.select(selectAssetState$).pipe(filter(state => state.loaded), take(1)),
            this.store.select(selectZoneState$).pipe(filter(state => state.loaded), take(1))
        ])),
        map(([action, assets, zones]) => mappingSomeAircraftPlanningFamiliesForced({
            aircraftPlanning: action.payload,
            assets: assets.data.assets,
            zones: zones.data
        }))
    ) });

    public updateAircraftPlanning$ = createEffect(() => { return this._actions$.pipe(
        ofType(updateAircraftPlanning),
        switchMap((action) => this._aircraftPlanningService.editAircraftPlanning(action.payload).pipe(
            map((res) => {
                this._snackBar.open(this._translate.instant('UPDATE_AIRCRAFT_PLANNING_SUCCESS'));
                this.effectSubject.next('success');
                return successUpdateAircraftPlanning({ payload: res });
            }),
            catchError((err) => {
                this._errorService.handleError(err, 'BANNER_FAIL_INTERNAL_UPDATE');
                this.effectSubject.next('error');
                return of(errorUpdateAircraftPlanning());
            })
        ))
    ) });

    // Change datasource with local clash data
    public fixClashesAircraftPlanning$ = createEffect(() => { return this._actions$.pipe(
        ofType(fixClashesAircraftPlanning),
        switchMap((action) => combineLatest([
            of(action)
        ])),
        map(([action]) => {
            this.effectSubject.next('success');
            this.effectClashSubject.next(true);
            return successFixClashesAircraftPlanning({
                payload: action.payload,
            });
        })
    ) });

    public effectSubject: Subject<EffectResult>;
    public effectClashSubject: Subject<EffectModeResult>;

    constructor(
        private _aircraftPlanningService: AircraftPlanningService,
        private _actions$: Actions,
        private _errorService: ErrorTranslationService,
        private _snackBar: SnackbarComponent,
        private _translate: TranslateService,
        private store: Store
    ) {
        this.effectSubject = new Subject<EffectResult>();
        this.effectClashSubject = new Subject<EffectModeResult>();
        this.effectClashSubject.next(false);
    }
}
