<main>
    <img src="assets/img/undraw_verified_re_4io7.png"
         alt="Illustration of verification">
    <div>
        <span>{{ "TOKEN_VERIFICATION" | translate }}</span>
        <div class="caption"
             *ngIf="tooLong$ | async">
            <span>{{ "TOKEN_VERIF_NOTHING_HAPPEN" | translate }}</span>
            <a [href]="redirectLink$ | async">{{ "CLICK_HERE" | translate }}</a>
        </div>
    </div>
</main>
