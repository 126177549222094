import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typedAs'
})
export class TypedAsPipe implements PipeTransform {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform<T = unknown>(value: unknown, _typedAs: T): T {
    return value as T;
  }

}
