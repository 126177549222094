<div class="mat-card-padding"
     style="max-width: calc(100vw - 36px);">
  <mat-card appearance="outlined" class="chart">
    <div [ngClass]="{'graph-scrollable': graphSize !== 'auto'}"
         class="mt-3">
      <div class="embed-responsive embed-responsive-16by9"
           [ngStyle]="{'width': graphSize, 'height.px': height }">
        <canvas #chart
                baseChart
                class="embed-responsive-item"
                [height]="height"
                [data]="chartConfiguration.data"
                [options]="chartConfiguration.options ?? {}"
                [plugins]="chartConfiguration.plugins ?? []"
                [type]="chartConfiguration.type">
        </canvas>
      </div>
    </div>
  </mat-card>
</div>
