// This file is used to store default configuration accross all configurations
// MUST be extend with environment specific values
// Check environment.prod.ts

export const environment = {
    production: false,
    envName: 'default-env',
    API_ENDPOINT: '',
    WS_ENDPOINT: '',
    clientId: 'CLI_2H66_WAY-V',
    appBaseUri: 'https://v3.airbus.com',
    scope: 'SCO_2H66_API_WAY-V',
    guideMeActive: false,
    guideMeUrl: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
