import { Pipe, PipeTransform } from '@angular/core';

export enum ListSelectionState {
    all,
    partial,
    none,
}

@Pipe({
    name: 'listSelectionCheckboxState'
})
export class ListSelectionCheckboxStatePipe implements PipeTransform {

    transform(list: unknown[], selection: unknown[], state: ListSelectionState): boolean {
        switch (state) {
            case ListSelectionState.all:
                return list.length > 0 && selection?.length === list.length;
            case ListSelectionState.partial:
                return selection?.length > 0 && selection?.length < list.length;
            case ListSelectionState.none:
                return !selection?.length || list.length === 0;
        }
    }

}
