import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-empty-state-fill-field',
  templateUrl: './empty-state-fill-field.component.html',
  styleUrls: ['./empty-state-fill-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateFillFieldComponent implements OnInit, OnDestroy {

    @Input() title: string;
    @Input() subtitle: string;
    @Input() imageSrc: string = "assets/img/undraw_text_field_htlv.png";

    private _subcription = new Subscription();

    constructor(
        private _translate: TranslateService,
        private language: LanguageService,
        private cd: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        if (!this.title) {
            this._subcription.add(
                this.language.selectedLanguage$.pipe(
                    distinctUntilChanged(),
                ).subscribe(
                    () => {
                        this.title = this._translate.instant("MANAGE_RIGHT_FILL_PANEL");
                        this.cd.markForCheck();
                    }
                )
            );
        }
    }

    ngOnDestroy(): void {
        this._subcription.unsubscribe();
    }

}
