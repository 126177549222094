import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const selectPlanningState$ = (state: AppState) => state.planning;

export const selectPlanning$ = createSelector(selectPlanningState$, (planning) => planning.data);

export const selectPlanningLoading$ = createSelector(selectPlanningState$, (planning) => planning.loading);

export const selectPlanningLoaded$ = createSelector(selectPlanningState$, (planning) => planning.loaded);

export const selectPlanningAPILoaded$ = createSelector(selectPlanningState$, (planning) => planning.planningAPILoaded);
