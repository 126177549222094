import { ContentLoaderService } from 'src/app/services/shared/content-loader.service';
import { Component, Inject, OnInit } from '@angular/core';
import { EMPTY } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export enum VideoGuide {
    kpi,
    map,
    alert,
    admin,
}

enum Languages {
    en = 'English',
    fr = 'Français',
    de = 'Deutsh',
    es = 'Español',
}

@Component({
    selector: 'app-video-guide',
    templateUrl: './video-guide.component.html',
    styleUrls: ['./video-guide.component.scss']
})
export class VideoGuideComponent implements OnInit {

    public sources: {
        src: SafeResourceUrl;
        type: string;
    }[] = [];
    public subtitles: {
        label: string;
        lang: string;
        url: SafeResourceUrl;
        default?: boolean;
    }[] = [];
    public readonly VideoGuide = VideoGuide;

    private readonly _videoFileAnalytics = 'tutorial-WAY-analytics.mp4';
    private readonly _videoFileMapTracking = 'tutoriel-WAY-map-tracking.mp4';
    private readonly _videoFileAlert = 'tutorial-WAY-alerts-module.mp4';
    private readonly _videoFileAdmin = 'tutorial-WAY-admin-module.mp4';
    private _videoPath: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: VideoGuide,
        private _contentLoaderService: ContentLoaderService,
        private _dialogRef: MatDialogRef<VideoGuideComponent>,
        private _sanitizer: DomSanitizer,
        private _http: HttpClient,
    ) { }

    ngOnInit() {

        switch (this.data) {
            case VideoGuide.kpi:
                this._videoPath = this._videoFileAnalytics;
                break;
            case VideoGuide.map:
                this._videoPath = this._videoFileMapTracking;
                break;
            case VideoGuide.alert:
                this._videoPath = this._videoFileAlert;
                break;
            case VideoGuide.admin:
                this._videoPath = this._videoFileAdmin;
                break;
            default:
                this._videoPath = '';
        }

        this._contentLoaderService.getFileUrl(this._videoPath, 'video').subscribe((data) => {
            this.sources.push({
                src: this._sanitizer.bypassSecurityTrustResourceUrl(data.message),
                type: 'video/mp4',
            });
        });
        this._fetchAndAddSubtitle('fr');
        this._fetchAndAddSubtitle('en');
        this._fetchAndAddSubtitle('es');
        this._fetchAndAddSubtitle('de');
    }

    private _fetchAndAddSubtitle(lang: string) {
        if (!Languages[lang]) {
            throw new RangeError(`Language ${lang} is not managed`);
        }
        this._contentLoaderService.getFileUrl(`${this._videoPath.replace(/\.\w+$/, '')}--${lang}.vtt`, 'subtitle').pipe(
            catchError(() => EMPTY),
            // switchMap(data => this._http.get(data.message, {
            //     context: new HttpContext().set(SKIP_AUTH, true),
            // })),
        ).subscribe((data) => {
            if (data?.message) {
                this.subtitles.push({
                    label: Languages[lang],
                    lang,
                    url: this._sanitizer.bypassSecurityTrustResourceUrl(data.message),
                });
            }
        });
    }
}
