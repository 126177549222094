import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const selectZoneState = (state: AppState) => state.zones;

export const selectZoneState$ = createSelector(selectZoneState, (zones) => zones);

export const selectZonesLoaded$ = createSelector(selectZoneState, (zones) => zones.loaded);

export const selectZones$ = createSelector(selectZoneState, (zones) => zones.data);

export const selectZoneById$ = (zoneId: string) => createSelector(selectZoneState, (zones) => zones.data.find(zone => zone.id === zoneId));
