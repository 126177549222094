import { Certificate } from 'src/app/models/certificate';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { createCertificateRequest, updateCertificateRequest } from 'src/app/store/actions/certificate.action';

@Component({
    selector: 'app-dialog-create-certificate-request',
    templateUrl: './dialog-create-certificate-request.component.html',
    styleUrls: ['./dialog-create-certificate-request.component.scss']
})
export class DialogCreateCertificateRequestComponent implements OnInit, OnDestroy {
    public certificateRequestForm: FormGroup<{
        machineId: FormControl<string>;
        phoneModel: FormControl<string>;
        phoneNum: FormControl<string>;
        contactName: FormControl<string>;
        contactPhoneNum: FormControl<string>;
        contactMail: FormControl<string>;
        company: FormControl<string>;
        focalPointName: FormControl<string>;
        focalPointEmail: FormControl<string>;
        focalPointPhone: FormControl<string>;
    }>;
    private _subscription = new Subscription();
    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: Certificate,
        public dialogRef: MatDialogRef<DialogCreateCertificateRequestComponent>,
        private store: Store,
        private _fb: FormBuilder,
    ) {
        const nonWhitespaceRegExp = new RegExp('^\\S*$');
        this.certificateRequestForm = this._fb.group({
            machineId: this._fb.control(null, [Validators.required, Validators.pattern(nonWhitespaceRegExp)]),
            phoneModel: this._fb.control(null, [Validators.required]),
            phoneNum: this._fb.control(null),
            contactName: this._fb.control(null, [Validators.required]),
            contactPhoneNum: this._fb.control(null),
            contactMail: this._fb.control(null, [Validators.required, Validators.email]),
            company: this._fb.control(null),
            focalPointEmail: this._fb.control(null, [Validators.required, Validators.email]),
            focalPointName: this._fb.control(null, [Validators.required]),
            focalPointPhone: this._fb.control(null, [Validators.required]),
        });
    }

    ngOnInit(): void {
        if (this.dialogData) {
            this._updateFormValues();
        }
    }

    /**
     * Initialize the update certificate form
     */
    private _updateFormValues() {
        this.certificateRequestForm.patchValue({
            machineId: this.dialogData?.machineId,
            phoneModel: this.dialogData?.phoneModel,
            phoneNum: this.dialogData?.phoneNum,
            contactName: this.dialogData?.contactName,
            contactPhoneNum: this.dialogData?.contactPhoneNum,
            contactMail: this.dialogData?.contactMail,
            company: this.dialogData?.company,
            focalPointEmail: this.dialogData?.focalPoint?.email,
            focalPointName: this.dialogData?.focalPoint?.name,
            focalPointPhone: this.dialogData?.focalPoint?.phoneNumber,
        });
        this.certificateRequestForm.controls.machineId.disable();
        this._markFilledAsTouched();
    }

    /**
     * Mark the fields with a value as touched so the user can identify erros
     */
    private _markFilledAsTouched() {
        const controls = this.certificateRequestForm.controls;
        for (const key in controls) {
            if (controls[key].value) {
                controls[key].markAsTouched();
            }
        }
    }

    /**
     * Called on click on confirm button
     * - Return the selected manager and the selected role to the RequestForRights Component
     */
    public submitCertificateRequest() {
        if (!this.dialogData) { // If we are creating a certificate request
            this.store.dispatch(createCertificateRequest({ payload: [this.certificateRequestForm.getRawValue()]}));
            this.dialogRef.close();
        } else { // If we are updating an existing certificate request
            this.store.dispatch(updateCertificateRequest({ payload: { ...this.certificateRequestForm.getRawValue(), id: this.dialogData.id }}));
            this.dialogRef.close();
        }
    }

    /**
     * Called when the cancel button or the closing cross button are pressed
     */
    public onClickCancel() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }
}
