import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
    pure: true,
})
export class ReplacePipe implements PipeTransform {

    transform(value: string, pattern: string | RegExp, replacement = ' '): string {
        return value.replace(pattern, replacement);
    }

}
