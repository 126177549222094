import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ScatterDataPoint,  } from 'chart.js';
import ChartBaseComponent, { ChartClickEvent } from 'src/app/components/shared/kpi/chart.base';
import { Ng2ChartConfiguration } from 'src/app/components/shared/kpi/chart.model';
import { KpiFamily } from 'src/app/services/kpi.service';

enum DatasetColors {
  interpolation = 'red',
  cycle = 'blue',
}

@Component({
  selector: 'app-trend-family-cycle',
  templateUrl: '../../../shared/kpi/chart-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendFamilyCycleComponent extends ChartBaseComponent<KpiFamily, 'line', string> implements OnInit {

  public chartConfiguration: Ng2ChartConfiguration<'line', (number | ScatterDataPoint)[], string> = {
    type: 'line',
    data: { datasets: [] },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      'onClick': (event, item) => this._onClickItem({event, item}),
      plugins: {
        title: {
          display: true,
          position: 'top',
        },
        legend: {
          display: false
        },
      },
      scales: {
        x: {
          type: 'time',
        },
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
    },
  };

  private _mapData: Record<string, {
    assetIndex: number;
    cycleIndex: number;
  }> = {};
  private _interpolationText: string;

  ngOnInit(): void {
    this._interpolationText = this._translate.instant('INTERPOLATION');
  }

  setKpiData(): void {
    const interpolationData: ScatterDataPoint[] = this.dataKPI.familyTendanceExpX.map(
      (time, index) => ({
        x: time,
        y: this.dataKPI.familyTendanceExpY[index],
      })
    );
    this.chartConfiguration.data = {
      labels: [this.dataKPI.familyName],
      datasets: [
        {
          label: this._interpolationText,
          data: interpolationData,
          borderColor: DatasetColors.interpolation,
          fill: false,
          borderWidth: 7,
          pointBorderColor: DatasetColors.interpolation,
          pointBackgroundColor: DatasetColors.interpolation,
          pointBorderWidth: 0,
          backgroundColor: DatasetColors.interpolation,
          showLine: true,
          cubicInterpolationMode: 'default',
          tension: 1,
        },
      ],
    };

    let minDate: number, maxDate: number;

    this.dataKPI.familyAssets.forEach(
      (asset, assetIndex) => asset.assetCycles.forEach(
        (cycle, cycleIndex) => {
          const data = {
            x: cycle.cycleStartDate * 1000,
            y: cycle.cycleCycleTime,
          };
          if (!minDate || data.x < minDate) {
            minDate = data.x;
          }
          if (!maxDate || data.x > maxDate) {
            maxDate = data.x;
          }
          this._mapData[JSON.stringify(data)] = { assetIndex, cycleIndex };
          return this.chartConfiguration.data.datasets.push({
            label: `${this._translate.instant('ASSET')} ${asset.assetName} ${this._translate.instant('LOOP')}: ${cycleIndex + 1}`,
            type: 'line',
            data: [data],
            borderColor: DatasetColors.cycle,
            fill: false,
            borderWidth: 1,
            pointBorderColor: DatasetColors.cycle,
            pointBackgroundColor: DatasetColors.cycle,
            pointBorderWidth: 1,
            showLine: false,
          });
        }
      )
    );
    this.chartConfiguration.options.scales.x.min = minDate;
    this.chartConfiguration.options.plugins.title.text = `${this._translate.instant('KPI_TENDANCE')} - ${this.dataKPI.familyName}`;
  }

  /**
   * Triggered when the chart is clicked
   */
  private _onClickItem({ event, item }: ChartClickEvent): void {
    if (!item?.[0]) {
      return;
    }
    const datasetIndex = item[0].datasetIndex;
    const dataset = this.chartConfiguration.data.datasets[datasetIndex];
    if (dataset.label === this._interpolationText) {
      // Do nothing for interpolations
      return;
    }
    const { assetIndex, cycleIndex } = this._mapData[JSON.stringify(dataset.data[0])];
    this.chartClicked.emit({ event, item, others: { assetIndex, cycleIndex } });
  }

}
