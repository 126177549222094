import {isArray, isObject, reduce, snakeCase, camelCase} from 'lodash';
import { SHA256 } from 'crypto-js';

export function formatToCamelCase(object: any) {
    const camelCaseKeys = (obj) => {
        if (!isObject(obj)) {
            return obj;
        } else if (isArray(obj)) {
            return obj.map((v) => camelCaseKeys(v));
        }
        return reduce(obj, (r, v, k) => {
            return {
                ...r,
                [camelCase(k)]: camelCaseKeys(v)
            };
        }, {});
    };

    return camelCaseKeys(object);
}

export function formatToSnakeCase(object: any) {
    const snakeCaseKeys = (obj) => {
        if (!isObject(obj)) {
            return obj;
        } else if (isArray(obj)) {
            return obj.map((v) => snakeCaseKeys(v));
        }
        return reduce(obj, (r, v, k) => {
            return {
                ...r,
                [snakeCase(k)]: snakeCaseKeys(v)
            };
        }, {});
    };

    return snakeCaseKeys(object);
}

export function base64URLEncode(str) {
    return str.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

export function sha256(buffer) {
    return SHA256(buffer);
}

export function formateHttpParam(param: string|number|boolean|Date|(string|number|boolean|Date)[]): string|number|boolean {
    if (param instanceof Date) {
        return param.toISOString();
    }
    if (param instanceof Array) {
        return `[${param.map(
            value => `"${formateHttpParam(value)}"`
        )}]`;
    }
    return param;
}
