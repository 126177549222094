import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { errorFetchAllAnchors, fetchAllAnchors, successFetchAllAnchors, updateAnchor, updateAnchorSuccess, updateAnchortError } from '../actions/anchor.action';
import { TrackerService } from 'src/app/services/tracker.service';
import { Anchor } from 'src/app/models/anchor';

export type EffectResult = 'success' | 'error';

@Injectable()
export class AnchorEffects {

    public fetchAllAnchors$ = createEffect(() => { return this._actions$.pipe(
        ofType(fetchAllAnchors),
        switchMap(() => this._trackerService.getAllAnchors()),
        map((allAnchors) => successFetchAllAnchors({ anchor: allAnchors })),
        catchError((error) => {
            this._errorTranslationService.handleError(error, 'BANNER_FAIL_CERTIFICATE_REQUEST_UPDATE');
            return of(errorFetchAllAnchors());
        })) }
    );


    public updateAnchor$ = createEffect(() => { return this._actions$.pipe(
        ofType(updateAnchor),
        switchMap((actions) => this._trackerService.updateAnchor(actions.payload, actions.id).pipe(
            map((anchor_updated: Anchor) => {
                const text: string = this._translate.instant('BANNER_SUCCESS_EDIT', { value: anchor_updated.anchorId });
                this._snackBar.open(text, 'green-snackbar', 5000);
                this.effectSubject.next('success');
                return updateAnchorSuccess({ payload: anchor_updated });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_EDIT');
                this.effectSubject.next('error');
                return of(updateAnchortError());
            }))
        )) });

    public effectSubject: Subject<EffectResult>;
    constructor(
        private _trackerService: TrackerService,
        private _actions$: Actions,
        private _snackBar: SnackbarComponent,
        private _translate: TranslateService,
        private _errorTranslationService: ErrorTranslationService,
    ) {
        this.effectSubject = new Subject<EffectResult>();
    }
}
