import { Injectable, inject } from '@angular/core';
import { Observable, distinctUntilChanged, map, shareReplay, startWith, take } from 'rxjs';
import { ConfigurationStorageKeys, LocalStorageService } from './local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

export enum Language {
    English = 'en',
    French = 'fr',
    German = 'de',
    Spanish = 'es',
}

export type ValueByLanguage = Record<Language, string>;

@Injectable({
    providedIn: 'root',
})
export class LanguageService {

    private readonly _localStorageService = inject(LocalStorageService);
    private readonly _translate = inject(TranslateService);

    public selectedLanguage$: Observable<Language> = this._localStorageService.lastStorageValueByKey$[ConfigurationStorageKeys.language].pipe(
        map(({ value }) => value as Language),
        startWith(this._translate.getBrowserLang() as Language),
        shareReplay(1),
    );

    public selectedDateFormat$: Observable<Language> = this._localStorageService.lastStorageValueByKey$[ConfigurationStorageKeys.dateFormat].pipe(
        map(({ value }) => value as Language),
        startWith(this._translate.getBrowserLang() as Language),
        shareReplay(1),
    );

    public readonly LANGUAGES: ValueByLanguage = {
        [Language.English]: 'English',
        [Language.French]: 'Français',
        [Language.German]: 'Deutsh',
        [Language.Spanish]: 'Español',
    };

    private _getDateFormatLabel(language: Language) {
        const localeData = moment.localeData(language);
        return `${localeData.longDateFormat('L')} ${localeData.longDateFormat('LT')} (${this.LANGUAGES[language]})`
    }

    public readonly DATE_FORMATS: ValueByLanguage = {
        [Language.English]: this._getDateFormatLabel(Language.English),
        [Language.French]: this._getDateFormatLabel(Language.French),
        [Language.German]: this._getDateFormatLabel(Language.German),
        [Language.Spanish]: this._getDateFormatLabel(Language.Spanish),
    };

    public set selectedLanguage(lang: Language) {
        this._localStorageService.store$(ConfigurationStorageKeys.language, lang).subscribe();
    }

    public set selectedDateFormat(lang: Language) {
        this._localStorageService.store$(ConfigurationStorageKeys.dateFormat, lang).subscribe();
    }

    constructor() {
        this.selectedLanguage$.pipe(
            distinctUntilChanged(),
        ).subscribe(
            value => {
                this._translate.use(value).pipe(take(1)).subscribe();
                moment.locale(value);
            }
        );
        this._localStorageService.fetchLocalStorageValues$().subscribe();
    }

}
