import { style, animate, trigger, state, transition } from '@angular/animations';

export const expandAnimationTimingFunction = '225ms cubic-bezier(0.4, 0.0, 0.2, 1)';

export const reveal = trigger('reveal', [
    state('visible', style({
        opacity: '1'
    })),
    state('invisible', style({
        opacity: '0'
    })),
    transition('visible <=> invisible', animate('2s'))
]);

export const detailExpand = trigger('detailExpand', [
    state('collapsed', style({
        height: '0',
        minHeight: '0',
    })),
    state('expanded', style({
        height: '*',
    })),
    transition('expanded <=> collapsed', animate(expandAnimationTimingFunction))
]);

export const rotate = (angle: number) => trigger(`rotate${angle}`, [
    state('rotate', style({
        transform: `rotate(${angle}deg)`,
    })),
    transition('void <=> rotate', animate(expandAnimationTimingFunction)),
]);
