<div class="toolbar">
  <mat-button-toggle-group name="scale"
                           aria-label="scale"
                           data-color="primary"
                           [value]="selectedScale"
                           (change)="onScaleChange($event)">
    <mat-button-toggle value="values">{{ 'VALUES' | translate }}</mat-button-toggle>
    <mat-button-toggle value="percent">{{ 'PERCENTAGE' | translate }}</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<app-bar-family-static [dataKPI]="dataKPIstatic"
                       [scale]="selectedScale"></app-bar-family-static>

<app-bar-asset-static [dataKPI]="dataKPIstatic"
                      [scale]="selectedScale"
                      (chartClicked)="onAssetChartClicked($event)"></app-bar-asset-static>

<ng-container *ngIf="(indexAssetClicked | typeOf) === 'number'; else selectElementTemplate">
  <app-bar-cycle-static [dataKPI]="dataKPIstatic"
                        [scale]="selectedScale"
                        [selectedAssetIndex]="indexAssetClicked"></app-bar-cycle-static>
</ng-container>

<ng-template #selectElementTemplate>
  <div class="placeholder-more-kpis">{{ 'KPI_SELECT_ELEMENT' | translate }}</div>
</ng-template>
