<div class="filter">
    <div class="filter-header">

        <div class="filter-header-title">{{'FILTER' | translate}}</div>

        <div class="filter-header-container">
            <button mat-button
                    color="primary"
                    [disabled]="filterForm.invalid"
                    (click)="requestKpi()">
                <fa-icon icon="check"></fa-icon>
                {{'APPLY' | translate}}
            </button>
            <button mat-button
                    color="primary"
                    (click)="clearForm()">
                <fa-icon icon="undo"></fa-icon>
                {{'CLEAR' | translate}}
            </button>
            <button mat-button
                    color="primary"
                    (click)="displayOrHideFiltersPanel()">
              <fa-icon [ngClass]="{'rotate': !displayFiltersPanel}"
                       class="animation-rotate"
                       icon="angle-down"></fa-icon>
              {{ (displayFiltersPanel ? 'VIEW_LESS' : 'VIEW_MORE') | translate }}
            </button>
        </div>
    </div>
    <form class="filter-form"
          [formGroup]="filterForm"
          [@detailExpand]="displayFiltersPanel ? 'expanded' : 'collapsed'">

        <div class="filter-form-container">

            <mat-form-field appearance="outline" class="filter-form-container-field">
                <mat-label>{{ 'ASSET_FAMILY' | translate }}</mat-label>
                <mat-select formControlName="family">
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                               [formControl]="searchForm.controls.family"
                                               [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option>
                        <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                    </mat-option>
                    <mat-option *ngFor="let family of families$
                                        | async
                                        | listFilter:'name':searchForm.value.family:true:true
                                        | sort:false:'name'"
                                [value]="family.id">
                        {{ family.name }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="(loadings.families$ | async) === true">
                    <mat-progress-spinner mode="indeterminate"
                                          diameter="12"
                                          strokeWidth="2"></mat-progress-spinner>
                    {{ "LOADING_DATA" | translate }}
                </mat-hint>
                <mat-error *ngIf="(loadings.families$ | async) === false && filterForm.controls.family.errors?.required">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "REQUIRED_SELECTION" | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="filter-form-container-field">
                <mat-label>{{ 'START_ZONE' | translate }}</mat-label>
                <mat-select formControlName="zone">
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                               [formControl]="searchForm.controls.zone"
                                               [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option>
                        <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                    </mat-option>
                    <mat-option *ngFor="let zone of zones$
                                        | async
                                        | listFilter:'name':searchForm.value.zone:true:true
                                        | sort:false:'name'"
                                [value]="zone.id">
                        {{ zone.name }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="(loadings.zones$ | async) === true">
                    <mat-progress-spinner mode="indeterminate"
                                          diameter="12"
                                          strokeWidth="2"></mat-progress-spinner>
                    {{ "LOADING_DATA" | translate }}
                </mat-hint>
                <mat-error *ngIf="(loadings.zones$ | async) === false && filterForm.controls.zone.errors?.required">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "REQUIRED_SELECTION" | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="filter-form-container-field">
                <mat-label>{{ 'PASSAGE_ZONE' | translate }}</mat-label>
                <mat-select formControlName="passzone">
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                               [formControl]="searchForm.controls.passZone"
                                               [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option>
                        <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                    </mat-option>
                    <mat-option *ngFor="let zone of zones$
                                        | async
                                        | listFilter:'name':searchForm.value.passZone:true:true
                                        | sort:false:'name'"
                                [value]="zone.id">
                        {{ zone.name }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="(loadings.zones$ | async) === true">
                    <mat-progress-spinner mode="indeterminate"
                                          diameter="12"
                                          strokeWidth="2"></mat-progress-spinner>
                    {{ "LOADING_DATA" | translate }}
                </mat-hint>
                <mat-error *ngIf="(loadings.zones$ | async) === false && filterForm.controls.passzone.errors?.required">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "REQUIRED_SELECTION" | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="filter-form-container-field">
                <mat-label>{{ 'IN_OUT_ZONE_FILTER' | translate }}</mat-label>
                <mat-select formControlName="inOutzone">
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                               [formControl]="searchForm.controls.inOutzone"
                                               [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option>
                        <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                    </mat-option>
                    <mat-option *ngFor="let zone of zones$
                                        | async
                                        | listFilter:'name':searchForm.value.inOutzone:true:true
                                        | sort:false:'name'"
                                [value]="zone.id">
                        {{ zone.name }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="(loadings.zones$ |async) === true">
                    <mat-progress-spinner mode="indeterminate"
                                          diameter="12"
                                          strokeWidth="2"></mat-progress-spinner>
                    {{ "LOADING_DATA" | translate }}
                </mat-hint>
                <mat-error *ngIf="(loadings.zones$ | async) === false && filterForm.controls.inOutzone.errors?.required">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "REQUIRED_SELECTION" | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" type="autoComplete" class="filter-form-container-field">
                <mat-label>{{ 'ASSET_NAME' | translate }}
                </mat-label>
                <mat-select formControlName="assets"
                            multiple>
                    <mat-select-trigger>
                        {{ assets$ | async | listFilter:'id':filterForm.value.assets?.[0] | extractPropertyPipe:'name' }}
                        <small *ngIf="filterForm.value.assets?.length > 1">
                            <br>+ {{ filterForm.value.assets.length - 1 }} {{ "OPTIONS_SELECTED" | translate }}
                        </small>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                               [formControl]="searchForm.controls.asset"
                                               [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let asset of assets$
                                        | async
                                        | listFilter:'name':searchForm.value.asset:true:true
                                        | sort:false:'name'"
                                [value]="asset.id">
                        {{ asset.name }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="(loadings.assets$ |async) === true">
                    <mat-progress-spinner mode="indeterminate"
                                          diameter="12"
                                          strokeWidth="2"></mat-progress-spinner>
                    {{ "LOADING_DATA" | translate }}
                </mat-hint>
                <mat-error *ngIf="(loadings.assets$ | async) === false && filterForm.controls.assets.errors?.required">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "REQUIRED_SELECTION" | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ "DATETIME_RANGE" | translate }}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input readonly
                           matStartDate
                           formControlName="startDate"
                           placeholder="{{'START_DATE' | translate}}">
                    <input readonly
                           matEndDate
                           formControlName="endDate"
                           placeholder="{{'END_DATE' | translate}}">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix
                                       [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="filterForm.controls.startDate.errors?.required || filterForm.controls.endDate.errors?.required">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "REQUIRED_SELECTION" | translate }}
                </mat-error>
            </mat-form-field>

        </div>
    </form>
</div>

<span id="kpi-filter-clear-value"
      style="display: none;">{{ 'CLEAR_SELECTION' | translate }}</span>
