<div class="avatar"
     class="status-{{stepStatus}}"
     [matTooltip]="statusText">
    <mat-icon [ngSwitch]="stepStatus">
        <ng-container *ngSwitchCase="Status.pending">
            hourglass_empty
        </ng-container>
        <ng-container *ngSwitchCase="Status.onHold">
            pause
        </ng-container>
        <ng-container *ngSwitchCase="Status.inProgress">
            play_arrow
        </ng-container>
        <ng-container *ngSwitchCase="Status.done">
            done
        </ng-container>
        <ng-container *ngSwitchCase="Status.block">
            block
        </ng-container>
        <ng-container *ngSwitchDefault>
            question_mark
        </ng-container>
    </mat-icon>
</div>
