<div class="way-component--filterable-container">

    <div class="panel">

        <div class="way-component--header">
            <div class="way-component--header-title">{{"MANAGE_RIGHT_TO_USER" | translate}}</div>
        </div>

        <div class="filter-form-container">
            <div>
                <mat-form-field appearance="outline" class="filter-form-container-field">
                    <mat-label>{{"MANAGE_RIGHT_NG_ID" | translate}}</mat-label>
                    <input type="text" name="userId" [placeholder]="'MANAGE_RIGHT_NG_ID' | translate" aria-label="Number"
                        matInput [formControl]="userIdFormControl" [matAutocomplete]="userIdAutoComplete" required>
                    <mat-hint *ngIf="(isLoadingUsers$ | async) === true">
                        <mat-progress-spinner mode="indeterminate"
                                                diameter="12"
                                                strokeWidth="2"></mat-progress-spinner>
                        {{ "LOADING_DATA" | translate }}
                    </mat-hint>
                    <mat-hint class="panel-hint-error" *ngIf="userIdFormControl.invalid && userIdFormControl.value">
                        {{"MANAGE_RIGHT_INVALID_NG_ID" | translate }}</mat-hint>
                </mat-form-field>
                <mat-autocomplete #userIdAutoComplete="matAutocomplete" [displayWith]="getNgID">
                    <mat-option *ngFor="let user of filteredUsers$ | async" [value]="user">
                        <!-- {{user.id}} - {{user.lastname}} {{user.firstname}} -->
                        {{user.userId}}{{user.email ? ' - ' + user.email : '' }}
                    </mat-option>
                </mat-autocomplete>
            </div>
            <div class="header-panel"
                 *ngIf="userIdFormControl.valid">
                <div>
                    <span *ngIf="selectedUserId">
                        <!-- {{selectedUser.lastname}} {{selectedUser.firstname}} -->
                        {{selectedUser.email}}
                    </span>
                </div>
                <app-user-groups-list [user]="selectedUser"
                                      [disabled]="!isGroupAdmin && !isAdmin"
                                      [action]="GROUP_ACTIONS.assign"
                                      [qtyGroupsToDisplay]="qtyGroupsToDisplay"></app-user-groups-list>
                <div>
                    <button *ngIf="isAdmin && this.selectedUser.email && !loading"
                        mat-flat-button
                        color="primary"
                        (click)="openDialogSpecificRole(userIdFormControl.value)">
                        <fa-icon icon="plus-circle"></fa-icon>
                        {{ "ADD_SPECIFIC_ROLE" | translate }}
                    </button>
                    <mat-progress-spinner *ngIf="loading" color="primary" mode="indeterminate" diameter="16">
                    </mat-progress-spinner>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!userIdFormControl.invalid; else fillUserId">

        <div class="way-filter-container">
            <app-filter [parentSourceView]="view" [dataForFilter$]="parentFamilies$"></app-filter>
        </div>

        <div class="way-component--header">
            <span class="way-component--header-title">{{ 'ROLES' | translate }}</span>
        </div>

        <div class="way-table--main-container">

            <div class="table-container custom-scrollbar">

                <table class="way-table"
                       mat-table
                       matSort
                       matSortActive="level"
                       matSortDirection="desc"
                       multiTemplateDataRows
                       [dataSource]="dataSource"
                       [attr.aria-label]="'ARIA_USER_ROLE_TABLE' | translate">
                    <!-- Parent Families -->
                    <ng-container matColumnDef="{{ column }}" *ngFor="let column of displayedColumns">
                        <th mat-header-cell mat-sort-header
                            [disabled]="(column === 'actionColumns') || (column === 'level') ? true : false"
                            *matHeaderCellDef scope="col">
                            {{ getRealName(column) }} </th>
                        <td mat-cell *matCellDef="let element">
                            <!-- Datas -->
                            <div *ngIf="element && column !== 'actionColumns'" class="infos-{{column}}">
                                <ng-container [ngSwitch]="column">
                                    <div *ngSwitchCase="'level'"
                                         (click)="$event.stopPropagation()">
                                      <app-role-selection color="accent"
                                                          [role]="element[column]"
                                                          [matTooltip]="!!element.familyFromGroup ? ('RIGHTS_ONLY_FROM_GROUP' | translate) : ('REQUEST_RIGHT_IN_PROGRESS' | translate)"
                                                          [matTooltipDisabled]="!element.familyFromGroup && !disabledRadioButton[element.id]"
                                                          [disabled]="element.familyFromGroup || (isParentManager(element) && disabledRadioButton[element.id])"
                                                          (selectedRoleChange)="onClickModifyRole(element, $event)"></app-role-selection>
                                    </div>
                                    <div *ngSwitchCase="'site'">
                                        {{ element.site?.name }}
                                    </div>
                                    <div *ngSwitchCase="'company'">
                                        {{ element.company?.name }}
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ element[column] }}
                                    </div>
                                </ng-container>

                            </div>
                            <!-- Buttons -->
                            <div *ngIf="element && column === 'actionColumns'" class="action-column">
                                <ng-container *ngIf="disabledRadioButton[element.id]" [ngTemplateOutlet]="parentActionLoader"></ng-container>
                                <button mat-icon-button *ngIf="!element.isLoading">
                                    <mat-icon [@rotate180]="element.expanded ? 'rotate' : 'void'">expand_more</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Families  -->
                    <ng-container matColumnDef="expandedDetail">
                        <th mat-cell *matCellDef="let parentFamily" [attr.colspan]="displayedColumns.length"
                            scope="col">
                            <div class="expanded-details"
                                [@detailExpand]="parentFamily.expanded ? 'expanded' : 'collapsed'">
                                <table *ngIf="parentFamily.families.length; else noDataTpl" class="sub-table" mat-table
                                    matSort [dataSource]="parentFamily.families"
                                    [attr.aria-label]="'ARIA_USER_ROLE_TABLE_EXPANDED' | translate">

                                    <ng-container matColumnDef="{{ column }}" *ngFor="let column of displayedColumns">
                                        <th mat-header-cell mat-sort-header
                                            [disabled]="column === 'actionColumns' ? true : false" *matHeaderCellDef
                                            scope="col">{{ getRealName(column) }} </th>
                                        <td mat-cell *matCellDef="let element" class="expanded-td">
                                            <!-- Datas -->
                                            <div *ngIf="element && column !== 'actionColumns'" class="infos-{{column}}">
                                                <ng-container [ngSwitch]="column">
                                                    <div *ngSwitchCase="'level'"
                                                         (click)="$event.stopPropagation()">
                                                      <app-role-selection color="accent"
                                                                          [role]="element[column]"
                                                                          [matTooltip]="!!element.fromGroupId ? ('RIGHTS_ONLY_FROM_GROUP' | translate) : ('REQUEST_RIGHT_IN_PROGRESS' | translate)"
                                                                          [matTooltipDisabled]="!element.fromGroupId && !disabledRadioButton[element.id]"
                                                                          [disabled]="!!element.fromGroupId || disabledRadioButton[element.id]"
                                                                          (selectedRoleChange)="onClickModifyRole(parentFamily, $event, element)"></app-role-selection>
                                                    </div>
                                                    <div *ngSwitchCase="'site'">
                                                        {{ parentFamily?.site?.name }}
                                                    </div>
                                                    <div *ngSwitchCase="'company'">
                                                        {{ parentFamily?.company?.name }}
                                                    </div>
                                                    <div *ngSwitchDefault>
                                                        {{ element[column] }}
                                                    </div>
                                                </ng-container>
                                            </div>

                                            <div *ngIf="element && column === 'actionColumns'" class="action-column">
                                                <ng-container *ngIf="disabledRadioButton[element.id]" [ngTemplateOutlet]="actionLoader"></ng-container>

                                            </div>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row style="display: none;" *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let familyRow; columns: displayedColumns;"></tr>
                                </table>
                                <ng-template #noDataTpl>
                                    <div class="expanded-row--no-data">
                                        <span>{{'MY_RIGHTS_NO_SUB_FAMILLY' | translate }}</span>
                                    </div>
                                </ng-template>
                            </div>
                        </th>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky : true"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="general-row"
                        [class.expanded-row]="element.expanded" (click)="onClickParentFamilyRow(element);">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
                </table>

                <div *ngIf="isLoadingTable" class="table-loader">
                    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
                    </mat-progress-spinner>
                </div>
            </div>
            <mat-paginator class="way-table--footer" (page)="scrollUp()" [pageSizeOptions]="[25, 50, 100]">
            </mat-paginator>

        </div>

    </ng-container>

    <ng-template #fillUserId>
        <app-empty-state-fill-field [subtitle]="'MANAGE_RIGHT_TRY_NG' | translate"></app-empty-state-fill-field>
    </ng-template>

    <ng-template #parentActionLoader>
        <span class="loader--parent">
            <mat-progress-spinner color="primary" mode="indeterminate" diameter="16">
            </mat-progress-spinner>
        </span>
    </ng-template>

    <ng-template #actionLoader>
        <span class="loader--family">
            <mat-progress-spinner color="primary" mode="indeterminate" diameter="16">
            </mat-progress-spinner>
        </span>
    </ng-template>

</div>
