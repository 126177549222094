/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import moment from 'moment';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {

    private _buildMessage(cwd: string, message: string): string {
        return `[${cwd} - ${moment().format('YYYY-MM-DDThh:mm:ss.SSS')}] ${message}`;
    }

    public error(cwd: string, message: string, ...optionalParams: any[]) {
        console.error(this._buildMessage(cwd, message), ...optionalParams);
    }

    public warn(cwd: string, message: string, ...optionalParams: any[]) {
        console.warn(this._buildMessage(cwd, message), ...optionalParams);
    }

    public log(cwd: string, message: string, ...optionalParams: any[]) {
        console.log(this._buildMessage(cwd, message), ...optionalParams);
    }

    public debug(cwd: string, message: string, ...optionalParams: any[]) {
        if (environment.production) {
            return;
        }
        console.debug(this._buildMessage(cwd, message), ...optionalParams);
    }

}
