import { Airline } from './airline';
import { Event } from './event';
import { Family } from './family';
import { Program } from './masterdata';
import { Tracker } from './tracker';
import { Zone } from './zone';

export interface Asset {
    id: string;
    family_name: string;
    name: string;
    pn: string;
    sn?: string;
    notes?: string;
    picture?: string | ArrayBuffer;
    last_event?: Event;
    last_zone?: {
      zone1?: LastZoneLevel;
      zone2?: LastZoneLevel;
      zone3?: LastZoneLevel;
    };
    last_no_gps?: LastNoGps;
    created_by?: string;
    created_at?: string;
    modified_at?: string;
    modified_by?: string;
    tracker?: Tracker;
    family?: Family;
    familyId: string;
    authorize?: boolean;
    associated: boolean;
    status?: AssetStatus;
    isLoading?: boolean;
    new_tracker?: Tracker;
    loadedElements?: Array<LoadedElements>;
    specificData: { isStatusForced: boolean };
    additionalData?: Partial<AssetAdditionalData>;
    trackerId?: string;
    msn?: string;
    section?: string;
    side?: string;
    sectionMsn?: Array<{ section: string, msn: string }>;
    lastEventTimestamp: number;
    keepAliveTimestamp: number;
    last_distance_event?: LastAssetDistanceEvent;
}

export interface LastAssetDistanceEvent {
    distance: number;
    status?: string;
}

export interface AssetAdditionalData {
    // i3m
    technicalWorkstation?: string;
    characteristics?: string;
    assignmentZone?: string;
    compatibleProgram?: Program[];
    conditionalMaintenance?: string;
    engineHour?: number;
    mileage?: number;
    energyLevel?: number;
    firmwareVersion?: number;
    // act
    immatriculation?: string;
    airline?: Airline;
    leaser?: string;
    contact?: string;
}

export interface LastZoneLevel {
  id: Zone['id'];
  name: Zone['name'];
}

export interface LastNoGps {
    data: string;
    event_time: string;
    insert_time: string;
    source: string;
    state: string;
}

export interface AssetRow extends Asset {
    expanded: boolean;
    fetchedLoadedElements?: LoadedElements[];
    isLoadedElementsLoading: boolean;
}

export interface AssetWithTracker extends Asset {
    tracker?: Tracker;
}

export interface LoadedElements {
    id?: string;
    asset_id?: string;
    section?: string;
    sectionName?: string;
    section_msn?: string;
    msn?: string;
    name?: string;
    asset_name?: string;
    category?: string;
}

export interface AssetLoadedElementDetails {
    capacity: number;
    data: LoadedElements[];
    id: string;
}

export type AssetStatus = 'inService' | 'maintenance' | 'quarantine';

export interface ForceStatusPlayload {
    status: string;
    isStatusForced: boolean;
}

export interface AssetAndTracker {
    asset: Asset;
    tracker: Tracker;
}
export type CreateAssetResponse = AssetAndTracker;


export interface ExtractAdminAsset {
    'Name': string;
    'Parent family': string;
    'Asset family': string;
    'Part Number': number|string;
    'Serial number': string;
    'Note': string;
    'Asset Status': string;
    'Signal Source': string | string[];
    'Associated Device': string;
}

export interface UpdateBeaconId {
    beaconId: string;
    trackerId: string;
}

export enum AssetStatusState {
    Loaded = 'LOADED',
    Empty = 'EMPTY',
}
