import { createAction, props } from '@ngrx/store';
import { Family, ParentFamily } from 'src/app/models/family';
import { CreateOrUpdateFamily } from 'src/app/services/family.service';

export const fetchAllFamilies = createAction('[Families] fetch all families');
export const successFetchAllFamilies = createAction('[Families] Success fetch all Families', props<{ payload: Family[] }>());
export const errorFetchAllFamilies = createAction('[Family] Error fetch all Families');

export const createFamily = createAction('[Family] create Family', props<{ family: Family, base64picture: string | ArrayBuffer }>());
export const successCreateFamilies = createAction('[Families] Success create Families', props<{ family: Family }>());
export const errorCreateFamilies = createAction('[Family] Error create Family');

export const updateFamily = createAction('[Family] update Family', props<{ family: CreateOrUpdateFamily }>());
export const successUpdateFamilies = createAction('[Families] success update Family', props<{ payload: Family[] }>());
export const errorUpdateFamilies = createAction('[Families] Error update Family');

export const deleteFamily = createAction('[Families] delete Family', props<{ payload: string }>());
export const successDeleteFamilies = createAction('[Families] success delete Families', props<{ payload: { id: string }[] }>());
export const errorDeleteFamilies = createAction('[Families] Error update Family');

export const mappingAllFamiliesParentFamilies = createAction('[Families] Mapping all Families and parent families', props<{ families: Family[], parentFamilies: { [id: string]: ParentFamily } }>());
export const mappingSomeFamiliesParentFamilies = createAction('[Families] Mapping some Families and parent families', props<{ families: Family[], parentFamilies: { [id: string]: ParentFamily } }>());
