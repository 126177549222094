import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UserFilters } from 'src/app/models/filter-context.model';
import { deleteUserContext, errorToggleContextFavorite, setDefaultContext, successToggleContextFavorite, toggleContextFavorite } from 'src/app/store/actions/filter-context.action';
import { contextIsValid$, selectUserFavoriteContexts$, selectUserSavedContexts$, selectUserSelectedContext$ } from 'src/app/store/selectors/filter-context.selectors';
import { DialogValidationComponent } from '../../shared/dialog/dialog-validation/dialog-validation.component';
import { Subscription, Observable, EMPTY, BehaviorSubject, combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
    DEFAULT_DIALOG_OPTIONS as CONTEXT_CONFIGURE_DEFAULT_DIALOG_OPTIONS,
    DialogConfigureContextComponent,
} from '../configure-context/dialog-configure-context.component';
import { FilterContextEffects } from 'src/app/store/effects/filter-context.effects';
import { catchError, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { FormBuilder, FormControl } from '@angular/forms';
import { CONTEXT_DIALOG_OPENED, ContextService } from 'src/app/services/init-filter.service';
import { SnackbarComponent } from '../../shared/snackbar/snackbar';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

export const DEFAULT_DIALOG_OPTIONS: MatDialogConfig<DialogManageContextComponent> = {
    hasBackdrop: true,
    width: '100vw',
    maxWidth: '460px',
    maxHeight: '85vh',
};

@Component({
    selector: 'app-dialog-manage-context',
    templateUrl: './dialog-manage-context.component.html',
    styleUrls: ['./dialog-manage-context.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogManageContextComponent implements OnInit {

    private _mobile: boolean;
    @HostBinding('class.mobile') get mobileClass() {
        return this._mobile;
    }

    @ViewChild('scrollViewport') private cdkVirtualScrollViewport: CdkVirtualScrollViewport;

    public loading = false;
    public searchFormField: FormControl<string> = this._fb.control('');
    public userContexts$: Observable<UserFilters[]> = this.searchFormField.valueChanges.pipe(
        startWith(''),
        switchMap(searchedValue => combineLatest([
            this.store.select(selectUserSavedContexts$),
            this.store.select(selectUserSelectedContext$),
        ]).pipe(
            map(([contexts, selectedContext]) => {
                const searched = new RegExp(searchedValue ?? '', 'i');
                return contexts.filter(({ name }) => searched.test(name)).map(
                    context => {
                        // Ensure default context is set from the selected one rather than from backend data
                        context.defaultContext = context.id === selectedContext?.id;
                        return context;
                    }
                );
            }),
        )),
        tap((contexts) => this.calculateContainerHeight(contexts.length)),
    );

    public warningTooManyFavorites$: Observable<boolean> = this.store.select(selectUserFavoriteContexts$).pipe(
        map(contexts => contexts.length > 5),
    );

    public disabledFavoritesButtons: Record<UserFilters['id'], boolean> = {};

    public canCloseDialog$ = this.store.select(contextIsValid$);

    public scrollContainerHeight: number;
    private _subscription: Subscription = new Subscription();

    constructor(
        @Inject(CONTEXT_DIALOG_OPENED) private _dialogOpened$: BehaviorSubject<boolean>,
        private store: Store,
        private _dialogRef: MatDialogRef<DialogManageContextComponent>,
        private _dialog: MatDialog,
        private _translate: TranslateService,
        private _cd: ChangeDetectorRef,
        private _fb: FormBuilder,
        private _contextEffects: FilterContextEffects,
        private _contextService: ContextService,
        private _snackbar: SnackbarComponent,
        private _errorTranslationService: ErrorTranslationService,
        private _breakpointObserver: BreakpointObserver,
    ) {
        this._dialogRef.afterOpened().pipe(
            take(1),
        ).subscribe(
            () => this._dialogOpened$.next(true)
        );
        this._dialogRef.afterClosed().pipe(
            take(1),
        ).subscribe(
            (data) => {
                if (!data?.preventCloseFlag) {
                    this._dialogOpened$.next(false);
                }
            }
        );
    }

    ngOnInit(): void {
        this._subscription.add(
            this._breakpointObserver.observe([
                Breakpoints.Handset,
                Breakpoints.HandsetLandscape,
            ]).subscribe(
                ({ matches }) => {
                    this._dialogRef.updateSize('100vw', matches ? 'calc(100% - 50px)' : 'auto');
                    this._dialogRef.updatePosition(matches ? { bottom: '0' } : null);
                    this._mobile = matches;
                    this._cd.markForCheck();
                }
            )
        );
    }

    public calculateContainerHeight(numberOfItems: number): void {
        // This should be the height of your item in pixels
        const itemHeight = 56;
        // The final number of items you want to keep visible
        const visibleItems = 10;

        setTimeout(() => {
            // Makes CdkVirtualScrollViewport to refresh its internal size values after
            // changing the container height. This should be delayed with a "setTimeout"
            // because we want it to be executed after the container has effectively
            // changed its height. Another option would be a resize listener for the
            // container and call this line there but it may requires a library to detect
            // the resize event.

            this.cdkVirtualScrollViewport.checkViewportSize();
        }, 300);

        // It calculates the container height for the first items in the list
        // It means the container will expand until it reaches `200px` (20 * 10)
        // and will keep this size.
        if (numberOfItems <= visibleItems) {
            this.scrollContainerHeight = itemHeight * numberOfItems;
        } else {
            // This function is called from the template so it ensures the container will have
            // the final height if number of items are greater than the value in "visibleItems".
            this.scrollContainerHeight = itemHeight * visibleItems;
        }
        this._cd.markForCheck();
    }

    /**
     * This method makes the selected filter active and set the selected filter as current context.
     */
    public onApply(selectedFilterId: string) {
        this.loading = true;
        this._contextEffects.effectSubject$.pipe(
            filter(({ action }) => action === setDefaultContext),
            take(1),
        ).subscribe(({ result }) => {
            if (result === 'success') {
                this._dialogRef.close();
                this._cd.markForCheck();
            }
            this.loading = false;
        });
        this.store.dispatch(setDefaultContext({ id: selectedFilterId }));
    }

    /**
     * This method open the edit context form
     */
    public openEditContext(element: UserFilters, event?: Event) {
        event?.stopImmediatePropagation();
        this._dialogRef.close({
            preventCloseFlag: true,
        });
        this._dialog.open(DialogConfigureContextComponent, {
            ...CONTEXT_CONFIGURE_DEFAULT_DIALOG_OPTIONS,
            data: element
        });
    }

    /**
     * This method opens the Create Context form.
     */
    public openCreateContext() {
        this.canCloseDialog$.pipe(
            take(1),
        ).subscribe(
            canClose => {
                this._dialogRef.close({
                    preventCloseFlag: true,
                });
                this._dialog.open(DialogConfigureContextComponent, {
                    ...CONTEXT_CONFIGURE_DEFAULT_DIALOG_OPTIONS,
                    hasBackdrop: canClose,
                    disableClose: !canClose,
                });
            }
        );
    }

    /**
     * Open dialog to delete the selected row of user filters
     * Delete must only be possible if filter is inactive
     * @param element The filter line to delete
     */
    public openDeleteDialog(element: UserFilters, event?: Event) {
        event?.stopImmediatePropagation();
        const dialogRef: MatDialogRef<DialogValidationComponent> = this._dialog.open(DialogValidationComponent, {
            minWidth: '250px',
            data: {
                title: this._translate.instant('DIALOG_DELETE_OPERATION_TITLE'),
                textContent: this._translate.instant('DIALOG_DELETE_VALIDATION', { item: 'filter ' + element.name }),
                validateText: this._translate.instant('DELETE'),
                cancelText: this._translate.instant('CANCEL'),
            }
        });
        this._subscription.add(dialogRef.componentInstance.onValidateEvent.subscribe(() => {
            this.loading = true;
            this._contextEffects.effectSubject$.pipe(
                filter(({ action }) => action === deleteUserContext),
                take(1),
            ).subscribe(() => {
                dialogRef.close();
                this.loading = false;
                this._cd.markForCheck();
            });
            this.store.dispatch(deleteUserContext({ id: element.id }));
        }));
    }

    /**
     * Toggle the favorite state of the context
     * @param context The context to toggle favorite state
     */
    public toggleFavorite(context: UserFilters, event?: Event) {
        event?.stopImmediatePropagation();
        // Don't use the store as it cancels the previous requests when they are launched in parallel
        this.disabledFavoritesButtons[context.id] = true;
        this._contextService.toggleContextFavorite(context.id).pipe(
            catchError(error => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
                return EMPTY;
            })
        ).subscribe({
            next: (data) => {
                const text = this._translate.instant(data.favorite ? 'CONTEXT_FAVORITE_ADDED' : 'CONTEXT_FAVORITE_REMOVED', { context: context.name });
                this._snackbar.open(text, 'green-snackbar', 5000);
                this.store.dispatch(successToggleContextFavorite({ context: data }));
            },
            error: () => {
                this.store.dispatch(errorToggleContextFavorite());
            },
            complete: () => {
                this.disabledFavoritesButtons[context.id] = false;
                this._cd.markForCheck();
            },
        });
    }

}
