import { Action, createReducer, on } from '@ngrx/store';
import { isEqual } from 'lodash';
import { AircraftPlanning } from 'src/app/models/aircraft-planning';
import {
    mappingAllAircraftPlanningFamilyAssetZone, successDeletePlannedMovement, mappingSomeAircraftPlanningFamilies,
    mappingSomeAircraftPlanningFamiliesForced, successUploadAircraftPlannings, successDeletePlannedMovementClash,
    successUpdateAircraftClash
} from '../actions/aircraft-planning.action';
export interface State {
    data: Array<AircraftPlanning>;
    loaded: boolean;
}

const initialState: State = {
    data: [],
    loaded: false,
};

const aircraftPlanningReducer = createReducer(
    initialState,
    on(mappingAllAircraftPlanningFamilyAssetZone, (state, action) => {
        return {
            ...state,
            loaded: true,
            data: (() => {
                const datas = [...action.aircraftPlanning];
                datas.forEach(aircraftPlanning => {
                    aircraftPlanning.program = action.families[aircraftPlanning.familyId]?.parent_family?.name;
                    aircraftPlanning.version = action.families[aircraftPlanning.familyId]?.name;
                    aircraftPlanning.msn = action.assets[aircraftPlanning.assetId]?.name;
                    aircraftPlanning.airline = action.assets[aircraftPlanning.assetId]?.additionalData?.airline?.value;
                    aircraftPlanning.currentPosition = action.zones.find(zone => zone.id === aircraftPlanning.zoneFrom);
                    aircraftPlanning.futurePosition = action.zones.find(zone => zone.id === aircraftPlanning.zoneTo);
                });
                return datas;
            })()
        };
    }),

    on(successUploadAircraftPlannings, (state): State => {
        return {
            ...state,
            loaded: true,
        };
    }),

    on(successDeletePlannedMovement, (state, action) => {
        return {
            ...state,
            loaded: true,
            data: (() => {
                const datas = [...state.data];
                const lineIdToDelete = action.payload;
                return datas.filter(actOpeMov => actOpeMov.id !== lineIdToDelete);
            })()
        };
    }),

    // Remove from the store, the selected local line => no id to identify the deleted line, so we compare the object in order to remove it
    on(successDeletePlannedMovementClash, (state, action) => {
        return {
            ...state,
            loaded: true,
            data: (() => {
                const datas = [...state.data];
                const lineToDelete = action.payload;
                const indexToRemove = datas.findIndex(_planningRow => {
                    return Object.keys(_planningRow).every(key => {
                        return isEqual(lineToDelete[key], _planningRow[key]);
                    });
                });
                // find first attribute matching in the store
                if (indexToRemove > -1) {
                    datas.splice(indexToRemove, 1);
                }
                return datas;
            })()
        };
    }),

    // Remove from the store, the selected local line => no id to identify the deleted line, so we compare the object in order to remove it
    on(successUpdateAircraftClash, (state, action) => {
        return {
            ...state,
            loaded: true,
            data: (() => {
                const datas = [...state.data];

                const mappedPlanning = {
                    ...action.editedPlanning,
                    program: action.assets[action.editedPlanning.assetId]?.family?.parent_family?.name,
                    version: action.assets[action.editedPlanning.assetId]?.family?.name,
                    msn: action.assets[action.editedPlanning.assetId]?.name,
                    airline: action.assets[action.editedPlanning.assetId]?.additionalData?.airline.value,
                    currentPosition: action.zones.find(zone => zone.id === action.editedPlanning.zoneFrom),
                    futurePosition: action.zones.find(zone => zone.id === action.editedPlanning.zoneTo),
                    zoneTo: action.editedPlanning.zoneTo,
                };

                const planningToUpdateIndex = datas.findIndex(_planningRow => {
                    return Object.keys(_planningRow).every(key => {
                        return isEqual(action.oldPlanning[key], _planningRow[key]);
                    });
                });
                if (planningToUpdateIndex > -1) { // ID found in the state, the planning operation exists, we replace it by the new one (update case)
                    datas[planningToUpdateIndex] = mappedPlanning;
                }
                return datas;
            })()
        };
    }),

    on(mappingSomeAircraftPlanningFamiliesForced, (state, action) => {
        return {
            ...state,
            data: (() => {
                return action.aircraftPlanning.map(aircraftPlanning => {
                    const newData: AircraftPlanning = {
                        ...aircraftPlanning,
                        program: action.assets[aircraftPlanning.assetId]?.family?.parent_family?.name,
                        version: action.assets[aircraftPlanning.assetId]?.family?.name,
                        msn: action.assets[aircraftPlanning.assetId]?.name,
                        airline: action.assets[aircraftPlanning.assetId]?.additionalData?.airline.value,
                        currentPosition: action.zones.find(zone => zone.id === aircraftPlanning.zoneFrom),
                        futurePosition: action.zones.find(zone => zone.id === aircraftPlanning.zoneTo),
                    };
                    return newData;
                });
            })()
        };
    }),

    on(mappingSomeAircraftPlanningFamilies, (state, action) => {
        return {
            ...state,
            data: (() => {
                const datas = [...state.data];
                const mappedPlanning = action.aircraftPlanning.map(aircraftPlanning => {
                    return {
                        ...aircraftPlanning,
                        program: action.assets[aircraftPlanning.assetId]?.family?.parent_family?.name,
                        version: action.assets[aircraftPlanning.assetId]?.family?.name,
                        msn: action.assets[aircraftPlanning.assetId]?.name,
                        airline: action.assets[aircraftPlanning.assetId]?.additionalData?.airline.value,
                        currentPosition: action.zones.find(zone => zone.id === aircraftPlanning.zoneFrom),
                        futurePosition: action.zones.find(zone => zone.id === aircraftPlanning.zoneTo),
                    };
                });

                mappedPlanning.forEach((planningRow) => {
                    const planningToUpdateIndex = datas.findIndex(_planningRow => _planningRow.id === planningRow.id);
                    if (planningToUpdateIndex > -1) {
                        // ID found in the state, the planning operation exists, we replace it by the new one (update case)
                        datas[planningToUpdateIndex] = planningRow;
                    } else {
                        // Create case
                        datas.push(planningRow);
                    }
                });
                return datas;
            })()
        };
    }),
);

export function reducer(state: State | undefined, action: Action) {
    return aircraftPlanningReducer(state, action);
}
