import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface WSBanner {
    id?: string;
    type: 'info'|'warning'|'error'|'success';
    message: string;
    link?: string;
}

export interface Banner {
    id?: string;
    type: 'info'|'warning'|'error'|'success';
    message: string;
    action?: {
        text: string;
        callback: () => void;
    };
    dismissed$?: Observable<void>;
}

@Injectable({
  providedIn: 'root'
})
export class BannerService {

    public banners$: Observable<Banner[]>;

    private _banners$ = new BehaviorSubject<Banner[]>([]);
    private _bannerDismiss = new WeakMap<Banner, Subject<void>>()

    constructor(
        private http: HttpClient,
    ) {
        this.banners$ = this._banners$.asObservable().pipe(
            shareReplay(1),
        );
    }

    public addBanner(banner: Banner) {
        this._bannerDismiss.set(banner, new Subject());
        banner.dismissed$ = this._bannerDismiss.get(banner).asObservable();
        this._banners$.pipe(
            take(1),
        ).subscribe(
            banners => this._banners$.next([...banners, banner])
        );
    }

    public dismiss(banner: Banner): void {
        this._banners$.pipe(
            take(1),
        ).subscribe(
            banners => {
                this._banners$.next(banners.filter(
                    b => b !== banner
                ));
            }
        );
        this._bannerDismiss.get(banner)?.next();
        this._bannerDismiss.delete(banner);
        if (banner.id) {
            this.http.post<void>(
                `${environment.API_ENDPOINT}/announcement/close/${banner.id}`,
                null,
            ).subscribe();
        }
    }

}
