import { ChangeDetectionStrategy, Component } from '@angular/core';
import { KpiFamily } from 'src/app/services/kpi.service';
import ChartBaseComponent from '../../../shared/kpi/chart.base';
import { Ng2ChartConfiguration } from '../../../shared/kpi/chart.model';

@Component({
  selector: 'app-bar-asset-loop',
  templateUrl: '../../../shared/kpi/chart-card-scrollable.component.html',
  styleUrls: ['../../../shared/kpi/chart-card-scrollable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarAssetLoopComponent extends ChartBaseComponent<KpiFamily, 'bar', string> {

  public chartConfiguration: Ng2ChartConfiguration<'bar', number[], string> = {
    type: 'bar',
    data: { datasets: [] },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      onClick: (event, item) => this.chartClicked.emit({ event, item }),
      plugins: {
        title: {
          display: true,
          position: 'top',
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          display: true,
          beginAtZero: true,
          grid: {
            display: false,
          },
        },
        y: {
          display: true,
          beginAtZero: true,
          ticks: {
            callback: function (label) {
              if (Math.floor(+label) === label) {
                return label;
              }
            }
          },
        },
      }
    }
  };

  setKpiData(): void {
    this.chartConfiguration.data = {
      labels: this.dataKPI.familyAssets.map(
        (asset) => asset.assetName
      ),
      datasets: [
          {
              label: this._translate.instant('LOOP_MONITORING'),
              backgroundColor: 'blue',
              data: this.dataKPI.familyAssets.map(
                (asset) => asset.getAssetNumberOfCycle(),
              ),
              borderColor: 'blue',
              borderWidth: 1,
          }
      ]
    };
    this.chartConfiguration.options.plugins.title.text =
      `${this._translate.instant('LOOP_MONITORING')} (${this.dataKPI.familyNumberOfCycle} ${this._translate.instant('TOTAL_NUMBER_OF_LOOP', {
        familyName: this.dataKPI.familyName,
        startDate: this.dataKPI.familyStartDate,
        endDate: this.dataKPI.familyEndDate,
      })})`;
      this.graphSize = this.dataKPI.familyAssets.length > 20 ? `${100 * this.dataKPI.familyAssets.length}px` : 'auto';
  }

}
