<div mat-dialog-title>
    <span>{{ 'CONDITIONS_OF_USE' | translate }}</span>
    <button mat-icon-button mat-dialog-close>
        <fa-icon *ngIf="data['validation']"
                 icon="times"></fa-icon>
    </button>
</div>

<div mat-dialog-content>
    <div class="custom-scrollbar">
        <p>
            Thank you for using the Airbus W&commat;Y (Where Are You?) solution.
        </p>
        <p class="condition--bold">
            Before using, please read
            carefully the Terms of Service.
        </p>
        <p class="condition--bold">
            You agree to use W&commat;Y for Airbus activities purposes only and in
            accordance with the relevant Airbus policies, including ICT Charter and security policies
            released from time to time by Airbus, and those Terms of Service.
        </p>
        <p> The "Where Are You"
            application ("W&commat;Y") has been designed to track and localize parts and tools owned or controlled
            by Airbus or with the authorization of the owner of such parts and tools ("Item") as displayed
            on OpenStreetMap (powered by ESRI) by using the Tracking Devices which are physically attached
            to the Items.
            <br>
            The Tracking Device is an electronic system attached to the Item using GPS and
            Bluetooth capabilities for the Item location and sending / receiving messages to / from the
            network.
        </p>
        <p class="condition--bold">
            When using the W&commat;Y application, you shall not (or allow those acting on your behalf
            to):
        </p>
        <ul>
            <li>
                Use W&commat;Y and/or equip any Item with a Tracking Device without Airbus formal prior
                authorization
            </li>
            <li>
                Equip with a Tracking Device and use W&commat;Y to track individuals and any living
                being (e.g. animals)
            </li>
            <li>
                Equip with a Tracking Device and use W&commat;Y for non-professional and non-Airbus purposes
            </li>
            <li>
                Equip with a Tracking Device any Item once embedded in an aircraft
                (or only use the certified Tracking Device and Battery models indicated in the User
                Guide
                for aircraft boarding)
            </li>
            <li>
                Equip with a Tracking Device any Item to be stored or transit in an
                explosive atmosphere (or only use the certified Tracking Device and Battery models
                indicated in
                the User Guide for ATEX environments)
            </li>
            <li>
                Use data collected by using W&commat;Y solely and strictly in
                accordance with Airbus directive and authorization
            </li>
            <li>
                Redistribute, copy, give access to or sell
                any Tracking Device or W&commat;Y, including data collected through W&commat;Y, to any third party and
                to Airbus employee not expressly authorized to access to it
            </li>
            <li>
                Download massively the data the
                content.
            </li>
        </ul>
        <p>
            When you use the W&commat;Y application data, Items locations OpenStreetMap (powered by
            ESRI) data and content ( <a href="https://www.esri.com/">https://www.esri.com/</a> )
            you may find that
            actual data
            and
            conditions differ from the application, map results and content and therefore you shall
            perform an
            independent judgment before using information resulting from the use of the W&commat;Y
            application.
            <br>
            W&commat;Y
            provides you an assistance but is not in substitution of your decision making which remains
            under your sole responsibility.
            <br>

            You are responsible at all times for your conduct and its
            consequences, and shall read the User Guide before using the application.

        </p>
    </div>
</div>

<div mat-dialog-actions
     *ngIf="!data['validation']">
    <button mat-flat-button
            color="primary"
            [disabled]="ongoingRequest"
            (click)="validate()">
        {{ 'I_AGREE' | translate }}
        <mat-progress-spinner *ngIf="ongoingRequest"
                              mode="indeterminate"
                              diameter="16"
                              strokeWidth="2">
        </mat-progress-spinner>
    </button>
</div>
