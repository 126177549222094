import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Alert } from '../models/alert';

export interface CreateAlert {
    parentFamilyId: string;
    familyId?: string;
    pn?: string;
    assetId?: string;
    trackerId?: string;
    name: string;
    type: string;
    zone?: {
        zone1: string;
        zone2?: string;
        zone3?: string;
    };
    durationValue?: number;
    durationUnit?: string;
    scope: string;
    active: boolean;
    interfaces: string[];
}
export interface UpdateAlert {
    id: string;
    parentFamilyId: string;
    familyId?: string;
    pn?: string;
    assetId?: string;
    trackerId?: string;
    name: string;
    type: string;
    zone?: {
        zone1: string;
        zone2?: string;
        zone3?: string;
    };
    durationValue?: number;
    durationUnit?: string;
    scope: string;
    active: boolean;
    interfaces: string[];
}

export interface UpdateActive {
    active: boolean;
}

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(private http: HttpClient) { }

    public getAlerts(): Observable<Alert[]> {
        return this.http.get<Alert[]>(`${environment.API_ENDPOINT}/alarms`);
    }

    public createAlert(alert: CreateAlert): Observable<Alert> {
        return this.http.post<Alert>(`${environment.API_ENDPOINT}/alarm`, JSON.stringify(alert), httpOptions);
    }

    public updateAlert(alert: UpdateAlert): Observable<Alert> {
        return this.http.put<Alert>(`${environment.API_ENDPOINT}/alarm/${alert.id}`, JSON.stringify((alert)), httpOptions);
    }

    public muteUnmute(alertId: string, payload: UpdateActive): Observable<Alert> {
        return this.http.put<Alert>(`${environment.API_ENDPOINT}/alarm/${alertId}/active`, payload, httpOptions);
    }

    public deleteAlert(alertId: string, scope: string): Observable<Alert> {
        return this.http.delete<Alert>(`${environment.API_ENDPOINT}/alarm/${alertId}/scope/${scope}`);
    }
}
