import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { KpiFamily } from 'src/app/services/kpi.service';
import { ChartClickEvent } from '../../shared/kpi/chart.base';
@Component({
  selector: 'app-kpi-loop-time-monitoring',
  templateUrl: './kpi-loop-time-monitoring.component.html',
  styleUrls: ['./kpi-loop-time-monitoring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiLoopTimeMonitoringComponent {
  @Input() public dataKPIloop: KpiFamily;
  @Input() public loaded: boolean;

  constructor(
    private _cd: ChangeDetectorRef
  ) { }

  public zoneLevel2 = false;
  public indexAssetClicked: number;
  public indexCycleClicked: number;

  public onCheckBoxChange(): void {
    this._cd.detectChanges();
  }

  public onAssetChartClick({ item }: ChartClickEvent): void {
    if (item?.[0]) {
      this.indexAssetClicked = item[0].index;
      this._cd.detectChanges();
    }
  }

  public onCycleChartClick({ item }: ChartClickEvent): void {
    if (item?.[0]) {
      this.indexCycleClicked = item[0].index;
      this._cd.detectChanges();
    }
  }

}
