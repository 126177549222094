<div mat-dialog-title>
    <span>{{'CONFIGURE_YOUR_CONTEXT_TITLE' | translate}}</span>
</div>
<div mat-dialog-content>
    <div class="content-title">
        <span>{{'CONTEXT_CONFIGURE_TEXT' | translate}}</span>
        <button *ngIf="canSelectContext$ | async"
                mat-flat-button
                color="primary"
                (click)="selectExistingContext()">
            {{ 'SELECT_EXISTING_CONTEXT' | translate }}
        </button>
    </div>

    <div class="splitted">
        <section #formContainer
                 class="form-container">
            <!-- First Column -->
            <form [formGroup]="initFilterForm">
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{ 'CONTEXT_NAME' | translate }}
                    </mat-label>
                    <input matInput
                           #inputName
                           type="text"
                           formControlName="name" />
                    <mat-hint *ngIf="!initFilterForm.controls.name.errors">
                        <span class="hide-overflow"
                              appShowIfTruncated
                              [matTooltip]="'CONTEXT_NAME_REQUIRED_FOR_SAVE' | translate">
                            {{ "CONTEXT_NAME_REQUIRED_FOR_SAVE" | translate }}
                        </span>
                    </mat-hint>
                    <mat-error *ngIf="initFilterForm.controls.name.errors?.required">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ "REQUIRED_INPUT" | translate }}
                    </mat-error>
                    <mat-error *ngIf="initFilterForm.controls.name.errors?.alreadyExists">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ "NAME_ALREADY_EXISTS" | translate }}
                    </mat-error>
                </mat-form-field>

                <!-- Filter Form -->
                <div class="form-section">
                    <span class="section-title">{{ "FILTER" | translate }}</span>
                    <div class="filter-header-container">
                        <button mat-stroked-button
                                color="primary"
                                type="button"
                                [disabled]="contextDataLoading$ | async"
                                (click)="resetFilter()">
                            <fa-icon icon="undo"
                                     [fixedWidth]="true"></fa-icon>
                            &nbsp;{{'CLEAR' | translate}}
                        </button>
                    </div>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'COMPANY' | translate }}</mat-label>
                    <mat-select formControlName="companies"
                                multiple>
                        <mat-select-trigger>
                            {{ selections.companies$ | async }}
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                                   [formControl]="searchControls.controls.companies"
                                                   [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate"
                                                   [showToggleAllCheckbox]="true"
                                                   [toggleAllCheckboxChecked]="companies$ | async | listSelectionCheckboxState:initFilterForm.value.companies:ListSelectionState.all"
                                                   [toggleAllCheckboxIndeterminate]="companies$ | async | listSelectionCheckboxState:initFilterForm.value.companies:ListSelectionState.partial"
                                                   (toggleAll)="toggleAll($event, initFilterForm.controls.companies, companies$, searchControls.controls.companies)">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let company of companies$
                                            | async
                                            | sort:false:'name'
                                            | listFilter:'name':searchControls.value.companies:true:true"
                                    [value]="company.id">
                            {{ company.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'SITE' | translate }}</mat-label>
                    <mat-select formControlName="sites"
                                multiple>
                        <mat-select-trigger>
                            {{ selections.sites$ | async }}
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                                   [formControl]="searchControls.controls.sites"
                                                   [showToggleAllCheckbox]="true"
                                                   [toggleAllCheckboxChecked]="sites$ | async | listSelectionCheckboxState:initFilterForm.value.sites:ListSelectionState.all"
                                                   [toggleAllCheckboxIndeterminate]="sites$ | async | listSelectionCheckboxState:initFilterForm.value.sites:ListSelectionState.partial"
                                                   (toggleAll)="toggleAll($event, initFilterForm.controls.sites, sites$, searchControls.controls.sites)"
                                                   [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let site of sites$
                                            | async
                                            | sort:false:'name'
                                            | listFilter:'name':searchControls.value.sites:true:true"
                                    [value]="site.id">
                            <span>{{ site.name }}</span><br>
                            <small *ngIf="site.company as company">{{ company.name }}</small>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'PARENT_FAMILY' | translate }}</mat-label>
                    <mat-select formControlName="parentFamilies"
                                multiple>
                        <mat-select-trigger>
                            {{ selections.parentFamilies$ | async }}
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                                   [formControl]="searchControls.controls.parentFamilies"
                                                   [showToggleAllCheckbox]="true"
                                                   [toggleAllCheckboxChecked]="parentFamilies$ | async | listSelectionCheckboxState:initFilterForm.value.parentFamilies:ListSelectionState.all"
                                                   [toggleAllCheckboxIndeterminate]="parentFamilies$ | async | listSelectionCheckboxState:initFilterForm.value.parentFamilies:ListSelectionState.partial"
                                                   (toggleAll)="toggleAll($event, initFilterForm.controls.parentFamilies, parentFamilies$, searchControls.controls.parentFamilies)"
                                                   [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let parentFamily of parentFamilies$
                                            | async
                                            | sort:false:'name'
                                            | listFilter:'name':searchControls.value.parentFamilies:true:true"
                                    [value]="parentFamily.id">
                            <span>{{ parentFamily.name }}</span><br>
                            <small *ngIf="parentFamily.site as site">{{ site.name }}</small>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'FAMILY' | translate }}</mat-label>
                    <mat-select formControlName="families"
                                multiple>
                        <mat-select-trigger>
                            {{ selections.families$ | async }}
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                                   [showToggleAllCheckbox]="true"
                                                   [toggleAllCheckboxChecked]="families$ | async | listSelectionCheckboxState:initFilterForm.value.families:ListSelectionState.all"
                                                   [toggleAllCheckboxIndeterminate]="families$ | async | listSelectionCheckboxState:initFilterForm.value.families:ListSelectionState.partial"
                                                   (toggleAll)="toggleAll($event, initFilterForm.controls.families, families$, searchControls.controls.families)"
                                                   [formControl]="searchControls.controls.families"
                                                   [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let family of families$
                                            | async
                                            | sort:false:'name'
                                            | listFilter:'name':searchControls.value.families:true:true"
                                    [value]="family.id">
                            <span>{{ family.name }}</span><br>
                            <small *ngIf="family.parentFamily as pf">{{ pf.name }}</small>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'PROVIDER' | translate }}</mat-label>
                    <mat-select formControlName="providers"
                                multiple>
                        <mat-select-trigger>
                            {{ selections.providers$ | async }}
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                                   [formControl]="searchControls.controls.providers"
                                                   [showToggleAllCheckbox]="true"
                                                   [toggleAllCheckboxChecked]="providers$ | async | listSelectionCheckboxState:initFilterForm.value.providers:ListSelectionState.all"
                                                   [toggleAllCheckboxIndeterminate]="providers$ | async | listSelectionCheckboxState:initFilterForm.value.providers:ListSelectionState.partial"
                                                   (toggleAll)="toggleAll($event, initFilterForm.controls.providers, providers$, searchControls.controls.providers)"
                                                   [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let provider of providers$
                                            | async
                                            | sort
                                            | listFilter:null:searchControls.value.providers:true:true"
                                    [value]="provider">
                            {{ provider || ('NO_DEVICE' | translate) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </form>
        </section>
        <!-- Second Column -->

        <div class="column column-context">
            <app-asset-quantity [assetQuantity]="totalAssetCount$ | async"
                                [loading]="contextDataLoading$ | async"></app-asset-quantity>
        </div>
    </div>
</div>

<!-- Action buttons-->
<div mat-dialog-actions>
    <span class="too-many-asset"
          *ngIf="initFilterForm.errors?.selectionTooLarge">
        {{ 'TOO_MANY_ASSETS' | translate }}
    </span>
    <button *ngIf="canCloseDialog$ | async"
            type="button"
            mat-stroked-button
            mat-dialog-close
            color="primary">
        {{ "CANCEL" | translate }}
    </button>
    <button type="submit"
            mat-flat-button
            color="primary"
            (click)="applyForSession()"
            [disabled]="initFilterForm.pristine || initFilterForm.invalid || (contextDataLoading$ | async) || submiting">
        <mat-icon>tune</mat-icon>
        {{ "APPLY_WITHOUT_SAVING" | translate }}
        <mat-progress-spinner *ngIf="submiting"
                              class="positive-action-icon"
                              color="primary"
                              mode="indeterminate"
                              diameter="16">
        </mat-progress-spinner>
    </button>
    <button type="submit"
            mat-flat-button
            color="primary"
            (click)="applyAndSave()"
            [disabled]="initFilterForm.pristine || initFilterForm.invalid || (contextDataLoading$ | async) || !initFilterForm.value.name || submiting">
        <mat-icon>save</mat-icon>
        {{ "SAVE_AND_APPLY" | translate }}
        <mat-progress-spinner *ngIf="submiting"
                              class="positive-action-icon"
                              color="primary"
                              mode="indeterminate"
                              diameter="16">
        </mat-progress-spinner>
    </button>
</div>
