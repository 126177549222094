import { createAction, props } from '@ngrx/store';
import { Site } from 'src/app/models/site';
import { Company } from 'src/app/models/company';

export const fetchAllSites = createAction('[site] fetch all Site');
export const successFetchAllSites = createAction('[site] success fetch all Site', props<{ sites: Site[] }>());
export const errorFetchAllSites = createAction('[site] failure Site');

export const mappingAllSitesCompanies = createAction('[site] mapping all sites companies', props<{ sites: Site[], companies: Company[] }>());
export const mappingSomeSitesCompanies = createAction('[site] mapping some sites companies', props<{ sites: Site[], companies: Company[] }>());

export const successCreateSites = createAction('[site] success create Site', props<{ payload: Site[] }>());
export const successUpdateSites = createAction('[site] success update Site', props<{ payload: Site[] }>());
export const successDeleteSites = createAction('[site] success delete Site', props<{ payload: Site[] }>());
