import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarComponent } from './../components/shared/snackbar/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ErrorTranslationService {

    constructor(
        private _translate: TranslateService,
        private _snackbar: SnackbarComponent
    ) { }

    /**
     * Check if a translation of error message code is available in i18n front files
     * If yes, use the translation to display in snackbar
     * If not, display the default error message in snackbar
     * @param httpError The error message received from the back end
     * @param defaultMessage The default message to display in case of translation missing
     */
    public handleError(httpError: HttpErrorResponse, defaultMessage: string): void {
        // Parse the stringified response from back end and get the messageCode property
        let messageCode = null;
        let error: string = null;
        try {
            const message: {
                messageCode: string;
                message?: any;
            } = JSON.parse(httpError?.error?.message);
            messageCode = message?.messageCode;
            error = message?.message?.message;
        } catch (err) {
            messageCode = defaultMessage;
        }
        const translateMessageCode = !messageCode ? messageCode : `${this._translate.instant(messageCode)}${error ? ' : ' + error : ''}`;
        if (translateMessageCode !== messageCode) {
            this._snackbar.open(translateMessageCode, 'red-snackbar', 5000);
        } else {
            this._snackbar.open(this._translate.instant(defaultMessage), 'red-snackbar', 5000);
        }
    }

    /**
     * Retreive Error Message inside back-end error
     * @param httpError error message received from backend call
     */
    public getMessageCode(httpError: HttpErrorResponse): string | null {
        try {
            return JSON.parse(httpError?.error?.message)?.messageCode || null;
        } catch (e) {
            return httpError?.error?.message?.messageCode || null;
        }
    }


    /**
     * Call the error snackbar with params needed for a translation
     * @param msg Code to translate
     * @param params Object params of translation
     */
    public openSnackBarErrorWithParams(msg: string, params: Record<string, string>): void {
        const text = this._translate.instant(msg, params) || this._translate.instant('INTERNAL_ERROR');
        this._snackbar.open(text, 'red-snackbar', 5000);
    }
}
