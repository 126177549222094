import { createSelector } from '@ngrx/store';
import { AppState } from '../../store';

export const selectCompaniesState = (state: AppState) => state.companies;

export const selectCompanies$ = createSelector(selectCompaniesState, (companies) => companies?.data);

export const selectCompaniesState$ = createSelector(selectCompaniesState, (companiesState) => companiesState);

export const selectCompaniesLoading$ = createSelector(selectCompaniesState, (companies) => companies?.loading);

export const selectCompaniesLoaded$ = createSelector(selectCompaniesState, (companies) => companies?.loaded);
