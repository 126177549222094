import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public tokenValid = new BehaviorSubject<boolean>(false);
    public acceptConditions = new BehaviorSubject<boolean>(false);
    loaded = false;
    accept = false;
    public userNg: string;

    constructor(public jwtHelper: JwtHelperService) { }
    // ...
    public isAuthenticated(): boolean {
        const idToken = this.getIdToken();
        // Check whether the token is expired and return
        // true or false
        if (!this.jwtHelper.isTokenExpired(idToken)) {
            this.setLoaded(true);
        }
        return !this.jwtHelper.isTokenExpired(idToken);
    }

    public setLoaded(value: boolean) {
        if (this.loaded) { return; }
        this.tokenValid.next(value);
        this.loaded = true;
    }

    public setAccept(value: boolean) {
        if (this.accept) { return; }
        this.acceptConditions.next(value);
        this.accept = true;
    }

    public getJwtToken() {
        return localStorage.getItem('token');
    }

    public setJwtToken(jwtToken: string) {
        localStorage.setItem('token', jwtToken);
    }

    public getIdToken() {
        return localStorage.getItem('id_token');
    }

    public setIdToken(idToken: string) {
        localStorage.setItem('id_token', idToken);
    }

}
