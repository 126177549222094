import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, } from '@angular/core';
import { KpiTimeInZone } from 'src/app/services/kpi.service';
import { ChartClickEvent } from '../../shared/kpi/chart.base';

@Component({
  selector: 'app-kpi-time-in-zone-monitoring',
  templateUrl: './kpi-time-in-zone-monitoring.component.html',
  styleUrls: ['./kpi-time-in-zone-monitoring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiTimeInZoneMonitoringComponent {
  @Input() public dataKPItimeinzone: KpiTimeInZone;
  @Input() public loaded: boolean;

  public idAssetClicked: string;

  constructor(
    private _cd: ChangeDetectorRef
  ) { }

  public onInZoneChartClick({ others }: ChartClickEvent): void {
    this.idAssetClicked = others.assetId;
    this._cd.detectChanges();
  }

}
