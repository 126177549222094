import { createSelector } from '@ngrx/store';
import { AppState } from '../../store';

export const selectFamilyRightState = (state: AppState) => state.familiesRight;

export const selectFamilyRightState$ = createSelector(selectFamilyRightState, (familiesRight) => familiesRight);

export const selectFamilyRight$ = createSelector(selectFamilyRightState, (familiesRight) => familiesRight.data);

export const selectFamilyRightLoading$ = createSelector(selectFamilyRightState, (familiesRight) => familiesRight.loading);

export const selectFamilyRightLoaded$ = createSelector(selectFamilyRightState, (familiesRight) => familiesRight.loaded);
