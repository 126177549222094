import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest, distinctUntilChanged, firstValueFrom, map, startWith } from 'rxjs';
import { Family, ParentFamily } from 'src/app/models/family';
import { Tracker } from 'src/app/models/tracker';
import { selectFamilyArray$ } from 'src/app/store/selectors/family.selectors';
import { selectParentFamilyArray$ } from 'src/app/store/selectors/parent-family.selectors';

@Component({
    selector: 'app-tracker-associate',
    templateUrl: './associate.component.html',
    styleUrls: ['./associate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociateComponent implements OnInit, OnDestroy {

    // #region Injections
    private _fb = inject(FormBuilder);
    private store = inject(Store);
    // #endregion

    @Input() tracker: Tracker;
    @Input() formGroup: FormGroup<{
        parentFamily: FormControl<ParentFamily['id']>;
        family: FormControl<Family>;
        msn: FormControl<string>;
        side: FormControl<'G'|'D'>; // G = left (gauche), D = right (droit)
    }> = this._fb.group({
        parentFamily: this._fb.control(null, [Validators.required]),
        family: this._fb.control(null, [Validators.required]),
        msn: this._fb.control(null, [Validators.required]),
        side: this._fb.control(null, [Validators.required]),
    });

    public searchForm: FormGroup<{
        parentFamily: FormControl<string>;
        family: FormControl<string>;
    }> = this._fb.group({
        parentFamily: this._fb.control(''),
        family: this._fb.control(''),
    });

    public families$ = this.store.select(selectFamilyArray$).pipe(
        map(families => families.filter(
            fam => fam.side
        )),
    );

    public parentFamilies$: Observable<ParentFamily[]> = combineLatest([
        this.store.select(selectParentFamilyArray$),
        this.families$,
    ]).pipe(
        map(
            ([parentFamilies, families]) => parentFamilies.filter(
                parentFam => families.some(
                    family => family.parent_family.id === parentFam.id
                )
            )
        ),
    );

    private _subscription = new Subscription();

    ngOnInit(): void {
        this._subscription.add(
            this.formGroup.controls.parentFamily.valueChanges.pipe(
                startWith(null),
                distinctUntilChanged(),
            ).subscribe(
                async v => {
                    this.formGroup.patchValue({ family: null });
                    if (v) {
                        const families = (await firstValueFrom(this.families$)).filter(
                            (family) => family.parentFamilyId === v
                        );
                        if (families.length === 1) {
                            this.formGroup.patchValue({ family: families[0] });
                        }
                    }
                    this.formGroup.controls.family[this.formGroup.get('parentFamily').value ? 'enable' : 'disable']();
                }
            )
        );
        this._subscription.add(
            this.formGroup.controls.family.valueChanges.pipe(
                startWith(null),
                distinctUntilChanged(),
            ).subscribe(
                async v => {
                    this.formGroup.controls.msn[v ? 'enable' : 'disable']();
                    this.formGroup.controls.side[v ? 'enable' : 'disable']();

                }
            )
        )
    }

    public trackById(item) {
        return item.id;
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

}
