import { createReducer, on } from '@ngrx/store';
import { UsageMetrics } from 'src/app/models/usage-metrics';
import { errorFetchUsageMetrics, fetchUsageMetrics, successFetchUsageMetrics } from '../actions/usage-metrics.action';

export interface State {
    data: Array<UsageMetrics>;
    loading: boolean;
    loaded: boolean;
}

// default state
const initialState: State = {
    data: [],
    loading: false,
    loaded: false,
};

export const reducer = createReducer(
    initialState,
    on(fetchUsageMetrics, (state): State => {
        return {
            ...state,
            loaded: false,
            loading: true,
        };
    }),
    on(successFetchUsageMetrics, (state, action): State => {
        return {
            ...state,
            loaded: true,
            loading: false,
            data: action.payload
        };
    }),
    on(errorFetchUsageMetrics, (state): State => {
        return {
            ...state,
            loaded: true,
            loading: false
        };
    }),
);
