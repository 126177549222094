import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectProfiles$, selectProfilesLoaded$ } from '../store/selectors/profile.selectors';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DEFAULT_DIALOG_OPTIONS, DialogConfigureContextComponent } from '../components/init-filter/configure-context/dialog-configure-context.component';
import { MatDialog } from '@angular/material/dialog';
import { CONTEXT_DIALOG_OPENED, ContextService, MAX_ASSET_COUNT } from '../services/init-filter.service';
import { of } from 'rxjs';

export const hasContextGuard: CanActivateFn = () => {
    const store = inject(Store);
    const dialogOpened$ = inject(CONTEXT_DIALOG_OPENED);
    const dialog = inject(MatDialog);
    const initFilterdataService = inject(ContextService);

    return store.select(selectProfilesLoaded$).pipe(
        filter(isLoaded => isLoaded),
        switchMap(() => store.select(selectProfiles$)),
        switchMap(({ profiles }) => {
            if (!profiles.parent_families?.length && !profiles.families?.length) {
                // No rights set => no context selectionnable anyway
                return of(false);
            }
            if (profiles.assetCount > MAX_ASSET_COUNT) {
                return dialogOpened$.pipe(
                    take(1),
                    tap((v) => {
                        if (!v) {
                            initFilterdataService.contextConfigDialogRef = dialog.open(
                                DialogConfigureContextComponent,
                                {
                                    ...DEFAULT_DIALOG_OPTIONS,
                                    hasBackdrop: false,
                                    disableClose: true,
                                }
                            );
                        }
                    }),
                    map(() => true),
                );
            }
            return of(true);
        })
    );
}
