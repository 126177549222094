import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from './../../components/shared/snackbar/snackbar';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Subject, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PlanningService } from 'src/app/services/planning.service';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import {
    deletePlanning, editPlanning, errorDeletePlanning, errorEditPlanning, fetchPlanning,
    loadOperation,
    successDeletePlanning, successEditPlanning, successFetchPlanning, successLoadOperation, unloadOperation
} from '../actions/planning.action';
import { HttpErrorResponse } from '@angular/common/http';

export type EffectResult = 'success' | 'error';
@Injectable()
export class PlanningEffects {

    public fetchPlanning$ = createEffect(() => { return this._actions$.pipe(
        ofType(fetchPlanning),
        switchMap((action) => this._planningService.getPlanning(action.fromDate, action.toDate)),
        map((planning) => successFetchPlanning({ payload: planning }))
    ) });

    public editPlanning$ = createEffect(() => { return this._actions$.pipe(
        ofType(editPlanning),
        switchMap((action) => this._planningService.editPlanning(action.payload).pipe(
            map((planningEvent) => {
                const text = this._translate.instant('BANNER_SUCCESS_EDIT', { value: action.payload.transportAssetName ? action.payload.transportAssetName : '' });
                this._snackbar.open(text, 'green-snackbar', 5000);
                return successEditPlanning({ payload: [planningEvent] });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_EDIT');
                return of(errorEditPlanning());
            })
        ))) });

    public deletePlanning$ = createEffect(() => { return this._actions$.pipe(
        ofType(deletePlanning),
        switchMap((action) => this._planningService.deletePlanning(action.payload).pipe(
            map((planningListId) => {
                const text = this._translate.instant('BANNER_SUCCESS_DELETE', {
                    item: this._translate.instant('APP_TAB_PLANNING')
                });
                this._snackbar.open(text, 'green-snackbar', 5000);
                return successDeletePlanning({ payload: planningListId });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_DELETE');
                return of(errorDeletePlanning());
            })
        ))) });

    public loadOperation$ = createEffect(() => { return this._actions$.pipe(
        ofType(loadOperation),
        switchMap((action) => this._planningService.loadOperation(action.payload, action.assetId).pipe(
            map(() => {
                this.effectSubject.next('success');
                const text = this._translate.instant('ASSET_SUCCESS_LOADED');
                this._snackbar.open(text, 'green-snackbar', 5000);
                return successLoadOperation();
            }),
            catchError((error) => {
                this.effectSubject.next('error');
                let text: string;
                const messageCode = this._errorTranslationService.getMessageCode(error);
                switch (messageCode) {
                    case `UNAUTHORIZED_LOAD_UNLOAD_OPERATION`:
                      text = this._translate.instant('UNAUTHORIZED_LOAD_UNLOAD_OPERATION');
                      break;
                    case `INCONSISTENT_DATA`:
                      text = error?.message || 'BANNER_FAIL_LOAD_ASSET';
                      break;
                    default:
                      text = 'BANNER_FAIL_LOAD_ASSET';
                      break;
                  }
                  this._errorTranslationService.handleError(error, text);
                return of(errorEditPlanning());
            })
        ))) });

    public unloadOperation$ = createEffect(() => { return this._actions$.pipe(
        ofType(unloadOperation),
        switchMap((action) => this._planningService.unloadOperation(action.payload, action.assetId).pipe(
            map(() => {
                this.effectSubject.next('success');
                const text = this._translate.instant('ASSET_SUCCESS_UNLOADED');
                this._snackbar.open(text, 'green-snackbar', 5000);
                return successLoadOperation();
            }),
            catchError((error) => {
                this.effectSubject.next('error');
                let text: string;
                const messageCode = this._errorTranslationService.getMessageCode(error);
                switch (messageCode) {
                    case `UNAUTHORIZED_LOAD_UNLOAD_OPERATION`:
                        text = this._translate.instant('UNAUTHORIZED_LOAD_UNLOAD_OPERATION');
                        break;
                    case `INCONSISTENT_DATA`:
                        text = error?.message || 'BANNER_FAIL_LOAD_ASSET';
                        break;
                    default:
                      text = 'BANNER_FAIL_UNLOAD_ASSET';
                      break;
                  }
                  this._errorTranslationService.handleError(error, text);
                return of(errorEditPlanning());
            })
        ))) });

        public effectSubject: Subject<string | HttpErrorResponse>;
    constructor(
        private _planningService: PlanningService,
        private _actions$: Actions,
        private _snackbar: SnackbarComponent,
        private _translate: TranslateService,
        private _errorTranslationService: ErrorTranslationService,
    ) {
        this.effectSubject = new Subject<string>();
    }
}
