<header mat-dialog-title>
    <span>{{ "STATUS" | translate }}</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</header>
<div class="linear-steps"
     mat-dialog-content>
    <span class="expiration">
        {{ "CERTIFICATEEXPIRATIONDATE" | translate }}: {{ progress.expiration | moment:'LL' }}
    </span>
    <div class="stage">
        <label class="step">
            {{ "STATUS1" | translate }}
        </label>
        <app-status-step class="icon"
                         [status]="progress.status1"></app-status-step>
        <label class="status">
            {{ progress.status1 | uppercase | translate }}
        </label>
        <app-status-separator class="separator"
                              [previousStatus]="progress.status1"
                              direction="vertical"
                              [nextStatus]="progress.status2"></app-status-separator>
    </div>
    <div class="stage">
        <label class="step">
            {{ "STATUS2" | translate }}
        </label>
        <app-status-step class="icon"
                         [status]="progress.status2"></app-status-step>
        <label class="status">
            {{ progress.status2 | uppercase | translate }}
        </label>
        <app-status-separator class="separator"
                              [previousStatus]="progress.status2"
                              direction="vertical"
                              [nextStatus]="progress.status3"></app-status-separator>
    </div>
    <div class="stage">
        <label class="step">
            {{ "STATUS3" | translate }}
        </label>
        <app-status-step class="icon"
                         [status]="progress.status3"></app-status-step>
        <label class="status">
            {{ progress.status3 | uppercase | translate }}
        </label>
        <app-status-separator class="separator"
                              [previousStatus]="progress.status3"
                              direction="vertical"
                              [nextStatus]="progress.status4"></app-status-separator>
    </div>
    <div class="stage">
        <label class="step">
            {{ "STATUS4" | translate }}
        </label>
        <app-status-step class="icon"
                         [status]="progress.status4"></app-status-step>
        <label class="status">
            {{ progress.status4 | uppercase | translate }}
        </label>
    </div>
</div>
