import { Pipe, PipeTransform } from '@angular/core';
import { SignalSources } from '../models/signal-source';

@Pipe({
    name: 'eventSourceName',
    pure: true,
})
export class EventSourceNamePipe implements PipeTransform {
    transform(assetSource: string): string {
        switch (assetSource.toLowerCase()) {
            case SignalSources.GO2SCAN:
                return 'GoToScan';
            default:
                return assetSource;
        }
    }

}
