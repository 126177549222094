<span *ngIf="(tracker?.batteryLevel | typeOf) === 'number' || (tracker?.batteryLife | typeOf) === 'number'">
    <fa-icon *ngIf="showBatteryIcon"
             [icon]="batteryIcon"></fa-icon>
    <ng-container *ngIf="batteryLabel">
        {{ batteryLabel }}
    </ng-container>
    <ng-container *ngIf="(tracker.batteryLevel | typeOf) === 'number'">
        <ng-container *ngIf="batteryLabel">&nbsp;&#448;&nbsp;</ng-container>
        {{ (tracker.batteryLevel / 100) | percent }}
    </ng-container>
</span>
