<div mat-dialog-title>
    <span>{{ 'DIALOG_EDIT_AIRCRAFT_PLANNING' | translate }}</span>
    <button mat-icon-button mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<form [formGroup]="editPlanningAircraftForm"
      (ngSubmit)="editPlanningAircraft()">

    <div class="custom-scrollbar" mat-dialog-content>

        <mat-form-field appearance="outline" appearance="outline">
            <mat-label>{{ 'ARRIVALTIME' | translate }}</mat-label>
            <input matInput
                   [mtxDatetimepicker]="picker"
                   formControlName="arrivalTime"
                   [min]="minDate">
            <mtx-datetimepicker-toggle [for]="picker"
                                       matSuffix></mtx-datetimepicker-toggle>
            <mtx-datetimepicker #picker
                                multiYearSelector></mtx-datetimepicker>
            <mat-error *ngIf="editPlanningAircraftForm.controls.arrivalTime.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_INPUT" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" appearance="outline">
            <mat-label>{{ 'FUTUREPOSITION' | translate }}</mat-label>
            <mat-select formControlName="futurePosition">
                <mat-option>
                    <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                           [formControl]="searchZoneForm"
                                           [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                    <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                </mat-option>
                <mat-option *ngFor="let zone of (locationFiltered
                                    | async
                                    | listFilter:'name':searchZoneForm.value:true:true
                                    | orderBy:'name')"
                            [value]="zone.id">
                    {{zone.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="editPlanningAircraftForm.controls.futurePosition.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_SELECTION" | translate }}
            </mat-error>
            <mat-error *ngIf="editPlanningAircraftForm.controls.futurePosition.errors?.invalid">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "INVALID_SELECTION" | translate }}
            </mat-error>
            <mat-hint *ngIf="(locationLoading$ | async) === true">
                <mat-progress-spinner mode="indeterminate"
                                      diameter="12"
                                      strokeWidth="2"></mat-progress-spinner>
                {{ "LOADING_DATA" | translate }}
            </mat-hint>
        </mat-form-field>

    </div>

    <div mat-dialog-actions>
        <button mat-stroked-button
                mat-dialog-close
                type="button"
                color="primary">
            {{ 'CANCEL' | translate }}
        </button>
        <button type="submit"
                mat-flat-button
                color="primary"
                [disabled]="!editPlanningAircraftForm?.valid || loading">
            {{ 'APPLY' | translate }}
            <mat-progress-spinner *ngIf="loading"
                                  class="positive-action-icon"
                                  color="primary"
                                  mode="indeterminate"
                                  diameter="16">
            </mat-progress-spinner>
        </button>
    </div>

</form>
