import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { State } from '../reducers/asset.reducer';
import { Asset } from 'src/app/models/asset';

export const selectAssetState = (state: AppState) => state.assets;

export const selectAssets$ = createSelector(selectAssetState, (assets) => assets.data);

export const selectAssetsArray$ = createSelector(selectAssetState, (assets) => Object.values(assets.data.assets));

export const selectAssetsById$ = (assetId: string) => createSelector(selectAssetState, state => state.data.assets[assetId]);

export const selectAssetsInParentFamily$ = createSelector(selectAssetState, (assetsState: State, props: { parentFamilyId: string }) => {
    return Object.values(assetsState.data.assets).filter(asset => props.parentFamilyId === asset.family.parent_family.id);
});

export const selectAssetsInFamily$ = createSelector(selectAssetState, (assetsState: State, props: { familyId: string }) => {
    return Object.values(assetsState.data.assets).filter(asset => props.familyId === asset.familyId);
});

export const selectAssetState$ = createSelector(selectAssetState, (state) => state);

export const selectAssetLoaded$ = createSelector(selectAssetState, (assets) => assets.loaded);

export const selectAssetReady$ = createSelector(selectAssetState, (assets) => assets.ready);

export const selectAssetsByFamiliesIdsAndSns = (ids, sns) => createSelector(selectAssetState, (assets) => {
    return Object.values(assets.data.assets).filter((asset) => ids.indexOf(asset.family.id) > -1 && sns.indexOf(asset.sn) > -1);
});

export const selectAssetsByPns = (pns) => createSelector(selectAssetState, (assets) => {
    if (assets && assets.data) {
        const assetsArray = [];
        for (const asset of Object.values(assets.data.assets)) {
            for (const pn of pns) {
                if (Array.isArray(asset.pn) && asset.pn.indexOf(pn) > -1) {
                    assetsArray.push(asset);
                }
                if (!Array.isArray(asset.pn) && asset.pn === pn) {
                    assetsArray.push(asset);
                }
            }
        }
        return assetsArray;
    }
});

export const selectAssetsByFamiliesIds = (ids: string[]) => createSelector(selectAssetState, (assets) => {
    return Object.values(assets.data.assets).filter(asset => ids.includes(asset.familyId));
});

export const selectAssetByTrackerId = (id: string) => createSelector((state: AppState) => state, (appState) => {
    const trackers = appState.trackers.data.trackers;
    const assets = appState.assets.data.assets;
    const assetId = trackers[id]?.assetId;
    return assetId ? assets[assetId] : undefined;
});

export const selectTransporterOfAsset$ = (id: Asset['id']) => createSelector(selectAssetsArray$, (assets) => {
    return assets.find(
        ({ loadedElements }) => loadedElements?.some(
            ({ asset_id }) => asset_id === id
        )
    );
});
