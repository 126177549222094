import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectProfileUserRights$, selectProfilesLoaded$ } from '../store/selectors/profile.selectors';
import { filter, map, switchMap } from 'rxjs/operators';

export const scopeGuard: CanActivateFn = (route) => {
    // #region Injections
    const store = inject(Store);
    const router = inject(Router);
    // #endregion

    // The role to check is sent throught the data property from app routing module
    const roleToCheck = route.data.role;

    return store.select(selectProfilesLoaded$).pipe(
        filter(loaded => loaded),
        switchMap(() => store.select(selectProfileUserRights$)),
        map(userRight => {
            // Check if the role to check is set to true in userRights store
            if (userRight.scope.includes(roleToCheck)) {
                return true;
            } else {
                router.navigate(['/']);
                return false;
            }
        }),
    );
};
