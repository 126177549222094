import { Component } from '@angular/core';

@Component({
    selector: 'app-more-kpi',
    template: `
    <div class="placeholder-more-kpis">
        <fa-icon icon="info-circle"></fa-icon>
        <span>{{ 'KPI_SELECT_ELEMENT' | translate }}</span>
    </div>
    `,
    styleUrls: ['./more-kpi.component.scss']
})
export class MoreKpiComponent {}
