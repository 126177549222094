<div mat-dialog-title>
    <span>{{"MANAGE_RIGHT_MODIFY_ROLE" | translate}}</span>
    <button mat-icon-button
            (click)="onClickCancel()">
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<div class="container">
    <div mat-dialog-content>

        <p>{{ "ROLE" | translate }}</p>

        <mat-form-field appearance="outline">
            <mat-select [(value)]="selectedRole"
                        [placeholder]="'MANAGE_RIGHT_SELECT_ROLE' | translate">
                <mat-option *ngFor="let role of roles"
                            [value]="role.value">{{ role.value | translate }}</mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <div mat-dialog-actions>
        <button mat-stroked-button
                color="primary"
                (click)="onClickCancel()">
            {{ "CANCEL" | translate }}
        </button>
        <button mat-flat-button
                color="primary"
                [disabled]="getUpdateDisabled()"
                (click)="onClickUpdate()">
            {{ "UPDATE" | translate }}
        </button>
    </div>

</div>
