import { createAction, props } from '@ngrx/store';
import { Alert } from 'src/app/models/alert';
import { CreateAlert, UpdateAlert } from 'src/app/services/alert.service';

export const fetchOneAlert = createAction('[alert] Load Admin Alert');
export const successFetchOneAlert = createAction('[alert] Success Admin Alert', props<{ payload: Alert }>());
export const errorFetchOneAlert = createAction('[alert] Error Admin Alert');
export const deleteAlert = createAction('[alert] Delete Admin Alert', props<{ payload: string }>());

export const fetchAllAlerts = createAction('[alert] Load Admin Alerts');
export const successFetchAllAlerts = createAction('[alert] Success Admin Alerts', props<{ payload: Alert[] }>());
export const errorFetchAllAlerts = createAction('[alert] Error Admin Alerts');

export const createAlert = createAction('[alert] Create Alert', props<{ payload: CreateAlert }>());
export const createAlertSuccess = createAction('[alert] Success Create Alert', props<{ payload: Alert }>());
export const createAlertError = createAction('[alert] Error Create Alert');

export const editAlert = createAction('[alert] Edit Alert', props<{ payload: UpdateAlert }>());
export const editAlertSuccess = createAction('[alert] Success Edit Alert', props<{ payload: Alert }>());
export const editAlertError = createAction('[alert] Error Edit Alert');

