import { createSelector } from '@ngrx/store';
import { AppState } from '../../store';

export const selectTrackerState = (state: AppState) => state.trackers;

export const selectTrackerState$ = createSelector(selectTrackerState, (state) => state);

export const selectTrackers$ = createSelector(selectTrackerState, (trackers) => trackers.data);

export const selectTrackersArray$ = createSelector(selectTrackerState, (trackers) => Object.values(trackers.data.trackers));

export const selectTrackerById$ = (trackerid: string) => createSelector(selectTrackerState, (trackers) => trackers.data.trackers[trackerid]);

export const selectTrackerByAssetsIds$ = (assetsIds: string[]) => createSelector(selectTrackerState, (state) => {
    return Object.values(state.data.trackers).filter(tracker => {
        return assetsIds.includes(tracker.assetId);
    });
});

export const selectTrackerLoaded$ = createSelector(selectTrackerState, (trackers) => trackers.loaded);
