import {
    errorFetchAllAnchors,
    fetchAllAnchors,
    successFetchAllAnchors,
    updateAnchor,
    updateAnchorSuccess,
    updateAnchortError
} from '../actions/anchor.action';
import { Action, createReducer, on } from '@ngrx/store';
import { Anchor } from 'src/app/models/anchor';

export interface State {
    data: Array<Anchor>;
    loading: boolean;
    loaded: boolean;
}

// default state
const INIT_STATE: State = {
    data: [],
    loading: false,
    loaded: false,
};

const AnchorsReducer = createReducer(
    INIT_STATE,
    on(fetchAllAnchors, (state): State => ({
        ...state, loading: true,
    })),
    on(successFetchAllAnchors, (state, { anchor }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: (() => {
            state.data = anchor;
            return state.data;
        })()
    })),
    on(errorFetchAllAnchors, (state): State => ({
            ...state, loading: false,
        })),
    on(updateAnchor, (state): State => ({
        ...state, loading: true,
    })),
    on(updateAnchorSuccess, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        data: (() => {
                const datas = [...state.data];
                const anchorToUpdate = payload;
                    const anchorIndex = datas.findIndex(_anchor => _anchor.id === anchorToUpdate.id);
                    if (anchorIndex > -1) {
                        datas[anchorIndex] = anchorToUpdate;
                    }
                return datas;
            })()
    })),
    on(updateAnchortError, (state): State => ({
        ...state, loading: false,
    })),
);

export function reducer(state: State | undefined, action: Action) {
    return AnchorsReducer(state, action);
}
