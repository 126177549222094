import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ValidationDialogData {
    title: string;
    cancelFunction?: () => void;
    textContent: string;
    cancelText?: string;
    validateText?: string;
    dangerousAction?: boolean;
}

@Component({
  selector: 'app-dialog-validation',
  templateUrl: './dialog-validation.component.html',
  styleUrls: ['./dialog-validation.component.scss']
})
export class DialogValidationComponent {

  public loading: boolean;
  public onValidateEvent = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<DialogValidationComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: ValidationDialogData,
  ) {
    this.loading = false;
  }

  // Action OnValidate Dialog
  public onValidate() {
    // Emit Event on Validate Button
    this.loading = true;
    this.onValidateEvent.emit();
  }

  // Action On Cancel Dialog
  public onCancel(): void {
    if (this.inputData.cancelFunction) {
      this.inputData.cancelFunction();
    }
    this.close();
  }

  // Function to call when process ended, disable loading mode
  public finishProcess(error?) {
    this.loading = false;
    if (!error) {
      this.close();
    }
  }

  // Close popup
  public close(): void {
    this.onValidateEvent.complete();
    this.dialogRef.close();
  }
}
