export interface UsageMetrics {
    id: string;
    dates: string[];
}

export interface UsageMetricsDataFormat {
    scope: string;
    parentFamilyId: string[];
    assetFamilyId: string[];
    groupId: string;
    startDate: string;
    endDate: string;
}

export enum MetricsType {
    TotalConnectedUsers
}

export const MetricsCardValues: { [type in MetricsType]: MetricsCard } = {
    [MetricsType.TotalConnectedUsers]: {
        type: MetricsType.TotalConnectedUsers,
        class: 'metrics-card-primary',
        count: 0,
        text: 'METRICS_TOTAL_CONNECTED_USERS_TEXT'
    }
};

export interface MetricsCard {
    type: MetricsType;
    class: string;
    count?: number;
    text: string;
    color?: string;
}

export enum UsageMetricScale {
    DAY,
    WEEK,
    MONTH,
}

export enum RangesType {
    lastWeek,
    lastMonth,
    lastQuarter,
    lastYear,
    custom,
}
