import { createAction, props } from '@ngrx/store';
import { ProfileFamily, ProfileParentFamily, Profile } from 'src/app/models/profile';

export const fetchAllProfiles = createAction('[Profiles] Load Profiles');
export const successFetchAllProfiles = createAction('[Profiles] Success Load Profiles', props<{ profile: Profile }>());
export const errorFetchAllProfiles = createAction('[Profiles] Error Load Profiles');

export const addFamilyProfile = createAction('[Profiles] Add family profiles', props<{ profileFamily: ProfileFamily }>());

export const addParentFamilyProfile = createAction('[Profiles] Add parent family profiles', props<{ profileParentFamily: ProfileParentFamily }>());

export const updateIsOversizeRight = createAction('[Profiles] Update right isOversize', props<{ payload: boolean }>());

export const updateAssetCount = createAction('[Profiles] Update the asset count of the profile', props<{ added?: number; removed?: number}>());
