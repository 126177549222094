import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { AssetEffects } from './effects/asset.effects';
import * as fromAlertsReducer from './reducers/alert.reducer';
import { reducer as alertsReducer } from './reducers/alert.reducer';
import * as fromAssetHistoryReducer from './reducers/asset-history.reducer';
import { reducer as assetsHistoryReducer } from './reducers/asset-history.reducer';
import * as fromAssetAdminReducer from './reducers/asset.reducer';
import { reducer as assetsReducer } from './reducers/asset.reducer';
import * as fromBeaconLayerReducer from './reducers/beacon-layer.reducer';
import { reducer as beaconLayerReducer } from './reducers/beacon-layer.reducer';
import * as fromFamilyReducer from './reducers/family.reducer';
import { reducer as familiesReducer } from './reducers/family.reducer';
import * as fromParentFamilyReducer from './reducers/parent-family.reducer';
import { reducer as parentFamiliesReducer } from './reducers/parent-family.reducer';
import * as fromTrackerReducer from './reducers/tracker.reducer';
import { reducer as trackersReducer } from './reducers/tracker.reducer';
import * as fromFiltersReducer from './reducers/filter.reducer';
import { reducer as filtersReducer } from './reducers/filter.reducer';
import * as fromProfilesReducer from './reducers/profile.reducer';
import { reducer as profilesReducer } from './reducers/profile.reducer';
import * as fromSitesReducer from './reducers/site.reducer';
import { reducer as sitesReducer } from './reducers/site.reducer';
import * as fromCompaniesReducer from './reducers/company.reducer';
import { reducer as companiesReducer } from './reducers/company.reducer';
import * as fromZonesReducer from './reducers/zone.reducer';
import { reducer as zonesReducer } from './reducers/zone.reducer';
import * as fromMasterdataReducer from './reducers/masterdata.reducer';
import { reducer as masterdataReducer } from './reducers/masterdata.reducer';
import * as fromPlanningReducer from './reducers/planning.reducer';
import { reducer as planningReducer } from './reducers/planning.reducer';
import { PlanningEffects } from './effects/planning.effects';
import * as fromAircraftPlanningReducer from './reducers/aircraft-planning.reducer';
import { reducer as aircraftPlanningReducer } from './reducers/aircraft-planning.reducer';
import { AircraftPlanningEffects } from './effects/aircraft-planning.effects';
import { MasterDataEffects } from './effects/masterdata.effects';
import * as fromUserRightReducer from './reducers/user-right.reducer';
import { reducer as userRightReducer } from './reducers/user-right.reducer';
import * as fromCertificateReducer from './reducers/certificate.reducer';
import { reducer as certificateReducer } from './reducers/certificate.reducer';
import { CompanyEffects } from './effects/company.effects';
import { ParentFamilyEffects } from './effects/parent-family.effects';
import { FamilyEffects } from './effects/family.effects';
import { AlertEffects } from './effects/alert.effects';
import { CertificateEffects } from './effects/certificate.effects';
import { TrackerEffects } from './effects/tracker.effects';
import { ZoneEffects } from './effects/zone.effects';
import { SiteEffects } from './effects/site.effects';
import { FamilyRightEffects } from './effects/familyRight.effects';
import * as fromFamilyRightReducer from './reducers/familyRight.reducer';
import * as fromUsageMetricsReducer from './reducers/usage-metrics.reducer';
import { reducer as familiesRightReducer } from './reducers/familyRight.reducer';
import { UserRightEffects } from './effects/user-right.effects';
import { BeaconLayerEffects } from './effects/beacon-layer.effects';
import { reducer as usageMetricsReducer } from './reducers/usage-metrics.reducer';
import { UsageMetricsEffect } from './effects/usage-metrics.effects';
import * as fromAnchorsReducer from './reducers/anchor.reducer';
import { reducer as anchorsReducer } from './reducers/anchor.reducer';
import { AnchorEffects } from './effects/anchor.effects';
import * as fromInitFiltersReducer from './reducers/filter-context.reducer';
import { reducer as initFiltersReducer } from './reducers/filter-context.reducer';
import { FilterContextEffects } from './effects/filter-context.effects';
import { CacheEffects } from './effects/cache.effects';

// root reducer
const reducers = {
    assetsHistory: assetsHistoryReducer,
    assets: assetsReducer,
    trackers: trackersReducer,
    parentFamilies: parentFamiliesReducer,
    families: familiesReducer,
    familiesRight: familiesRightReducer,
    beaconLayer: beaconLayerReducer,
    alerts: alertsReducer,
    filters: filtersReducer,
    profiles: profilesReducer,
    sites: sitesReducer,
    companies: companiesReducer,
    zones: zonesReducer,
    masterdata: masterdataReducer,
    planning: planningReducer,
    aircraftPlanning: aircraftPlanningReducer,
    userRight: userRightReducer,
    certificates: certificateReducer,
    usageMetrics: usageMetricsReducer,
    anchors: anchorsReducer,
    initFilters: initFiltersReducer
};

export interface AppState {
    assetsHistory: fromAssetHistoryReducer.State;
    assets: fromAssetAdminReducer.State;
    trackers: fromTrackerReducer.State;
    parentFamilies: fromParentFamilyReducer.State;
    families: fromFamilyReducer.State;
    familiesRight: fromFamilyRightReducer.State;
    beaconLayer: fromBeaconLayerReducer.State;
    alerts: fromAlertsReducer.State;
    filters: fromFiltersReducer.State;
    profiles: fromProfilesReducer.State;
    sites: fromSitesReducer.State;
    companies: fromCompaniesReducer.State;
    zones: fromZonesReducer.State;
    masterdata: fromMasterdataReducer.State;
    planning: fromPlanningReducer.State;
    aircraftPlanning: fromAircraftPlanningReducer.State;
    userRight: fromUserRightReducer.State;
    certificates: fromCertificateReducer.State;
    usageMetrics: fromUsageMetricsReducer.State;
    anchors: fromAnchorsReducer.State;
    initFilters: fromInitFiltersReducer.State;
}
// Used for AOT
export function getReducers() {
    return reducers;
}
// Used for AOT
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers');
export const appEffects = [
    AlertEffects,
    AssetEffects,
    MasterDataEffects,
    PlanningEffects,
    AircraftPlanningEffects,
    CompanyEffects,
    ParentFamilyEffects,
    FamilyEffects,
    CertificateEffects,
    SiteEffects,
    TrackerEffects,
    FamilyRightEffects,
    ZoneEffects,
    UserRightEffects,
    BeaconLayerEffects,
    UsageMetricsEffect,
    AnchorEffects,
    FilterContextEffects,
    CacheEffects,
];
