import { Pipe, PipeTransform } from '@angular/core';
import { SignalSourceProviderValues } from '../models/signal-source';
import { Tracker } from '../models/tracker';

@Pipe({ name: 'deviceProvider' })
export class DeviceProviderPipe implements PipeTransform {
    transform(element: Tracker, ...providers: SignalSourceProviderValues[]): boolean {
        return (providers.includes(element.provider));
    }
}
