import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';
import { Certificate } from 'src/app/models/certificate';
import { CertificateService } from 'src/app/services/certificate.service';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { successUpdateCertificateRequest } from 'src/app/store/actions/certificate.action';

@Component({
    selector: 'app-dialog-certificate-totp',
    templateUrl: './dialog-certificate-totp.component.html',
    styleUrls: ['./dialog-certificate-totp.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogCertificateTotpComponent implements OnInit {

    @ViewChild('totp') totpDiv: ElementRef<HTMLDivElement>;
    @ViewChild('submitButton') submitButton: MatButton;
    public totpForm: FormGroup<{
        totp: FormArray<FormControl<number>>
    }>;
    public remainingTries: number;
    public requestInProgress = false;
    public errors = {
        invalidTotp: false,
        totpTooManyErrors: false,
    };

    private readonly TOTP_LENGTH = 6;

    public get totpFormArray() {
        return this.totpForm.get('totp') as FormArray;
    }

    public get totpCode(): string {
        return this.totpFormArray.controls.map(
            control => control.value
        ).join('');
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public certificate: Certificate,
        private _dialogRef: MatDialogRef<DialogCertificateTotpComponent>,
        private _certificateService: CertificateService,
        private store: Store,
        private _errorService: ErrorTranslationService,
        private _cd: ChangeDetectorRef,
        private _translate: TranslateService,
        private _fb: FormBuilder,
        private _snackbar: SnackbarComponent,
    ) {
        const totpFormControls: FormControl<number>[] = [];
        for (let i = 0; i < this.TOTP_LENGTH; i++) {
            totpFormControls.push(this._fb.control<number>(null, [
                Validators.required,
                Validators.pattern(/^\d$/),
                Validators.maxLength(1),
            ]));
        }
        this.totpForm = this._fb.group({
            totp: this._fb.array(totpFormControls)
        });
    }

    ngOnInit(): void {
        this._fetchRemainingTries();
    }

    /**
     * Get the quantity of remaining tries & display it
     */
    private _fetchRemainingTries(): void {
        this._certificateService.getCertificateTotpRemainingTries(this.certificate.id).subscribe(
            tries => {
                this.remainingTries = tries;
                this._cd.markForCheck();
            }
        );
    }

    /**
     * Focus the next input field in the totp form relative to the given index
     * Focus the button if the index is the last one of the form
     * @param index The index of the current element
     */
    private _focusNextFormEl(index: number, canFocusSubmitButton = true): void {
        const inputField = this.totpDiv.nativeElement.children[index + 1] as HTMLInputElement;
        if (inputField) {
            inputField?.focus();
            inputField?.select();
        } else if (canFocusSubmitButton) {
            this.submitButton.focus();
        }
        this._cd.markForCheck();
    }

    /**
     * Focus the previous input field in the totp form relative to the given index
     * @param index The index of the current element
     */
    private _focusPreviousFormEl(index: number): void {
        const inputField = this.totpDiv.nativeElement.children[index - 1] as HTMLInputElement;
        if (inputField) {
            inputField?.focus();
            inputField?.select();
        }
        this._cd.markForCheck();
    }

    /**
     * @param event The input event
     * @param index The index in the form array
     */
    public onInput(event: Event, index: number): void {
        if (!(event as InputEvent).data) {
            return;
        }
        this._focusNextFormEl(index);
        this.totpDiv.nativeElement.classList.remove('error');
        this._cd.markForCheck();
    }

    /**
     * @param event The paste event
     * @param index The index in the form array
     */
    public onPaste(event: ClipboardEvent, index: number): void {
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData.getData('text');
        const inputFields = this.totpFormArray.controls;
        for (let i = 0; i < inputFields.length - index; i++) {
            inputFields[i + index].patchValue(pastedText[i]);
            if (!pastedText[i]) {
                this._focusNextFormEl(i + index);
                return;
            }
        }
        this._focusNextFormEl(index + pastedText.length);
    }

    /**
     * Select the content of the input field when the input or its direct parent is selected
     * @param event The click event
     */
    public selectInputContent(event: MouseEvent): void {
        const el = event.target as HTMLElement;
        let inputField;
        if (el.tagName === 'INPUT') {
            inputField = el as HTMLInputElement;
        } else {
            inputField = el.children[0] as HTMLInputElement;
        }
        inputField.select();
    }

    /**
     * @param event The keyboard event
     * @param index The index in the form array
     */
    public onKeyup(event: KeyboardEvent, index: number): void {
        switch (event.key) {
            case 'ArrowRight':
                return this._focusNextFormEl(index, false);
            case 'ArrowLeft':
            case 'Backspace':
                return this._focusPreviousFormEl(index);
        }
    }

    /**
     * Send the code to the backend and handle success / error
     */
    public submitCode(): void {
        this.requestInProgress = true;
        this.errors.totpTooManyErrors = false;
        this.errors.invalidTotp = false;
        this.totpDiv.nativeElement.classList.remove('error');
        this._certificateService.sendCertificateTotp(this.certificate.id, this.totpCode).pipe(
            catchError(
                (error) => {
                    const messageCode = JSON.parse(error?.error?.message)?.messageCode;
                    this._errorService.handleError(error, this._translate.instant('CERT_ERROR_SEND_TOTP'));
                    switch (messageCode) {
                        case 'M2M_INVALID_TOTP':
                            break;
                        case 'M2M_TOTP_TOO_MANY_ERRORS':
                            this.errors.totpTooManyErrors = true;
                            break;
                    }
                    this.totpDiv.nativeElement.classList.add('shake');
                    this.totpDiv.nativeElement.classList.add('error');
                    setTimeout(() => this.totpDiv.nativeElement.classList.remove('shake'), 5 * 125);
                    this._fetchRemainingTries();
                    this.requestInProgress = false;
                    return of();
                }
            )
        ).subscribe(
            (certificate: Certificate) => {
                this.requestInProgress = false;
                if (certificate) {
                    this.store.dispatch(successUpdateCertificateRequest({ payload: [certificate] }));
                    this._snackbar.open(this._translate.instant('M2M_TOTP_CONFIRMED'), 'green-snackbar');
                    this._dialogRef.close();
                }
            }
        );
    }
}
