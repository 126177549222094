import { createAction, props } from '@ngrx/store';
import { Certificate } from 'src/app/models/certificate';

export const fetchCertificate = createAction('[Certificate] Load Certificates');
export const successFetchCertificate = createAction('[Certificate] Success load Certificates', props<{ payload: Array<Certificate> }>());
export const errorFetchCertificate = createAction('[Certificate] Error load Certificates');

export const createCertificateRequest = createAction('[Certificate] Create Certificate request', props<{ payload: Array<Certificate> }>());
export const successCreateCertificateRequest = createAction('[Certificate] Success create certificate request', props<{ payload: Array<Certificate> }>());
export const errorCreateCertificateRequest = createAction('[Certificate] Error create certificate request');

export const updateCertificateRequest = createAction('[Certificate] Update Certificate request', props<{ payload: Certificate }>());
export const successUpdateCertificateRequest = createAction('[Certificate] Success update certificate request', props<{ payload: Array<Certificate> }>());
export const errorUpdateCertificateRequest = createAction('[Certificate] Error update certificate request');

export const deleteCertificateRequest = createAction('[Certificate] Delete Certificate request', props<{ payload: string }>());
export const successDeleteCertificateRequest = createAction('[Certificate] Success delete certificate request', props<{ payload: Array<Certificate> }>());
export const errorDeleteCertificateRequest = createAction('[Certificate] Error delete certificate request');

export const renewCertificateRequest = createAction('[Certificate] Renew certificate request', props<{ payload: Certificate }>());
export const successRenewCertificateRequest = createAction('[Certificate] Success renew certificate request', props<{ payload: Array<Certificate> }>());
export const errorRenewCertificateRequest = createAction('[Certificate] Error renew certificate request');

export const acceptCertificateRequest = createAction('[Certificate] Accept certificate request', props<{ payload: Array<{ machineId: string }> }>());
export const successAcceptCertificateRequest = createAction('[Certificate] Success accept certificate request', props<{ payload: Array<Certificate> }>());
export const errorAcceptCertificateRequest = createAction('[Certificate] Error accept certificate request');

export const refuseCertificateRequest = createAction('[Certificate] Refuse certificate request', props<{ payload: Array<{ machineId: string }> }>());
export const successRefuseCertificateRequest = createAction('[Certificate] Success refuse certificate request', props<{ payload: Array<Certificate> }>());
export const errorRefuseCertificateRequest = createAction('[Certificate] Error refuse certificate request');

export const cancelCertificateDeletion = createAction('[Certificate] Cancel certificate deletion', props<{ payload: string }>());
export const successCancelCertificateDeletion = createAction('[Certificate] Success cancel certificate deletion', props<{ payload: Certificate }>());
export const errorCancelCertificateDeletion = createAction('[Certificate] Error cancel certificate deletion');

export const reinstallCertificate = createAction('[Certificate] reinstall certificate', props<{ payload: string }>());
export const successReinstallCertificate = createAction('[Certificate] Success reinstall certificate', props<{ payload: Certificate }>());
export const errorReinstallCertificate = createAction('[Certificate] Error reinstall certificate');
