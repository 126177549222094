import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, StaticProvider } from '@angular/core';
import { TutorialComponent } from './tutorial.component';
import { TutorialStep } from './tutorial.model';
import { Overlay, OverlayConfig, STANDARD_DROPDOWN_BELOW_POSITIONS } from '@angular/cdk/overlay';
import { TutorialRef } from './tutorial-ref';

@Injectable({
    providedIn: 'root'
})
export class TutorialService {

    private _steps: TutorialStep[];
    private _tutorialRef: TutorialRef;

    constructor(
        private _overlay: Overlay,
        private _injector: Injector,
    ) { }

    public launch(steps: TutorialStep[]): void {
        this._steps = steps;
        this._displayStep();
    }

    public next() {
        this._tutorialRef.next();
    }

    public finish() {
        this._tutorialRef.close();
    }

    private _displayStep(stepIndex = 0): void {
        const step = this._steps[stepIndex];
        this._tutorialRef = this._openStep(step);
        this._tutorialRef.afterClosed$.subscribe(
            (res) => {
                if (res.type === 'next') {
                    this._displayStep(++stepIndex);
                }
            }
        );
    }

    private _getOverlayConfig(step: TutorialStep): OverlayConfig {
        const offset = 8;
        return new OverlayConfig({
            hasBackdrop: !step.actionOn,
            backdropClass: 'tutorial-backdrop',
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(step.target)
                .withPositions(STANDARD_DROPDOWN_BELOW_POSITIONS.map(
                    (pos) => ({
                        ...pos,
                        offsetY: pos.originY === 'top' ? -offset : offset
                    })
                ))
                .withPush(false),
            scrollStrategy: this._overlay.scrollStrategies.reposition(),
            disposeOnNavigation: true,
        });
    }

    private _openStep(step: TutorialStep): TutorialRef {
        const overlayRef = this._overlay.create(this._getOverlayConfig(step));
        const tutorialRef = new TutorialRef(
            overlayRef,
            step,
            this._steps.findIndex(s => s === step) === this._steps.length - 1,
        );
        const injector = this._createInjector(tutorialRef, this._injector);
        overlayRef.attach(new ComponentPortal(TutorialComponent, null, injector));
        return tutorialRef;
    }

    private _createInjector(tutorialRef: TutorialRef, injector: Injector) {
        const providers: StaticProvider[] = [
            { provide: TutorialRef, useValue: tutorialRef },
        ];
        return Injector.create({
            providers,
            parent: injector
        });
    }

}
