import { KeyValue, KeyValuePipe } from '@angular/common';
import { KeyValueDiffers, Pipe, PipeTransform, inject } from '@angular/core';

@Pipe({
    name: 'additionalDataKeyvalue',
    pure: true,
})
export class AdditionalDataKeyvaluePipe implements PipeTransform {

    private keyValuePipe = new KeyValuePipe(inject(KeyValueDiffers));

    transform(
        input: ReadonlyMap<string, any>,
        compareFn?: (a: KeyValue<string, any>, b: KeyValue<string, any>) => number
    ): KeyValue<string, any>[] {
        return this.keyValuePipe.transform(input, compareFn)
    }

}
