import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { UsageMetricsService } from 'src/app/services/usage-metrics.service';
import { errorFetchUsageMetrics, fetchUsageMetrics, successFetchUsageMetrics } from '../actions/usage-metrics.action';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export type EffectResult = 'success' | 'error';

@Injectable()
export class UsageMetricsEffect {

    public fetchUsageMetrics$ = createEffect(() => { return this._actions$.pipe(
        ofType(fetchUsageMetrics),
        switchMap((action) => this._usageMetricsService.fetchUsageMetrics(action.fromDate, action.toDate, {
            scopes: action.scopes,
            families: action.families,
            groups: action.groups,
            parentFamilies: action.parentFamilies,
        })),
        map((usageMetrics) => successFetchUsageMetrics({ payload: usageMetrics })),
        catchError(
            (error: HttpErrorResponse) => {
                this._errorTranslationService.handleError(error, this._translate.instant('ERROR_FETCH_USAGE_METRICS'));
                return of(errorFetchUsageMetrics());
            }
        ),
    ) });

    constructor(
        private _usageMetricsService: UsageMetricsService,
        private _actions$: Actions,
        private _translate: TranslateService,
        private _errorTranslationService: ErrorTranslationService,
    ) { }
}
