import { KeyValuePipe, KeyValue } from '@angular/common';
import { KeyValueDiffers, Pipe, inject, type PipeTransform } from '@angular/core';
import { PlanningCount } from 'src/app/models/aircraft-planning';


@Pipe({
  name: 'appPlanningCountKeyvalue',
  pure: true,
})
export class PlanningCountKeyvaluePipe implements PipeTransform {

    private keyValuePipe = new KeyValuePipe(inject(KeyValueDiffers));

    transform(
        input: PlanningCount,
        compareFn?: (a: KeyValue<keyof PlanningCount, number>, b: KeyValue<keyof PlanningCount, number>) => number
    ): KeyValue<keyof PlanningCount, number>[] {
        return this.keyValuePipe.transform(input, compareFn)
    }

}
