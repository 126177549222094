import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { MapService } from './map.service';
import {
  successFiltersAdminAssets,
  successFiltersAdminDevices,
  successFiltersAdminFamilies,
  successFiltersAdminZones,
  successFiltersAircraftPlanning,
  successFiltersManageUserRole,
  successFiltersMapAssets,
  successFiltersOversizePlanning,
  successFiltersRequestForRights,
  successFiltersCertificates,
  successFiltersManageFamilyUsers,
  successFiltersGroups,
  successFiltersAnchors
} from '../store/actions/filter.actions';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class FilterService {

  constructor(
    private store: Store,
    private _mapService: MapService,
    private _route: ActivatedRoute,
  ) { }

  public filteredDataLength$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public workerBusy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasClickedApplyButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public closeFilterSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _isFirstZoomDone = false;

  public applyFilter(view: string, data: any[]): void {
    const filteredData = data;
    switch (view) {
      case 'mapView':
        this.store.dispatch(successFiltersMapAssets({ mapAssets: filteredData, loaded: true }));
        if (filteredData.length) {
          this._handleFirstZoom('asset');
        }
        break;
      case 'tabView':
        this.store.dispatch(successFiltersMapAssets({ mapAssets: filteredData, loaded: true }));
        break;
      case 'zonesTabView':
        this.store.dispatch(successFiltersMapAssets({ mapAssets: filteredData, loaded: true }));
        break;
      case 'assetsView':
        this.store.dispatch(successFiltersAdminAssets({ assets: filteredData, loaded: true }));
        break;
      case 'familiesView':
        this.store.dispatch(successFiltersAdminFamilies({ parentFamilies: filteredData }));
        break;
      case 'devicesView':
        this.store.dispatch(successFiltersAdminDevices({ devices: filteredData }));
        break;
      case 'planningView':
        this.store.dispatch(successFiltersOversizePlanning({ oversizePlanning: filteredData }));
        break;
      case 'aircraftPlanningView':
        this.store.dispatch(successFiltersAircraftPlanning({ aircraftPlanning: filteredData }));
        break;
      case 'requestForRightsView':
        this.store.dispatch(successFiltersRequestForRights({ rights: filteredData }));
        break;
      case 'manageUserRoleView':
        this.store.dispatch(successFiltersManageUserRole({ roles: filteredData }));
        break;
      case 'zonesView':
        this.store.dispatch(successFiltersAdminZones({ zones: filteredData }));
        if (filteredData.length) {
          this._handleFirstZoom('zone');
        }
        break;
      case 'certificatesView':
        this.store.dispatch(successFiltersCertificates({ certificates: filteredData }));
        break;
      case 'manageFamilyUsersView':
        this.store.dispatch(successFiltersManageFamilyUsers({ users: filteredData }));
        break;
      case 'manageGroups':
        this.store.dispatch(successFiltersGroups({ groups: filteredData }));
        break;
      case 'anchorView':
        this.store.dispatch(successFiltersAnchors({ anchors: filteredData }));
        break;
    }
    this.filteredDataLength$.next(filteredData.length);
  }

  public clearFilter(view: string, filteredDataList?: Array<any>): void {
    switch (view) {
      case 'mapView':
        this.store.dispatch(successFiltersMapAssets({ mapAssets: filteredDataList, loaded: true }));
        this._mapService.zoomAndCenterOnAssets();
        break;
      case 'tabView':
        this.store.dispatch(successFiltersMapAssets({ mapAssets: filteredDataList, loaded: true }));
        break;
      case 'zonesTabView':
        this.store.dispatch(successFiltersMapAssets({ mapAssets: filteredDataList, loaded: true }));
        break;
      case 'assetsView':
        this.store.dispatch(successFiltersAdminAssets({ assets: filteredDataList, loaded: false }));
        break;
      case 'familiesView':
        this.store.dispatch(successFiltersAdminFamilies({ parentFamilies: filteredDataList }));
        break;
      case 'devicesView':
        this.store.dispatch(successFiltersAdminDevices({ devices: filteredDataList }));
        break;
      case 'planningView':
        this.store.dispatch(successFiltersOversizePlanning({ oversizePlanning: filteredDataList }));
        break;
      case 'aircraftPlanningView':
        this.store.dispatch(successFiltersAircraftPlanning({ aircraftPlanning: filteredDataList }));
        break;
      case 'zonesView':
        this.store.dispatch(successFiltersAdminZones({ zones: filteredDataList }));
        this._mapService.zoomOnZones();
        break;
      case 'certificatesView':
        this.store.dispatch(successFiltersCertificates({ certificates: filteredDataList }));
        break;
      case 'manageGroups':
        this.store.dispatch(successFiltersGroups({ groups: filteredDataList }));
        break;
      case 'anchorView':
        this.store.dispatch(successFiltersAnchors({ anchors: filteredDataList }));
        break;
    }
    this.filteredDataLength$.next(filteredDataList.length);
  }

  /**
   * Handle the automatic first zoom. Can be on Assets or on Zones business rule :
   * - if there are only zone filter set -> zoom on filtered zones
   * - else -> zoom on assets
   * @param filterdObjectType specifies which object type is filtering
   */
  private _handleFirstZoom(filterdObjectType: string) {
    if (!this._isFirstZoomDone) {
      const filteredAttributes = Object.keys(this._route.snapshot.queryParams);
      // make sure that there are some query params (filter attributes)
      if (filteredAttributes.length) {
        switch (filterdObjectType) {
          case 'asset':
            // if there are not only zone filters from the url, zoom on assets
            if (filteredAttributes.some(param => !param.startsWith('zone'))) {
              this._mapService.zoomAndCenterOnAssets();
              this._isFirstZoomDone = true;
            }
            break;
          case 'zone':
            // if there are only zones filter from the url, zoom on zones
            if (!filteredAttributes.some(param => !param.startsWith('zone'))) {
              this._mapService.zoomOnZones();
              this._isFirstZoomDone = true;
            }
        }
      } else {
        // default zoom on assets
        this._mapService.zoomAndCenterOnAssets();
        this._isFirstZoomDone = true;
      }
    }
  }
}
