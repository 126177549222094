import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Component } from '@angular/core';

export type SnackbarColor = 'green-snackbar'|'orange-snackbar'|'red-snackbar'|'blue-snackbar';

@Component({
  selector: 'app-snackbar',
  template: ''
})
export class SnackbarComponent {


  constructor(public snackBar: MatSnackBar) { }

  /**
   * Display a banner who is an angular-material native.
   *
   * @param text : Align center text : Default 'Text'
   * @param color : CSS class : 'green-snackbar' or 'red-snackbar'
   * @param time : Time in milisecond : By default 5 seconds
   */
  open(text: string, color: SnackbarColor = 'green-snackbar', time = 5000, action?: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'start';
    config.duration = time;
    config.panelClass = ['toast-notification', color];
    // config.direction = "ltr";
    if (!action && !time) {
        config.panelClass.push('action-icon');
        action = '\ue5cd';
    }
    this.snackBar.open(text, action, config);
    return this;
  }

  dismiss(): void {
    this.snackBar.dismiss();
  }

}
