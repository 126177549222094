import { environment as defaultEnvironment } from './environment.default';

export const environment = {
    ...defaultEnvironment,
    envName: 'val',
    API_ENDPOINT: 'https://way.airbus-v.com/api/desktop',
    production: false,
    guideMeActive: true,
    guideMeUrl: 'https://guideme.apps.ocp01.airbus.corp/know/servlet/publicationList?applicationId=WaY_test',
    WS_ENDPOINT: 'wss://44cxly9qs7.execute-api.eu-west-1.amazonaws.com/val'
};
