<div mat-dialog-title>
    <span>{{"MANAGE_RIGHT_MODIFY_ROLE" | translate}}</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<div mat-dialog-content>
    <p>{{"ROLE_WILL_BE_APPLIED_TO_ALL_IN_TABLE" | translate:{ role: role | translate } }}</p>
</div>

<div mat-dialog-actions>
    <button mat-stroked-button
            mat-dialog-close
            color="primary">
        {{"CANCEL" | translate}}
    </button>
    <button mat-flat-button
            color="primary"
            (click)="onClickConfirm()">
        {{"I_UNDERSTAND_AND_CONFIRM" | translate}}
    </button>
</div>
