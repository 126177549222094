import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import ChartBaseComponent from 'src/app/components/shared/kpi/chart.base';
import { Ng2ChartConfiguration } from 'src/app/components/shared/kpi/chart.model';
import { KpiFamily } from 'src/app/services/kpi.service';

@Component({
  selector: 'app-bar-cycles-cycle',
  templateUrl: '../../../shared/kpi/chart-card-scrollable.component.html',
  styleUrls: ['../../../shared/kpi/chart-card-scrollable.component.scss'],
})
export class BarCyclesCycleComponent extends ChartBaseComponent<KpiFamily, 'bar', number> implements OnChanges {

  @Input() selectedAssetIndex: number;

  public chartConfiguration: Ng2ChartConfiguration<'bar', number[], number> = {
    type: 'bar',
    data: {
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      'onClick': (event, item) => this.chartClicked.emit({ event, item }),
      plugins: {
        title: {
          display: true,
          text: this._translate.instant('CYCLE_TIME_MONITORING'),
          position: 'top',
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          display: true,
          beginAtZero: true,
          grid: {
            display: false
          }
        },
        y: {
          display: true,
          beginAtZero: true,
        },
      },
    },
  };

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    if (changes.selectedAssetIndex) {
      if (changes.selectedAssetIndex.currentValue !== changes.selectedAssetIndex.previousValue) {
        this.setKpiData();
        this._cd.markForCheck();
      }
    }
    super.ngOnChanges(changes);
  }

  setKpiData(): void {
    if (this.selectedAssetIndex === undefined || this.selectedAssetIndex === null) {
      return;
    }
    const listDataCycle = this.dataKPI.familyAssets[this.selectedAssetIndex].assetCycles;
    const nameAssetClicked = this.dataKPI.familyAssets[this.selectedAssetIndex].assetName;
    this.graphSize = (listDataCycle.length > 30) ? `${60 * listDataCycle.length}px` : 'auto';
    this.chartConfiguration.options.plugins.title.text = `${this._translate.instant('CYCLE_TIME_MONITORING')} (ASSET: ${nameAssetClicked})`;
    this.chartConfiguration.data = {
      labels: listDataCycle.map(
        (cycle) => cycle.cycleCycleOrder
      ),
      datasets: [
        {
          label: this._translate.instant('CYCLE_TIME'),
          backgroundColor: 'blue',
          data: listDataCycle.map(
            (cycle) => +cycle.cycleCycleTime.toFixed(2)
          ),
          borderColor: 'blue',
          borderWidth: 1,
        },
      ]
    };
    this.chartConfiguration.options.plugins.annotation = {
      annotations: {
        familyMeanCycle: {
          type: 'line',
          yMin: this.dataKPI.familyMeanCycleTime.toFixed(2),
          yMax: this.dataKPI.familyMeanCycleTime.toFixed(2),
            borderColor: 'tomato',
            borderWidth: 1,
            borderDash: [2, 2],
        },
      },
    };
  }

}
