import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SCOPE_SETTINGS } from '../models/scope-management.model';

@Injectable({
    providedIn: 'root'
})
@Pipe({
    name: 'transformPropertyPipe'
})
export class TransformPropertyPipe implements PipeTransform {
    private scopesSettings = SCOPE_SETTINGS;
    transform(valueToTransform: string, propertyToTransform: string, scope: string): string {
        return this.scopesSettings[scope]?.transformPropertiesToDisplay?.[propertyToTransform]?.(valueToTransform) ?? valueToTransform;
    }
}
