import { createSelector } from '@ngrx/store';
import { AppState } from '../../store';

export const selectAnchorState$ = (state: AppState) => state.anchors;

export const selectAnchor$ = createSelector(selectAnchorState$, (anchors) => anchors?.data);

export const selectAnchorsLoaded$ = createSelector(selectAnchorState$, (anchors) => anchors?.loaded);

export const selectAnchorLayerLoading$ = createSelector(selectAnchorState$, (anchors) => anchors?.loading);

