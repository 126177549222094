<ng-container *ngIf="editableRoleLimit && !canModify; else elseTemplate">
  <span [matTooltip]="'BANNER_FAIL_RIGHTS' | translate">{{ role | translate }}</span>
</ng-container>
<ng-template #elseTemplate>
  <mat-button-toggle-group name="role"
                           aria-label="Role selection"
                           [attr.data-color]="color"
                           [disabled]="disabled"
                           [value]="role"
                           (change)="onSelectionChange($event)">
    <mat-button-toggle *ngFor="let roleName of roles"
                       [ngStyle]="{'display': editableRoleLimit !== 'administrator' && roleName.value === 'administrator' ? 'none' : 'initial'}"
                       [value]="roleName.value"
                       [disabled]="roleName.disabled"
                       [checked]="roleName.value === role">
      {{ roleName.text | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</ng-template>
