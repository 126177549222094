<div class="mat-card-padding"
     style="max-width: calc(100vw - 36px);">
  <mat-card class="chart" appearance="outlined">
    <div class="embed-responsive embed-responsive-16by9"
         [ngStyle]="{'height.px': height}">
      <canvas #chart
              baseChart
              class="embed-responsive-item"
              [height]="height"
              [data]="chartConfiguration.data"
              [options]="chartConfiguration.options ?? {}"
              [plugins]="chartConfiguration.plugins ?? []"
              [type]="chartConfiguration.type">
        </canvas>
    </div>
  </mat-card>
</div>
