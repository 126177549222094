<mat-action-list>
    <mat-list-item *ngFor="let item of items"
                   (click)="clickItem(item)">
        <mat-icon *ngIf="item.icon"
                  matListItemIcon>{{ item.icon }}</mat-icon>
        <span matListItemTitle
              class="headline">
            {{ item.titleI18n | translate }}
        </span>
        <span matLine
              class="subtitle"
              *ngIf="item.subtitleI18n">
            {{ item.subtitleI18n | translate }}
        </span>
    </mat-list-item>
</mat-action-list>
