<div mat-dialog-title>
    <span>{{ 'DIALOG_GROUP_REQUEST_TITLE' | translate }}</span>
    <button mat-icon-button mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<form class="container"
      (ngSubmit)="onClickConfirm()"
      [formGroup]="groupForm">

    <div class="custom-scrollbar"
         mat-dialog-content>
        <mat-form-field appearance="outline">
            <mat-label>{{ 'DIALOG_REQUEST_STATUS_SELECT' | translate }}</mat-label>
            <input matInput
                   required
                   formControlName="group"
                   [matAutocomplete]="groupList">
            <mat-hint *ngIf="(groupsLoaded$ | async) === false">
                <mat-progress-spinner mode="indeterminate"
                                      diameter="12"
                                      strokeWidth="2"></mat-progress-spinner>
                {{ "LOADING_DATA" | translate }}
            </mat-hint>
            <mat-error *ngIf="groupForm.controls.group.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_SELECTION" | translate }}
            </mat-error>
            <mat-autocomplete autoActiveFirstOption
                              #groupList="matAutocomplete"
                              [displayWith]="getName">
                <mat-option *ngFor="let group of allGroups$ | async"
                            [value]="group">
                    {{ group.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{ 'NOTE' | translate }}</mat-label>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                formControlName="note"></textarea>
        </mat-form-field>
    </div>

    <div mat-dialog-actions>
        <button mat-stroked-button
                mat-dialog-close
                type="button"
                color="primary">
            {{ 'CANCEL' | translate }}
        </button>
        <button mat-flat-button
                color="primary"
                type="submit"
                [disabled]="requestInProgress || groupForm.pristine || groupForm.invalid">
            {{ 'DIALOG_VALIDATE_OPTION' | translate }}
            <mat-progress-spinner *ngIf="requestInProgress"
                                  class="positive-action-icon"
                                  color="primary"
                                  mode="indeterminate"
                                  diameter="16">
            </mat-progress-spinner>
        </button>
    </div>

</form>
