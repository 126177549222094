import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '../models/company';
import { CacheDeltaResponse } from '../models/cache';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {


    constructor(private http: HttpClient) { }

    public getCompanies(delta?: string): Observable<CacheDeltaResponse<Company>> {
        let params = new HttpParams();

        if (delta !== undefined) {
            params = params.set('delta', delta);
        }

        return this.http.get<CacheDeltaResponse<Company>>(`${environment.API_ENDPOINT}/companies`,
            { params }
        );
    }

}
