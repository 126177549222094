import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';
import { AircraftPlanning, AircraftPlanningUpload, Clash, UploadPlanningAPIResponse } from 'src/app/models/aircraft-planning';
import { csvToJson, formatDate } from 'src/app/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { AircraftPlanningService } from 'src/app/services/aircraft-planning.service';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { Store } from '@ngrx/store';
import { createAircraftPlannings, fixClashesAircraftPlanning } from 'src/app/store/actions/aircraft-planning.action';
import { AircraftPlanningEffects } from 'src/app/store/effects/aircraft-planning.effects';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-planning-dialog',
  templateUrl: './dialog-upload-planning.component.html',
  styleUrls: ['./dialog-upload-planning.component.scss']
})
export class DialogUploadPlanningComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { planning: AircraftPlanningUpload[], onClashValidated: () => void },
    private _dialogRef: MatDialogRef<DialogUploadPlanningComponent>,
    private _snackBar: SnackbarComponent,
    private _translate: TranslateService,
    private _aircraftPlanningService: AircraftPlanningService,
    private _errorService: ErrorTranslationService,
    private store: Store,
    private _aircraftPlanningEffect: AircraftPlanningEffects
  ) { }

  public planningToUpload: AircraftPlanningUpload[];
  public errors: string[];
  public clashes: string[] = [];
  public numberOfValidRows: number;
  public checkLoading: boolean;
  public loading: boolean;
  public hasClashes: boolean;
  public loadingClash = false;

  private _subscriptions = new Subscription();
  private planningResponse: AircraftPlanning[];

  ngOnInit(): void {
    this._initData();
  }

  /**
   * Init data and call backend to check clashes
   */
  private _initData() {
    if (this.data) {
      this.uploadPlanning(this.data.planning, false);
    }
  }

  /**
   * Triggered on click on cross button
   */
  public dialogCloseClick() {
    this._dialogRef.close();
  }

  /**
   * Triggered when the user drop a file or select a file from the file picker
   * @param files List of files dropped on the drop zone
   */
  public onFile(files: File[]) {
    if (files?.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsText(file, 'ISO-8859-1'); // Read file as string and UTF-8 encoding
      reader.onload = () => { // Called once  readAsText is completed
        try {
          this.planningToUpload = csvToJson(reader.result as string) as unknown as AircraftPlanningUpload[];
          // Format the date
          this.planningToUpload.forEach(operation => {
            operation.date = formatDate(operation.date);
          });
          this.uploadPlanning(this.planningToUpload, false);
        } catch (err) {
          this._snackBar.open(this._translate.instant('UPLOAD_AIRCRAFT_PLANNING_FILE_ERROR'), 'red-snackbar', 5000);
        }
      };
    }
  }

  /**
   * Open the file picker when the user click on the browse button
   * @param htmlInput Html element of the file picker input
   */
  public openFilePicker(htmlInput: HTMLInputElement) {
    htmlInput.click();
  }

  /**
   * Upload the planning
   * @param planning planning to upload
   * @param submit false if a simple consistency check is needed
   */
  public uploadPlanning(planning: AircraftPlanningUpload[], submit: boolean) {
    submit ? this.loading = true : this.checkLoading = true;
    if (!submit) {
      this._subscriptions.add(this._aircraftPlanningService.uploadAircraftPlanning(planning, submit).subscribe(
        (res) => {
          this.loading = false;
          this.checkLoading = false;
          this.planningResponse = res.plannedOperations;
          this.errors = res.errors.map((error) => {
            return `${this._translate.instant('ERROR_ROW_NUMBER', { row: error.row + 1 })}:\n
                ${this._translate.instant(error.column)}: ${this._translate.instant(error.error)}`;
          });
          this.numberOfValidRows = res.numberOfValidRows;
          if (res.hasClashes) {
            this.hasClashes = res.hasClashes;
            this._displayClash(res);
          }
        },
        (error) => {
          this._errorService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
          this.loading = false;
          this.checkLoading = false;
        }
      ));

    } else {
      this.loading = true;
      this._subscriptions.add(this._aircraftPlanningEffect.effectSubject.subscribe((successOrError) => {
        this.loading = false;
        if (successOrError === 'success') {
          if (this.data?.onClashValidated) {
            // Refresh data after clash managed
            this.data?.onClashValidated();
          }
          this._dialogRef.close();
        }
      }));
      this.store.dispatch(createAircraftPlannings({ aircraftPlanning: this.data?.planning || planning }));
    }
  }

  /**
   * Display the clash list in the upload popup
   * @param res Return of the back API on upload check
   */
  private _displayClash(res: UploadPlanningAPIResponse) {
    res.plannedOperations.forEach((operation: AircraftPlanning) => {
      if (operation.clashes) {
        operation.clashes.forEach((clash: Clash) => {
          if (clash.version && clash.msn) { // Clash between 2 msn
            this.clashes.push(` ${this._translate.instant(clash.message)} ${clash.version} - ${clash.msn}`);
          } else { // Clash on a single msn
            this.clashes.push(`${this._translate.instant(clash.message)}`);
          }
        });
      }
    });
  }

  /**
   * Push clash data and turn on clashMode
   */
  public fixClashes() {
    this.loadingClash = true;
    this._subscriptions.add(this._aircraftPlanningEffect.effectSubject.subscribe((successOrError) => {
      this.loading = false;
      if (successOrError === 'success') {
        this._dialogRef.close();
      }
    }));
    // Put an incremental id to avoid issues on "local" data, will be remove by the back end after API call
    this.planningResponse.forEach((planningRow, i) => planningRow.id = i.toString());
    this.store.dispatch(fixClashesAircraftPlanning({ payload: this.planningResponse }));
  }

  /**
   * Download the csv template for the upload planning
   */
  public downloadTemplate() {
    const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + 'msn;program;version;zoneFrom;siteFrom;zoneTo;siteTo;date');
    window.open(encodedUri);
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
