import { selectAllUsers$, selectUserLoaded$ } from 'src/app/store/selectors/user-right.selectors';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ReplaySubject, Subscription, combineLatest } from 'rxjs';
import { User } from 'src/app/models/user';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserRightService } from 'src/app/services/user-right.service';
import { distinctUntilChanged, map, pairwise, startWith } from 'rxjs/operators';
import { Option } from 'src/app/models/user-right';
import { ItselfOrArray } from 'src/app/utils/utils';
import { SelectionModel } from '@angular/cdk/collections';
import { isEqual } from 'lodash';

export interface SelectUserDialogData {
    /** The users should have at least one of these specific roles */
    specificRoles?: (keyof Option)[];
    /** List of users to exclude from the selection */
    exclude?: User['id'][];
    /**
     * Mutliple or single selection
     * @default true
     */
    multiple?: boolean;
    /** Limit of items which can be selected */
    limit?: number;
    /** Field to display in the input */
    displayField?: keyof User;
}

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectUserComponent implements OnInit, OnDestroy {

  public userForm = this._fb.group({
    users: this._fb.control<ItselfOrArray<User['id']>>([], Validators.required)
  });
  public searchControl = this._fb.control('');
  public users!: User[];
  public users$: ReplaySubject<User[]> = new ReplaySubject();
  public usersLoading: boolean;
  public selectedUsers = new SelectionModel<User>(true, [], true, (o1, o2) => o1.id === o2.id);

  private _subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SelectUserDialogData,
    private userRightService: UserRightService,
    private _fb: FormBuilder,
    private store: Store,
    private _dialogRef: MatDialogRef<SelectUserComponent>,
    private _cd: ChangeDetectorRef,
  ) {
    this.data = {
        multiple: true,
        displayField: 'userId',
        ...this.data,
    };
    if (this.data.limit && this.data.multiple) {
        this.userForm.controls.users.addValidators(
            (control: AbstractControl<User[], User[]>): ValidationErrors|null => {
                if (control.value.length > this.data.limit) {
                    return { overLimit: true }
                }
                return null
            }
        )
    }
  }

  ngOnInit(): void {
    this._subscription.add(
        this.store.select(selectUserLoaded$).pipe(
            map(loaded => !loaded),
        ).subscribe(
            loading => {
                this.usersLoading = loading;
                this.userForm.controls.users[loading ? 'disable' : 'enable']();
            }
        )
    );
    this.userRightService.checkUserLoaded();
    this._subscription.add(this.store.select(selectAllUsers$).subscribe(
      users => {
        this.users = users.filter(user => !this.data?.exclude?.some((id) => user.id === id));
        this.users.sort(
          (a, b) => a.userId.localeCompare(b.userId)
        );
        if (Object.entries(this.data).length) {
            this.users = this.users.filter(
                ({ id, option }) => {
                    let shouldInclude = true;
                    if (shouldInclude && this.data.exclude) {
                        shouldInclude = !this.data.exclude.includes(id);
                    }
                    if (shouldInclude && this.data.specificRoles) {
                        shouldInclude = this.data.specificRoles.some(
                            (role) => !!option?.[role]
                        );
                    }
                    return shouldInclude;
                }
            );
        }
        this._filterUsers();
      }
    ));
    this._subscription.add(
      this.searchControl.valueChanges.pipe(
        distinctUntilChanged(),
      ).subscribe(
        () => this._filterUsers()
      )
    );
    this._subscription.add(
        this.selectedUsers.changed.subscribe(
            () => this._cd.markForCheck()
        )
    );
    this._subscription.add(
        combineLatest([
            this.users$,
            this.userForm.controls.users.valueChanges.pipe(
                startWith([]),
                distinctUntilChanged<string|string[]>(isEqual),
                map(value => {
                    if (!(value instanceof Array)) {
                        return [value];
                    } else {
                        return value;
                    }
                }),
                pairwise(),
            ),
        ]).subscribe(
            ([filteredUsers, [previousSelection, currentSelection]]) => {
                const removed = previousSelection.filter(
                    (id) => !currentSelection.includes(id)
                );
                const added = currentSelection.filter(
                    (id) => !previousSelection.includes(id)
                );
                for (const id of removed) {
                    const user = filteredUsers.find(
                        (user) => user.id === id
                    );
                    if (user) {
                        this.selectedUsers.deselect(user);
                    }
                }
                for (const id of added) {
                    const user = filteredUsers.find(
                        (user) => user.id === id
                    );
                    if (user) {
                        this.selectedUsers.select(user);
                    }
                }
            }
        )
    );
  }


    public onOptionSelectionChange(user: User) {
        this.selectedUsers.toggle(user);
    }

  /**
   * Filter the users with the searched text
   */
  private _filterUsers(): void {
    if (!this.users) {
      return;
    }
    const search = this.searchControl.value;
    if (!search) {
      this.users$.next([...this.users]);
    } else {
      this.users$.next([...this.users.filter(
        user => {
          const searchRegex = new RegExp(search, 'i');
          return searchRegex.test(user.userId) || searchRegex.test(user.email);
        }
      )]);
    }
  }

  /**
   * Close the dialog & pass the selected users
   */
  public async onFormSubmit(): Promise<void> {
    this._dialogRef.close(this.selectedUsers.selected);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

}
