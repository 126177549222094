import { Injectable } from '@angular/core';
import { Observable, Subscription, } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MasterData } from '../models/masterdata';
import { Store } from '@ngrx/store';
import * as fromRoot from './../store/index';
import { fetchAllMasterData } from '../store/actions/masterdata.action';
import { fetchAllZones } from '../store/actions/zone.action';
import { successFiltersAdminZones } from '../store/actions/filter.actions';
import { fetchAllCompanies } from '../store/actions/company.actions';
import { fetchAllSites } from '../store/actions/site.action';
import { fetchAllParentFamilies } from '../store/actions/parent-family.action';
import { fetchAllFamilies } from '../store/actions/family.action';
import { fetchAllAssets } from '../store/actions/asset.actions';
import { fetchAllTrackers } from '../store/actions/tracker.action';
import { selectZoneState$ } from '../store/selectors/zone.selector';
import { filter, take } from 'rxjs/operators';
import { FamilyService } from './family.service';
import { WebSocketService } from './websocket.service';
import { UserService } from './user.service';
import { FilterContextEffects } from '../store/effects/filter-context.effects';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoadParentFamilyRight } from '../store/actions/user-right.action';
import { SuccessFamilyRight } from '../store/actions/familyRight.action';
import { FamilyRight } from '../models/family';

@Injectable({
    providedIn: 'root'
})
export class MasterdataService {

    private _subscription: Subscription = new Subscription();

    constructor(private http: HttpClient, public router: Router, public dialog: MatDialog, private contextEffect: FilterContextEffects, private familyService: FamilyService, private userService: UserService, private store: Store<fromRoot.AppState>, private _webSocketService: WebSocketService) { }

    /**
 * Check user are already loaded or not in store(if not call API call)
 */

    public loadServices() {
        this.store.dispatch(fetchAllMasterData());
        this.store.dispatch(fetchAllZones());

        this._subscription.add(
            this.store.select(selectZoneState$).pipe(
                filter(zoneState => zoneState.loaded),
                take(1)
            ).subscribe((zones) => {
                this.contextEffect.loader$.next(false);
                this.store.dispatch(successFiltersAdminZones({ zones: zones.data }));
            })
        );


        // right requests and parent families rights
        this.store.dispatch(new LoadParentFamilyRight());
        this._subscription.add(
            this.familyService.getFamiliesRights().subscribe((familiesWithRights) => {
                this.store.dispatch(new SuccessFamilyRight(familiesWithRights as unknown as FamilyRight[]));
            })
        );

        this.store.dispatch(fetchAllCompanies());
        this.store.dispatch(fetchAllSites());
        this.store.dispatch(fetchAllParentFamilies());
        this.store.dispatch(fetchAllFamilies());
        this.store.dispatch(fetchAllAssets());
        this.store.dispatch(fetchAllTrackers());

        // WebSocket connection
    }

    public getMasterdata(): Observable<MasterData> {
        return this.http.get<MasterData>(`${environment.API_ENDPOINT}/master-data`);
    }
}
