export function capitalizeFirstLetter(element: string | Array<string>): string {
        if (Array.isArray(element)) {
                return element.map(el => el.charAt(0).toUpperCase() + el.slice(1)).join(', ');
        } else {
                return element.charAt(0).toUpperCase() + element.slice(1);
        }
}

/**
* Capitalize the first letters of a string in which 2 elements are separated by a specific character
* Can be improved if necessary by looping over a number of times the special character appears
*/
export function capitalizeFirstLetters(element: string | Array<string>, specialChar?: RegExp): string {
        if (Array.isArray(element)) {
                if (specialChar) {
                        if (specialChar.test(element[0])) {
                                // Capitalize first letters of 2 items from a string separated by a special character in between and then recompose the word
                                const stringSplitted: string[] = element[0].split(specialChar);
                                return capitalizeFirstLetter(stringSplitted[0]) + specialChar + capitalizeFirstLetter(stringSplitted[1]);
                        }
                }
                return capitalizeFirstLetter(element);
        } else {
                if (specialChar) {
                        if (specialChar.test(element)) {
                                const stringSplitted: string[] = element.split(specialChar);
                                return capitalizeFirstLetter(stringSplitted[0]) + specialChar + capitalizeFirstLetter(stringSplitted[1]);
                        }
                }
                return capitalizeFirstLetter(element);
        }
}
