<div class="way-table--main-container">
    <div class="ownership" *ngIf="!isOwner && !familiesLoading">
        <mat-icon>info</mat-icon>
        <ng-container>
            <span>{{ "GROUP_MANAGE_FAMILIES_RESTRICTION" | translate }}</span>
        </ng-container>
    </div>
    <div #table
         class="table-container custom-scrollbar">
        <table class="way-table"
               mat-table
               matSort
               [dataSource]="dataSource"
               [attr.aria-describedby]="'ARIA_LABEL_GROUP_TABLE_FAMILIES' | translate"
               recycleRows>
            <ng-container matColumnDef="parentFamilyName"
                          style="padding-left: 24px;">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{ "PARENT_FAMILY" | translate }}
                </th>
                <td mat-cell
                    *matCellDef="let family">
                    {{ family.parentFamilyName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{ "ASSET_FAMILY" | translate }}
                </th>
                <td mat-cell
                    *matCellDef="let family">
                    {{ family.name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="level">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>{{ "ROLE" | translate }}</th>
                <td mat-cell
                    *matCellDef="let family">
                    <app-role-selection *ngIf="isOwner; else roleChip"
                                        [role]="family.level"
                                        color="primary"
                                        [availableRoles]="availableRolesForSelectionByFamily[family.id]"
                                        (selectedRoleChange)="onSelectedRoleChange(family, $event)"></app-role-selection>
                    <ng-container *ngIf="!availableRolesForSelectionByFamily[family.id].includes(family.level)">
                        <mat-icon class="warn"
                                [matTooltip]="'ROLE_OUT_OF_BOUNDS' | translate">
                            warning
                        </mat-icon>
                        {{ 'FIX_ROLE' | translate }}
                    </ng-container>
                    <ng-template #roleChip>
                        <mat-chip class="{{family.level}}">
                            {{ family.level | translate }}
                        </mat-chip>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container *ngIf="isOwner"
                          matColumnDef="actions">
                <th mat-header-cell
                    *matHeaderCellDef>
                    <div class="actions">
                        <button mat-icon-button
                                *ngIf="selection.selected.length"
                                [matTooltip]="('REMOVE_SELECTED_FAMILIES' | translate) + ' ('+ selection.selected.length + ')'"
                                [disabled]="disabled || familiesLoading"
                                (click)="onClickRemoveMass()">
                            <fa-icon icon="trash"></fa-icon>
                        </button>
                        <button #addFamilyButton
                                mat-icon-button
                                class="action-button"
                                [disabled]="disabled || familiesLoading"
                                [matTooltip]="'ADD_FAMILIES' | translate"
                                (click)="onClickAdd()"
                                [ngClass]="{'highlight': !disabled && !familiesLoading && families?.length === 0}">
                            <fa-icon icon="plus"></fa-icon>
                        </button>
                    </div>
                </th>
                <td mat-cell
                    *matCellDef="let family">
                    <div class="actions">
                        <mat-checkbox [disabled]="disabled"
                                      color="primary"
                                      (change)="selection.toggle(family)"></mat-checkbox>
                        <button mat-icon-button
                                class="action-button"
                                [disabled]="disabled"
                                (click)="onClickRemove(family)">
                            <fa-icon icon="trash"></fa-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>
    <!-- Loading indicator -->
    <ng-container *ngIf="familiesLoading">
        <div class="loading-spinner">
            <mat-progress-spinner color="primary"
                                  mode="indeterminate"
                                  diameter="32"></mat-progress-spinner>
        </div>
    </ng-container>
    <!-- Paginator -->
    <mat-paginator [appHidden]="!dataSource.data.length"
                   class="way-table--footer"
                   showFirstLastButtons
                   [attr.aria-label]="'SELECT_PAGE_OF_TABLE' | translate"
                   (page)="scrollUp()"
                   [pageSizeOptions]="[10, 25, 50]">
    </mat-paginator>
</div>
<!-- Empty status -->
<ng-container *ngIf="!familiesLoading && !dataSource.data.length">
    <div class="no-data">
        <img src="assets/img/undraw_void_3ggu.png"
             alt="Illustration of empty data">
        <span class="status">{{ "MANAGE_GROUPS_NO_FAMILY" | translate }}</span>
        <span class="hint">{{ "HINT_ADD_FAMILY" | translate }}</span>
    </div>
</ng-container>
