<div mat-dialog-title
     [ngClass]="{'warn': !!inputData.dangerousAction}">
    <span>{{ inputData.title }}</span>
    <button mat-icon-button mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>
<div class="custom-scrollbar" mat-dialog-content>
    <p class="dialog-text" [innerHTML]="inputData.textContent"></p>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button
            color="primary"
            [disabled]="loading"
            type="button"
            (click)="onCancel()">
        {{ inputData.cancelText ? inputData.cancelText : 'CANCEL' | translate }}
    </button>
    <button type="submit"
            mat-flat-button
            [color]="inputData.dangerousAction ? 'warn' : 'primary'"
            [disabled]="loading"
            (click)="onValidate()"
            cdkFocusInitial>
        {{ inputData.validateText ? inputData.validateText : 'DIALOG_VALIDATE_OPTION' | translate }}
        <mat-progress-spinner *ngIf="loading"
                                class="positive-action-icon"
                                [color]="inputData.dangerousAction ? 'warn' : 'primary'"
                                mode="indeterminate"
                                diameter="16">
        </mat-progress-spinner>
    </button>
</div>
