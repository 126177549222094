import { ChangeDetectionStrategy, Component, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { DevToolsService } from '../../dev-tools.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-dialog-generate-plannings',
    templateUrl: './dialog-generate-plannings.component.html',
    styleUrls: ['./dialog-generate-plannings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogGeneratePlanningsComponent {

    public planningForm: FormGroup<{
        planning: FormControl<string>
    }> = this._fb.group({
        planning: this._fb.control(null, [Validators.required]),
    });
    public loading: boolean;

    constructor(
        private readonly _fb: FormBuilder,
        private readonly _devToolsService: DevToolsService,
        private readonly _snackBar: SnackbarComponent,
        private readonly _translate: TranslateService,
        private readonly _errorTranslationService: ErrorTranslationService,
        public readonly dialogRef: MatDialogRef<DialogGeneratePlanningsComponent>
    ) { }

    public generatePlanning() {
        this.loading = true;
        const { planning } = this.planningForm.value;
        this._devToolsService.postPlanning(planning).subscribe({
            next: () => {
                this._snackBar.open(this._translate.instant('PLANNING_GENERATED'), 'green-snackbar', 5000);
                this.dialogRef.close();
            },
            error:  (err) => {
                this._errorTranslationService.handleError(err, 'BANNER_FAIL_INTERNAL_CREATE');
                this.loading = false;
            },
            complete: () => {
                this.loading = false;
            },
        });
    }
}
