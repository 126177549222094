<div *ngIf="isFormInitialised"
     class="filter"
     [ngClass]="{'filter--map': isMapView()}">
    <!-- Header -->
    <div class="filter-header"
         [ngClass]="{'filter-header--map': isMapView()}">
        <div class="filter-header-title">{{'FILTER' | translate}}</div>
        <div class="filter-header-container">
            <mat-progress-spinner *ngIf="hasClickedApplyFilterButton"
                                  color="primary"
                                  mode="indeterminate"
                                  diameter="16">
            </mat-progress-spinner>
            <button mat-button
                    color="primary"
                    [ngClass]="{'filter-header-container-action--map': isMapView()}"
                    [disabled]="hasClickedApplyFilterButton"
                    (click)="hasClickedApplyFilterButton ? null : onClickSubmit(); clickSubmit.emit()">
                <fa-icon icon="check"></fa-icon>
                {{'APPLY' | translate}}
            </button>
            <button mat-button
                    color="primary"
                    [ngClass]="{'filter-header-container-action--map': isMapView()}"
                    [disabled]="hasClickedApplyFilterButton"
                    (click)="hasClickedApplyFilterButton ? null : resetFilters(); clickSubmit.emit()">
                <fa-icon icon="undo"></fa-icon>{{'CLEAR' | translate}}
            </button>
            <button *ngIf="!isMapView() && !hideCollapseButton"
                    mat-button
                    color="primary"
                    (click)="displayOrHideFiltersPanel()">
                <fa-icon [ngClass]="{'rotate': !displayFiltersPanel}"
                         class="animation-rotate"
                         icon="angle-down"></fa-icon>
                {{ (displayFiltersPanel ? 'VIEW_LESS' : 'VIEW_MORE') | translate }}
            </button>
        </div>
    </div>
    <!-- Form -->
    <form class="filter-form"
          [formGroup]="dynamicFilterForm"
          (ngSubmit)="onClickSubmit()"
          [@detailExpand]="displayFiltersPanel ? 'expanded' : 'collapsed'">
        <div class="filter-form-container">

            <ng-container *ngFor="let entry of filterFormEntries">
                <!-- Auto Complete Fields -->
                <mat-form-field appearance="outline"
                                (click)="onClickSelect(entry)"
                                *ngIf="entry.type === 'autoComplete'"
                                class="filter-form-container-field">
                    <mat-label>{{entry.placeholder | translate}}
                        {{multiselect.value?.length ? " - " + multiselect.value.length + " " + ("OPTIONS_SELECTED" |
                        translate) :
                        ''}}</mat-label>
                    <mat-select (openedChange)="onOpenSelect($event, entry)"
                                [formControlName]="entry.name"
                                multiple
                                #multiselect
                                (selectionChange)="selected(entry, $event)"
                                matTooltipClass="multiline-tooltip"
                                [matTooltip]="multiselect.value?.map ? (multiselect.value | extractPropertyPipe:'value':entry.translationRequired | join:'') : ''"
                                [compareWith]="selectOptionCompare">
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                                   [formControl]="searchFieldControls.controls[entry.name]"
                                                   [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate"
                                                   [searching]="filterAutocompleteLoaders[entry.name]">
                            </ngx-mat-select-search>
                        </mat-option>
                        <div [appHidden]="filterAutocompleteLoaders[entry.name]">
                            <mat-option *ngFor="let option of (filteredDataForAutocomplete[entry.name] | async)"
                                        [value]="option">
                                {{transformOption(option, entry)}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
                <!-- Date Picker fields -->
                <div *ngIf="entry.type === 'datePicker'">

                    <mat-form-field appearance="outline"
                                    class="filter-form-container-field">
                        <mat-label>{{'RANGE'| translate}} {{entry.placeholder | translate}}</mat-label>
                        <mat-select [(ngModel)]="rangeControl"
                                    (ngModelChange)="setRange($event, entry)"
                                    [ngModelOptions]="{standalone: true}">
                            <mat-option [value]="range.value"
                                        *ngFor="let range of ranges">
                                {{range.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="filter-form-container">
                        <mat-form-field appearance="outline"
                                        [ngClass]="{'filter-form-container-field-hidden':!displayCustomRange}"
                                        class="filter-form-container-field">
                            <mat-date-range-input [rangePicker]="picker"
                                                  [formGroup]="datesFilterForm[entry.name]">
                                <input matStartDate
                                       [formControl]="datesFilterForm[entry.name].controls.start"
                                       (dateChange)="onDatePickerChanges(entry)"
                                       placeholder="{{'START_DATE' | translate}}">
                                <input matEndDate
                                       [formControl]="datesFilterForm[entry.name].controls.end"
                                       (dateChange)="onDatePickerChanges(entry)"
                                       placeholder="{{'END_DATE' | translate}}">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix
                                                   [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
        </div>
    </form>
</div>
