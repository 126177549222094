<div mat-dialog-title>
    <span>{{"REQUEST_RIGHT_TITLE" | translate}}</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<form [formGroup]="requestForm"
      (submit)="onClickConfirm()">

    <div mat-dialog-content>
        <p>{{ "DIALOG_ASK_ROLE" | translate:{familyName: familyName} }}</p>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'REQUEST_RIGHT_SELECT_MANAGER' | translate }}</mat-label>
            <mat-select formControlName="manager">
                <mat-option>
                    <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                            [formControl]="searchManagerForm"
                                            [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                    <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                </mat-option>
                <mat-option *ngFor="let manager of filteredManagers$
                                    | async
                                    | sort:false:'userId'"
                            [matTooltip]="manager.email"
                            matTooltipPosition="right"
                            [value]="manager.id">
                    {{manager.userId}} - {{manager.email}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="requestForm.controls.manager.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_SELECTION" | translate }}
            </mat-error>
            <mat-error *ngIf="requestForm.controls.manager.errors?.invalid">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "INVALID_SELECTION" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'MANAGE_RIGHT_SELECT_ROLE' | translate }}</mat-label>
            <mat-select formControlName="role">
                <mat-option *ngFor="let role of roles"
                            [value]="role">
                    {{ role | translate }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="requestForm.controls.role.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_SELECTION" | translate }}
            </mat-error>
            <mat-error *ngIf="requestForm.controls.role.errors?.invalid">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "INVALID_SELECTION" | translate }}
            </mat-error>
        </mat-form-field>

    </div>

    <div mat-dialog-actions>
        <button mat-dialog-close
                mat-stroked-button
                type="button"
                color="primary">
            {{"CANCEL" | translate}}
        </button>
        <button type="submit"
                [disabled]="!requestForm.valid"
                mat-flat-button
                color="primary">
            {{"DIALOG_VALIDATE_OPTION" | translate}}
        </button>
    </div>

</form>
